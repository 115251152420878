<template>
    <div class="container-fluid">
        <div v-if="isLoading" class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center mt-4 pt-4">
                <font-awesome-icon class="blue-text" size="4x" icon="spinner" pulse/>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <template v-if="!isLoading">
            <div class="row">
                <div class="col">
                    <h1 v-if="insurance.insuranceOnFile" class="header">Is this the correct <b>{{ insuranceType }}</b> insurance information?</h1>
                    <h1 v-if="!insurance.insuranceOnFile" class="header">Do you have <b>{{ insuranceType }}</b> insurance for this visit?</h1>
                    <h2 class="subheader"></h2>
                </div>
            </div>
            <div v-if="generalErrorMessage" class="row">
                <div class="col-xl-3"></div>
                <div class="col">
                    <div class="alert alert-danger" role="alert">
                        {{ generalErrorMessage }}
                    </div>
                </div>
                <div class="col-xl-3"></div>
            </div>
            <div v-if="insurance.insuranceOnFile" class="row">
                <div class="col-xl-3"></div>
                <div class="col">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Insured First Name">
                                <b-form-input readonly size="lg" v-model="insurance.insuredFirstName"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Insured Last Name">
                                <b-form-input readonly size="lg" v-model="insurance.insuredLastName"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3"></div>
            </div>
            <div v-if="insurance.insuranceOnFile" class="row">
                <div class="col-xl-3"></div>
                <div class="col">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Insurance Company">
                                <b-form-input readonly size="lg" v-model="insurance.insuranceCompany"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Policy Number">
                                <b-form-input readonly size="lg" v-model="insurance.policyNumber"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3"></div>
            </div>
            <div v-if="insurance.insuranceOnFile" class="form-row mt-2">
                <div class="col-xl-3"></div>
                <div class="col">
                  <div class="form-row">
                    <div v-if="!insurance.appendOnlyInsurance" class="col-md-4 mt-2 text-center">
                        <button type="submit" @click.prevent="confirm()" class="btn btn-primary btn-lg mx-auto insurance-button"><font-awesome-icon class="mr-2" v-show="confirmSaving" icon="spinner" pulse/>This is correct</button>
                    </div>
                    <div v-if="!insurance.appendOnlyInsurance" class="col-md-4 mt-2 text-center">
                        <button type="submit" @click.prevent="update()" class="btn btn-primary btn-lg mx-auto insurance-button"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Let me update it</button>
                    </div>
                    <div v-if="!insurance.appendOnlyInsurance" class="col-md-4 mt-2 text-center">
                        <button type="submit" @click.prevent="noInsurance()" class="btn btn-primary btn-lg mx-auto insurance-button"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>I don't have insurance</button>
                    </div>
                    <div v-if="insurance.appendOnlyInsurance" class="col-md-6 mt-2 text-center">
                        <button type="submit" @click.prevent="confirm()" class="btn btn-primary btn-lg mx-auto insurance-button"><font-awesome-icon class="mr-2" v-show="confirmSaving" icon="spinner" pulse/>This is correct</button>
                    </div>
                    <div v-if="insurance.appendOnlyInsurance" class="col-md-6 mt-2 text-center">
                        <button type="submit" @click.prevent="skipInsurance()" class="btn btn-primary btn-lg mx-auto insurance-button"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>See front desk</button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3"></div>
            </div>
            <div v-if="!insurance.insuranceOnFile" class="row">
                <div class="col-xl-3"></div>
                <div class="col-md-6">
                    <multiselect-list :options="options" :is-selected="isOptionSelected" @optionSelected="optionSelected"></multiselect-list>
                </div>
                <div class="col-xl-3"></div>
            </div>
            <div v-if="!insurance.insuranceOnFile" class="form-row mt-2">
                <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </template>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import { UAParser } from 'ua-parser-js'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Insurance',

    components: { MultiselectList, BFormGroup, BFormInput },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      priority: {
        type: String,
        default: 'primary'
      }
    },

    data () {
      return {
        insurance: {
          insuranceOnFile: false,
          insuranceImageOnFile: false,
          policyNumber: ''
        },
        hasInsurance: null,
        isSaving: false,
        isLoading: false,
        confirmSaving: false,
        options: [
          {
            id: 1,
            name: 'Yes',
            value: true
          },
          {
            id: 2,
            name: 'No',
            value: false
          }
        ]
      }
    },

    computed: {
      insuranceType () {
        return this.$store.insurance.insurancePriority
      }
    },

    watch: {
      priority (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.insurance.insurancePriority = newValue
          this.loadData()
        }
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.insurance.insuranceOnFile && (this.hasInsurance !== true && this.hasInsurance !== false)) {
          this.addErrorForField('exception', 'You must select an option')
        }

        return !this.hasErrors()
      },

      loadData () {
        this.isLoading = true
        const prio = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)

        api.get('/Patient/Insurance?insurancePriority=' + prio).then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.insurance = response.data
          this.insurance.insurancePriority = this.$store.mapInsurancePriorityName(this.insurance.insurancePriority)
          if (this.insurance && this.insurance.insuranceIsVerified) {
            this.trackEvent(this.$options.name, 'InsuranceAlreadyVerified', prio)
          }

          if (this.$store.insurance.insurancePriority === 'secondary' && this.insurance.insuranceOnFile === false && this.insurance.appendOnlyInsurance === true) {
            // if secondary and not on file, and appendonly == true, just continue on
            this.$store.setModuleStatus('Insurance', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }

          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading insurance: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      isOptionSelected (option) {
        return option.value === this.hasInsurance
      },

      optionSelected (option) {
        this.hasInsurance = option.value
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        if (!this.insurance.insuranceOnFile) {
          if (this.hasInsurance === false) {
            if (this.$store.insurance.insurancePriority === 'primary') {
              api.put('/Patient/Insurance/SelfPay').then(response => {
                this.trackEvent(this.$options.name, 'SelfPay', 'ResponseTime', response.config.requestDuration)
                this.$store.setModuleStatus('Insurance', true, () => {
                  this.isSaving = false
                  this.$router.push(this.$store.nextRoute())
                })
              }).catch(error => {
                this.isSaving = false
                if (!this.$store.canHandleNetworkError(error)) {
                  this.$store.logErrorResponse(this.$options.name, 'SelfPayError', error)
                  this.setFromApiErrorResponse(error)
                }
              })
            } else {
              const prio = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)
              api.delete('/Patient/Insurance?insurancePriority=' + prio).then(response => {
                this.trackEvent(this.$options.name, 'Delete', 'ResponseTime', response.config.requestDuration)
                if (this.$store.insuranceBenefits && this.$store.insuranceBenefits.insuranceEligibilityStatus === 'Verified') {
                  this.$router.push({ name: 'benefits' })
                } else {
                  this.$store.setModuleStatus('Insurance', true, () => {
                    this.isSaving = false
                    this.$router.push(this.$store.nextRoute())
                  })
                }
              }).catch(error => {
                this.isSaving = false
                if (!this.$store.canHandleNetworkError(error)) {
                  this.$store.logErrorResponse(this.$options.name, 'DeleteError', error)
                  this.setFromApiErrorResponse(error)
                }
              })
            }
          } else {
            if (this.canCaptureCards()) {
              this.trackEvent(this.$options.name, 'Detection', 'MobileDeviceDetected')
              this.trackEvent(this.$options.name, 'CanCaptureCards')
              this.$router.push({ name: 'camera-access' })
              this.isSaving = false
            } else {
              this.trackEvent(this.$options.name, 'Detection', 'DesktopDetected')
              this.trackEvent(this.$options.name, 'CanNotCaptureCards')
              this.$router.push({ name: 'upload-file' })
              this.isSaving = false
            }
          }
        } else {
          if (this.canCaptureCards()) {
            this.trackEvent(this.$options.name, 'Detection', 'MobileDeviceDetected')
            this.trackEvent(this.$options.name, 'CanCaptureCards')
            this.$router.push({ name: 'camera-access' })
            this.isSaving = false
          } else {
            this.trackEvent(this.$options.name, 'Detection', 'DesktopDetected')
            this.trackEvent(this.$options.name, 'CanNotCaptureCards')
            this.$router.push({ name: 'upload-file' })
            this.isSaving = false
          }
        }
      },

      canCaptureCards () {
        const parser = new UAParser()
        const deviceType = parser.getResult().device.type
        if (['mobile', 'tablet'].includes(deviceType)) {
          return true
        }

        this.trackEvent(this.$options.name, 'CameraDetection', 'SkippingDeviceType', deviceType)
        return false
      },

      confirm () {
        this.confirmSaving = true
        this.trackEvent(this.$options.name, 'Insurance', 'Confirmed')

        if (!this.insurance.insuranceImageOnFile && this.canCaptureCards()) {
          this.trackEvent(this.$options.name, 'InsuranceConfirmedGetMissingImages')
          this.$router.push({ name: 'camera-access', params: { captureOnly: true } })
        } else if (!this.insurance.insuranceImageOnFile && !this.canCaptureCards()) {
          this.trackEvent(this.$options.name, 'InsuranceConfirmedGetMissingImages')
          this.$router.push({ name: 'upload-file', params: { captureOnly: true } })
        } else {
          const prio = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)
          this.trackEvent(this.$options.name, 'InsuranceConfirmed', prio)

          api.get('/Patient/InsuranceBenefits?insurancePriority=' + prio).then(response => {
            if (response.data.insuranceEligibilityStatus === 'Verified') {
              this.trackEvent(this.$options.name, 'InsuranceVerified', prio)
              this.$store.insuranceBenefits = response.data
              this.confirmSaving = false
              if (this.$store.insurance.insurancePriority === 'primary') {
                this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
              } else {
                // If confirming secondary then no benefits
                this.$store.setModuleStatus('Insurance', true, () => {
                  this.confirmSaving = false
                  this.$router.push(this.$store.nextRoute())
                })
              }
            } else {
              this.trackEvent(this.$options.name, 'InsuranceNotVerified', prio)
              if (this.$store.insurance.insurancePriority === 'primary') {
                this.confirmSaving = false
                this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
              } else {
                this.$store.setModuleStatus('Insurance', true, () => {
                  this.confirmSaving = false
                  this.$router.push(this.$store.nextRoute())
                })
              }
            }
          }).catch(error => {
            this.confirmSaving = false
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error loading benefits: ' + error.message, error)
              if (error.response && error.response.data) {
                this.setErrorsFromResponse(error.response.data)
              } else if (error.message) {
                this.addErrorForField('exception', error.message)
              }
            }
          })
        }
      },

      update () {
        this.trackEvent(this.$options.name, 'Insurance', 'Update')
        if (this.canCaptureCards()) {
          this.$router.push({ name: 'camera-access' })
        } else {
          this.$router.push({ name: 'upload-file' })
        }
      },

      skipInsurance () {
        this.trackEvent(this.$options.name, 'PatientSkippedInsurance')
        this.$store.setModuleStatus('Insurance', true, () => {
          this.isSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      noInsurance () {
        this.trackEvent(this.$options.name, 'PatientSelectedNoInsurance')

        this.insurance.insuranceOnFile = false
        this.hasInsurance = false
        this.submit()
      }
    },

    created () {
      this.$store.insurance.insurancePriority = this.priority
      this.loadData()
    },

    beforeRouteUpdate (to, from, next) {
      this.$store.insurance.insurancePriority = to.params.priority
      this.loadData()
      next()
    }
  }
</script>

<style scoped>

    .insurance-button {
        width: 150px;
    }

    @media (max-width: 450px) {
        .insurance-button {
            width: 250px;
        }
    }
</style>
