const OrthoMixin = {
  data () {
    return {
      orthoReasonForVisit: {
        bodyPartCodes: []
      },
      kneeCodes: ['LKNEE', 'RKNEE', 'BIKNEE'],
      shoulderCodes: ['LSHOU', 'RSHOU', 'BISHOU'],
      footAndAnkleCodes: ['LANK', 'RANK', 'BIANKLE', 'LBTO', 'LITO', 'LMTO', 'LFTO', 'LLTO', 'RBTO', 'RITO', 'RMTO', 'RFTO', 'RLTO', 'LHEEL', 'RHEEL', 'LMFOOT', 'RMFOOT', 'RIGHTFOOT', 'LEFTFOOT'],
      backCodes: ['MBACK', 'LBACK', 'BISHOU']
    }
  },

  computed: {
    primaryBodyPartName () {
      if (this.orthoReasonForVisit && this.orthoReasonForVisit.primaryBodyPart && this.orthoReasonForVisit.primaryBodyPart.name) {
        return this.orthoReasonForVisit.primaryBodyPart.name
      } else if (this.$store.primaryBodyPart && this.$store.primaryBodyPart.name) {
        return this.$store.primaryBodyPart.name
      }

      return ''
    },

    footOrAnkleSelected () {
      const code = this.orthoReasonForVisit.bodyPartCodes.filter((code) => {
        return this.footAndAnkleCodes.includes(code)
      }).shift()

      if (code) {
        return true
      }

      return false
    },

    backSelected () {
      const backCode = this.orthoReasonForVisit.bodyPartCodes.filter((code) => {
        return this.backCodes.includes(code)
      }).shift()

      if (backCode) {
        return true
      }

      return false
    },

    kneeSelected () {
      const kneeCode = this.orthoReasonForVisit.bodyPartCodes.filter((code) => {
        return this.kneeCodes.includes(code)
      }).shift()

      if (kneeCode) {
        return true
      }

      return false
    },

    shoulderSelected () {
      const shoulderCode = this.orthoReasonForVisit.bodyPartCodes.filter((code) => {
        return this.shoulderCodes.includes(code)
      }).shift()

      if (shoulderCode) {
        return true
      }

      return false
    }
  },

  methods: {
    nextOrthoRouteName () {
      if (this.$route.name === 'ortho-shoulder-difficulty-reaching') {
        this.$store.orthoModule.shoulderCompleted = true
      } else if (this.$route.name === 'ortho-knee-pain') {
        this.$store.orthoModule.kneeCompleted = true
      } else if (this.$route.name === 'ortho-recurring') {
        this.$store.orthoModule.recurringCompleted = true
      } else if (this.$route.name === 'ortho-back-lose-control') {
        this.$store.orthoModule.backCompleted = true
      } else if (this.$route.name === 'ortho-foot-size') {
        this.$store.orthoModule.footAnkleCompleted = true
      }

      if ((this.shoulderSelected || this.kneeSelected) && this.$store.orthoModule.recurringCompleted === false) {
        // Ask recurring injury question
        return 'ortho-recurring'
      } else if (this.shoulderSelected && this.$store.orthoModule.shoulderCompleted === false) {
        // Go down shoulder path
        return 'ortho-shoulder-neck'
      } else if (this.kneeSelected && this.$store.orthoModule.kneeCompleted === false) {
        // Go down knee path
        return 'ortho-knee'
      } else if (this.backSelected && this.$store.orthoModule.backCompleted === false) {
        // Go down back path
        return 'ortho-back-leg-pain'
      } else if (this.footOrAnkleSelected && this.$store.orthoModule.footAnkleCompleted === false) {
        // Go down foot/ankle path
        return 'ortho-foot-orthotics'
      } else {
        return 'ortho-prior-treatments'
      }
    }
  }
}

export default OrthoMixin
