<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What medications are you currently taking?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
        <form-and-dosage-modal
                :name="selectedMedication.name"
                :selected-dosage="selectedMedication.dosage"
                :selected-form="selectedMedication.form"
                :selected-frequency-id="selectedMedication.medicationFrequencyId"
                :show-remove="true"
                :organization-patient-medication-id="selectedMedication.organizationPatientMedicationId"
                :header="modalHeader"
                :forms="forms" @save="saveMedication"
                @remove="removeMedication"
                :show-save-spinner="isSaving"
                :show-remove-spinner="isRemoving"
                :visible="showFormAndDosage"
                @update:visible="visible => showFormAndDosage = visible">
        </form-and-dosage-modal>
        <list-modal :header-image="require('../../assets/medications.svg')" :header="confirmModalHeader" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="confirmOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import FormAndDosageModal from '../../components/FormAndDosageModal'
  import ListModal from '@/components/ListModal'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Medications',

    components: { MultiselectList, FormAndDosageModal, ListModal },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        medicationResponse: [],
        isSaving: false,
        isRemoving: false,
        selectedMedication: {
          organizationPatientMedicationId: 0,
          name: ''
        },
        showFormAndDosage: false,
        forms: [],
        showListModal: false,
        confirmOptions: [
          {
            id: 1,
            name: 'No medications',
            selected: false
          },
          {
            id: 2,
            name: 'Let me add medications',
            selected: false
          }
        ],
        confirmModalHeader: 'Please confirm the patient is not taking any medications.'
      }
    },

    computed: {
      options () {
        let options = []
        if (this.medicationResponse.medications && this.medicationResponse.medications.length > 0) {
          options = this.medicationResponse.medications.map((option) => {
            return {
              id: option.organizationPatientMedicationId,
              type: 'edit',
              name: option.activeIngredient ? option.name + ' (' + option.activeIngredient + ')' : option.name,
              selected: true
            }
          })
        }

        options.push({
          id: 0,
          type: 'add',
          name: 'Add Medication'
        })
        return options
      },

      modalHeader () {
        if (this.selectedMedication && this.selectedMedication.name) {
          if (this.selectedMedication.activeIngredient) {
            return 'Please tell us about ' + this.selectedMedication.name + ' (' + this.selectedMedication.activeIngredient + ')'
          } else {
            return 'Please tell us about ' + this.selectedMedication.name
          }
        }

        return 'Please tell us about this medication'
      },

      selectedOptions () {
        return this.medicationResponse.medications
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Medications').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.medicationResponse = response.data
          // Stash these away in the store to be used by the forms and dosage modal
          this.$store.frequencyOptions = this.medicationResponse.frequencyOptions
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      optionSelected (item) {
        if (item.type === 'add') {
          this.$router.push('medication-search')
        } else {
          const medication = this.medicationResponse.medications.filter((aMedication) => {
            return aMedication.organizationPatientMedicationId === item.id
          }).shift()

          if (medication) {
            this.selectedMedication = medication
            api.get('/Patient/Medications/FormsAndDosages?name=' + medication.name).then(response => {
              this.trackEvent(this.$options.name, 'Load', 'FormsAndDosageResponseTime', response.config.requestDuration)
              if (response.data.forms && response.data.forms.length > 0) {
                this.forms = response.data.forms
              } else {
                this.forms = [{ name: 'Other', dosages: [{ name: 'Other' }] }]
              }
              this.showFormAndDosage = true

            }).catch(error => {
              if (!this.$store.canHandleNetworkError(error)) {
                this.$store.logErrorResponse(this.$options.name, 'FormsAndDosageError', error)
                this.setFromApiErrorResponse(error)
              }
            }).then(() => {
              this.isSaving = false
            })
          }
        }
      },

      saveMedication (medication) {
        this.isSaving = true

        if (!medication.organizationPatientMedicationId) {
          delete medication.organizationPatientMedicationId
        }

        api.put('/Patient/Medication', medication).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.loadData()
          this.showFormAndDosage = false
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      removeMedication (medication) {
        this.isRemoving = true

        if (!medication.organizationPatientMedicationId) {
          this.$log.debug('Not removing medication due to missing id')
          return
        }

        api.delete('/Patient/Medication', { data: medication }).then(response => {
          this.trackEvent(this.$options.name, 'Delete', 'ResponseTime', response.config.requestDuration)
          this.loadData()
          this.showFormAndDosage = false
        }).catch(error => {
          this.$store.logErrorResponse(this.$options.name, 'DeleteError', error)
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'DeleteError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isRemoving = false
        })
      },

      modalClosed () {
        const confirmed = this.confirmOptions[0].selected === true
        if (confirmed) {
          this.submit(true)
        }
      },

      modalSelection (item) {
        for (const anOption of this.confirmOptions) {
          if (anOption.id === item.id) {
            anOption.selected = true
          } else {
            anOption.selected = false
          }
        }
      },

      submit (confirmed = false) {
        if (this.validate() !== true) {
          return
        }

        if (this.selectedOptions.length === 0 && confirmed === false) {
          this.showListModal = true
          return
        }

        this.isSaving = true
        this.$store.setModuleStatus('Medications', true, () => {
          this.$router.push(this.$store.nextRoute())
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
