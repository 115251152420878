<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Who performed the {{ this.priorTreatmentModel.name }}?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group label="Provider Name" :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input @input="searchInput" autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm && hasChanged" @click.prevent="selectSearchTerm(searchTerm)" class="result">
                        <h1 class="text-center">{{ searchTerm }}</h1>
                    </div>
                    <div @click.prevent="selectProvider(provider)" v-for="(provider,index) in searchResults" :key="index" class="result">
                        <h1 class="text-center">{{ provider.firstName + " " + provider.lastName + (provider.suffix ?  provider.suffix : '') }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div v-if="!hasChanged" class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoPriorTreatmentProvider',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    props: {
      priorTreatmentOption: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        searchResults: {},
        displayName: '',
        searchTerm: '',
        priorTreatmentModel: {
          orthoPriorTreatmentId: 0,
          name: '',
          isSelected: true,
          orthoPriorTreatmentPeriodId: 0,
          hasProviderSearch: false,
          providerId: 0,
          provider: {}
        },
        hasChanged: false,
        isSaving: false
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    methods: {
      initializeModel () {
        this.priorTreatmentModel.orthoPriorTreatmentId = this.priorTreatmentOption.orthoPriorTreatmentId
        this.priorTreatmentModel.name = this.priorTreatmentOption.name
        this.priorTreatmentModel.providerId = this.priorTreatmentOption.providerId
        this.priorTreatmentModel.provider = this.priorTreatmentOption.provider
        if (this.priorTreatmentModel.provider && this.priorTreatmentModel.provider.firstName && this.priorTreatmentModel.provider.lastName) {
          this.displayName = this.priorTreatmentModel.provider.firstName + ' ' + this.priorTreatmentModel.provider.lastName
          this.searchTerm = this.displayName
        }
        this.priorTreatmentModel.orthoPriorTreatmentPeriodId = this.priorTreatmentOption.orthoPriorTreatmentPeriodId
        this.priorTreatmentModel.hasProviderSearch = this.priorTreatmentOption.hasProviderSearch
      },

      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      searchInput () {
        this.hasChanged = true
      },

      performSearch () {
        if (this.searchTerm === this.displayName || this.isLoading || !this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/ProviderSearch?query=' + this.searchTerm).then(response => {
          this.trackEvent(this.$options.name, 'ProviderSearch', 'ResponseTime', response.config.requestDuration)
          this.searchResults = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SearchError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      selectSearchTerm (searchTerm) {
        this.submit()
      },

      selectProvider (provider) {
        this.priorTreatmentModel.provider = provider
        this.submit()
      },

      submit () {
        this.isSaving = true

        if (!this.hasChanged) {
          this.$router.push('/ortho-prior-treatments')
          return
        }

        api.put('/Patient/OrthoPriorTreatment', this.priorTreatmentModel).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.priorTreatmentModel = response.data
          this.$router.push('/ortho-prior-treatments')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.initializeModel()
    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,108,198,1.0);
        font-weight: bold;
        padding: .25em;
    }

    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgb(255, 79, 0);
        }
    }
</style>
