<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header" v-html="headerCopy"></h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Patient First Name" :state="stateForField('firstName')" :invalid-feedback="errorForField('firstName')">
                                <b-form-input autocomplete="given-name" size="lg" :state="stateForField('firstName')" v-model="patientSearch.firstName" placeholder="First Name"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Patient Last Name" :state="stateForField('lastName')" :invalid-feedback="errorForField('lastName')">
                                <b-form-input autocommplete="family-name" size="lg" :state="stateForField('lastName')" v-model="patientSearch.lastName" placeholder="Last Name"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group id="birthDateGroup" label="Birth Date (MM/DD/YYYY)" :state="stateForField('birthDate')" :invalid-feedback="errorForField('birthDate')">
                                <date-input :current-date="patientSearch.birthDate" @valid="birthDateUpdated" @invalid="birthDateInvalid" @change="birthDateChanged"/>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="appointmentDateGroup" label="Appointment Date (MM/DD/YYYY)" :state="stateForField('appointmentDate')" :invalid-feedback="errorForField('appointmentDate')">
                                <date-input :current-date="patientSearch.appointmentDate" @valid="appointmentUpdated" @invalid="appointmentInvalid" @change="appointmentChanged"/>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center">
                            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../services/api'
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import DateInput from '../components/DateInput'
  import moment from 'moment'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PatientSearch',

    components: { BFormGroup, BFormInput, DateInput },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      token: {
        type: String,
        required: false
      },
      next: {
        type: String,
        required: false
      }
    },

    data () {
      return {
        orgName: '',
        patientSearch: {
          firstName: '',
          lastName: '',
          birthDate: '',
          appointmentDate: '',
          zip: ''
        },
        isSaving: false
      }
    },

    metaInfo () {
      return {
        meta: [{
          name: 'og:image',
          content: this.$store.apiBaseUrl + '/WebClient/ShareImage/' + this.token
        }]
      }
    },

    watch: {
      token (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadPatientInfo()
        }
      }
    },

    computed: {
      headerCopy () {
        if (this.orgName) {
          return 'Let\'s find your appointment at <b>' + this.orgName + '</b>.'
        }

        return 'Let\'s find your appointment.'
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.patientSearch.firstName) {
          this.addErrorForField('firstName', 'First name is required.')
        }

        if (!this.patientSearch.lastName) {
          this.addErrorForField('lastName', 'Last name is required.')
        }

        if (!this.patientSearch.birthDate) {
          this.addErrorForField('birthDate', 'Birth date is required.')
        } else {
          if (this.patientSearch.birthDate >= Date.now()) {
            this.addErrorForField('birthDate', 'Birth date must be in the past')
          }
        }

        if (!this.patientSearch.appointmentDate) {
          this.addErrorForField('appointmentDate', 'Appointment date is required.')
        } else {
          if (this.patientSearch.appointmentDate < moment().startOf('day')) {
            this.addErrorForField('appointmentDate', 'Appointment date must not be in the past')
          }
        }

        return !this.hasErrors()
      },

      birthDateUpdated (birthDate) {
        this.clearFieldError('birthDate')
        this.patientSearch.birthDate = birthDate
      },

      birthDateInvalid (birthDate) {
        this.addErrorForField('birthDate', 'Invalid birth date')
      },

      birthDateChanged () {
        this.clearFieldError('birthDate')
      },

      appointmentUpdated (birthDate) {
        this.clearFieldError('appointmentDate')
        this.patientSearch.appointmentDate = birthDate
      },

      appointmentInvalid (birthDate) {
        this.addErrorForField('appointmentDate', 'Invalid appointment date')
      },

      appointmentChanged () {
        this.clearFieldError('appointmentDate')
      },

      loadPatientInfo () {
        api.get('/WebClient/PatientInfo?token=' + this.token).then(response => {
          this.trackEvent('PatientSearch', 'Load', 'ResponseTime', response.config.requestDuration)
          if (response.data.firstName && response.data.lastName) {
            this.patientSearch.firstName = response.data.firstName
            this.patientSearch.lastName = response.data.lastName
            this.patientSearch.appointmentDate = response.data.appointmentDate
            this.patientSearch.zip = response.data.zip
          }
          this.orgName = response.data.orgName
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            // Ignore this error
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.post('/WebClient/PatientSearch', this.patientSearch).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)

          this.$store.startAuthorization(response.data)
          this.$store.patientZipCode = this.patientSearch.zip
          this.$store.patientBirthDate = this.patientSearch.birthDate
          this.$router.push({ name: 'authorize', params: { next: this.next } })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for patient: ' + error.message, error)
            this.setFromApiErrorResponse(error)
            this.trackEvent(this.$options.name, 'SaveError', error)
            if (this.hasError('noVisitFound')) {
              this.trackEvent(this.$options.name, 'VisitNotFound', error)
              this.addErrorForField('exception', this.errorForField('noVisitFound'))
              this.clearFieldError('noVisitFound')
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      if (this.$route.params.token) {
        this.token = this.$route.params.token
      }
      if (this.$route.params.next) {
        this.next = this.$route.params.next
      }
      if (this.token) {
        this.loadPatientInfo()
      }
    },

    beforeRouteUpdate (to, from, next) {
      if (this.$route.params.token) {
        this.token = this.$route.params.token
      }
      if (this.token) {
        this.loadPatientInfo()
      }
    }
  }
</script>

<style scoped>

</style>
