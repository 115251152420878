<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Who is your Emergency Contact?</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <form @submit.prevent="submit()">
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group label="Name" :state="stateForField('emergencyContactName')"
                            :invalid-feedback="errorForField('emergencyContactName')">
                <b-form-input @input="clearFieldError('emergencyContactName')" size="lg"
                              :state="stateForField('emergencyContactName')"
                              v-model="demographics.emergencyContactName"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Phone Number" :state="stateForField('emergencyContactPhone')"
                            :invalid-feedback="errorForField('emergencyContactPhone')">
                <b-form-input pattern="[0-9]*" @input="numberInput" size="lg"
                              :state="stateForField('emergencyContactPhone')"
                              :value="emergencyContactPhoneDisplay"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group label="Relationship" :state="stateForField('emergencyContactTypeId')"
                            :invalid-feedback="errorForField('emergencyContactTypeId')">
                <b-form-select size="lg" @input="clearFieldError('emergencyContactTypeId')"
                               v-model="demographics.emergencyContactTypeId"
                               :state="stateForField('emergencyContactTypeId')"
                               :options="contactTypeOptions"></b-form-select>
              </b-form-group>
            </div>
          </div>
            <div class="col-md-6">
            </div>
            <div class="form-row">
              <div class="col-md-6">
              <b-form-group label="Protected Information in Voicemail" :state="stateForField('voicemail')" :invalid-feedback="errorForField('voicemail')">
                <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.voicemailAllowed" name="check-button" switch>
                  <span v-if="hipaaContact.voicemailAllowed">
                    Allowed
                  </span>
                  <span v-else>
                    Not Allowed
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </div>
              <div class="col-md-6">
                <b-form-group label="Appointment Information" :state="stateForField('appointment')"
                              :invalid-feedback="errorForField('appointment')">
                  <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.appointment" name="check-button" switch>
                    <span v-if="hipaaContact.appointment">
                      Allowed
                    </span>
                    <span v-else>
                      Not Allowed
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group label="Billing Information" :state="stateForField('billing')"
                            :invalid-feedback="errorForField('billing')">
                <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.billing" name="check-button" switch>
                  <span v-if="hipaaContact.billing">
                  Allowed
                  </span>
                  <span v-else>
                  Not Allowed
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Treatment Information" :state="stateForField('treatment')"
                            :invalid-feedback="errorForField('treatment')">
                <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.treatment" name="check-button" switch>
                  <span v-if="hipaaContact.treatment !== null">
                    Allowed
                  </span>
                  <span v-else>
                    Not Allowed
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <button type="submit" @click.prevent="skip()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%">
              <font-awesome-icon class="mr-2" v-show="isSkipping" icon="spinner" pulse/>
              Skip
            </button>
            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%">
              <font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>
              Next
            </button>
          </div>
        </form>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormSelect, BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue/src/index'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'DemographicsEmergencyContact',

    components: { BFormGroup, BFormInput, BFormSelect, BFormCheckbox },

    mixins: [ErrorsMixin, OptionsMixin, DemographicsMixin, AnalyticsMixin],

    data () {
      return {
        demographics: {
          emergencyContactName: '',
          emergencyContactArea: '',
          emergencyContactExchange: '',
          emergencyContactNumber: ''
        },
        hipaaContact: {
          voicemailAllowed: true,
          treatment: true,
          billing: true,
          appointment: true
        },
        isSaving: false,
        isSkipping: false
      }
    },

    computed: {
      contactTypeOptions () {
        return this.optionsFromArray(this.demographics.contactTypeOptions, 'contactTypeId')
      },

      emergencyContactPhoneDisplay () {
        if (this.demographics.emergencyContactArea) {
          if (this.demographics.emergencyContactNumber.length > 0) {
            return '(' + this.demographics.emergencyContactArea + ') ' + this.demographics.emergencyContactExchange + '-' + this.demographics.emergencyContactNumber
          } else if (this.demographics.emergencyContactExchange.length > 0) {
            return '(' + this.demographics.emergencyContactArea + ') ' + this.demographics.emergencyContactExchange
          } else if (this.demographics.emergencyContactArea.length > 0) {
            return '(' + this.demographics.emergencyContactArea
          }
        }
        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.demographics.emergencyContactName) {
          this.addErrorForField('emergencyContactName', 'Emergency contact name is required.')
        }

        if (!this.demographics.emergencyContactArea || !this.demographics.emergencyContactExchange || !this.demographics.emergencyContactNumber) {
          this.addErrorForField('emergencyContactPhone', 'Emergency contact phone is required.')
        }
        if (!this.demographics.emergencyContactTypeId) {
          this.addErrorForField('emergencyContactTypeId', 'Emergency relationship is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Demographics').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.demographics = response.data
          if (response.data.hipaaContact !== null) {
            this.hipaaContact = response.data.hipaaContact
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for patient: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      numberInput (value) {
        this.clearFieldError('emergencyContactPhone')
        const newNumber = value
        let cleanNumber = newNumber.replace(/\D/g, '')
        if (cleanNumber.length > 0) {
          if (parseInt(cleanNumber[0]) === 1) {
            cleanNumber = cleanNumber.substr(1, cleanNumber.length - 1)
          }

          this.demographics.emergencyContactArea = cleanNumber.substr(0, cleanNumber.length > 3 ? 3 : cleanNumber.length)
          if (cleanNumber.length > 3) {
            this.demographics.emergencyContactExchange = cleanNumber.substr(3, cleanNumber.length > 6 ? 3 : cleanNumber.length - 3)
          } else {
            this.demographics.emergencyContactExchange = ''
          }
          if (cleanNumber.length > 6) {
            this.demographics.emergencyContactNumber = cleanNumber.substr(6, cleanNumber.length < 10 ? cleanNumber.length - 6 : 4)
          } else {
            this.demographics.emergencyContactNumber = ''
          }
        } else {
          this.demographics.emergencyContactArea = ''
          this.demographics.emergencyContactExchange = ''
          this.demographics.emergencyContactNumber = ''
        }

      },

      submit () {
        if (this.validate() !== true) {
          return
        }
        this.demographics.hipaaContact = this.hipaaContact

        this.isSaving = true
        api.put('/Patient/EmergencyContact', this.demographics).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('MarriageStatus', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.$log.error('Error saving demographics: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
          this.isSaving = false
        })
      },

      skip () {
        this.isSkipping = true
        this.trackEvent(this.$options.name, 'Skip')

        this.$store.setModuleStatus('MarriageStatus', true, () => {
          this.isSkipping = false
          this.$router.push(this.$store.nextRoute())
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
