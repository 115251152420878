<template>
    <div class="container-fluid">

        <div class="row">
            <div class="col">
                <h1 class="header">Take photos of your {{insuranceType}} insurance card</h1>
            </div>
        </div>

        <div class="row video-window-clipping" style="background-color: black;">
            <div v-if="!frontImage || !backImage" class="photo-prompts-alignment-container">
                <img class="photo-prompts-alignment-image"  src="../../assets/card-corners.png">
            </div>
            <div v-if="!frontImage || !backImage" class="photo-prompts-card">
                <h3>{{ !frontImage ? "FRONT OF CARD" : "BACK OF CARD" }}</h3>
            </div>
            <div v-if="frontImage && backImage" class="photo-prompts-checkmark">
                <font-awesome-icon icon="check-circle"/>
            </div>
            <web-cam ref="webcam"
                         :device-id="deviceId"
                         width="100%"
                         @error="onError"
                         @cameras="onCameras"
                         />
        </div>

        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col custom-button-center">
                <button v-if="!frontImage || !backImage" type="button" class="btn btn-primary btn-lg mx-auto" @click="onCapture">Capture {{ !frontImage ? "Front" : "Back" }}</button>
                <button v-else type="button" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%" @click="submit"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                <button v-if="!frontImage || !backImage" type="button" class="btn btn-primary btn-lg mx-auto" @click="onSkipInsuranceCapture">Skip</button>
            </div>
            <div class="col-xl-3"></div>
        </div>

        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>

        <div class="row photo-preview-row">
            <div class="photo-preview-container">
                <transition name="preview-animation">
                    <img v-if="frontImage" class="photo-preview-images"  :src="frontImage">
                </transition>
                <img v-if="!frontImage" class="preview-card-image" src="../../assets/insurance-front.svg">
            </div>
            <div class="photo-preview-container">
                <img v-if="!frontImage && !backImage" class="preview-card-image" src="../../assets/insurance-back-grey.png">
                <img v-if="frontImage && !backImage" class="preview-card-image" src="../../assets/insurance-back.svg">
                <transition name="preview-animation">
                    <img v-if="backImage" class="photo-preview-images" :src="backImage">
                </transition>
            </div>
        </div>

        <div v-if="frontImage || backImage" class="row">
            <div class="col-md-12 custom-button-center">
                <div class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%" @click.prevent="clearImages"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Start Over</div>
            </div>
        </div>

    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import WebCam from '../../components/WebCam.vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'CardCapture',

    components: { WebCam },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      captureOnly: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        frontImage: null,
        backImage: null,
        camera: null,
        deviceId: null,
        devices: [],
        isSaving: false,
        isClassificationRunning: false,
        userConfirmed: false,
        visibleHeight: 420,
        captureAttempts: 0
      }
    },

    watch: {
      camera: function (id) {
        this.deviceId = id
      },
      devices: function () {
        const first = this.devices[0]
        if (first) {
          this.camera = first.deviceId
          this.deviceId = first.deviceId
        }
      }
    },

    computed: {
      insuranceType () {
        return this.$store.insurance.insurancePriority
      }
    },

    methods: {
      onCapture () {
        if (!this.frontImage) {
          this.frontImage = this.$refs.webcam.capture()
          if (this.frontImage) {
            this.uploadFrontImage()
            this.captureAttempts = 0
          } else {
            this.trackEvent(this.$options.name, 'NoImageReceivedFromCamera')
          }
        } else {
          this.backImage = this.$refs.webcam.capture()
          if (!this.backImage) {
            this.trackEvent(this.$options.name, 'NoImageReceivedFromCamera')
          }
        }
      },

      onError (error) {
        this.trackEvent(this.$options.name, 'CameraAccessError', error)
        if (this.captureOnly) {
          if (this.$store.insurance.insurancePriority === 'secondary') {
            this.$store.setModuleStatus('Insurance', true, () => {
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
          }
        } else {
          if (this.$store.insurance.insurancePriority === 'secondary') {
            if (this.$store.insuranceBenefits.insuranceEligibilityStatus === 'Verified') {
              this.$store.setModuleStatus('Insurance', true, () => {
                this.$router.push(this.$store.nextRoute())
              })
            } else {
              this.$router.push({ name: 'payor-select' })
            }
          } else {
            if (this.$store.insuranceBenefits.insuranceEligibilityStatus === 'Verified') {
              this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
            } else {
              this.$router.push({ name: 'payor-select' })
            }
          }
        }
      },

      onCameras (cameras) {
        this.devices = cameras
      },

      clearImages () {
        if (!this.isSaving) {
          this.frontImage = null
          this.backImage = null
        }
      },

      uploadFrontImage () {
        const imageFile = new File([this.dataURItoBlob(this.frontImage)], 'insurance-card-front.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)

        const prio = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)

        if (this.captureOnly) {
          // Only upload no classification
          api.put('/Patient/InsuranceUploadRaw?insurancePriority=' + prio + '&visibleHeight=' + this.visibleHeight, multipartForm).then(response => {
            this.trackEvent(this.$options.name, 'UploadFront', 'ResponseTime', response.config.requestDuration)
            this.$store.insurance.insuranceCardFront = response.data.url
            this.frontImage = response.data.url
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.trackEvent(this.$options.name, 'SaveError', 'CardImageNotSaved')
              this.$log.error('Error uploading front card image: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          })
        } else {
          this.isClassificationRunning = true
          api.put('/Patient/InsuranceUploadRawAndClassify?insurancePriority=' + prio + '&visibleHeight=' + this.visibleHeight, multipartForm).then(response => {
            this.trackEvent(this.$options.name, 'UploadAndClassifyFront', 'ResponseTime', response.config.requestDuration)
            this.$store.insuranceBenefits = response.data
            this.$store.insurance.insuranceCardFront = response.data.insuranceFrontPhotoUrl
            this.frontImage = response.data.insuranceFrontPhotoUrl
            this.trackEvent(this.$options.name, 'ClassificationResult', response.data.classifierSuccess)
            if (this.userConfirmed === true) {
              this.continueModule()
            }
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.trackEvent(this.$options.name, 'SaveError', 'CardImageNotSaved')
              this.$log.error('Error uploading front card image: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          }).finally(() => {
            this.isClassificationRunning = false
          })
        }
      },

      uploadBackImage () {
        this.isSaving = true
        const imageFile = new File([this.dataURItoBlob(this.backImage)], 'insurance-card-back.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)

        const prio = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)

        api.put('/Patient/InsuranceUploadRaw?insurancePriority=' + prio + '&visibleHeight=' + this.visibleHeight + '&cardSide=back', multipartForm).then(response => {
          this.trackEvent(this.$options.name, 'UploadBack', 'ResponseTime', response.config.requestDuration)
          this.$store.insurance.insuranceCardBack = response.data.url
          this.backImage = response.data.url
          if (this.isClassificationRunning === false) {
            this.isSaving = false
            this.continueModule()
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'UploadBackError', error)
            this.trackEvent(this.$options.name, 'UploadBackError', 'CardImageNotSaved')
            this.$log.error('Error saving insurance card image: ' + error.message, error)
            this.setFromApiErrorResponse(error)
          }
        })
      },
      onSkipInsuranceCapture () {
        this.trackEvent(this.$options.name, 'SkipInsuranceCapture')
        if (this.captureOnly === false) {
          this.$router.push('/payor-select')
        } else {
          this.$store.setModuleStatus('Insurance', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }
      },
      continueModule () {
        this.$router.push({ name: 'card-capture-verification', params: { captureOnly: this.captureOnly, frontImage: this.frontImage, backImage: this.backImage } })
      },

      dataURItoBlob (dataURI) {
        // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
        // convert base64/URLEncoded data component to raw binary data held in a string

        if (!dataURI) {
          return
        }

        let byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
      },

      submit () {
        this.isSaving = true
        this.userConfirmed = true
        this.uploadBackImage()
      }
    }
  }
</script>

<style scoped>
    h3 {
        font-weight: bold;
        color: #ffffff;
        margin-top: 30px;
    }
    .container-fluid {
        padding-right: 0
    }
    .row {
        margin-right: 0
    }
    .header {
        margin-bottom: 30px;
    }
    .video-window-clipping {
        max-height: 350px;
        max-width: 600px;
        overflow: hidden;
    }
    @media only screen and (min-width: 600px) {
        .video-window-clipping {
            max-height: 350px;
            max-width: 600px;
            overflow: hidden;
            margin: auto;
        }
        .container-fluid {
            padding-left: 0
        }
    }
    .photo-prompts-card {
        position: absolute;
        text-align: center;
        height: 350px;
        width: 100%;
        max-width: 600px;
    }
    .photo-prompts-alignment-container {
        position: absolute;
        text-align: center;
        height: 350px;
        width: 100%;
        max-width: 600px;
        vertical-align: middle;
        display: inline-block;
    }
    .photo-prompts-alignment-image {
        max-width: 95%;
        margin-top: 80px;
        border-radius: 5px;
    }
    @media only screen and (min-width: 401px) {
        .photo-prompts-alignment-image {
            max-width: 80%;
        }
    }
    @media only screen and (min-width: 501px) {
        .photo-prompts-alignment-image {
            max-width: 65%;
        }
    }
    @media only screen and (min-width: 401px) and (orientation: landscape) {
        .photo-prompts-alignment-image {
            max-width: 65%;
        }
    }
    .photo-prompts-checkmark {
        position: absolute;
        width: 100%;
        max-width: 600px;
        text-align: center;
        font-size: 200px;
        color: #176cc6;
        height: 350px;
        line-height: 350px;
    }
    .custom-button-center {
        margin: 10px auto 0;
        display: flex;
    }
    .photo-preview-row {
        max-width: 768px;
        margin: 10px 0 10px -15px !important;
    }
    .photo-preview-container {
        width: 40%;
        margin: auto;
        overflow: hidden;
        height: 125px;
    }
    @media only screen and (min-width: 600px) {
        .photo-preview-row {
            max-width: 768px;
            margin: 10px auto 10px !important;
        }
    }
    @media only screen and (min-width: 401px) {
        .photo-preview-container {
            width: 40%;
            margin: auto;
            overflow: hidden;
            height: auto;
        }
    }
    @media only screen and (min-width: 401px) and (orientation: landscape) {
        .photo-preview-images {
            margin: 0 auto 0;
            max-height: 400px;
            max-width: 100%;
            display: block;
        }
    }
    .photo-preview-images {
        margin: 0 auto 0;
        max-height: 200px;
        max-width: 100%;
        display: block;
    }
    .preview-card-image {
        width: 100%;
        margin-top: 10px;
    }
    .preview-animation-enter {
        opacity: 0
    }
    .preview-animation-enter-active {
        transition: opacity .5s
    }
</style>
