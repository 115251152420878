<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
              <h1 class="header">
                Total
              </h1>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
          <br>
        </div>
        <div class="row">
            <div class="col-centered pb-2">
                <span class="numberCircleOrange">
                  <h1 class="price">
                    ${{ formatNumberString(selectedPaymentAmount) }}
                  </h1>
                </span>
             </div>
        </div>

        <div v-if="this.hasToken">
          <div class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center">
              <div class="form-row mt-4">
              <button type="button" @click.prevent="authPayment()" class="btn btn-primary btn-lg mx-auto" style="width: 80%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>{{ paymentMessage() }}</button>
              </div>
              <div class="form-row mt-4">
                <button type="button" @click.prevent="changeCard()" class="btn btn-outline-primary btn-lg mx-auto" style="width: 80%">Change card</button>
              </div>
              </div>
            <div class="col-xl-3"></div>
          </div>
          </div>
        <div v-else>
          <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
              <div class="row align-items-center">
                <div class="col-1"></div>
                <div class="col-2">
                    <span class="numberCircleBlue float-right">
                      1
                    </span>
                </div>
                <div class="col">
                  <h1 class="detail float-left">
                      Enter card details now
                  </h1>
               </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
              <div class="row align-items-center">
                <div class="col-1"></div>
                <div class="col-2">
                    <span class="numberCircleBlue float-right">
                      2
                    </span>
                </div>
                <div class="col">
                  <h1 class="detail float-left">
                      We'll charge your card when you check-in
                  </h1>
               </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
              <div class="row align-items-center">
                <div class="col-1"></div>
                <div class="col-2">
                    <span class="numberCircleBlue float-right">
                      3
                    </span>
                </div>
                <div class="col">
                  <h1 class="detail float-left">
                    A receipt will be available at the clinic.
                  </h1>
               </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center">
              <div class="form-row mt-4">
              <button type="submit" @click.prevent="addCard()" class="btn btn-primary btn-lg mx-auto payment-button" style="width: 80%;">Add a card</button>
              </div>
              <div class="form-row mt-4">
                <button type="submit" @click.prevent="back()" class="btn btn-outline-primary btn-lg mx-auto payment-button" style="width: 80%;">Back</button>
            </div>
              </div>
            <div class="col-xl-3"></div>
          </div>
        </div>
    </div>
</template>

<script>
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import api from '../../services/api'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'TokenizePayment',

    components: {},

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      paymentAmount: {
        default: null
      },
      patientHasToken: {
        type: Boolean,
        default: false
      },
      last4: {
        type: String,
        default: ''
      },
      cardType: {
        type: String,
        default: ''
      },
      discountId: {
        type: Number,
        default: 0
      },
      discountAmount: {
        type: Number,
        default: 0
      }
    },

    data () {
      return {
        orgName: this.$store.orgName,
        prevRoute: null,
        isSaving: false,
        payment: {}
      }
    },
    computed: {
      selectedPaymentAmount () {
        if (this.$props.paymentAmount) {
          return this.$props.paymentAmount
        }
        return 0
      },

      hasToken () {
        return this.$props.patientHasToken
      }
    },

    methods: {
      validate () {
        return !this.hasErrors()
      },

      numberFromString (value) {
        if (value) {
          value = value.replace(',', '')
          return Number.parseFloat(value)
        }

        return 0
      },

      formatNumberString (value) {
        let number = 0.00
        if (typeof value === 'string') {
          value = value.replace(',', '')
          number = this.numberFromString(value)
        } else {
          number = value
        }

        if (number) {
          return this.formatNumber(number) + ' '
        }

        return number + ' '
      },

      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      },

      paymentMessage () {
        if (this.$props.cardType && this.$props.last4) {
          return 'Pay With ' + this.$props.cardType + ' (' + this.$props.last4 + ')'
        } else if (this.$props.last4) {
          return 'Authorize Payment (' + this.$props.last4 + ')'
        } else {
          return 'Authorize Payment'
        }
      },

      authPayment () {
        if (this.validate() !== true) {
          return
        }

        if (this.isSaving) {
          return
        }

        this.payment.amount = this.selectedPaymentAmount
        this.payment.cardNumber = null
        this.payment.creditCardType = this.cardType
        this.payment.TokenPayment = this.$props.patientHasToken
        this.payment.discountAmount = this.$props.discountAmount
        this.payment.organizationOutstandingBalanceDiscountId = this.$props.discountId
        this.isSaving = true
        api.put('/Patient/Visit/Payment', this.payment).then(response => {
          this.trackEvent(this.$options.name, 'PaymentAuthCompleted', 'Amount', this.payment.totalAmount)
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)

          this.$store.setModuleStatus('Payment', true, () => {
            this.isSaving = false
          })
          this.$router.push({ name: 'payment-auth-success', params: { paymentAmount: this.$props.paymentAmount } })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      addCard () {
        this.$router.push({ name: 'visit-credit-card', params: { patientHasToken: this.$props.patientHasToken, paymentAmount: this.$props.paymentAmount, tokenizationEnabled: true, discountId: this.$props.discountId, discountAmount: this.$props.discountAmount } })
      },

      changeCard () {
        this.$router.push({ name: 'visit-credit-card', params: { patientHasToken: this.$props.patientHasToken, paymentAmount: this.$props.paymentAmount, tokenizationEnabled: true, discountId: this.$props.discountId, discountAmount: this.$props.discountAmount } })
      },

      back () {
        this.$router.push({ name: 'visit-payment' })
      }
    }
  }
</script>
<style scoped>

.numberCircleOrange {
  height: 11em;
  width: 11em;
  border: 2px solid #fb3709;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.numberCircleBlue {
  height: 3em;
  width: 3em;
  border: 1px solid #fb3709;
  background-color: #fb3709;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #ffffff;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.detail {
  margin: 15px;
  font-size: 20px;
  line-height: 1.6;
}

.price {
  margin: 15px;
  font-size: 1.6em;
  line-height: 1.6;
  font-weight: 800;
}

</style>
