const DemographicsMixin = {
  data () {
    return {
    }
  },

  computed: {
    promptSsn () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptSSN', true)
    },

    promptSsnOptional () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptSSNOptional', false)
    },

    promptDominantHand () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptDominantHand', false)
    },

    promptMaritalStatus () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptMaritalStatus', true)
    },

    promptAddress () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptAddress', true)
    },

    promptRace () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptRace', true)
    },

    promptHeightWeight () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptHeightWeight', true)
    },

    promptAdditional () {
      return this.promptMaritalStatus || this.promptDominantHand || this.promptDominantHand
    },

    promptAdditionalPhone () {
      return this.$store.boolForModuleConfig('Demographics', 'PromptAdditionalPhone', false)
    }
  },

  methods: {
  }
}

export default DemographicsMixin
