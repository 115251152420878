<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Thank You for Your Response</h1>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col text-center mt-4"><img class="image-complete" src="../../assets/surveyComplete.svg"></div>
    </div>
  </div>
</template>

<script>
  import ErrorsMixin from '../../mixins/ErrorsMixin'

  export default {
    name: 'PatientSurveyComplete',

    mixins: [ErrorsMixin],

    data () {
      return {
        isSaving: false,
        selectedAnswer: 0,
        question: {}
      }
    },

    created () {
      this.clearAllErrors()
      this.loadData()
    }
  }

</script>

<style scoped>
p, h4 {
  text-align: center
}

@media (min-width: 768px) {
  p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
  }
}

.image-complete {
  max-height: 140px;
  max-width: 49.9999%;
}

</style>
