<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Authorization Successful!</h1>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <div class="col-centered pb-2">
        <h1 class="price">
          <span class="numberCircleOrange">
            ${{ formatNumberString(selectedPaymentAmount) }}
          </span>
        </h1>
      </div>
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <div class="col-xl-3"></div>

      <div class="col-xl-6">
        <h2 class="subheader detail">
          <center>
            We were able to authorize your card successfully. It will not be
            charged until you check-in to your appointment.
          </center>
        </h2>
      </div>
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-md-6 text-center pt-4">
        <button
          type="submit"
          @click.prevent="submit()"
          class="btn btn-primary btn-lg mx-auto"
          style="width: 50%"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import ErrorsMixin from '../../mixins/ErrorsMixin'

  export default {
    name: 'PaymentAuthSuccess',

    components: {},

    mixins: [ErrorsMixin],

    props: {
      paymentAmount: {
        default: null
      }
    },

    data () {
      return {
        orgName: this.$store.orgName,
        isSaving: false
      }
    },

    computed: {
      selectedPaymentAmount () {
        if (this.$props.paymentAmount) {
          return this.$props.paymentAmount
        }
        return 0
      }
    },

    methods: {
      validate () {
        return !this.hasErrors()
      },

      submit () {
        this.$router.push(this.$store.nextRoute())
      },

      numberFromString (value) {
        if (value) {
          value = value.replace(',', '')
          return Number.parseFloat(value)
        }

        return 0
      },

      formatNumberString (value) {
        let number = 0.0
        if (typeof value === 'string') {
          value = value.replace(',', '')
          number = this.numberFromString(value)
        } else {
          number = value
        }

        if (number) {
          return this.formatNumber(number)
        }

        return number
      },

      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      }
    }
  }
</script>
<style scoped>
.numberCircleOrange {
  height: 9em;
  width: 9em;
  border: 2px solid #fb3709;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.detail {
  margin: 15px;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
}

.price {
  margin: 15px;
  font-size: 1.6em;
  line-height: 1.6;
  font-weight: 800;
}
</style>
