<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Start typing the name of the provider to search.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group label="Provider Name" :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm" @click.prevent="selectSearchTerm(searchTerm)" class="result">
                        <h1 class="text-center">{{ searchTerm }}</h1>
                    </div>
                    <div @click.prevent="selectProvider(provider)" v-for="(provider,index) in searchResults" :key="index" class="result">
                        <h1 class="text-center">{{ provider.firstName + " " + provider.lastName + (provider.suffix ?  provider.suffix : '') }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'

  export default {
    name: 'ReferringPhysician',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        searchResults: {},
        searchTerm: '',
        referringPhysicianResponse: {
          patientZipCode: 0
        },
        isSaving: false,
        selectedProvider: {},
        forms: [],
        showFormAndDosage: false
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/ReferringPhysician').then(response => {
          this.referringPhysicianResponse = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading Referring Physician: ' + error.message, error)
          }
        })
      },

      performSearch () {
        if (!this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/ProviderSearch?query=' + this.searchTerm).then(response => {
          this.searchResults = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for provider: ' + error.message, error)
            this.addErrorForField('exception', error.message)
          }
        })
      },

      selectSearchTerm (searchTerm) {
        this.selectedProvider = {
          searchTerm
        }
        this.submit()
      },

      selectProvider (provider) {
        this.selectedProvider = provider
        this.submit()
      },

      submit () {
        this.isSaving = true

        this.selectedProvider.searchTerm = this.searchTerm

        api.put('/Patient/ReferringPhysician', this.selectedProvider).then(response => {
          this.$store.setModuleStatus('HowDidYouHearAboutUs', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving medicaiton: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
        padding: .25em;
    }

    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgba(255,62,3,1.0);
        }
    }
</style>
