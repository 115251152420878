<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Who is your primary care physician?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group label="Provider Name" :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input @input="searchInput" autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm && hasChanged" @click.prevent="selectSearchTerm(searchTerm)" class="result">
                        <h1 class="text-center">{{ searchTerm }}</h1>
                    </div>
                    <div @click.prevent="selectProvider(provider)" v-for="(provider,index) in searchResults" :key="index" class="result">
                        <h1 class="text-center">{{ provider.firstName + " " + provider.lastName + (provider.suffix ?  provider.suffix : '') }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div v-if="!hasChanged" class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PrimaryPhysician',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        searchResults: {},
        displayName: '',
        searchTerm: '',
        primaryPhysician: {},
        isSaving: false,
        selectedProvider: {},
        forms: [],
        showFormAndDosage: false,
        isLoading: false,
        hasChanged: false
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        this.isLoading = true
        api.get('/Patient/PrimaryPhysician').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.primaryPhysician = response.data
          if (this.primaryPhysician.firstName && this.primaryPhysician.lastName) {
            this.displayName = this.primaryPhysician.firstName + ' ' + this.primaryPhysician.lastName
            this.searchTerm = this.displayName
          } else {
            this.hasChanged = true
          }
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      searchInput () {
        this.hasChanged = true
      },

      performSearch () {
        if (this.searchTerm === this.displayName || this.isLoading || !this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/ProviderSearch?query=' + this.searchTerm).then(response => {
          this.trackEvent(this.$options.name, 'Search', 'ResponseTime', response.config.requestDuration)
          this.searchResults = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SearchError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      selectSearchTerm (searchTerm) {
        this.selectedProvider = {
          searchTerm
        }
        this.submit()
      },

      selectProvider (provider) {
        this.selectedProvider = provider
        this.submit()
      },

      submit () {
        this.isSaving = true

        if (!this.hasChanged) {
          this.$store.setModuleStatus('PrimaryCarePhysician', true, () => {
            this.$router.push(this.$store.nextRoute())
            this.isSaving = false
          })
          return
        }

        this.selectedProvider.searchTerm = this.searchTerm

        api.put('/Patient/PrimaryPhysician', this.selectedProvider).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('PrimaryCarePhysician', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
        padding: .25em;
    }

    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgba(255,62,3,1.0);
        }
    }
</style>
