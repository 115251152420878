<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Patient Signature</h1>
        <h2 class="subheader">I have reviewed this estimate and understand that actual costs may vary.</h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
      <b-form-group label="Signature" :state="stateForField('signature')" :invalid-feedback="errorForField('signature')">
        <b-form-input @input="clearFieldError('signature')" size="lg" :state="stateForField('signature')" v-model="signature"></b-form-input>
      </b-form-group>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col text-center">***Disclaimers:***</div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col" v-html="this.disclaimerData.disclaimer">
      </div>
    <div class="col-xl-3"></div>
    </div>

    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
          <div class="form-row">
            <div class="form-group col-md-12 text-center">
              <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Make Payment</button>
            </div>
          </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'EstimateSignature.vue',

    components: { BFormGroup, BFormInput },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      token: {
        type: String
      },
      id: {
        default: 0
      }
    },

    data () {
      return {
        signature: '',
        isSaving: false,
        disclaimerData: {
          disclaimer: ''
        }
      }
    },

    watch: {
    },

    computed: {
      orgName () {
        if (this.estimate.orgName) {
          return this.estimate.orgName
        } else if (this.paymentInfo.orgName) {
          return this.paymentInfo.orgName
        }

        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.signature) {
          this.addErrorForField('signature', 'Signature is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Estimate/' + this.id + '/Disclaimer').then(response => {
          this.trackEvent('EstimateDisclaimer', 'View', this.token)
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.disclaimerData = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading disclaimer estimate: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.validate() !== true || this.isSaving) {
          return
        }

        const model = {
          organizationPatientEstimateId: this.id,
          signature: this.signature
        }

        this.isSaving = true
        api.post('/Patient/Estimate/Sign', model).then(response => {
          this.trackEvent('Estimate', 'Signature', this.token)
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)

          this.$router.push({ name: 'payment', params: { token: this.$props.token } })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
            this.$log.error('Error saving signature: ' + error.message, error)
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }

  }
</script>

<style scoped>
.logo-url {
  max-height: 140px;
  max-width: 100%;
}
</style>
