<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Do you currently have a Medical Power of Attorney?</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <form @submit.prevent="submit()">
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group label="Power of Attorney" :state="stateForField('hasPowerOfAttorney')" :invalid-feedback="errorForField('hasPowerOfAttorney')">
                <b-form-select size="lg" @input="clearFieldError('hasPowerOfAttorney')" v-model="senior.hasPowerOfAttorney" :state="stateForField('hasPowerOfAttorney')" :options="yesNoOptions"></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
          </div>
        </form>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PowerOfAttorney',

    components: { BFormGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        senior: {
          hasFallen: false,
          causedInjury: false,
          fallCountId: 0,
          fallCountOptions: [],
          hasPowerOfAttorney: false,
          hasLivingWill: false
        },
        isSaving: false,
        yesNoOptions: [
          {
            value: null,
            text: ''
          },
          {
            text: 'Yes',
            value: true
          },
          {
            text: 'No',
            value: false
          }
        ]
      }
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.senior.hasPowerOfAttorney !== true && this.senior.hasPowerOfAttorney !== false) {
          this.addErrorForField('hasPowerOfAttorney', 'Please select an option.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Senior').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.senior = response.data
          if (this.senior.isSenior === false) {
            this.$store.setModuleStatus('Senior', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/Senior', this.senior).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('Senior', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }

    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
