<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Do any of these apply to you?</h1>
                <h2 class="subheader">
                  COVID Symptoms: Fever or chills, Cough, Shortness of breath or difficulty breathing, Fatigue, Muscle or body aches, Headache, New loss of smell or taste, Sore throat, Congestion or runny nose, Nausea or vomiting, Diarrhea
                  <p></p>
                  Contact is 6 feet or closer for more than 15 minutes, or having direct contact with fluids from a person with COVID (I.e. being coughed/sneezed on).
                </h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 30%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import AnalyticsMixin from '../../mixins/AnalyticsMixin'

  export default {
    name: 'Blocker',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        blockReasons: [],
        selectedId: null,
        isSaving: false
      }
    },

    computed: {
      options () {
        let options = []
        if (this.blockReasons && this.blockReasons.length > 0) {
          options = this.blockReasons.map((option) => {
            return {
              id: option.organizationBlockReasonId,
              type: 'item',
              name: option.item,
              selected: this.selectedId === option.organizationBlockReasonId
            }
          })
        }

        options.push({
          id: 'none',
          type: 'item',
          name: 'None of these',
          selected: this.selectedId === -1
        })

        return options
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.selectedId === null) {
          this.addErrorForField('exception', 'You must select an item to continue.')
        }
        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/BlockReasons').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.blockReasons = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading block reasons: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      optionSelected (item) {
        if (item.id === 'none') {
          item.selected = !item.selected
          if (item.selected === true) {
            this.selectedId = -1
          } else {
            this.selectedId = null
          }
        } else {
          item.selected = !item.selected
          if (item.selected === true) {
            this.selectedId = item.id
          } else {
            this.selectedId = null
          }
        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        if (this.selectedId > 0) {
          api.put('/Patient/BlockReason/' + this.selectedId, this.therapies).then(response => {
            this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
            this.$store.setModuleStatus('Blocker', true, () => {
              this.isSaving = false
              this.$router.push('/visit-error')
            })
          }).catch(error => {
            this.isSaving = false
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error setting block reason: ' + error.message, error)
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.setFromApiErrorResponse(error)
            }
          })
        } else {
          this.$store.setModuleStatus('Blocker', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
</style>
