<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Have you had any prior treatments or diagnostics on your {{ primaryBodyPartName }}?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import MultiselectList from '../../components/MultiselectList'
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import OrthoMixin from '../../mixins/OrthoMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoPriorTreatments',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, OrthoMixin, AnalyticsMixin],

    data () {
      return {
        isSaving: false,
        priorTreatments: {
          priorTreatmentOptions: [],
          periodOptions: []
        },
        noneOption: {
          id: 0,
          name: 'None',
          selected: false
        }
      }
    },

    computed: {
      options () {
        const options = this.multiselectOptionsFromArray(this.priorTreatments.priorTreatmentOptions, 'orthoPriorTreatmentId', 'name', '', 'isSelected')
        options.push(this.noneOption)

        return options
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        const firstSelection = this.priorTreatments.priorTreatmentOptions.filter(anItem => {
          return anItem.isSelected
        }).shift()

        if (!firstSelection) {
          if (this.noneOption.selected === false) {
            this.addErrorForField('exception', 'You must select an option.')
          }
        }

        return !this.hasErrors()
      },

      optionSelected (item) {
        this.clearAllErrors()

        if (item.name === 'None') {
          if (item.selected === false) {
            this.priorTreatments.priorTreatmentOptions = this.priorTreatments.priorTreatmentOptions.map(anOption => {
              anOption.isSelected = false
              anOption.imagingPeriodId = null
              return anOption
            })
          }
          item.selected = !item.selected
        } else {
          this.noneOption.selected = false
          const selectedItem = this.priorTreatments.priorTreatmentOptions.filter((anItem) => {
            return anItem.orthoPriorTreatmentId === item.id
          }).shift()

          if (selectedItem) {
            selectedItem.isSelected = !selectedItem.isSelected
            if (selectedItem.isSelected) {
              // Show time period view
              this.$router.push({
                name: 'ortho-prior-treatment-period',
                params: {
                  priorTreatmentOption: selectedItem,
                  periodOptions: this.priorTreatments.periodOptions
                }
              })
            }
          }
        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/OrthoPriorTreatments', this.priorTreatments).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.priorTreatments = response.data
          this.$store.setModuleStatus('OrthoReasonForVisit', true, () => {
            this.$router.push(this.$store.nextRoute())
            this.isSaving = false
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      loadData () {
        api.get('/Patient/OrthoPriorTreatments').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.priorTreatments = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
