<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Select each item to confirm.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col">
                <div v-for="item in consentItems" :key="item.consentItemId" class="row">
                    <div @click.prevent="selectItem(item)" class="col consent-container">
                        <img v-if="item.hasConsented" class="radio-button" src="../../assets/radio-selected.svg">
                        <img v-if="!item.hasConsented" class="radio-button" src="../../assets/radio-unselected.svg">
                        <p @click.prevent="selectItem(item)"  class="consent-title ml-3">{{ item.itemText }}<span v-if="item.isRequired"><b>*</b></span></p>
                    </div>
                    <div class="col text-right mb-4">
                        <button type="button" @click.prevent="viewDetails(item)" class="btn btn-outline-primary">Learn More</button>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
              <div class="consent-title mt-4"><b>*</b> Indicates that your consent is required to proceed</div>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import AnalyticsMixin from '../../mixins/AnalyticsMixin'

  export default {
    name: 'Consent',

    components: { },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        isMinor: false,
        consentItems: [],
        isSaving: false
      }
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        const uncheckedRequiredItems = this.consentItems.filter((anItem) => {
          return anItem.hasConsented === false && anItem.isRequired === true
        })
        if (uncheckedRequiredItems && uncheckedRequiredItems.length > 0) {
          this.addErrorForField('exception', 'You must select all required items to continue.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Consent').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.isMinor = response.data.isMinor
          this.consentItems = response.data.consentItems
          const consentItemIds = this.getStoredConsentItemIds()
          if (consentItemIds && consentItemIds.length > 0) {
            this.consentItems.forEach((item) => {
              if (consentItemIds.includes(item.consentItemId)) {
                item.hasConsented = true
              }
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      selectItem (item) {
        this.clearAllErrors()

        if (item) {
          item.hasConsented = !item.hasConsented
          if (item.hasConsented) {
            this.storeConsentItemId(item)
          } else {
            this.removeConsentItemId(item)
          }
        }
      },

      getStoredConsentItemIds () {
        let currentConsentedItemIds = []
        const currentConsentedItemIdsString = localStorage.getItem('consented-item-ids')
        if (currentConsentedItemIdsString && currentConsentedItemIdsString.length > 0) {
          currentConsentedItemIds = JSON.parse(currentConsentedItemIdsString)
        }
        return currentConsentedItemIds
      },

      storeConsentItemIds (consentItemIds) {
        if (consentItemIds && consentItemIds.length > 0) {
          localStorage.setItem('consented-item-ids', JSON.stringify(consentItemIds))
        } else {
          localStorage.removeItem('consented-item-ids')
        }
      },

      storeConsentItemId (item) {
        const currentConsentedItemIds = this.getStoredConsentItemIds()
        if (!currentConsentedItemIds.includes(item.consentItemId)) {
          currentConsentedItemIds.push(item.consentItemId)
          this.storeConsentItemIds(currentConsentedItemIds)
        }
      },

      removeConsentItemId (item) {
        const currentConsentedItemIds = this.getStoredConsentItemIds()
        const itemIdIndex = currentConsentedItemIds.indexOf(item.consentItemId)
        if (itemIdIndex >= 0) {
          delete currentConsentedItemIds[itemIdIndex]
          this.storeConsentItemIds(currentConsentedItemIds)
        }
      },

      viewDetails (item) {
        this.$router.push({ name: 'consent-item-detail', params: { item } })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        const body = {
          consentItems: this.consentItems
        }

        this.isSaving = true
        api.put('/Patient/Consent', body).then(response => {
          this.storeConsentItemIds([])
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('/consent-signature')
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
    .consent-title {
        font-size: 20px;
    }
    .radio-button {
        height: 64px;
        width: 64px;
    }
    .consent-container {
      display: flex;
      flex-basis: auto;
    }
    @media (min-width: 768px) {
      .container-fluid {
        width: 50%;
      }
    }
</style>
