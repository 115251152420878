<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Camera/File Access</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="row">
          <div class="col text-center">
            <p>Please provide access to your camera or files to either take an image or upload an existing image of your health insurance card.</p>
          </div>
        </div>
        <div class="row">
          <div class="col text-center mb-2">
            <img src="../../assets/camera-access.svg">
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <p>Having the correct insurance information on file is important for your care.</p>
          </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
        <div class="row">
          <div class="col-xl-3"></div>
          <div class="col text-center">
        <div class="form-row mt-2">
            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 50%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Open Camera</button>
        </div>
            <div class="form-row mt-2">
            <button type="submit" @click.prevent="goToUploadFile()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Upload File</button>
            </div>
            </div>
          <div class="col-xl-3"></div>
        </div>
  </div>
</template>

<script>

  export default {
    name: 'CameraAccess',

    props: {
      captureOnly: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isSaving: false
      }
    },

    computed: {
      logoUrl () {
        return this.$store.orgLogoUrl
      },

      orgName () {
        return this.$store.orgName
      }
    },

    methods: {
      submit () {
        this.$router.push({ name: 'card-capture', params: { captureOnly: this.captureOnly } })
      },
      goToUploadFile () {
        this.$router.push({ name: 'upload-file', params: { captureOnly: this.captureOnly } })
      }
    }
  }
</script>

<style scoped>
.appointment-image {
  max-height: 140px;
}

.logo-image {
  max-height: 140px;
  max-width: 49.9999%;
}

.detail {
  margin: 2em 0em;
  font-size: 24px;
  line-height: 1.6;
}
</style>
