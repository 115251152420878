<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Have you tried any therapies or treatments?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import AnalyticsMixin from '../../mixins/AnalyticsMixin'

  export default {
    name: 'ConservativeTherapies',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        therapies: [],
        isSaving: false
      }
    },

    computed: {
      options () {
        if (this.therapies && this.therapies.length > 0) {
          const options = this.therapies.map((option) => {
            return {
              id: option.conservativeTherapyId,
              patientId: option.organizationPatientConservativeTherapyId,
              type: 'item',
              name: option.medicationName ? option.medicationName : option.otherText ? option.otherText : option.name,
              selected: option.hadTherapy
            }
          })

          options.unshift({
            id: 'addOther',
            type: 'add',
            name: 'Add Other'
          })

          options.unshift({
            id: 'addMedication',
            type: 'add',
            name: 'Add Medication'
          })

          return options
        }

        return []
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/ConservativeTherapies').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.therapies = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading conservative therapies: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      optionSelected (item) {
        if (item.id === 'addOther') {
          const newModel = {
            name: null,
            medicationName: null,
            otherText: null,
            startDate: null,
            startDateMonth: null,
            startDateYear: null,
            endDate: null,
            endDateMonth: null,
            endDateYear: null,
            relief: 5
          }
          this.$router.push({ name: 'conservative-therapy', params: { therapy: newModel, isOther: true } })

        } else if (item.id === 'addMedication') {
          this.$router.push({ name: 'conservative-therapy-medication' })

        } else {
          let selectedItem = this.therapies.filter((anItem) => {
            return anItem.conservativeTherapyId === item.id
          }).shift()
          if (item.patientId) {
            selectedItem = this.therapies.filter((anItem) => {
              return anItem.organizationPatientConservativeTherapyId === item.patientId
            }).shift()
          }

          if (selectedItem) {
            selectedItem.hadTherapy = !selectedItem.hadTherapy
            if (selectedItem.hadTherapy) {
              this.$router.push({ name: 'conservative-therapy', params: { therapy: selectedItem } })
            }
          }
        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/ConservativeTherapies', this.therapies).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('ConservativeTherapy', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving conservative therapies: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
