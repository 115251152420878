import { render, staticRenderFns } from "./OrthoBackNeckPain.vue?vue&type=template&id=85d3e334&scoped=true"
import script from "./OrthoBackNeckPain.vue?vue&type=script&lang=js"
export * from "./OrthoBackNeckPain.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85d3e334",
  null
  
)

export default component.exports