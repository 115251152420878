<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What is your pain level?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="range">
                    <input type="range" min="0" max="10" v-model="orthoReasonForVisit.painSeverity" class="slider">
                    <div class="sliderticks">
                        <p @click.prevent="setSliderValue('painSeverity', 0)">0</p>
                        <p @click.prevent="setSliderValue('painSeverity', 1)">1</p>
                        <p @click.prevent="setSliderValue('painSeverity', 2)">2</p>
                        <p @click.prevent="setSliderValue('painSeverity', 3)">3</p>
                        <p @click.prevent="setSliderValue('painSeverity', 4)">4</p>
                        <p @click.prevent="setSliderValue('painSeverity', 5)">5</p>
                        <p @click.prevent="setSliderValue('painSeverity', 6)">6</p>
                        <p @click.prevent="setSliderValue('painSeverity', 7)">7</p>
                        <p @click.prevent="setSliderValue('painSeverity', 8)">8</p>
                        <p @click.prevent="setSliderValue('painSeverity', 9)">9</p>
                        <p @click.prevent="setSliderValue('painSeverity', 10)">10</p>
                    </div>
                    <div class="row">
                        <div class="col mt-3 ml-1 text-left">
                            <span style="width: 50%; padding-left: 10px;">Low</span>
                        </div>
                        <div class="col mt-3 text-right">
                            <span class="text-right" style="width: 50%; padding-right: 10px;">High</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <h1 class="header">What is your functional limitation?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="range">
                    <input type="range" min="0" max="10" v-model="orthoReasonForVisit.functionalLimitation" class="slider">
                    <div class="sliderticks">
                        <p @click.prevent="setSliderValue('functionalLimitation', 0)">0</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 1)">1</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 2)">2</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 3)">3</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 4)">4</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 5)">5</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 6)">6</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 7)">7</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 8)">8</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 9)">9</p>
                        <p @click.prevent="setSliderValue('functionalLimitation', 10)">10</p>
                    </div>
                    <div class="row">
                        <div class="col mt-3 ml-1 text-left">
                            <span style="width: 50%; padding-left: 10px;">Low</span>
                        </div>
                        <div class="col mt-3 text-right">
                            <span class="text-right" style="width: 50%; padding-right: 10px;">High</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row mt-4">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import OrthoMixin from '../../mixins/OrthoMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoSeverity',

    components: { },

    mixins: [ErrorsMixin, OptionsMixin, OrthoMixin, AnalyticsMixin],

    props: {
      standalone: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isSaving: false,
        orthoReasonForVisit: {
          functionalLimitation: 5,
          painSeverity: 5,
          primaryBodyPart: {
            code: '',
            name: ''
          }
        }
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      setSliderValue (field, value) {
        this.orthoReasonForVisit[field] = value
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/OrthoReasonForVisit', this.orthoReasonForVisit).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
          if (this.standalone) {
            this.$store.setModuleStatus('OrthoPainSeverity', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            this.isSaving = false
            this.$router.push('ortho-better')
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      loadData () {
        api.get('/Patient/OrthoReasonForVisit').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
          if (!this.orthoReasonForVisit.functionalLimitation) {
            this.orthoReasonForVisit.functionalLimitation = 5
          }
          if (!this.orthoReasonForVisit.painSeverity) {
            this.orthoReasonForVisit.painSeverity = 5
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 2px;
        background: rgba(23,56,96,1.0);
        outline: none;
        cursor: pointer;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 40px;
        height: 40px;
        background: rgba(255,62,3,1.0);
        cursor: pointer;
        border-radius: 20px;
        z-index: 15;
    }

    .slider::-moz-range-thumb {
        width: 40px;
        height: 40px;
        background: rgba(255,62,3,1.0);
        cursor: pointer;
        border-radius: 20px;
        z-index: 15;
    }

    .sliderticks {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }

    .sliderticks p {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 1px;
        font-size: 24px;
        font-weight: bold;
        height: 10px;
        line-height: 40px;
        margin: 10px 0 20px 0;
        color: rgba(255,62,3,1.0);
        cursor: pointer;
    }
</style>
