<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header" v-html="headerCopy"></h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Patient First Name" :state="stateForField('firstName')" :invalid-feedback="errorForField('firstName')">
                                <b-form-input autocomplete="given-name" size="lg" :state="stateForField('firstName')" v-model="patientPaymentSearch.firstName" placeholder="First Name"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Patient Last Name" :state="stateForField('lastName')" :invalid-feedback="errorForField('lastName')">
                                <b-form-input autocommplete="family-name" size="lg" :state="stateForField('lastName')" v-model="patientPaymentSearch.lastName" placeholder="Last Name"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Birth Date (MM/DD/YYYY)" :state="stateForField('birthDate')" :invalid-feedback="errorForField('birthDate')">
                                <date-input :current-date="patientPaymentSearch.birthDate" @valid="birthDateUpdated" @invalid="birthDateInvalid" @change="birthDateChanged"/>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center">
                            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../services/api'
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import DateInput from '../components/DateInput'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PatientPaymentSearch',

    components: { BFormGroup, BFormInput, DateInput },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      token: {
        type: String,
        required: false
      },
      next: {
        type: String,
        required: false
      }
    },

    data () {
      return {
        orgName: '',
        patientPaymentSearch: {
          firstName: '',
          lastName: '',
          birthDate: '',
          paymentToken: ''
        },
        isSaving: false
      }
    },

    metaInfo () {
      return {
        meta: [{
          name: 'og:image',
          content: this.$store.apiBaseUrl + '/WebClient/ShareImage/' + this.token
        }]
      }
    },

    watch: {
      token (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.patientPaymentSearch.paymentToken = newValue
        }
      }
    },

    computed: {
      headerCopy () {
        if (this.orgName) {
          return 'Let\'s lookup your estimate for <b>' + this.orgName + '</b>.'
        }

        return 'Let\'s look up your estimate.'
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.patientPaymentSearch.firstName) {
          this.addErrorForField('firstName', 'First name is required.')
        }

        if (!this.patientPaymentSearch.lastName) {
          this.addErrorForField('lastName', 'Last name is required.')
        }

        if (!this.patientPaymentSearch.birthDate) {
          this.addErrorForField('birthDate', 'Birth date is required.')
        } else {
          if (this.patientPaymentSearch.birthDate >= Date.now()) {
            this.addErrorForField('birthDate', 'Birth date must be in the past')
          }
        }

        return !this.hasErrors()
      },

      birthDateUpdated (birthDate) {
        this.clearFieldError('birthDate')
        this.patientPaymentSearch.birthDate = birthDate
      },

      birthDateInvalid (birthDate) {
        this.addErrorForField('birthDate', 'Invalid birth date')
      },

      birthDateChanged (birthDate) {
        this.clearFieldError('birthDate')
        if (birthDate instanceof Date && !isNaN(birthDate)) {
          this.patientPaymentSearch.birthDate = birthDate

        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.post('/WebClient/PatientPaymentSearch', this.patientPaymentSearch).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)

          this.$store.startAuthorization(response.data)
          if (this.$route.params.next) {
            this.$router.push({ name: 'authorize', params: { next: this.$route.params.next } })
          } else {
            this.$router.push('/authorize')
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for patient: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
              if (this.hasError('noVisitFound')) {
                this.trackEvent(this.$options.name, 'SaveError', 'VisitNotFound')
                this.addErrorForField('exception', this.errorForField('noVisitFound'))
                this.clearFieldError('noVisitFound')
              }
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      if (this.$route.params.token) {
        this.patientPaymentSearch.paymentToken = this.$route.params.token
      }
    },

    beforeRouteUpdate (to, from, next) {
      if (this.$route.params.token) {
        this.patientPaymentSearch.paymentToken = this.$route.params.token
      }
    }
  }
</script>

<style scoped>

</style>
