import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

api.interceptors.request.use((config) => {
  config.requestStartTime = new Date()
  return config
})

api.interceptors.response.use((response) => {
  response.config.requestDuration = new Date() - response.config.requestStartTime
  return response
})

export default api
