<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header" v-html="headerCopy"></h1>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col text-center">
        <img class="complete-image" src="../../assets/precheck-complete.svg">
        <div>
          <div class="complete-copy">
            You are ready for your upcoming appointment.
          </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import ErrorsMixin from '../../mixins/ErrorsMixin'

  export default {
    name: 'PaymentPassComplete',

    mixins: [ErrorsMixin],

    computed: {
      headerCopy () {
        return 'You\'re all set'
      }
    }
  }
</script>

<style scoped>
.complete-image {
  max-height: 140px;
  max-width: 49.9999%;
}

.complete-copy {
  font-size: 20px;
}
</style>
