<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Which region of the body brings you in?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :is-selected="isRegionSelected" :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import MultiselectList from '../../components/MultiselectList'
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'BodyRegions',

    components: { MultiselectList },

    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        allergyResponse: [],
        isSaving: false,
        selectedRegion: '',
        orthoReasonForVisit: {
          primaryBodyPartCode: ''
        }
      }
    },

    computed: {
      options () {
        return this.$store.bodyRegions
      }
    },

    methods: {

      isRegionSelected (item) {
        if (item.name === this.selectedRegion) {
          return true
        }

        return false
      },

      optionSelected (item) {
        this.clearAllErrors()
        this.selectedRegion = item.name
      },

      loadData () {
        api.get('/Patient/OrthoReasonForVisit').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.selectedRegion) {
          this.isSaving = true
          const regionBodyParts = this.$store.bodyPartsByRegion[this.selectedRegion]
          if (regionBodyParts && regionBodyParts.length === 1) {
            this.$store.selectedBodyParts.push(regionBodyParts[0].code)
            this.$store.primaryBodyPart = regionBodyParts[0]
            this.orthoReasonForVisit.bodyPartCodes = _.clone(this.$store.selectedBodyParts)
            this.orthoReasonForVisit.primaryBodyPartCode = _.clone(this.$store.selectedBodyParts[0])
            api.put('/Patient/OrthoReasonForVisit', this.orthoReasonForVisit).then(response => {
              this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
              this.$router.push('ortho-pain-type')
              this.isSaving = false
            }).catch(error => {
              if (!this.$store.canHandleNetworkError(error)) {
                this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
                this.setFromApiErrorResponse(error)
              }
              this.isSaving = false
            })
          } else {
            this.$router.push({ name: 'body-parts', params: { region: this.selectedRegion } })
            this.isSaving = false
          }
        } else {
          this.addErrorForField('exception', 'You must select a region.')
        }

      }
    },

    created () {
      // Reset all the state variables when starting module
      this.$store.orthoModule.kneeCompleted = false
      this.$store.orthoModule.shoulderCompleted = false
      this.$store.orthoModule.recurringCompleted = false
      this.$store.orthoModule.footAnkleCompleted = false
      this.$store.orthoModule.backCompleted = false
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
