var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_vm._m(0),(_vm.generalErrorMessage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col"},[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.generalErrorMessage)+" ")])]),_c('div',{staticClass:"col-xl-3"})]):_vm._e(),_vm._m(1),_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-centered pb-2"},[_c('h1',{staticClass:"price"},[_c('span',{staticClass:"numberCircleOrange"},[_vm._v(" $"+_vm._s(_vm.formatNumberString(_vm.selectedPaymentAmount))+" ")])])])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-xl-6"},[_c('h2',{staticClass:"subheader detail"},[_c('center',[_vm._v(" We were able to authorize your card successfully. It will not be charged until you check-in to your appointment. ")])],1)])]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"}),_c('div',{staticClass:"col-md-6 text-center pt-4"},[_c('button',{staticClass:"btn btn-primary btn-lg mx-auto",staticStyle:{"width":"50%"},attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[_vm._v(" Next ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h1',{staticClass:"header"},[_vm._v("Authorization Successful!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('br')])
}]

export { render, staticRenderFns }