<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Which part of {{ region }}?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :is-selected="isOptionSelected" :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
        <list-modal :header-image="require('../../assets/doctor.svg')" header="Which side of the body?" @optionSelected="sideSelected" :options="sideOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import MultiselectList from '../../components/MultiselectList'
  import ListModal from '../../components/ListModal'
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'BodyParts',

    components: { MultiselectList, ListModal },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      region: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        isSaving: false,
        showListModal: false,
        modalPart: {},
        selectedParts: {},
        orthoReasonForVisit: {
          primaryBodyPartCode: ''
        }
      }
    },

    computed: {
      options () {
        return this.$store.bodyPartsByRegion[this.region]
      },

      sideOptions () {
        const options = [
          {
            name: 'Left',
            selected: false
          },
          {
            name: 'Right',
            selected: false
          }
        ]

        if (this.modalPart.bilateral) {
          options.push({
            name: 'Both',
            selected: false
          })
        }

        return options
      }
    },

    methods: {
      isOptionSelected (option) {
        return this.$store.isBodyPartSelected(option)
      },

      optionSelected (item) {
        this.clearAllErrors()

        if (this.$store.isBodyPartSelected(item)) {
          this.$store.deselectBodyPart(item)
        } else {
          if (item.sidedness) {
            this.modalPart = item
            this.showListModal = true
          } else {
            this.$store.selectedBodyParts.push(item.code)
          }
        }
      },

      sideSelected (side) {
        side.selected = true
        let code = ''
        if (side.name === 'Left') {
          if (this.modalPart.leftCode) {
            code = this.modalPart.leftCode
          } else {
            code = 'L' + this.modalPart.codeSuffix
          }
        } else if (side.name === 'Right') {
          if (this.modalPart.rightCode) {
            code = this.modalPart.rightCode
          } else {
            code = 'R' + this.modalPart.codeSuffix
          }
        } else {
          if (this.modalPart.bilateralCode) {
            code = this.modalPart.bilateralCode
          } else {
            code = 'BI' + this.modalPart.codeSuffix
          }
        }
        this.$store.selectedBodyParts.push(code)
        this.showListModal = false
      },

      loadData () {
        api.get('/Patient/OrthoReasonForVisit').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.$store.selectedBodyParts.length === 0) {
          this.addErrorForField('exception', 'You must select at least one body part.')
          return
        }

        if (this.$store.selectedBodyParts.length > 1) {
          this.$router.push('primary-body-part')
        } else {
          this.orthoReasonForVisit.bodyPartCodes = _.clone(this.$store.selectedBodyParts)
          this.orthoReasonForVisit.primaryBodyPartCode = _.clone(this.orthoReasonForVisit.bodyPartCodes[0])
          this.$store.primaryBodyPart.code = this.orthoReasonForVisit.primaryBodyPartCode
          this.$store.primaryBodyPart.name = this.$store.bodyPartNames[this.orthoReasonForVisit.primaryBodyPartCode]
          this.isSaving = true
          api.put('/Patient/OrthoReasonForVisit', this.orthoReasonForVisit).then(response => {
            this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
            this.$router.push('ortho-pain-type')
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.setFromApiErrorResponse(error)
            }
          }).then(() => {
            this.isSaving = false
          })
        }
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
