<template>
  <div>Nothing here yet</div>
</template>

<script>
  export default {
    name: 'OncologyMedicalHistory'
  }
</script>

<style scoped>

</style>
