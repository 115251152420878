<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Select all medical conditions that apply.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
      <list-modal :header-image="require('../../assets/medical-history.svg')" :header="modalHeader" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="confirmOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import ListModal from '@/components/ListModal'

  export default {
    name: 'MedicalHistory',

    components: { MultiselectList, ListModal },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        medicalConditions: {
          medicalConditions: [],
          frequencyOptions: []
        },
        isSaving: false,
        showListModal: false,
        confirmOptions: [
          {
            id: 1,
            name: 'No medical conditions',
            selected: false
          },
          {
            id: 2,
            name: 'Let me add medical history',
            selected: false
          }
        ],
        modalHeader: 'Please confirm the patient has no medical conditions.'
      }
    },

    computed: {
      options () {
        return this.multiselectOptionsFromArray(this.medicalConditions.medicalConditions, 'medicalConditionId', 'name', 'otherText', 'hasMedicalCondition')
      },

      selectedOptions () {
        return this.medicalConditions.medicalConditions.filter((anOption) => {
          return anOption.hasMedicalCondition
        })
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/MedicalConditions').then(response => {
          this.medicalConditions = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading medical history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      optionSelected (item) {
        const selectedItem = this.medicalConditions.medicalConditions.filter((anItem) => {
          // Have to match on name because there are a mix of ids
          if (anItem.name) {
            return anItem.name === item.name
          } else {
            return anItem.otherName === item.name
          }

        }).shift()

        if (selectedItem) {
          selectedItem.hasMedicalCondition = !selectedItem.hasMedicalCondition
        }
      },

      modalClosed () {
        const confirmed = this.confirmOptions[0].selected === true
        if (confirmed) {
          this.submit(true)
        }
      },

      modalSelection (item) {
        for (const anOption of this.confirmOptions) {
          if (anOption.id === item.id) {
            anOption.selected = true
          } else {
            anOption.selected = false
          }
        }
      },

      submit (confirmed = false) {
        if (this.validate() !== true) {
          return
        }

        if (this.selectedOptions.length === 0 && confirmed === false) {
          this.showListModal = true
          return
        }

        this.isSaving = true
        api.put('/Patient/MedicalConditions', this.medicalConditions.medicalConditions).then(response => {
          this.$store.setModuleStatus('MedicalHistory', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving medical history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
</style>
