<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header mt-4">{{ currentSurvey.name }}</h1>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col"><p>{{ currentSurvey.instructions }}</p></div>
    </div>
    <div class="row">
      <div class="col text-center mt-2">
        <button type="button" @click.prevent="next()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'

  export default {
    name: 'PatientSurvey',

    components: {},

    props: {
      token: {
        type: String
      },
      lastCompletedSurveyId: Number
    },

    mixins: [ErrorsMixin],

    data () {
      return {
        isSaving: false,
        surveys: [],
        currentSurvey: {},
        currentQuestion: {},
        organizationPatientSurveyId: {}
      }
    },

    methods: {
      loadData () {
        if (this.token) {
          let tokenUrl = '/Patient/Survey/' + this.token
          if (this.lastCompletedSurveyId) {
            tokenUrl += '/Next/' + this.lastCompletedSurveyId
          }
          api.get(tokenUrl).then(response => {
            this.surveys = response.data
            this.organizationPatientSurveyId = response.data.organizationPatientSurveyId
            this.getSurvey()
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error attempting to get surveys: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          })
        } else {
          let url = '/Patient/Survey'
          if (this.lastCompletedSurveyId) {
            url += '/Next/' + this.lastCompletedSurveyId
          }
          api.get(url).then(response => {
            this.surveys = response.data
            this.getSurvey()
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error attempting to get surveys: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          })
        }
      },

      getSurvey: function () {
        const surveys = this.surveys.surveys
        if (surveys.length === 0) {
          this.currentSurvey = { name: 'No new surveys are available right now.' }
        } else {
          const currentSurvey = surveys[0]
          this.currentSurvey = currentSurvey
        }
      },

      next () {
        if (this.surveys.surveys.length === 0) {
          if (this.token) {
            this.$router.push({ name: 'patient-survey-complete' })
          } else {
            this.$router.push(this.$store.nextRoute())
          }
        } else if (this.token) {
          this.tokenNext()
        } else {
          this.sessionNext()
        }
      },

      tokenNext () {
        this.currentSurvey.token = this.token
        this.currentSurvey.organizationPatientSurveyId = this.organizationPatientSurveyId

        api.post('/Patient/Survey/', this.currentSurvey).then(response => {
          this.isSaving = false
          this.$router.push({
            name: 'patient-survey-question',
            params: {
              currentSurvey: this.currentSurvey,
              currentQuestion: response.data.nextQuestion
            },
            query: {
              questionId: response.data.nextQuestion.surveyQuestionId,
              token: this.token,
              organizationPatientSurveyResultId: response.data.organizationPatientSurveyResultId,
              surveyCount: this.surveys.surveys.length
            }
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to update survey: ' + error.message, error)
          }
        })
      },

      sessionNext () {
        api.post('/Patient/VisitSurvey/', this.currentSurvey).then(response => {
          this.isSaving = false
          this.$router.push({
            name: 'patient-survey-question',
            params: {
              currentSurvey: this.currentSurvey,
              currentQuestion: response.data.nextQuestion
            },
            query: {
              questionId: response.data.nextQuestion.surveyQuestionId,
              organizationPatientSurveyResultId: response.data.organizationPatientSurveyResultId,
              surveyCount: this.surveys.surveys.length
            }
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to update survey: ' + error.message, error)
          }
        })
      }
    },

    created () {
      this.clearAllErrors()
      this.loadData()
    }
  }

</script>

<style scoped>
p, h4 {
  text-align: center
}

@media (min-width: 768px) {
 p{
   text-align: center;
   margin-left: auto;
   margin-right: auto;
   max-width: 50%;
  }
}

</style>
