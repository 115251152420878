import Vue from 'vue'
import api from './api.js'
import router from './router.js'
import axios from 'axios'
import ErrorsMixin from '../mixins/ErrorsMixin'
import AnalyticsMixin from '../mixins/AnalyticsMixin'

try {
  window.localStorage.setItem('canary', true)
} catch (e) {
  document.write("<div style='font-size:1.6em; font-family:Arial; text-align:center; padding:10%;'><p>Please enable cookies in your browser settings and refresh the page to continue.</p></div>")
  throw new Error('failed to load')
}

const store = new Vue({
  mixins: [ErrorsMixin, AnalyticsMixin],

  data () {
    return {
      isInitialized: false,
      apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
      debug: true,
      idToken: null,
      sessionId: null,
      isAuthenticated: false,
      providerFirstName: null,
      providerLastName: null,
      providerCredential: null,
      providerName: null,
      orgName: null,
      orgLogoUrl: null,
      headshotUrl: null,
      appointmentDate: null,
      hasPaymentToken: null,
      webClientId: '',
      hasVisit: false,
      isVirtualVisit: false,
      isPaymentPassSearch: false,
      locationName: null,
      locationTimezone: null,
      locationStreet1: null,
      locationStreet2: null,
      locationCity: null,
      locationState: null,
      locationZip: null,
      billingSupportEmail: null,
      billingSupportPhoneNumber: null,
      patientSearchResponse: {
        smsMessageSent: false,
        smsMaskedNumber: '',
        emailMessageSent: false,
        emailMaskedEmail: '',
        hasVisit: true,
        estimateId: 0
      },
      previousPath: null,
      patientZipCode: '',
      patientBirthDate: null,
      authorizationDest: '',
      isLoggingIn: false,
      modules: [],
      successconfig: [],
      insurance: {
        insuranceCompanyShortCode: '',
        policyNumber: '',
        insurancePriority: 'primary',
        insuranceCardFront: null,
        insuranceCardBack: null
      },
      insuranceBenefits: {
        insuranceEligibilityStatus: ''
      },
      frequencyOptions: [],
      sideOptions: [],
      contactTypeOptions: [],
      surgeryCategoryOtherId: 12, // This is hard coded on the back-end
      surgeryOtherId: 78, // This is hard coded on the back-end
      selectedBodyParts: [],
      primaryBodyPart: {
        code: '',
        name: ''
      },
      orthoModule: {
        shoulderCompleted: false,
        kneeCompleted: false,
        recurringCompleted: false,
        backCompleted: false,
        footAnkleCompleted: false
      },
      bodyRegions: [
        {
          id: 1,
          type: 'item',
          name: 'Arm / Shoulder',
          selected: false
        },
        {
          id: 2,
          type: 'item',
          name: 'Back / Neck',
          selected: false
        },
        {
          id: 4,
          type: 'item',
          name: 'Foot / Ankle',
          selected: false
        },
        {
          id: 11,
          type: 'item',
          name: 'Head',
          selected: false
        },
        {
          id: 5,
          type: 'item',
          name: 'Hand',
          selected: false
        },
        {
          id: 6,
          type: 'item',
          name: 'Joint',
          selected: false
        },
        {
          id: 7,
          type: 'item',
          name: 'Leg / Knee',
          selected: false
        },
        {
          id: 8,
          type: 'item',
          name: 'Pelvis',
          selected: false
        },
        {
          id: 10,
          type: 'item',
          name: 'Other',
          selected: false
        }
      ],
      bodyPartsByRegion: {
        'Arm / Shoulder': [
          {
            codeSuffix: 'BIC',
            name: 'Bicep',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'ELB',
            name: 'Elbow',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'SHOU',
            name: 'Shoulder',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'FARM',
            name: 'Forearm',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'TRI',
            name: 'Tricep',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'WRI',
            bilateralCode: 'BIWRIST',
            name: 'Wrist',
            sidedness: true,
            bilateral: true
          }
        ],
        'Back / Neck': [
          {
            code: 'NECK',
            name: 'Neck',
            sidedness: false,
            bilateral: false
          },
          {
            code: 'MBACK',
            name: 'Mid Back',
            sidedness: false,
            bilateral: false
          },
          {
            code: 'LBACK',
            name: 'Lower Back',
            sidedness: false,
            bilateral: false
          }
        ],
        Other: [
          {
            bilateralCode: 'BIANKLE',
            codeSuffix: 'ANK',
            name: 'Ankle',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'HANDBACK',
            name: 'Back of Hand',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'BIC',
            name: 'Bicep',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'BTO',
            name: 'Big Toe',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'CALF',
            name: 'Calf',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'ELB',
            name: 'Elbow',
            sidedness: true,
            bilateral: true
          },
          {
            leftCode: 'LEFTFOOT',
            rightCode: 'RIGHTFOOT',
            codeSuffix: 'FOOT',
            name: 'Foot',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'FARM',
            name: 'Forearm',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'FTO',
            name: 'Fourth Toe',
            sidedness: true,
            bilateral: true
          },
          {
            leftCode: 'LEFTHAND',
            rightCode: 'RIGHTHAND',
            codeSuffix: 'HAND',
            name: 'Hand',
            sidedness: true,
            bilateral: true
          },
          {
            code: 'HEAD',
            name: 'Head',
            sidedness: false,
            bilateral: false
          },
          {
            codeSuffix: 'HEEL',
            name: 'Heel',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'HIP',
            name: 'Hip',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'PFING',
            name: 'Index Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'ITO',
            name: 'Index Toe',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'KNEE',
            name: 'Knee',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'LTO',
            name: 'Little Toe',
            sidedness: true,
            bilateral: true
          },
          {
            code: 'LBACK',
            name: 'Lower Back',
            sidedness: false,
            bilateral: false
          },
          {
            code: 'MBACK',
            name: 'Mid Back',
            sidedness: false,
            bilateral: false
          },
          {
            codeSuffix: 'MFOOT',
            name: 'Mid Foot',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'MFING',
            name: 'Middle Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'MTO',
            name: 'Middle Toe',
            sidedness: true,
            bilateral: true
          },
          {
            code: 'NECK',
            name: 'Neck',
            sidedness: false,
            bilateral: false
          },
          {
            codeSuffix: 'PALM',
            name: 'Palm',
            sidedness: true,
            bilateral: true
          },
          {
            code: 'PELV',
            name: 'Pelvis',
            sidedness: false,
            bilateral: false
          },
          {
            codeSuffix: 'PINKIE',
            name: 'Pinkie Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'RFING',
            name: 'Ring Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'SHIN',
            name: 'Shin',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'SHOU',
            name: 'Shoulder',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'THI',
            name: 'Thigh',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'TFING',
            name: 'Thumb',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'TRI',
            name: 'Tricep',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'WRI',
            bilateralCode: 'BIWRIST',
            name: 'Wrist',
            sidedness: true,
            bilateral: true
          },
          {
            code: 'CHES',
            name: 'Chest',
            sidedness: false,
            bilateral: false
          },
          {
            code: 'STOM',
            name: 'Stomach',
            sidedness: false,
            bilateral: false
          }
        ],
        Head: [
          {
            code: 'HEAD',
            name: 'Head',
            sidedness: false,
            bilateral: false
          }
        ],
        'Foot / Ankle': [
          {
            bilateralCode: 'BIANKLE',
            codeSuffix: 'ANK',
            name: 'Ankle',
            sidedness: true,
            bilateral: true
          },
          {
            leftCode: 'LEFTFOOT',
            rightCode: 'RIGHTFOOT',
            codeSuffix: 'FOOT',
            name: 'Foot',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'MFOOT',
            name: 'Mid Foot',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'HEEL',
            name: 'Heel',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'LTO',
            name: 'Little Toe',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'FTO',
            name: 'Fourth Toe',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'MTO',
            name: 'Middle Toe',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'ITO',
            name: 'Index Toe',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'BTO',
            name: 'Big Toe',
            sidedness: true,
            bilateral: true
          }
        ],
        Hand: [
          {
            codeSuffix: 'WRI',
            bilateralCode: 'BIWRIST',
            name: 'Wrist',
            sidedness: true,
            bilateral: true
          },
          {
            leftCode: 'LEFTHAND',
            rightCode: 'RIGHTHAND',
            codeSuffix: 'HAND',
            name: 'Hand',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'HANDBACK',
            name: 'Back of Hand',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'PALM',
            name: 'Palm',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'PINKIE',
            name: 'Pinkie Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'RFING',
            name: 'Ring Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'MFING',
            name: 'Middle Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'PFING',
            name: 'Index Finger',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'TFING',
            name: 'Thumb',
            sidedness: true,
            bilateral: true
          }
        ],
        Joint: [
          {
            codeSuffix: 'ELB',
            name: 'Elbow',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'WRI',
            bilateralCode: 'BIWRIST',
            name: 'Wrist',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'HIP',
            name: 'Hip',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'KNEE',
            name: 'Knee',
            sidedness: true,
            bilateral: true
          },
          {
            bilateralCode: 'BIANKLE',
            codeSuffix: 'ANK',
            name: 'Ankle',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'SHOU',
            name: 'Shoulder',
            sidedness: true,
            bilateral: true
          }
        ],
        'Leg / Knee': [
          {
            codeSuffix: 'HIP',
            name: 'Hip',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'THI',
            name: 'Thigh',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'KNEE',
            name: 'Knee',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'SHIN',
            name: 'Shin',
            sidedness: true,
            bilateral: true
          },
          {
            codeSuffix: 'CALF',
            name: 'Calf',
            sidedness: true,
            bilateral: true
          }
        ],
        Pelvis: [
          {
            code: 'PELV',
            name: 'Pelvis',
            sidedness: false,
            bilateral: false
          }
        ]
      },
      bodyPartNames: {
        LSHOU: 'Left Shoulder',
        RSHOU: 'Right Shoulder',
        LBIC: 'Left Bicep',
        RBIC: 'Right Bicep',
        LELB: 'Left Elbow',
        RELB: 'Right Elbow',
        LWRI: 'Left Wrist',
        RWRI: 'Right Wrist',
        RTFING: 'Right Thumb',
        RPFING: 'Right Index Finger',
        RMFING: 'Right Middle Finger',
        RRFING: 'Right Ring Finger',
        RPINKIE: 'Right Pinkie Finger',
        RPALM: 'Right Palm',
        LTFING: 'Left Thumb',
        LPFING: 'Left Index Finger',
        LMFING: 'Left Middle Finger',
        LRFING: 'Left Ring Finger',
        LPINKIE: 'Left Pinkie Finger',
        LPALM: 'Left Palm',
        LHIP: 'Left Hip',
        RHIP: 'Right Hip',
        LTHI: 'Left Thigh',
        RTHI: 'Right Thigh',
        LKNEE: 'Left Knee',
        RKNEE: 'Right Knee',
        LSHIN: 'Left Shin',
        RSHIN: 'Right Shin',
        LANK: 'Left Ankle',
        RANK: 'Right Ankle',
        LBTO: 'Left Big Toe',
        LITO: 'Left Index Toe',
        LMTO: 'Left Middle Toe',
        LFTO: 'Left Fourth Toe',
        LLTO: 'Left Little Toe',
        RBTO: 'Right Big Toe',
        RITO: 'Right Index Toe',
        RMTO: 'Right Middle Toe',
        RFTO: 'Right Fourth Toe',
        RLTO: 'Right Little Toe',
        LHEEL: 'Left Heel',
        RHEEL: 'Right Heel',
        LMFOOT: 'Left Mid Foot',
        RMFOOT: 'Right Mid Foot',
        NECK: 'Neck',
        MBACK: 'Mid Back',
        LBACK: 'Lower Back',
        PELV: 'Pelvis',
        CHES: 'Chest',
        STOM: 'Stomach',
        RFARM: 'Right Forearm',
        LFARM: 'Left Forearm',
        LTRI: 'Left Tricep',
        RTRI: 'Right Tricep',
        LCALF: 'Left Calf',
        RCALF: 'Right Calf',
        BISHOU: 'Both Shoulders',
        BIHIP: 'Both Hips',
        BIKNEE: 'Both Knees',
        BIANKLE: 'Both Ankles',
        BIWRIST: 'Both Wrists',
        RIGHTHAND: 'Right Hand',
        LEFTHAND: 'Left Hand',
        RIGHTFOOT: 'Right Foot',
        LEFTFOOT: 'Left Foot',
        BIHAND: 'Both Hands',
        LHANDBACK: 'Left Back of Hand',
        RHANDBACK: 'Right Back of Hand',
        HEAD: 'Head',
        BIBIC: 'Both Biceps',
        BIELB: 'Both Elbows',
        BITFING: 'Both Thumbs',
        BIPFING: 'Both Index Fingers',
        BIMFING: 'Both Middle Fingers',
        BIRFING: 'Both Ring Fingers',
        BIPINKIE: 'Both Pinkie Fingers',
        BIPALM: 'Both Palms',
        BITHI: 'Both Thighs',
        BISHIN: 'Both Shins',
        BIBTO: 'Both Big Toes',
        BIITO: 'Both Index Toes',
        BIMTO: 'Both Middle Toes',
        BIFTO: 'Both Fourth Toes',
        BILTO: 'Both Little Toes',
        BIHEEL: 'Both Heels',
        BIMFOOT: 'Both Mid Feet',
        BIFARM: 'Both Forearms',
        BITRI: 'Both Triceps',
        BICALF: 'Both Calves',
        BIFOOT: 'Both Feet',
        BIHANDBACK: 'Both Backs of Hands'
      },

      insurancePriorities: {
        primary: 1,
        secondary: 2
      }
    }
  },

  watch: {
    isAuthenticated (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('user:authenticated', newValue)
      }
    },

    isInitialized (newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$emit('store:initialized')
      }
    },
    $data: {
      handler: function (val, oldVal) {
        localStorage.setItem('storedata', JSON.stringify(val))
      },
      deep: true
    }
  },

  created: function () {
    if (this.providerFirstName == null || this.providerLastName == null) {
      const obj = JSON.parse(localStorage.getItem('storedata'))
      if (obj) {
        Object.assign(this, obj)
      }
    }
  },

  computed: {},

  methods: {
    userAuthenticated (idToken, sessionId, hasVisit, completion) {
      this.isLoggingIn = true
      this.clearAuthorizationState()
      this.idToken = idToken
      this.sessionId = sessionId
      this.hasVisit = hasVisit
      axios.defaults.headers.common.Authorization = 'Bearer ' + this.idToken
      axios.defaults.headers.common['X-HH-Session-Id'] = this.sessionId
      this.trackEvent('Session', 'Authenticated', this.sessionId)
      this.setUserId(this.sessionId)

      if (hasVisit) {
        if (this.isPaymentPassSearch) {
          api.get('/Mobile/PaymentPassModules').then(response => {
            this.trackEvent('Store', 'LoadPaymentPassModules', 'ResponseTime', response.config.requestDuration)

            const filteredModules = response.data.filter((module) => {
              return module.code !== 'TokenizeBanner'
            })

            const modules = filteredModules
            let successconfig = {}
            let index = -1
            // find our config for success and hide it from the menu
            for (let i = 0; i < modules.length; i++) {
              if (modules[i].code === 'WebSuccess') {
                successconfig = modules[i].configurationItems
                index = i
              }
            }
            this.$store.successconfig = successconfig

            if (index !== -1) {
              modules.splice(index, 1)
            }

            this.modules = filteredModules
            this.isLoggingIn = false
            this.isAuthenticated = true
            this.persistSession()
            completion()
          }).catch(error => {
            this.isLoggingIn = false
            if (error.response && error.response.status === 401) {
              this.logoutUser()
              router.push('/patient-search')
            } else {
              this.logErrorResponse('Store', 'LoadPaymentPassModulesError', error)
              this.$log.error('Error attempting to load modules: ' + error.message, error)
            }
          })
        } else {
          api.get('/Mobile/IntakeModules').then(response => {
            this.trackEvent('Store', 'LoadModules', 'ResponseTime', response.config.requestDuration)

            const filteredModules = response.data.filter((module) => {
              return module.code !== 'TokenizeBanner'
            })
            const modules = filteredModules
            let successconfig = {}
            let index = -1
            // find our config for success and hide it from the menu
            for (let i = 0; i < modules.length; i++) {
              if (modules[i].code === 'WebSuccess') {
                successconfig = modules[i].configurationItems
                index = i
              }
            }
            this.$store.successconfig = successconfig

            if (index !== -1) {
              modules.splice(index, 1)
            }

            this.modules = filteredModules
            this.isLoggingIn = false
            this.isAuthenticated = true
            this.persistSession()
            completion()
          }).catch(error => {
            this.isLoggingIn = false
            if (error.response && error.response.status === 401) {
              this.logoutUser()
              router.push('/patient-search')
            } else {
              this.logErrorResponse('Store', 'LoadModulesError', error)
              this.$log.error('Error attempting to load modules: ' + error.message, error)
            }
          })
        }
      } else {
        this.isLoggingIn = false
        this.isAuthenticated = true
        this.persistSession()
        completion()
      }
    },

    logoutUser () {
      this.trackEvent('Session', 'Logout')
      this.resetUserId()
      this.$log.debug('logout user triggered')

      delete api.defaults.headers.common.Authorization
      delete api.defaults.headers.common['X-HH-Session-Id']
      this.isLoggingIn = false
      this.idToken = null
      this.sessionId = null
      this.modules = []
      this.isAuthenticated = false
      localStorage.removeItem('idToken')
      localStorage.removeItem('sessionId')
      localStorage.removeItem('hasVisit')
      localStorage.removeItem('isVirtualVisit')
      localStorage.removeItem('isPaymentPassSearch')
      localStorage.removeItem('storedata')
    },

    persistSession () {
      localStorage.setItem('idToken', this.idToken)
      localStorage.setItem('sessionId', this.sessionId)
      localStorage.setItem('hasVisit', this.hasVisit)
      localStorage.setItem('isVirtualVisit', this.isVirtualVisit)
      localStorage.setItem('isPaymentPassSearch', this.isPaymentPassSearch)
    },

    restoreSession () {
      // check route to make sure we dont try to load things for 'token' links
      // unfortunately this is called before the router has been initialized
      // and will need to be parsed by hand

      const currentURL = new URL(window.location)
      const hashParts = currentURL.hash.substr(1).split('/') // #/slug/slug
      const stub = { name: hashParts[1] }
      if (router.isUnprotectedRoute(stub)) {
        return
      }

      try {
        localStorage.getItem('idToken')
      } catch (exception) {
        this.trackEvent('Store', 'LocalStorageError')

        this.$log.error('Exception accessing localstorage', exception)
        this.isLoggingIn = false
        this.isAuthenticated = false
        router.push('/error')
        return
      }

      const storedToken = localStorage.getItem('idToken')
      const storedSession = localStorage.getItem('sessionId')
      const hasVisit = localStorage.getItem('hasVisit')
      const isVirtualVisit = localStorage.getItem('isVirtualVisit')
      const isPaymentPassSearch = localStorage.getItem('isPaymentPassSearch')

      if (hasVisit === 'true') {
        this.hasVisit = true
      } else {
        this.hasVisit = false
      }
      if (isVirtualVisit === 'true') {
        this.isVirtualVisit = true
      } else {
        this.isVirtualVisit = false
      }
      if (isPaymentPassSearch === 'true') {
        this.isPaymentPassSearch = true
      } else {
        this.isPaymentPassSearch = false
      }
      if (storedToken && storedSession) {
        this.trackEvent('Session', 'Restore')
        this.userAuthenticated(storedToken, storedSession, hasVisit, () => {
          if (router.currentRoute.name === 'home') {
            router.push(this.nextRoute())
          }
        })
      } else if (this.isAuthorizationInProgress()) {
        this.trackEvent('Authorization', 'Restore')
        this.restoreAuthorizationState()
      } else {
        this.logoutUser()
      }
      this.isInitialized = true
    },

    startAuthorization (patientSearchResponse) {
      this.patientSearchResponse = patientSearchResponse
      this.webClientId = patientSearchResponse.webClientId

      localStorage.setItem('webClientId', this.webClientId)
      localStorage.setItem('patientSearchResponse', JSON.stringify(this.patientSearchResponse))
      localStorage.setItem('authorizationStartDate', new Date())
    },

    isAuthorizationInProgress () {
      const authorizationDateValue = localStorage.getItem('authorizationStartDate')
      if (authorizationDateValue) {
        const authroizationDate = new Date(authorizationDateValue)
        const nowMillis = new Date().getTime()
        const twentyMinutesInMillis = 1000 * 60 * 20
        if (authroizationDate && nowMillis - authroizationDate.getTime() < twentyMinutesInMillis) {
          this.trackEvent('Authorization', 'InProgress')
          return true
        } else {
          this.clearAuthorizationState()
        }
      }

      return false
    },

    restoreAuthorizationState () {
      const webClientIdValue = localStorage.getItem('webClientId')
      if (webClientIdValue) {
        this.webClientId = webClientIdValue
      }
      const patientSearchResponseValue = localStorage.getItem('patientSearchResponse')
      if (patientSearchResponseValue) {
        this.patientSearchResponse = JSON.parse(patientSearchResponseValue)
      }
    },

    clearAuthorizationState () {
      this.trackEvent('Authorization', 'Clear')
      localStorage.removeItem('webClientId')
      localStorage.removeItem('patientSearchResponse')
      localStorage.removeItem('authorizationStartDate')
      localStorage.removeItem('storedata')
    },

    canHandleNetworkError (error) {
      if (error.response && error.response.status === 401) {
        this.trackEvent('Session', 'Error', '401 Error')
        this.logoutUser()
        router.push('/patient-search')
        return true
      }

      return false
    },

    logErrorResponse (component, name, error) {
      let errorMessage = ''
      if (error.response) {
        if (error.response.data) {
          this.setErrorsFromResponse(error.response.data)
          if (this.hasError('exception')) {
            errorMessage = this.errorForField('exception')
          } else {
            errorMessage = this.errorsSummary()
          }
        }
      }
      if (!errorMessage && error.message) {
        errorMessage = error.message
      }

      this.trackEvent(component, name, errorMessage)
    },

    uppercaseFirstLetters (string) {
      if (string) {
        return string.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }

      return string
    },

    nextRoute () {
      const firstModule = this.modules.filter((aModule) => {
        return aModule.completed === false
      }).shift()
      if (firstModule) {
        return this.firstRouteForModule(firstModule)
      } else {
        if (this.isPaymentPassSearch) {
          return '/payment-pass-complete'
        } else {
          return '/precheck-complete'
        }
      }
    },

    firstRouteForModule (module) {
      if (module.code === 'Demographics') {
        return '/demographics'
      } else if (module.code === 'PatientPhoto') {
        return '/patient-photo'
      } else if (module.code === 'VisitPhoto') {
        return '/visit-photo'
      } else if (module.code === 'Address') {
        return '/address'
      } else if (module.code === 'Blocker') {
        return '/visit-check'
      } else if (module.code === 'WorkersCompPrompt') {
        return '/workers-comp'
      } else if (module.code === 'MarriageStatus') {
        return '/spouse'
      } else if (module.code === 'FluVaccine') {
        return '/flu-vaccine'
      } else if (module.code === 'Insurance') {
        return { name: 'insurance', params: { priority: 'primary' } }
      } else if (module.code === 'FamilyHistory') {
        return '/family-history'
      } else if (module.code === 'Allergies') {
        return '/allergies'
      } else if (module.code === 'MedicalHistory') {
        return '/medical-history'
      } else if (module.code === 'Medications') {
        return '/medications'
      } else if (module.code === 'SocialHistory') {
        const promptChildren = this.boolForModuleConfig(module.code, 'PromptNumberChildren', false)
        const promptInterests = this.boolForModuleConfig(module.code, 'PromptInterests', false)
        const promptTobacco = this.boolForModuleConfig(module.code, 'PromptTobacco', true)
        if (promptChildren || promptInterests) {
          return '/social-history'
        } else if (promptTobacco) {
          return '/tobacco-usage'
        } else {
          return '/alcohol-usage'
        }
      } else if (module.code === 'SocialSetting') {
        return '/living-setting'
      } else if (module.code === 'OrthoReasonForVisit') {
        return '/body-regions'
      } else if (module.code === 'Pharmacy') {
        return '/pharmacy-confirmation'
      } else if (module.code === 'Payment') {
        return '/visit-payment'
      } else if (module.code === 'TokenizeBanner') {
        return '/tokenize-banner'
      } else if (module.code === 'ProviderThanks') {
        return '/provider-thanks'
      } else if (module.code === 'ReviewOfSystems2') {
        return '/review-of-systems'
      } else if (module.code === 'Surgeries') {
        return '/surgeries'
      } else if (module.code === 'HowDidYouHearAboutUs') {
        return '/how-did-you-hear-about-us-referral'
      } else if (module.code === 'PrimaryCarePhysician') {
        return '/primary-physician'
      } else if (module.code === 'VisitGuarantor') {
        return '/visit-guarantor'
      } else if (module.code === 'Consent') {
        return '/consent'
      } else if (module.code === 'DLFront') {
        return '/drivers-license-capture'
      } else if (module.code === 'OrthoPainSeverity') {
        return { name: 'ortho-severity', params: { standalone: true } }
      } else if (module.code === 'Senior') {
        return '/falls'
      } else if (module.code === 'Employment') {
        return '/employment'
      } else if (module.code === 'HIPAAContact') {
        return '/hipaa-contacts'
      } else if (module.code === 'ConservativeTherapy') {
        return '/conservative-therapies'
      } else if (module.code === 'SpecialAlerts') {
        return '/special-alerts'
      } else if (module.code === 'OncologyMedicalHistory') {
        return '/oncology-medical-history'
      } else if (module.code === 'SurveySet') {
        return '/patient-survey'
      } else if (module.code === 'SpecialBenefits') {
        return '/special-benefits-employer'
      } else {
        this.trackEvent('Store', 'ModuleDefaultRouteNotFound', module.code)
        return '/'
      }

    },

    isModuleEnabledByCode (code) {
      const module = this.modules.filter(module => {
        return module.code === code
      })

      return !!module.length
    },

    boolForModuleConfig (code, configName, defaultValue) {
      const module = this.configForModule(code)
      if (module && module.configurationItems && module.configurationItems.length > 0) {
        const configItem = module.configurationItems.filter(item => {
          return item.name === configName
        }).shift()

        if (configItem) {
          return configItem.boolValue
        }
      }

      return defaultValue
    },

    configForModule (code) {
      const matchingModules = this.modules.filter(module => {
        return module.code === code
      })

      if (matchingModules) {
        return matchingModules[0]
      } else {
        this.$log.error('Could not find module for code: ' + code)
      }
    },

    setModuleStatus (code, completed, completion, immediate) {
      const body = {
        code,
        complete: completed
      }

      // update immediately so we can proceed while request is in flight
      if (immediate) {
        const moduleConfig = this.configForModule(code)
        if (moduleConfig) {
          moduleConfig.completed = completed
        }
      }

      api.post('/Patient/IntakeModule/Status', body).then(response => {
        this.trackEvent('Store', 'UpdateModuleStatus', 'ResponseTime', response.config.requestDuration)
        const moduleConfig = this.configForModule(code)
        if (moduleConfig) {
          moduleConfig.completed = completed
        } else {
          this.trackEvent('Store', 'UpdateModuleStatusConfigNotFound', code)
          this.$log.error('Could not find configForModule code ' + code)
        }
        completion()
      }).catch(error => {
        if (error.response && error.response.status === 401) {
          this.logoutUser()
        } else {
          this.logErrorResponse('Store', 'ModuleStatusUpdateError', error)
          // There are currently exceptions from org-int filtering through this
          // endpoint so for now we just keep moving the user through
          const moduleConfig = this.configForModule(code)
          if (moduleConfig) {
            moduleConfig.completed = completed
          } else {
            this.trackEvent('Store', 'UpdateModuleStatusConfigNotFound', code)
          }
          completion()
          this.$log.error('Error attempting to load modules: ' + error.message, error)
        }
      })
    },

    codesForBodyPart (bodyPart) {
      const codes = []
      if (bodyPart.code) {
        codes.push(bodyPart.code)
      } else {
        if (bodyPart.sidedness) {
          if (bodyPart.leftCode) {
            codes.push(bodyPart.leftCode)
          } else {
            codes.push('L' + bodyPart.codeSuffix)
          }
          if (bodyPart.rightCode) {
            codes.push(bodyPart.rightCode)
          } else {
            codes.push('R' + bodyPart.codeSuffix)
          }
        }

        if (bodyPart.bilateral) {
          if (bodyPart.bilateralCode) {
            codes.push(bodyPart.bilateralCode)
          } else {
            codes.push('BI' + bodyPart.codeSuffix)
          }
        }
      }

      return codes
    },

    isBodyPartSelected (bodyPart) {
      const codes = this.codesForBodyPart(bodyPart)

      const matchedCode = this.selectedBodyParts.filter(aCode => {
        return codes.includes(aCode)
      }).shift()

      if (matchedCode) {
        return true
      }

      return false
    },

    deselectBodyPart (bodyPart) {
      const codes = this.codesForBodyPart(bodyPart)
      this.selectedBodyParts = this.selectedBodyParts.filter(aCode => {
        return !codes.includes(aCode)
      })
    },

    mapInsurancePriorityId (name) {
      return this.insurancePriorities[name]
    },
    mapInsurancePriorityName (id) {
      for (const key in this.insurancePriorities) {
        if (this.insurancePriorities[key] === id) {
          return key
        }
      }
    }
  }
})

export default store
