<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Tell us about your tobacco usage.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Tobacco Usage" :state="stateForField('tobaccoUsageId')" :invalid-feedback="errorForField('tobaccoUsageId')">
                                <b-form-select size="lg" @input="clearFieldError('tobaccoUsageId')" v-model="socialHistory.tobaccoUsageId" :state="stateForField('tobaccoUsageId')" :options="tobaccoUsageRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showStartDate" label="Start Date" :state="stateForField('tobaccoStartDate')" :invalid-feedback="errorForField('tobaccoStartDate')">
                                <b-input-group>
                                    <b-form-select size="lg" @input="clearFieldError('tobaccoStartDate')" v-model="socialHistory.startDateMonth" :state="stateForField('tobaccoStartDate')" :options="monthRadioOptions"></b-form-select>
                                    <b-form-select size="lg" @input="clearFieldError('tobaccoStartDate')" v-model="socialHistory.startDateYear" :state="stateForField('tobaccoStartDate')" :options="yearRadioOptions"></b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group v-if="showQuitDate" label="Quit Date" :state="stateForField('tobaccoQuitDate')" :invalid-feedback="errorForField('tobaccoQuitDate')">
                                <b-input-group>
                                    <b-form-select size="lg" @input="clearFieldError('tobaccoQuitDate')" v-model="socialHistory.quitDateMonth" :state="stateForField('tobaccoQuitDate')" :options="monthRadioOptions"></b-form-select>
                                    <b-form-select size="lg" @input="clearFieldError('tobaccoQuitDate')" v-model="socialHistory.quitDateYear" :state="stateForField('tobaccoQuitDate')" :options="yearRadioOptions"></b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showTobaccoType" label="Tobacco Type" :state="stateForField('tobaccoTypes')" :invalid-feedback="errorForField('tobaccoTypes')">
                                <b-form-input @click.prevent="tobaccoTypeModal" size="lg" class="tap-input" readonly :state="stateForField('tobaccoTypes')" v-model="selectedTobaccoTypes"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group v-if="showTobaccoPacks" label="Packs Per Day" :state="stateForField('tobaccoUsagePackQuantity')" :invalid-feedback="errorForField('tobaccoUsagePackQuantity')">
                                <b-form-select size="lg" @input="clearFieldError('tobaccoUsagePackQuantity')" v-model="socialHistory.tobaccoUsagePackQuantity" :state="stateForField('tobaccoUsagePackQuantity')" :options="tobaccoPacksRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showTobaccoCans" label="Cans per Day" :state="stateForField('tobaccoUsageCanQuantity')" :invalid-feedback="errorForField('tobaccoUsageCanQuantity')">
                                <b-form-select size="lg" @input="clearFieldError('tobaccoUsageCanQuantity')" v-model="socialHistory.tobaccoUsageCanQuantity" :state="stateForField('tobaccoUsageCanQuantity')" :options="tobaccoCansRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <list-modal :header-image="require('../../assets/social-history.svg')" header="Which tobacco types have you used?" @optionSelected="tobaccoTypeSelected" :options="tobaccoTypeOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormInput, BFormSelect, BFormGroup, BInputGroup } from 'bootstrap-vue'
  import moment from 'moment'
  import ListModal from '../../components/ListModal'

  export default {
    name: 'TobaccoUsage',

    components: { BFormInput, BFormGroup, BInputGroup, BFormSelect, ListModal },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        socialHistory: {
          tobaccoUsageOptions: [],
          tobaccoTypeOptions: []
        },
        isSaving: false,
        showListModal: false
      }
    },

    computed: {
      promptDetails () {
        return this.$store.boolForModuleConfig('SocialHistory', 'TobaccoDetails', true)
      },

      tobaccoUsageRadioOptions () {
        return this.optionsFromArray(this.socialHistory.tobaccoUsageOptions, 'tobaccoUsageId')
      },

      selectedUsage () {
        return this.socialHistory.tobaccoUsageOptions.filter(anItem => {
          return anItem.tobaccoUsageId === this.socialHistory.tobaccoUsageId
        }).shift()
      },

      showStartDate () {
        const usageOption = this.selectedUsage

        if (usageOption) {
          return usageOption.hasStartDate
        }

        return false
      },

      showQuitDate () {
        const usageOption = this.selectedUsage

        if (usageOption) {
          return usageOption.hasEndDate
        }

        return false
      },

      showTobaccoType () {
        const usageOption = this.selectedUsage

        if (usageOption) {
          if (usageOption.tobaccoUsageId !== 1 && usageOption.tobaccoUsageId !== 5) {
            return true
          }
        }

        return false
      },

      showTobaccoPacks () {
        if (this.showTobaccoType) {
          const cigaretteType = this.socialHistory.tobaccoTypeOptions.filter(anItem => {
            return anItem.name === 'Cigarettes' && anItem.usesTobaccoType
          }).shift()
          if (cigaretteType) {
            return true
          }
        }

        return false
      },

      showTobaccoCans () {
        if (this.showTobaccoType) {
          const canType = this.socialHistory.tobaccoTypeOptions.filter(anItem => {
            return anItem.name === 'Chewing' && anItem.usesTobaccoType
          }).shift()
          if (canType) {
            return true
          }
        }

        return false
      },

      selectedTobaccoTypes () {
        const types = this.socialHistory.tobaccoTypeOptions.filter(anItem => {
          return anItem.usesTobaccoType
        }).map(anItem => {
          return anItem.name
        })

        if (types) {
          return types.join(', ')
        }

        return ''
      },

      monthRadioOptions () {
        return [
          {
            value: null,
            text: ''
          },
          {
            value: 1,
            text: 'January'
          },
          {
            value: 2,
            text: 'February'
          },
          {
            value: 3,
            text: 'March'
          },
          {
            value: 4,
            text: 'April'
          },
          {
            value: 5,
            text: 'May'
          },
          {
            value: 6,
            text: 'June'
          },
          {
            value: 7,
            text: 'July'
          },
          {
            value: 8,
            text: 'August'
          },
          {
            value: 9,
            text: 'September'
          },
          {
            value: 10,
            text: 'October'
          },
          {
            value: 11,
            text: 'November'
          },
          {
            value: 12,
            text: 'December'
          }
        ]
      },

      yearRadioOptions () {
        const years = ['']
        for (let i = moment().year(); i > 1900; i--) {
          years.push(i)
        }

        return years
      },

      tobaccoPacksRadioOptions () {
        return [
          {
            value: 0.5,
            text: 'Less than one'
          },
          {
            value: 1,
            text: 'One'
          },
          {
            value: 2,
            text: 'Two'
          },
          {
            value: 3,
            text: 'Three or more'
          }
        ]
      },

      tobaccoCansRadioOptions () {
        return [
          {
            value: 1,
            text: 'One'
          },
          {
            value: 2,
            text: 'Two'
          },
          {
            value: 3,
            text: 'Three'
          },
          {
            value: 4,
            text: 'Four'
          },
          {
            value: 5,
            text: 'Five or more'
          }
        ]
      },

      tobaccoTypeOptions () {
        return this.multiselectOptionsFromArray(this.socialHistory.tobaccoTypeOptions, 'tobaccoTypeId', 'name', '', 'usesTobaccoType')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.socialHistory.tobaccoUsageId) {
          this.addErrorForField('tobaccoUsageId', 'Tobacco usage is required.')
        }

        if (this.showStartDate && (!this.socialHistory.startDateMonth || !this.socialHistory.startDateYear)) {
          this.addErrorForField('tobaccoStartDate', 'Start date is required')
        }

        if (this.showQuitDate && (!this.socialHistory.quitDateMonth || !this.socialHistory.quitDateYear)) {
          this.addErrorForField('tobaccoQuitDate', 'Quit date is required')
        }

        const selectedType = this.socialHistory.tobaccoTypeOptions.filter(anItem => {
          return anItem.usesTobaccoType
        }).shift()
        if (this.showTobaccoType && !selectedType) {
          this.addErrorForField('tobaccoTypes', 'Tobacco types are required')
        }

        if (this.showTobaccoPacks && !this.socialHistory.tobaccoUsagePackQuantity) {
          this.addErrorForField('tobaccoUsagePackQuantity', 'Tobacco packs are required')
        }

        if (this.showTobaccoCans && !this.socialHistory.tobaccoUsageCanQuantity) {
          this.addErrorForField('tobaccoUsageCanQuantity', 'Cans are required')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SocialHistory').then(response => {
          this.socialHistory = response.data
          if (this.socialHistory.tobaccoStartDate) {
            const startDate = moment(this.socialHistory.tobaccoStartDate)
            this.socialHistory.startDateMonth = startDate.month() + 1
            this.socialHistory.startDateYear = startDate.year()
          }
          if (this.socialHistory.tobaccoQuitDate) {
            const quitDate = moment(this.socialHistory.tobaccoQuitDate)
            this.socialHistory.quitDateMonth = quitDate.month() + 1
            this.socialHistory.quitDateYear = quitDate.year()
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        if (this.showStartDate) {
          this.socialHistory.tobaccoStartDate = this.socialHistory.startDateYear + '-' + this.socialHistory.startDateMonth + '-01'
        } else {
          this.socialHistory.tobaccoStartDate = null
        }

        if (this.showQuitDate) {
          this.socialHistory.tobaccoQuitDate = this.socialHistory.quitDateYear + '-' + this.socialHistory.quitDateMonth + '-01'
        } else {
          this.socialHistory.tobaccoQuitDate = null
        }

        this.isSaving = true
        api.put('/Patient/SocialHistory', this.socialHistory).then(response => {
          this.$router.push('alcohol-usage')
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      tobaccoTypeModal () {
        this.clearFieldError('tobaccoTypes')
        this.showListModal = true
      },

      tobaccoTypeSelected (item) {
        const selectedItem = this.socialHistory.tobaccoTypeOptions.filter((anItem) => {
          return anItem.tobaccoTypeId === item.id
        }).shift()

        if (selectedItem) {
          selectedItem.usesTobaccoType = !selectedItem.usesTobaccoType
        }
      }

    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
