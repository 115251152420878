import { render, staticRenderFns } from "./ConsentItemDetail.vue?vue&type=template&id=3c238455&scoped=true"
import script from "./ConsentItemDetail.vue?vue&type=script&lang=js"
export * from "./ConsentItemDetail.vue?vue&type=script&lang=js"
import style0 from "./ConsentItemDetail.vue?vue&type=style&index=0&id=3c238455&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c238455",
  null
  
)

export default component.exports