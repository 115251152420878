<template>
  <div>
    <div class="row">
      <div class="col mt-4">
        <div><h5><p class="instructions" v-html="question.instructionsHtml"></p></h5></div>
      </div>
    </div>
  <div :class="{ 'container-fluid mt-4' : question.type==='Select' }">
    <div :class="{ 'container-adjustments': question.type==='Slider' }" >
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>

    </div>
    <div class="col mt-3">
      <div><h5><p v-html="question.titleHtml"></p></h5></div>
      <div v-if="question.type === 'Select'" class="big-title">
      <multiselect-list :is-selected="isAnswerSelected" :options="options" @optionSelected="answerSelected"></multiselect-list>
      </div>
      <div v-else-if="question.type === 'Slider'">
        <div class="row"></div>
        <div class="col-xl-3"></div>
        <div class="col-m-0">
        <div class="range">
          <input type="range" min="0" :max="question.answers.length - 1" @change="updateInput()" v-model="sliderValue" class="slider">
          <div class="sliderticks">
            <div v-for="answer in question.answers" :key="answer.id">
                <p @click.prevent="setSliderValue(answer)">{{answer.name}}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div v-else-if="question.type === 'NumericScale'">
        <div class="row mt-4 mb-4">
          <div class="col text-center">
            <p class="slider-value">{{ this.sliderValue }}</p>
          </div>
        </div>
        <div class="row"></div>
        <div class="col-xl-3"></div>
        <div class="range-parent col-m-0">
          <div>
              <input type="range" :min="question.rangeStart" :max="question.rangeEnd" @change="updateInput()" v-model="sliderValue" class="slider">
          </div>
          <div class="range-container">
          <div class="range-number-label mt-3">
            <span class="left">{{question.rangeStart}}</span>
            <span class="right">{{question.rangeEnd}}</span>
          </div>
          <div>
            <span class="left">{{question.label1}}</span>
            <span class="right text-right">{{question.label2}}</span>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col text-center mt-4">
        <button type="button" @click.prevent="saveAnswer()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
      </div>
    </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import _ from 'lodash'

  export default {
    name: 'PatientSurveyQuestion',

    components: { MultiselectList },

    props: {
      currentSurvey: {},
      currentQuestion: {}
    },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        questionId: 0,
        organizationPatientSurveyResultId: 0,
        surveyCount: 1,
        token: null,
        isSaving: false,
        selectedAnswer: {
          id: 0
        },
        question: {
          instructionsHtml: '',
          answers: []
        },
        sliderValue: 0,
        numericScale: []
      }
    },

    watch: {
      currentQuestion (newValue, oldValue) {
        this.selectedAnswer = {
          id: 0
        }

        this.questionId = _.clone(this.$route.query.questionId)
        this.token = _.clone(this.$route.query.token)
        this.surveyCount = _.clone(this.$route.query.surveyCount)
        this.organizationPatientSurveyResultId = _.clone(this.$route.query.organizationPatientSurveyResultId)

        if (this.$props.currentQuestion != null) {
          this.question = _.clone(this.$props.currentQuestion)
          this.sortAnswers()
        } else {
          api.get('/Patient/Survey/Question/' + this.questionId).then(response => {
            this.question = response.data
            this.sortAnswers()

          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error attempting to get question: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          })
        }
      }
    },

    computed: {
      options () {
        const answers = []

        for (const answer of this.question.answers) {
          const option = {
            id: answer.surveyAnswerId,
            name: answer.name,
            options: this.optionsFromArray(answer.question, 'surveyQuestionId')
          }
          answers.push(option)
        }

        return answers
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.question.type === 'NumericScale') {
          if (!this.sliderValue) {
            this.addErrorForField('exception', 'You must select an answer to continue')
          }
        }

        if (this.question.type !== 'NumericScale') {
          if (!this.selectedAnswer || !this.selectedAnswer.id) {
            this.addErrorForField('exception', 'You must select an answer to continue')
          }
        }
        return !this.hasErrors()
      },

      setSliderValue (value) {
        this.selectedAnswer = value
        this.selectedAnswer.id = value.surveyAnswerId
      },

      updateInput () {
        this.selectedAnswer = this.question.answers[this.sliderValue]
        this.selectedAnswer.id = this.selectedAnswer.surveyAnswerId
      },

      setNumericScale () {
        if (this.question.type === 'NumericScale' && this.question.rangeStart != null && this.question.rangeEnd != null) {
          this.numericScale.push({
            range: this.question.rangeStart,
            label: this.question.label1
          })
          this.numericScale.push({
            range: this.question.rangeEnd,
            label: this.question.label2
          })
        }
      },

      saveAnswer: function () {
        if (this.validate() !== true) {
          return
        }
        let questionAnswer = {}

        if (this.question.type === 'NumericScale') {
          questionAnswer = {
            organizationPatientSurveyResultId: this.organizationPatientSurveyResultId,
            surveyQuestionId: this.question.surveyQuestionId,
            points: this.sliderValue,
            surveyAnswerId: null
          }
        } else {
          questionAnswer = {
            organizationPatientSurveyResultId: this.organizationPatientSurveyResultId,
            surveyQuestionId: this.question.surveyQuestionId,
            surveyAnswerId: this.selectedAnswer.id
          }
        }

        api.post('/Patient/Survey/Answer', questionAnswer).then(response => {
          this.isSaving = false
          if (response.data.nextQuestion == null) {
            if (this.surveyCount <= 1) {
              this.completeSurveySet()
            } else {
              this.nextSurveyInSet()
            }
          } else {
            this.nextQuestion(response.data.nextQuestion)
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to update answer: ' + error.message, error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      completeSurveySet () {
        if (this.token) {
          this.$router.push({
            name: 'patient-survey-complete'
          })
        } else {
          this.$store.setModuleStatus('SurveySet', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }
      },

      nextSurveyInSet () {
        if (this.token) {
          this.$router.push({
            name: 'patient-survey-token',
            params: { lastCompletedSurveyId: this.currentSurvey.organizationSurveyId, token: this.token }
          })
        } else {
          this.$router.push({
            name: 'patient-survey',
            params: { lastCompletedSurveyId: this.currentSurvey.organizationSurveyId }
          })
        }
      },

      nextQuestion (question) {
        this.$router.push({
          name: 'patient-survey-question',
          params: {
            currentSurvey: this.currentSurvey,
            currentQuestion: question,
            organizationPatientSurveyResultId: this.organizationPatientSurveyResultId,
            token: this.token,
            surveyCount: this.surveyCount
          },
          query: { questionId: question.surveyQuestionId, organizationPatientSurveyResultId: this.organizationPatientSurveyResultId, token: this.token, surveyCount: this.surveyCount } // vue sucks
        })
      },

      isAnswerSelected (item) {
        if (item.id === this.selectedAnswer.id) {
          return true
        }

        return false
      },

      answerSelected (item) {
        this.clearFieldError('exception')
        if (item) {
          this.selectedAnswer = item
        }
      },

      sortAnswers () {
        this.question.answers.sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
      }
    },

    created () {
      this.question = _.clone(this.$props.currentQuestion)
      this.questionId = _.clone(this.$route.query.questionId)
      this.token = _.clone(this.$route.query.token)
      this.surveyCount = _.clone(this.$route.query.surveyCount)
      this.organizationPatientSurveyResultId = _.clone(this.$route.query.organizationPatientSurveyResultId)
      this.sortAnswers()
      this.setNumericScale()
    }
  }

</script>

<style scoped>

.big-title {
}

.big-title >>> .option {
  box-shadow: none;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.big-title >>> .title {
  padding-top:0px;
  padding-bottom:0px;
}

h5 {
  text-align: center;
}

@media (min-width: 320px) and (max-width: 768px) {
  .container-fluid {
    border: 2px solid transparent;
    box-shadow: 2px 2px 5px darkgray;
    width: 350px;
  }
  .range-parent {
    padding:0 20px;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    border: 2px solid transparent;
    box-shadow: 2px 2px 5px darkgray;
    width: 75%;
  }
  .range-parent {
    padding:0 200px;
  }
  .big-title >>> .d-flex {
    display: flex;
    flex-direction: column;
    align-content: space-around;
  }

  .big-title >>> .option {
    align-items: center;
    border: none;
  }

  h5 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
  }
}

  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: rgba(23,56,96,1.0);
    outline: none;
    cursor: pointer;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background: rgba(255,62,3,1.0);
    cursor: pointer;
    border-radius: 20px;
    z-index: 15;
  }

  .slider::-moz-range-thumb {
    width: 40px;
    height: 40px;
    background: rgba(255,62,3,1.0);
    cursor: pointer;
    border-radius: 20px;
    z-index: 15;
  }
  .sliderticks {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
.slider-value {
  font-size: 24px;
  border: 2px solid rgba(255,62,3,1.0);
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-top: 10px;
}
  .container-adjustments {
    border: none;
    box-shadow: none;
    width: auto;
  }

.instructions{
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

.right{
  float:right;
  max-width: 50%;
}
.left{
  float:left;
  max-width: 50%;
}
.range-number-label {
  font-size: 24px;
  font-weight: bold;
  color: rgba(255,62,3,1.0);
  cursor: pointer;
}
.range-container {
  display: flex;
  flex-direction: column;
}
.sliderticks p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 1px;
  font-size: 24px;
  font-weight: bold;
  height: 10px;
  line-height: 40px;
  margin: 10px 0 20px 0;
  color: rgba(255,62,3,1.0);
  cursor: pointer;
}
</style>
