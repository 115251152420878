<template>
    <div class="container-fluid">

        <div class="row">
            <div class="col">
                <h1 class="header">Please place your drivers license <strong>face up</strong> and take a photo.</h1>
            </div>
        </div>

        <div class="row video-window-clipping" style="background-color: black;">
            <div v-if="!image" class="photo-prompts-alignment-container">
                <img class="photo-prompts-alignment-image"  src="../../assets/card-corners.png">
            </div>
            <div v-if="!image" class="photo-prompts-card">
                <h3>DRIVERS LICENSE FRONT</h3>
            </div>
            <div v-if="image" class="photo-prompts-checkmark">
                <font-awesome-icon icon="check-circle"/>
            </div>
            <div :class="{ 'portrait-adjustments': this.screenWidth > 600 && this.inPortraitMode }" >
                <div v-if="image">
                    <img :style="{width: this.imageWidth}" :src="image">
                </div>
                <web-cam ref="webcam"
                         :device-id="deviceId"
                         width="100%"
                         :height="this.screenWidth < 500 ? '500px' : this.inPortraitMode ? '800px' : '450px'"
                         @error="onError"
                         @cameras="onCameras"
                />
            </div>
        </div>

        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger error-message-spacing" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>

        <div class="row">
            <div class="col-md-12 custom-button-center">
                <button v-if="!image" type="button" class="btn btn-primary btn-lg mx-auto" @click="onCapture">Take Photo</button>
                <button v-else type="button" class="btn btn-primary btn-lg mx-auto" @click="clearImages">Retake Photo</button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 custom-button-center">
                <div class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%" @click.prevent="submit()"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</div>
            </div>
        </div>

    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import WebCam from '../../components/WebCam.vue'
  import { UAParser } from 'ua-parser-js'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'DriversLicenseCapture',

    components: { WebCam },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {},

    data () {
      return {
        image: null,
        camera: null,
        deviceId: null,
        devices: [],
        isSaving: false,
        visibleHeight: 420,
        screenWidth: null,
        imageWidth: null,
        inPortraitMode: false
      }
    },

    watch: {
      camera: function (id) {
        this.deviceId = id
      },
      devices: function () {
        const first = this.devices[0]
        if (first) {
          this.camera = first.deviceId
          this.deviceId = first.deviceId
        }
      }
    },

    mounted () {
      this.screenWidth = screen.width
      this.inPortraitMode = window.matchMedia('(orientation: portrait)').matches
      if (!this.canCaptureCards()) {
        this.continueModule()
      }
      this.checkForSavedImage()
    },

    methods: {
      canCaptureCards () {
        const parser = new UAParser()
        const deviceType = parser.getResult().device.type
        if (['mobile', 'tablet'].includes(deviceType)) {
          return true
        }
        this.trackEvent(this.$options.name, 'CameraDetection', 'SkippingDeviceType', deviceType)
        return false
      },

      onCapture () {
        this.trackEvent(this.$options.name, 'ImageCaptured')
        this.imageWidth = screen.width < 600 ? `${screen.width}px` : '600px'
        this.image = this.$refs.webcam.capture()
        this.clearAllErrors()
      },

      onError (error) {
        this.trackEvent(this.$options.name, 'CameraAccessError', error)
        this.continueModule()
      },

      onCameras (cameras) {
        this.trackEvent(this.$options.name, 'CamerasLoaded', cameras ? cameras.length : 'None')
        this.devices = cameras
      },

      clearImages () {
        this.image = null
      },

      checkForSavedImage () {
        api.get('/Patient/DLFrontPhoto').then(response => {
          this.trackEvent(this.$options.name, 'CheckForSavedImage', 'ResponseTime', response.config.requestDuration)
          if (response.data.imageOnFile === true) {
            this.trackEvent(this.$options.name, 'CheckForSavedImage', 'FoundImage')
            this.continueModule()
          } else {
            this.trackEvent(this.$options.name, 'CheckForSavedImage', 'NoImageFound')
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'FetchError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      uploadImage () {
        const imageFile = new File([this.dataURItoBlob(this.image)], 'dl-front.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)
        api.put('/Patient/DriversLicenseUploadRaw?visibleHeight=' + this.visibleHeight, multipartForm).then(response => {
          this.trackEvent(this.$options.name, 'UploadRawDriversLicense', 'ResponseTime', response.config.requestDuration)
          this.continueModule()
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      continueModule () {
        this.trackEvent(this.$options.name, 'ContinueModule')
        this.$store.setModuleStatus('DLFront', true, () => {
          this.$router.push(this.$store.nextRoute())
        })
      },

      dataURItoBlob (dataURI) {
        // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
        // convert base64/URLEncoded data component to raw binary data held in a string

        if (!dataURI) {
          return
        }

        let byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
      },

      validate () {
        this.clearAllErrors()
        if (!this.image) {
          this.addErrorForField('exception', 'You must take a photo')
        }
        return !this.hasErrors()
      },

      submit () {
        if (this.validate() !== true) {
          return
        }
        this.uploadImage()
        this.isSaving = true
      }
    }
  }
</script>

<style scoped>
    h3 {
        font-weight: bold;
        color: #ffffff;
        margin-top: 30px;
    }
    .container-fluid {
        padding-right: 0
    }
    .row {
        margin-right: 0
    }
    .header {
        margin-bottom: 30px;
    }
    .video-window-clipping {
        max-height: 350px;
        max-width: 600px;
        overflow: hidden;
    }
    @media only screen and (min-width: 600px) {
        .video-window-clipping {
            max-height: 350px;
            max-width: 600px;
            overflow: hidden;
            margin: auto;
        }
        .container-fluid {
            padding-left: 0
        }
    }
    .portrait-adjustments {
        margin: auto;
        width: 100%;
    }
    .photo-prompts-card {
        position: absolute;
        text-align: center;
        height: 350px;
        width: 100%;
        max-width: 600px;
    }
    .photo-prompts-alignment-container {
        position: absolute;
        text-align: center;
        height: 350px;
        width: 100%;
        max-width: 600px;
        vertical-align: middle;
        display: inline-block;
    }
    .photo-prompts-alignment-image {
        max-width: 95%;
        margin-top: 80px;
        border-radius: 5px;
    }
    @media only screen and (min-width: 401px) {
        .photo-prompts-alignment-image {
            max-width: 80%;
        }
    }
    @media only screen and (min-width: 501px) {
        .photo-prompts-alignment-image {
            max-width: 65%;
        }
    }
    @media only screen and (min-width: 401px) and (orientation: landscape) {
        .photo-prompts-alignment-image {
            max-width: 65%;
        }
    }
    .photo-prompts-checkmark {
        position: absolute;
        width: 100%;
        max-width: 600px;
        text-align: right;
        font-size: 65px;
        color: #176cc6;
        height: 350px;
        line-height: 90px;
        padding-right: 15px;
    }
    .custom-button-center {
        margin: 10px auto 0;
        display: flex;
    }
    .error-message-spacing {
        margin-top: 20px;
    }
</style>
