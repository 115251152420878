<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Benefits Confirmation</h1>
        <h2 class="subheader">Are your benefits with this employer?</h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col text-center">
        <img class="img-container" :src="employment.organizationEmployer.logoUrl" :alt="employment.organizationEmployer.name">

          <div class="form-row mt-2">
            <button type="submit" @click.prevent="confirm()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">Confirm</button>
          </div>
          <div class="form-row mt-2">
            <button type="submit" @click.prevent="change()" class="btn btn-outline-primary btn-lg mx-auto" style="width: 80%">Change</button>
          </div>
      </div>
      <div class="col-xl-3"></div>
    </div>

  </div>

</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'EmployerConfirm',

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        employment: {
          organizationEmployerId: 0,
          organizationEmployer: {
            name: '',
            logoUrl: ''
          }
        }
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Employment').then(response => {
          this.employment = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading employment: ' + error.message, error)
            this.setFromApiErrorResponse(error)
            this.trackEvent(this.$options.name, 'GetEmploymentError', error)
          }
        })
      },

      confirm () {
        this.$store.setModuleStatus('Employment', true, () => {
          this.isSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      change () {
        this.$router.push('employer-list')
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
.img-container {
  margin: 20px;
  max-width: 49.9999%;
  max-height: 400px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.img-container img {
  max-width: 100%;
  max-height: 100%;
}

.employer h1 {
  margin-top: 75px;
  font-size: 20px;
  color: rgba(23,56,96,1.0);
  font-weight: bold;
}
</style>
