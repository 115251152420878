<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Additional contact information</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <form @submit.prevent="submit()">
          <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
              <b-form-group label="Phone Number" :state="stateForField('phone')" :invalid-feedback="errorForField('phone')">
                <b-form-input pattern="[0-9]*" @input="numberInput" size="lg" :state="stateForField('phone')" :value="phoneDisplay"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
              <b-form-group label="Phone Number Type" :state="stateForField('phoneType')" :invalid-feedback="errorForField('phoneType')">
                <b-form-select size="lg" @input="clearFieldError('phoneType')" v-model="additionalNumber.phoneType" :state="stateForField('phoneType')" :options="phoneTypeOptions"></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
          </div>
          <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center pt-4">
              <a href = "#" @click.prevent="skip()" class="detail">Skip</a>
            </div>
            <div class="col-xl-3"></div>
          </div>
        </form>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormGroup, BFormInput, BFormSelect } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'DemographicsPhone',
    components: { BFormGroup, BFormInput, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin, DemographicsMixin, AnalyticsMixin],

    data () {
      return {
        demographics: {
          phoneNumbers: []
        },
        additionalNumber: {
          organizationPatientPhoneNumberId: 0,
          area: '',
          exchange: '',
          number: '',
          phoneType: ''
        },
        phoneTypeOptions: [
          {
            text: 'Home',
            value: 'Home'
          },
          {
            text: 'Work',
            value: 'Work'
          }
        ],
        isSaving: false
      }
    },

    computed: {
      phoneDisplay () {
        if (this.additionalNumber.area) {
          if (this.additionalNumber.number.length > 0) {
            return '(' + this.additionalNumber.area + ') ' + this.additionalNumber.exchange + '-' + this.additionalNumber.number
          } else if (this.additionalNumber.exchange.length > 0) {
            return '(' + this.additionalNumber.area + ') ' + this.additionalNumber.exchange
          } else if (this.additionalNumber.area.length > 0) {
            return '(' + this.additionalNumber.area
          }
        }
        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.additionalNumber.area || !this.additionalNumber.exchange || !this.additionalNumber.number) {
          this.addErrorForField('phone', 'A Valid Phone Number is required.')
        }

        if (!this.additionalNumber.phoneType) {
          this.addErrorForField('phoneType', 'Phone Number Type is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Demographics').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.demographics = response.data
          this.demographics.phoneNumbers.forEach(phoneNumber => {
            if (phoneNumber.phoneType === 'Home' || phoneNumber.phoneType === 'Work') {
              this.additionalNumber = phoneNumber
            }
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for loading demographics: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        if (this.additionalNumber.organizationPatientPhoneNumberId) {
          this.demographics.phoneNumbers = this.demographics.phoneNumbers.filter(phoneNumber => phoneNumber.organizationPatientPhoneNumberId !== this.additionalNumber.organizationPatientPhoneNumberId)
        }
        this.demographics.phoneNumbers.push(this.additionalNumber)

        this.isSaving = true
        api.put('/Patient/Demographics', this.demographics).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('Demographics', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.$log.error('Error saving demographics: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      skip () {
        this.$store.setModuleStatus('Demographics', true, () => {
          this.$router.push(this.$store.nextRoute())
        })
      },

      numberInput (value) {
        this.clearFieldError('phone')
        const newNumber = value
        const cleanNumber = newNumber.replace(/\D/g, '')
        if (cleanNumber.length > 0) {
          this.additionalNumber.area = cleanNumber.substr(0, cleanNumber.length > 3 ? 3 : cleanNumber.length)
          if (cleanNumber.length > 3) {
            this.additionalNumber.exchange = cleanNumber.substr(3, cleanNumber.length > 6 ? 3 : cleanNumber.length - 3)
          } else {
            this.additionalNumber.exchange = ''
          }
          if (cleanNumber.length > 6) {
            this.additionalNumber.number = cleanNumber.substr(6, cleanNumber.length < 10 ? cleanNumber.length - 6 : 4)
          } else {
            this.additionalNumber.number = ''
          }
        } else {
          this.additionalNumber.area = ''
          this.additionalNumber.exchange = ''
          this.additionalNumber.number = ''
        }

      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
