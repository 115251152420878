<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Tell us about your recreational drug usage.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Drug Usage" :state="stateForField('drugUsageId')" :invalid-feedback="errorForField('drugUsageId')">
                                <b-form-select size="lg" @input="clearFieldError('drugUsageId')" v-model="socialHistory.drugUsageId" :state="stateForField('drugUsageId')" :options="drugUsageRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup } from 'bootstrap-vue'

  export default {
    name: 'DrugUsage',

    components: { BFormGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        socialHistory: {
          alcoholUsageOptions: []
        },
        isSaving: false,
        showListModal: false
      }
    },

    computed: {
      drugUsageRadioOptions () {
        return this.optionsFromArray(this.socialHistory.drugUsageOptions, 'drugUsageId')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.socialHistory.drugUsageId) {
          this.addErrorForField('drugUsageId', 'Drug usage is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SocialHistory').then(response => {
          this.socialHistory = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/SocialHistory', this.socialHistory).then(response => {
          this.$store.setModuleStatus('SocialHistory', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
