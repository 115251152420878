<template>
    <div class="loading" v-if="shouldSpin">
      <div class="row">
        <div class="col text-center align-self-center">
          <h1><font-awesome-icon class="blue-text" icon="spinner" pulse/></h1>
          Please wait...
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
        <div class="row">
            <div class="col">
              <div v-if="!isCheckedIn"> <!-- in precheck window -->
                <div v-if="hasCustomizations">
                  <h1 class="header">{{ CustomHeading }}</h1>
                </div>
                <div v-else>
                  <h1 class="header">Pre-Check Complete!</h1>
                </div>
              </div>
              <div v-else> <!-- not in precheck window -->
                <div v-if="hasCustomizations">
                  <h1 class="header">{{ CustomHeading }}</h1>
                </div>
                <div v-else>
                  <h1 class="header">You’re checked in!</h1>
                </div>
              </div>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center">
                <img class="complete-image" src="../assets/precheck-complete.svg">
              <div v-if="!isCheckedIn"> <!-- in precheck window -->
                <div v-if="hasCustomizations">
                  <div class="complete-copy">{{ CustomSubHeading }}</div>
                  <div v-if="ExternalLink" class="form-row mt-2">
                      <button type="submit" @click.prevent="leave()" class="btn btn-primary btn-lg mx-auto">{{ ButtonText }}</button>
                  </div>
                </div>
                <div v-else>
                  <div class="complete-copy">
                    Your pre-check has been completed and you are ready for your upcoming appointment.
                  </div>
                </div>
              </div>
              <div v-else> <!-- not in precheck window -->
                <div v-if="hasCustomizations">
                  <div class="complete-copy">{{ CustomSubHeading }}</div>
                  <div v-if="ExternalLink" class="form-row mt-2">
                      <button type="submit" @click.prevent="leave()" class="btn btn-primary btn-lg mx-auto">{{ ButtonText }}</button>
                  </div>
                </div>
                <div v-else>
                  <div class="complete-copy">
                    Please make yourself comfortable in the waiting room. Your provider will see you soon.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../services/api'
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PreCheckComplete',

    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        isLoading: true,
        isCheckedIn: false,
        CustomHeading: 'You’re checked in!',
        CustomSubHeading: 'Please make yourself comfortable in the waiting room. Your provider will see you soon.',
        ButtonText: 'Next',
        ExternalLink: null,
        inWindow: false,
        timerms: 0
      }
    },

    computed: {
      shouldSpin () {
        return this.isLoading
      },

      hasCustomizations () {
        const size = this.$store.successconfig.length
        let ret = false
        if (size > 0) {
          for (let i = 0; i < this.$store.successconfig.length; i++) {
            const item = this.$store.successconfig[i]
            if (item.stringValue && item.stringValue.length > 0) {
              ret = true
              break
            }
          }
        }
        return ret
      }
    },

    methods: {
      submit () {
        api.post('/Patient/PreCheckComplete').then(response => {
          this.isLoading = false
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.isCheckedIn = response.data.patientCheckedIn
          setTimeout(() => {
            this.$store.clearAuthorizationState()
            this.$store.logoutUser()
          }, 10000)

        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error completing precheck: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      leave () {
        if (this.ExternalLink) {
          window.location.href = this.ExternalLink
        }
      }
    },

    created () {
      const data = this.$store.successconfig

      for (let i = 0; i < data.length; i++) {
        this[data[i].name] = data[i].stringValue
      }

      this.submit()
    }
  }
</script>

<style scoped>
    .complete-image {
        max-height: 140px;
        max-width: 49.9999%;
    }

    .complete-copy {
        font-size: 20px;
    }

    .passkit-logo {
        max-width: 200px;
    }

    .loading {
      margin-top: 3rem;
        min-height: 450px;
    }
</style>
