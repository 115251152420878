<template>
    <input
        ref="dateInput"
        @blur="dateInputBlur"
        @keyup.delete="dateDeleteKeyUp"
        :class="inputClasses"
        @input.prevent="dateInputEvent"
        type="text"
        pattern="\d*"
        placeholder="__/__/____"/>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'DateInput',

    props: {
      currentDate: {
        required: false
      }
    },

    data () {
      return {
        isDateValid: true,
        dateDisplay: ''
      }
    },

    watch: {
      currentDate (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          const dateValue = moment.utc(newValue)
          if (dateValue) {
            this.$refs.dateInput.value = dateValue.format('MM/DD/YYYY')
          }
        }
      }
    },

    computed: {
      inputClasses () {
        if (this.isDateValid) {
          return ['form-control', 'form-control-lg', 'form-control']
        } else {
          return ['form-control', 'form-control-lg', 'form-control', 'is-invalid']
        }
      }
    },

    methods: {
      dateDeleteKeyUp (event) {
        this.isDateValid = true
        const cleanNumber = this.$refs.dateInput.value.replace(/\D/g, '')
        if (cleanNumber.length === 2) {
          this.dateDisplay = cleanNumber.substring(0, 1) + '_/__/____'
          this.$refs.dateInput.value = this.dateDisplay
          this.$nextTick(() => {
            this.$refs.dateInput.setSelectionRange(1, 1, 'none')
          })
        } else if (cleanNumber.length === 4) {
          this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 3) + '_/____'
          this.$refs.dateInput.value = this.dateDisplay
          this.$nextTick(() => {
            this.$refs.dateInput.setSelectionRange(4, 4, 'none')
          })
        }
      },

      dateInputEvent (event) {
        this.isDateValid = true
        const cleanNumber = this.$refs.dateInput.value.replace(/\D/g, '')
        if (cleanNumber.length < 2) {
          if (cleanNumber.length === 1) {
            this.dateDisplay = cleanNumber + '_/__/____'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(1, 1, 'none')
            })
          } else {
            this.dateDisplay = '__/__/____'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(0, 0, 'none')
            })
          }
        } else if (cleanNumber.length < 4) {
          if (cleanNumber.length === 2) {
            this.dateDisplay = cleanNumber + '/__/____'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(3, 3, 'none')
            })
          } else if (cleanNumber.length === 3) {
            this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 3) + '_/____'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(4, 4, 'none')
            })
          }
        } else if (cleanNumber.length < 8) {
          if (cleanNumber.length === 4) {
            this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 4) + '/____'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(6, 6, 'none')
            })
          } else if (cleanNumber.length === 5) {
            this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 4) + '/' + cleanNumber.substring(4, 5) + '___'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(7, 7, 'none')
            })
          } else if (cleanNumber.length === 6) {
            this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 4) + '/' + cleanNumber.substring(4, 6) + '__'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(8, 8, 'none')
            })
          } else if (cleanNumber.length === 7) {
            this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 4) + '/' + cleanNumber.substring(4, 7) + '_'
            this.$nextTick(() => {
              this.$refs.dateInput.setSelectionRange(9, 9, 'none')
            })
          }
        } else {
          this.dateDisplay = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 4) + '/' + cleanNumber.substring(4, 8)
        }
        this.$emit('change', new Date(this.dateDisplay))
        this.$refs.dateInput.value = this.dateDisplay
      },

      dateInputBlur () {
        this.isDateValid = true
        const dobDate = new Date(this.$refs.dateInput.value)
        if (dobDate instanceof Date && !isNaN(dobDate)) {
          this.$emit('valid', dobDate)
        } else {
          this.$emit('invalid', dobDate)
          this.isDateValid = false
        }
      }
    },

    mounted () {
      if (this.currentDate) {
        const dateValue = moment.utc(this.currentDate)
        if (dateValue) {
          this.$refs.dateInput.value = dateValue.format('MM/DD/YYYY')
        }
      }
    }
  }
</script>

<style scoped>

</style>
