<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Which {{ pharmacyDisplayName }} is your preferred location?</h1>
                <h2 class="subheader">Tap a map pin to select a location.</h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div ref="map" class="w-100 map"></div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row mt-4">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="form-row">
                <div class="col-md-6">
                    <b-form-group :state="stateForField('searchTerm')" :invalid-feedback="errorForField('searchTerm')">
                        <b-form-input size="lg" :state="stateForField('searchTerm')" v-model="searchTerm"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-3 pt-1">
                    <button type="button" @click.prevent="search()" class="btn btn-primary mx-auto"><font-awesome-icon class="mr-2" v-show="isSearching" icon="spinner" pulse/>Search</button>
                </div>
                <div class="col-md-3 pt-1">
                    <button type="button" @click.prevent="notListed()" class="btn btn-primary mx-auto"><font-awesome-icon class="mr-2" v-show="notListedSaving" icon="spinner" pulse/>Not Listed</button>
                </div>
            </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div style="display: none">
            <div ref="infoContent">
                <div class="pharmacy-name">{{ infoWindowData.name }}</div>
                <div class="pharmacy-address">
                    <p>{{ infoWindowData.address }}</p>
                    <button type="button" @click.prevent="selectPharmacy(infoWindowData.placeId)" class="btn btn-primary mx-auto"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Select</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import googlemaps from '../../services/googlemaps'
  import { BFormInput, BFormGroup } from 'bootstrap-vue'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PharmacySearch',

    components: { BFormGroup, BFormInput },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    props: {
      pharmacyName: {
        type: String,
        default: 'pharmacy'
      }
    },

    data () {
      return {
        pharmacyPlaces: [],
        pharmacyMarkers: [],
        searchTerm: '',
        isSaving: false,
        isSearching: false,
        location: {},
        mapService: null,
        placeService: null,
        notListedSaving: false,
        infoWindowData: {
          name: '',
          address: '',
          placeId: ''
        },
        infoWindow: null
      }
    },

    watch: {
      pharmacyName (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchTerm = _.clone(newValue)
          this.search()
        }
      }
    },

    computed: {
      pharmacyDisplayName () {
        if (this.pharmacyName === 'pharmacy') {
          return ''
        }

        return this.pharmacyName.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      search (initial = false) {
        const request = {
          location: this.location,
          radius: '15000',
          type: 'pharmacy'
        }
        if (this.searchTerm) {
          request.keyword = this.searchTerm
        }

        if (initial === false) {
          request.bounds = this.mapService.getBounds()
          delete request.location
          delete request.radius
        }
        this.isSearching = true
        this.trackEvent(this.$options.name, 'PlaceSearch', this.searchTerm)

        this.pharmacyMarkers.forEach(marker => {
          marker.setMap(null)
        })
        this.pharmacyMarkers = []
        this.pharmacyPlaces = []
        this.placeService.nearbySearch(request, (results, status) => {
          const bounds = new this.google.maps.LatLngBounds()

          this.trackEvent(this.$options.name, 'PlaceSearch', 'ResultCount', results.length)

          if (!results || results.length === 0) {
            this.isSearching = false
            return
          }

          for (let i = 0; i < results.length; i++) {
            const place = results[i]
            const marker = new this.google.maps.Marker({
              position: place.geometry.location,
              title: place.name
            })
            bounds.extend(marker.getPosition())
            marker.setMap(this.mapService)
            marker.addListener('click', this.markerClickHandler)
            this.pharmacyPlaces.push(place)
            this.pharmacyMarkers.push(marker)
          }

          this.mapService.fitBounds(bounds)
          this.isSearching = false

        })
      },

      placeForLatLng (lat, lng) {
        const place = this.pharmacyPlaces.filter(aPlace => {
          return aPlace.geometry.location.lat === lat && aPlace.geometry.location.lng === lng
        }).shift()

        return place
      },

      markerClickHandler (marker) {
        const place = this.placeForLatLng(marker.latLng.lat, marker.latLng.lng)
        this.infoWindowData.name = place.name
        this.infoWindowData.address = place.vicinity
        this.infoWindowData.placeId = place.place_id
        this.$log.info('Marker clicked', marker)
        this.infoWindow.open(this.mapService)
        this.infoWindow.setPosition(marker.latLng)
      },

      selectPharmacy (placeId) {
        this.isSaving = true
        this.clearAllErrors()

        api.put('/Patient/Pharmacy', { placeId }).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('Pharmacy', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      notListed () {
        this.trackEvent(this.$options.name, 'Pharmacy', 'NotListed')
        this.notListedSaving = true
        this.$store.setModuleStatus('Pharmacy', true, () => {
          this.notListedSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      initializeMap () {
        this.isSearching = true
        let patientZip = this.$store.patientZipCode
        const zipMatch = patientZip.match(/^\d{5}/)
        if (zipMatch) {
          patientZip = zipMatch[0]
        }
        this.geocoder.geocode({ address: patientZip }, (results, status) => {
          if (status !== 'OK' || !results[0]) {
            this.trackEvent(this.$options.name, 'GeocodeZip', patientZip)
            this.trackEvent(this.$options.name, 'GeocodeError', status)
            this.$log.error('Error attempting to geocode: ', status)
            this.addErrorForField('exception', 'Geocoding error ' + status)
            throw new Error(status)
          }

          this.location = results[0].geometry.location
          this.mapService.setCenter(results[0].geometry.location)
          this.mapService.fitBounds(results[0].geometry.viewport)
          this.search(true)
        })
      }
    },

    created () {
      this.searchTerm = _.clone(this.pharmacyName)
    },

    async mounted () {
      try {
        const mapOptions = {
          mapTypeControlOptions: {
            mapTypeIds: []
          },
          streetViewControl: false
        }
        this.google = await googlemaps()
        this.geocoder = new this.google.maps.Geocoder()
        this.mapService = new this.google.maps.Map(this.$refs.map, mapOptions)
        this.placeService = new this.google.maps.places.PlacesService(this.mapService)

        this.infoWindow = new this.google.maps.InfoWindow({
          content: this.$refs.infoContent
        })

        if (!this.$store.patientZipCode) {
          api.get('/Patient/Pharmacy').then(response => {
            this.trackEvent(this.$options.name, 'PatientZipLookup', response.data.patientZipCode)
            this.$store.patientZipCode = response.data.patientZipCode
            this.initializeMap()
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error looking up patient zip code: ' + error.message, error)
            }
          })
        } else {
          this.initializeMap()
        }

      } catch (error) {
        this.$log.error('Error initializing maps: ', error)
        this.addErrorForField('exception', error.message)
      }
    }

  }
</script>

<style scoped>
    .map {
        height: 400px;
    }

    .pharmacy-name {
        font-size: 1.5em;
        font-weight: bold;
    }

    .pharmacy-address {
        font-size: 1.25em;
        font-weight: bolder;
    }
</style>
