<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Select all the symptoms you are currently experiencing.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-grouped-list :option-groups="optionGroups" @optionSelected="optionSelected"></multiselect-grouped-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectGroupedList from '../../components/MultiselectGroupedList'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'ReviewOfSystems',

    components: { MultiselectGroupedList },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        reviewOfSystems: [],
        isSaving: false,
        isRemoving: false,
        selectedMedication: {
          organizationPatientMedicationId: 0,
          name: ''
        },
        showFormAndDosage: false,
        forms: []
      }
    },

    computed: {

      optionGroups () {
        const groups = []
        for (const category of this.reviewOfSystems) {
          const groupOption = {
            id: category.organizationReviewOfSystemCategoryId,
            name: category.name,
            options: this.multiselectOptionsFromArray(category.reviewOfSystems, 'organizationReviewOfSystemId', 'name', 'otherText', 'hasSystem')
          }
          groups.push(groupOption)
        }

        return groups
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/ReviewOfSystems').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.reviewOfSystems = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading review of systems: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      optionSelected (group, item) {

        const category = this.reviewOfSystems.filter((aGroup) => {
          return aGroup.organizationReviewOfSystemCategoryId === group.id
        }).shift()

        if (!category) {
          return
        }

        const selectedItem = category.reviewOfSystems.filter((anItem) => {
          return anItem.organizationReviewOfSystemId === item.id
        }).shift()

        if (selectedItem) {
          selectedItem.hasSystem = !selectedItem.hasSystem
          item.isSelected = !item.isSelected
        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        if (!Array.isArray(this.reviewOfSystems)) {
          this.reviewOfSystems = []
        }
        const allItems = this.reviewOfSystems.flatMap((category) => {
          return category.reviewOfSystems.filter((item) => {
            return item.hasSystem
          })
        })

        api.put('/Patient/ReviewOfSystems', allItems).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('ReviewOfSystems2', true, () => {
            this.$router.push(this.$store.nextRoute())
            this.isSaving = false
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving review of systems: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
