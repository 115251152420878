<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Tell us about your exercise.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Exercise Frequency" :state="stateForField('exerciseFrequencyId')" :invalid-feedback="errorForField('exerciseFrequencyId')">
                                <b-form-select size="lg" @input="clearFieldError('exerciseFrequencyId')" v-model="socialHistory.exerciseFrequencyId" :state="stateForField('exerciseFrequencyId')" :options="exerciseFrequencyRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showExcerciseType" label="Exercise Type" :state="stateForField('exerciseTypes')" :invalid-feedback="errorForField('exerciseTypes')">
                                <b-form-input @click.prevent="exerciseTypeModal" size="lg" class="tap-input" readonly :state="stateForField('exerciseTypes')" v-model="selectedExerciseTypes"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <list-modal :header-image="require('../../assets/vitals.svg')" header="Which exercise types do you do?" @optionSelected="exerciseTypeSelected" :options="exerciseTypeOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormInput, BFormSelect, BFormGroup } from 'bootstrap-vue/src/index'
  import ListModal from '../../components/ListModal'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Exercise',

    components: { BFormInput, BFormGroup, BFormSelect, ListModal },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        socialHistory: {
          exerciseFrequencyId: null,
          exerciseFrequencyOptions: [],
          exerciseTypeOptions: []
        },
        isSaving: false,
        showListModal: false
      }
    },

    computed: {
      exerciseFrequencyRadioOptions () {
        return this.optionsFromArray(this.socialHistory.exerciseFrequencyOptions, 'exerciseFrequencyId')
      },

      selectedFrequency () {
        return this.socialHistory.exerciseFrequencyOptions.filter(anItem => {
          return anItem.exerciseFrequencyId === this.socialHistory.exerciseFrequencyId
        }).shift()
      },

      showExcerciseType () {
        const usageOption = this.selectedFrequency

        if (usageOption) {
          if (usageOption.promptType) {
            return true
          }
        }

        return false
      },

      selectedExerciseTypes () {
        const types = this.socialHistory.exerciseTypeOptions.filter(anItem => {
          return anItem.hasExerciseType
        }).map(anItem => {
          return anItem.name
        })

        if (types) {
          return types.join(', ')
        }

        return ''
      },

      exerciseTypeOptions () {
        return this.multiselectOptionsFromArray(this.socialHistory.exerciseTypeOptions, 'exerciseTypeId', 'name', '', 'hasExerciseType')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.socialHistory.exerciseFrequencyId) {
          this.addErrorForField('exerciseFrequencyId', 'Exercise frequency is required.')
        }

        const selectedType = this.socialHistory.exerciseTypeOptions.filter(anItem => {
          return anItem.hasExerciseType
        }).shift()
        if (this.showExcerciseType && !selectedType) {
          this.addErrorForField('exerciseTypes', 'Exercise types are required')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SocialHistory').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.socialHistory = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/SocialHistory', this.socialHistory).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('daily-activity')
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      exerciseTypeModal () {
        this.clearFieldError('exerciseTypes')
        this.showListModal = true
      },

      exerciseTypeSelected (item) {
        const selectedItem = this.socialHistory.exerciseTypeOptions.filter((anItem) => {
          return anItem.exerciseTypeId === item.id
        }).shift()

        if (selectedItem) {
          selectedItem.hasExerciseType = !selectedItem.hasExerciseType
        }
      }

    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
