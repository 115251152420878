<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 v-if="isOther" class="header">What other therapy or treatment have you tried?</h1>
                <h1 v-else class="header">Tell us about {{ therapyName }}.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div v-if="isOther" class="form-row">
                        <div class="col">
                            <b-form-group label="Therapy Name" :state="stateForField('otherText')" :invalid-feedback="errorForField('otherText')">
                                <b-form-input autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('otherText')" v-model="conservativeTherapy.otherText"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <b-form-group label="Start Date" :state="stateForField('startDate')" :invalid-feedback="errorForField('startDate')">
                            <b-input-group>
                                <b-form-select size="lg" @input="clearFieldError('startDate')" v-model="conservativeTherapy.startDateMonth" :state="stateForField('startDate')" :options="monthRadioOptions"></b-form-select>
                                <b-form-select size="lg" @input="clearFieldError('startDate')" v-model="conservativeTherapy.startDateYear" :state="stateForField('startDate')" :options="yearRadioOptions"></b-form-select>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label="End Date" :state="stateForField('endDate')" :invalid-feedback="errorForField('endDate')">
                            <b-input-group>
                                <b-form-select size="lg" @input="clearFieldError('endDate')" v-model="conservativeTherapy.endDateMonth" :state="stateForField('endDate')" :options="monthRadioOptions"></b-form-select>
                                <b-form-select size="lg" @input="clearFieldError('endDate')" v-model="conservativeTherapy.endDateYear" :state="stateForField('endDate')" :options="yearRadioOptions"></b-form-select>
                            </b-input-group>
                            <div class="pt-1">
                                <span class="text-muted align-bottom">Leave blank if still using.</span>
                            </div>
                        </b-form-group>
                    </div>
                </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <h1 class="header">How much relief did it provide?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="range">
                    <input type="range" min="1" max="10" v-model="conservativeTherapy.relief" class="slider">
                    <div class="sliderticks">
                        <p @click.prevent="setSliderValue(1)">1</p>
                        <p @click.prevent="setSliderValue(2)">2</p>
                        <p @click.prevent="setSliderValue(3)">3</p>
                        <p @click.prevent="setSliderValue(4)">4</p>
                        <p @click.prevent="setSliderValue(5)">5</p>
                        <p @click.prevent="setSliderValue(6)">6</p>
                        <p @click.prevent="setSliderValue(7)">7</p>
                        <p @click.prevent="setSliderValue(8)">8</p>
                        <p @click.prevent="setSliderValue(9)">9</p>
                        <p @click.prevent="setSliderValue(10)">10</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row mt-4">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormInput, BFormSelect, BFormGroup, BInputGroup } from 'bootstrap-vue'
  import moment from 'moment'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'ConservativeTherapyDetails',

    components: { BFormGroup, BInputGroup, BFormSelect, BFormInput },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    props: {
      therapy: {
        type: Object,
        required: true
      },

      isOther: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        conservativeTherapy: {
          name: null,
          medicationName: null,
          otherText: null,
          startDate: null,
          startDateMonth: null,
          startDateYear: null,
          endDate: null,
          endDateMonth: null,
          endDateYear: null,
          relief: 5
        },
        isSaving: false
      }
    },

    watch: {
      therapy (newValue, oldValue) {
        this.initializeModel()
      }
    },

    computed: {
      therapyName () {
        if (this.conservativeTherapy.medicationName) {
          return this.conservativeTherapy.medicationName
        } else if (this.conservativeTherapy.otherText) {
          return this.conservativeTherapy.otherText
        } else {
          return this.conservativeTherapy.name
        }
      },

      monthRadioOptions () {
        return [
          {
            value: null,
            text: ''
          },
          {
            value: 1,
            text: 'January'
          },
          {
            value: 2,
            text: 'February'
          },
          {
            value: 3,
            text: 'March'
          },
          {
            value: 4,
            text: 'April'
          },
          {
            value: 5,
            text: 'May'
          },
          {
            value: 6,
            text: 'June'
          },
          {
            value: 7,
            text: 'July'
          },
          {
            value: 8,
            text: 'August'
          },
          {
            value: 9,
            text: 'September'
          },
          {
            value: 10,
            text: 'October'
          },
          {
            value: 11,
            text: 'November'
          },
          {
            value: 12,
            text: 'December'
          }
        ]
      },

      yearRadioOptions () {
        const years = []
        for (let i = moment().year(); i > 1900; i--) {
          years.push(i)
        }

        return years
      }

    },

    methods: {
      initializeModel () {
        this.conservativeTherapy.organizationPatientConservativeTherapyId = this.therapy.organizationPatientConservativeTherapyId
        this.conservativeTherapy.conservativeTherapyId = this.therapy.conservativeTherapyId
        this.conservativeTherapy.otherText = this.therapy.otherText
        this.conservativeTherapy.relief = this.therapy.relief
        this.conservativeTherapy.name = this.therapy.name
        this.conservativeTherapy.hadTherapy = true
        this.conservativeTherapy.medicationName = this.therapy.medicationName

        if (this.therapy.startDate) {
          const startDate = moment(this.therapy.startDate)
          this.conservativeTherapy.startDateMonth = startDate.month() + 1
          this.conservativeTherapy.startDateYear = startDate.year()
        }

        if (this.therapy.endDate) {
          const endDate = moment(this.therapy.endDate)
          this.conservativeTherapy.endDateMonth = endDate.month() + 1
          this.conservativeTherapy.endDateYear = endDate.year()
        }
      },

      setSliderValue (value) {
        this.conservativeTherapy.relief = value
      },

      validate () {
        this.clearAllErrors()

        if (!this.conservativeTherapy.startDateMonth || !this.conservativeTherapy.startDateYear) {
          this.addErrorForField('startDate', 'Start date is required')
        }

        if (this.isOther && !this.conservativeTherapy.otherText) {
          this.addErrorForField('otherText', 'Please provide a name.')
        }
        return !this.hasErrors()
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.conservativeTherapy.startDate = this.conservativeTherapy.startDateYear + '-' + this.conservativeTherapy.startDateMonth + '-01'

        if (this.conservativeTherapy.endDateMonth && this.conservativeTherapy.endDateYear) {
          this.conservativeTherapy.endDate = this.conservativeTherapy.endDateYear + '-' + this.conservativeTherapy.endDateMonth + '-01'
        } else {
          this.conservativeTherapy.endDate = null
        }

        this.isSaving = true
        api.put('/Patient/ConservativeTherapy', this.conservativeTherapy).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('conservative-therapies')
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving conservative therapy: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.initializeModel()
    }
  }
</script>

<style scoped>
    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 2px;
        background: rgba(23,56,96,1.0);
        outline: none;
        cursor: pointer;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 40px;
        height: 40px;
        background: rgba(255,62,3,1.0);
        cursor: pointer;
        border-radius: 20px;
        z-index: 15;
    }

    .slider::-moz-range-thumb {
        width: 40px;
        height: 40px;
        background: rgba(255,62,3,1.0);
        cursor: pointer;
        border-radius: 20px;
        z-index: 15;
    }

    .sliderticks {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }

    .sliderticks p {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 1px;
        font-size: 24px;
        font-weight: bold;
        height: 10px;
        line-height: 40px;
        margin: 10px 0 20px 0;
        color: rgba(255,62,3,1.0);
        cursor: pointer;
    }
</style>
