import { render, staticRenderFns } from "./Falls.vue?vue&type=template&id=687a2218&scoped=true"
import script from "./Falls.vue?vue&type=script&lang=js"
export * from "./Falls.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687a2218",
  null
  
)

export default component.exports