<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Select all of the options that describe the pain with your {{ primaryBodyPartName }}.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import MultiselectList from '../../components/MultiselectList'
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import OrthoMixin from '../../mixins/OrthoMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoPainCharacteristics',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, OrthoMixin, AnalyticsMixin],

    data () {
      return {
        isSaving: false,
        orthoReasonForVisit: {
          primaryBodyPart: {
            code: '',
            name: ''
          },
          painCharacteristics: []
        }
      }
    },

    computed: {
      options () {
        return this.multiselectOptionsFromArray(this.orthoReasonForVisit.painCharacteristics, 'orthoPainCharacteristicId', 'name', '', 'isSelected')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        const firstSelection = this.orthoReasonForVisit.painCharacteristics.filter(anItem => {
          return anItem.isSelected
        }).shift()

        if (!firstSelection) {
          this.addErrorForField('exception', 'You must select at least one option.')
        }

        return !this.hasErrors()
      },

      optionSelected (item) {
        this.clearAllErrors()

        const selectedItem = this.orthoReasonForVisit.painCharacteristics.filter((anItem) => {
          return anItem.orthoPainCharacteristicId === item.id
        }).shift()

        if (selectedItem) {
          selectedItem.isSelected = !selectedItem.isSelected
        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/OrthoReasonForVisit', this.orthoReasonForVisit).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('ortho-pain-cause')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      loadData () {
        api.get('/Patient/OrthoReasonForVisit').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
