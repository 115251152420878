<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Have you had a Pneumonia Vaccine?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Pneumonia Vaccine" :state="stateForField('hasHadPneumoniaVaccine')" :invalid-feedback="errorForField('hasHadPneumoniaVaccine')">
                                <b-form-select size="lg" @input="clearFieldError('hasHadPneumoniaVaccine')" v-model="senior.hasHadPneumoniaVaccine" :state="stateForField('hasHadPneumoniaVaccine')" :options="yesNoOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showDetails" label="What year did you last have it?" :state="stateForField('pneumoniaVaccineYear')" :invalid-feedback="errorForField('pneumoniaVaccineYear')">
                                <b-form-select size="lg" @input="clearFieldError('pneumoniaVaccineYear')" v-model="senior.pneumoniaVaccineYear" :state="stateForField('pneumoniaVaccineYear')" :options="yearRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup } from 'bootstrap-vue'
  import moment from 'moment'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PneumoniaVaccine',

    components: { BFormGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        senior: {
          hasHadPneumoniaVaccine: false,
          pneumoniaVaccineYear: 0
        },
        isSaving: false,
        yesNoOptions: [
          {
            text: 'Yes',
            value: true
          },
          {
            text: 'No',
            value: false
          }
        ]
      }
    },

    computed: {
      promptAdvancedDirective () {
        return this.$store.boolForModuleConfig('Senior', 'PromptAdvancedDirective', true)
      },

      showDetails () {
        return this.senior.hasHadPneumoniaVaccine
      },

      yearRadioOptions () {
        const years = ['']
        for (let i = moment().year(); i > 1900; i--) {
          years.push(i)
        }

        return years
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.senior.hasHadPneumoniaVaccine !== true && this.senior.hasHadPneumoniaVaccine !== false) {
          this.addErrorForField('hasHadPneumoniaVaccine', 'Please select an option.')
        }

        if (this.showDetails && !this.senior.pneumoniaVaccineYear) {
          this.addErrorForField('pneumoniaVaccineYear', 'The year is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Senior').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.senior = response.data
          if (this.senior.isSenior === false) {
            this.$store.setModuleStatus('Senior', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/Senior', this.senior).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          if (this.promptAdvancedDirective) {
            this.$router.push('living-will')
          } else {
            this.$store.setModuleStatus('Senior', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }

    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
