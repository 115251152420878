<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Who is financially responsible for this visit?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6" v-if="isMinor === false">
                            <b-form-group label="Responsible Person" :state="stateForField('patientIsGuarantor')" :invalid-feedback="errorForField('patientIsGuarantor')">
                                <b-form-select @change="setPatientIsGuarantor()" size="lg" @input="clearFieldError('patientIsGuarantor')" v-model="guarantorOrPatient" :state="stateForField('patientIsGuarantor')" :options="guarantorOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="visitGuarantor.patientIsGuarantor === false" label="Relationship to Patient" :state="stateForField('contactTypeId')" :invalid-feedback="errorForField('contactTypeId')">
                                <b-form-select size="lg" @input="clearFieldError('contactTypeId')" v-model="visitGuarantor.contactTypeId" :state="stateForField('contactTypeId')" :options="contactTypeOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div v-if="visitGuarantor.patientIsGuarantor === false" class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="First Name" :state="stateForField('firstName')" :invalid-feedback="errorForField('firstName')">
                                <b-form-input @input="clearFieldError('firstName')" size="lg" :state="stateForField('firstName')" v-model="visitGuarantor.firstName"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Last Name" :state="stateForField('lastName')" :invalid-feedback="errorForField('lastName')">
                                <b-form-input @input="clearFieldError('lastName')" size="lg" :state="stateForField('lastName')" v-model="visitGuarantor.lastName"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div v-if="visitGuarantor.patientIsGuarantor === false" class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Phone Number" :state="stateForField('phone')" :invalid-feedback="errorForField('phone')">
                                <b-form-input pattern="[0-9]*" @input="numberInput" size="lg" :state="stateForField('phone')" :value="phoneDisplay"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Birth Date (MM/DD/YYYY)" :state="stateForField('birthDate')" :invalid-feedback="errorForField('birthDate')">
                                <date-input :current-date="visitGuarantor.birthDate" @valid="birthDateUpdated" @invalid="birthDateInvalid" @change="birthDateChanged"/>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                  <div class="form-row">
                    <div class="col-xl-3"></div>
                    <div class="col-md-6 text-center pt-4">
                      <a href = "#" @click.prevent="viewDetails()" class="detail"><u>Learn More</u></a>
                    </div>
                    <div class="col-xl-3"></div>
                  </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import DateInput from '../../components/DateInput'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup, BFormInput } from 'bootstrap-vue'
  import moment from 'moment'

  export default {
    name: 'VisitGuarantor',

    components: { BFormGroup, BFormInput, DateInput, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        visitGuarantor: {
          area: '',
          exchange: '',
          number: '',
          patientIsGuarantor: null,
          birthDate: null,
          contactTypeOptions: []
        },
        isSaving: false,
        guarantorOptions: [
          {
            text: 'Patient',
            value: 'Patient'
          },
          {
            text: 'Me, the person completing this information (not the patient)',
            value: 'Guarantor'
          },
          {
            text: 'Another person, who is not present',
            value: 'Other'
          }
        ],
        guarantorOrPatient: ''
      }
    },

    computed: {
      contactTypeOptions () {
        return this.optionsFromArray(this.visitGuarantor.contactTypeOptions.filter(contact => contact.contactTypeId !== 8), 'contactTypeId') // filter out self, it doesn't fit this context
      },

      phoneDisplay () {
        if (this.visitGuarantor.area) {
          if (this.visitGuarantor.number.length > 0) {
            return '(' + this.visitGuarantor.area + ') ' + this.visitGuarantor.exchange + '-' + this.visitGuarantor.number
          } else if (this.visitGuarantor.exchange.length > 0) {
            return '(' + this.visitGuarantor.area + ') ' + this.visitGuarantor.exchange
          } else if (this.visitGuarantor.area.length > 0) {
            return '(' + this.visitGuarantor.area
          }
        }
        return ''
      },

      isMinor () {
        const birthDate = moment(this.$store.patientBirthDate)

        if (birthDate) {
          if (moment().diff(birthDate, 'years') < 18) {
            return true
          }
        }

        return false
      }

    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.visitGuarantor.patientIsGuarantor === false) {
          if (!this.visitGuarantor.firstName) {
            this.addErrorForField('firstName', 'First name is required.')
          }

          if (!this.visitGuarantor.lastName) {
            this.addErrorForField('lastName', 'Last name is required.')
          }

          if (!this.visitGuarantor.area || !this.visitGuarantor.exchange || !this.visitGuarantor.number) {
            this.addErrorForField('phone', 'Phone is required.')
          }

          if (!this.visitGuarantor.birthDate) {
            this.addErrorForField('birthDate', 'Birth date is required.')
          } else {
            const birthDate = moment(this.visitGuarantor.birthDate)
            if (moment().diff(birthDate, 'years') < 18) {
              this.addErrorForField('birthDate', 'Responsible party must be over 18.')
            }
          }
        }
        return !this.hasErrors()
      },

      birthDateUpdated (birthDate) {
        this.clearFieldError('birthDate')
        this.visitGuarantor.birthDate = birthDate
      },

      viewDetails () {
        this.$router.push({ name: 'visit-guarantor-detail' })
      },

      birthDateInvalid (birthDate) {
        this.addErrorForField('birthDate', 'Invalid birth date')
      },

      birthDateChanged () {
        this.clearFieldError('birthDate')
      },

      loadData () {
        api.get('/Patient/VisitGuarantor').then(response => {
          this.visitGuarantor = response.data
          if (this.isMinor) {
            this.visitGuarantor.patientIsGuarantor = false
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      numberInput (value) {
        this.clearFieldError('phone')
        const newNumber = value
        let cleanNumber = newNumber.replace(/\D/g, '')
        if (cleanNumber.length > 0) {
          if (parseInt(cleanNumber[0]) === 1) {
            cleanNumber = cleanNumber.substr(1, cleanNumber.length - 1)
          }

          this.visitGuarantor.area = cleanNumber.substr(0, cleanNumber.length > 3 ? 3 : cleanNumber.length)
          if (cleanNumber.length > 3) {
            this.visitGuarantor.exchange = cleanNumber.substr(3, cleanNumber.length > 6 ? 3 : cleanNumber.length - 3)
          } else {
            this.visitGuarantor.exchange = ''
          }
          if (cleanNumber.length > 6) {
            this.visitGuarantor.number = cleanNumber.substr(6, cleanNumber.length < 10 ? cleanNumber.length - 6 : 4)
          } else {
            this.visitGuarantor.number = ''
          }
        } else {
          this.visitGuarantor.area = ''
          this.visitGuarantor.exchange = ''
          this.visitGuarantor.number = ''
        }

      },

      setPatientIsGuarantor () {
        if (this.guarantorOrPatient === 'Patient') {
          this.visitGuarantor.patientIsGuarantor = true
          this.visitGuarantor.guarantorIsPresent = true
        } else if (this.guarantorOrPatient === 'Guarantor') {
          this.visitGuarantor.patientIsGuarantor = false
          this.visitGuarantor.guarantorIsPresent = true
        } else {
          this.visitGuarantor.patientIsGuarantor = false
          this.visitGuarantor.guarantorIsPresent = false
        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/VisitGuarantor', this.visitGuarantor).then(response => {
          if (this.visitGuarantor.patientIsGuarantor) {
            this.$store.setModuleStatus('VisitGuarantor', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            // Prompt for address
            this.$router.push(('/visit-guarantor-address'))
            this.isSaving = false
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
