<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Tell us a little bit about yourself.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group v-if="promptNumberOfChildren" label="Number of Children" :state="stateForField('numberOfChildren')" :invalid-feedback="errorForField('numberOfChildren')">
                                <b-form-select size="lg" @input="clearFieldError('numberOfChildren')" v-model="socialHistory.numberOfChildren" :state="stateForField('numberOfChildren')" :options="numberOfChildrenOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">

                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <b-form-group v-if="promptInterests" label="Interests" :state="stateForField('interests')" :invalid-feedback="errorForField('interests')">
                                <b-form-textarea size="lg" @input="clearFieldError('interests')" :state="stateForField('interests')" v-model="socialHistory.interests"></b-form-textarea>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormTextarea, BFormSelect, BFormGroup } from 'bootstrap-vue'

  export default {
    name: 'SocialHistory',

    components: { BFormGroup, BFormTextarea, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        socialHistory: {},
        isSaving: false
      }
    },

    computed: {
      promptInterests () {
        return this.$store.boolForModuleConfig('SocialHistory', 'PromptInterests', true)
      },

      promptNumberOfChildren () {
        return this.$store.boolForModuleConfig('SocialHistory', 'PromptNumberChildren', false)
      },

      numberOfChildrenOptions () {
        return [
          {
            value: null,
            text: ''
          },
          {
            value: 0,
            text: 'None'
          },
          {
            value: 1,
            text: '1'
          },
          {
            value: 2,
            text: '2'
          },
          {
            value: 3,
            text: '3'
          },
          {
            value: 4,
            text: '4'
          },
          {
            value: 5,
            text: '5 or more'
          }
        ]
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.promptInterests && !this.socialHistory.interests) {
          this.addErrorForField('interests', 'Interests is required.')
        }

        if (this.promptNumberOfChildren && !this.socialHistory.numberOfChildren && this.socialHistory.numberOfChildren !== 0) {
          this.addErrorForField('numberOfChildren', 'Number of children is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SocialHistory').then(response => {
          this.socialHistory = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/SocialHistory', this.socialHistory).then(response => {
          if (this.$store.boolForModuleConfig('SocialHistory', 'PromptTobacco', true)) {
            this.$router.push('tobacco-usage')
          } else {
            this.$router.push('alcohol-usage')
          }
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
