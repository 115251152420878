<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div v-if="paymentInfo.orgLogoUrl" class="text-center">
          <img class="logo-url" :src="paymentInfo.orgLogoUrl">
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col mt-2">
        <h1 class="header">Make a Payment</h1>
        <h2 class="subheader">Please enter your payment details below.</h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-md-12 my-2 text-center">
        <img src="../assets/cc-amex.svg"/> &nbsp;
        <img src="../assets/cc-discover.svg"/> &nbsp;
        <img src="../assets/cc-visa.svg"/> &nbsp;
        <img src="../assets/cc-mastercard.svg"/>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div v-if="message" class="alert alert-success fade show" role="alert">
          {{ message }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div v-if="isEstimatePayment" class="row">
      <div class="col-xl-3"></div>
      <div class="col text-center">
        <button @click.prevent="downloadEstimate()" class="btn btn-primary btn-lg mx-auto mt-4">
          <font-awesome-icon class="mr-2" v-show="isLoadingEstimate" icon="spinner" pulse/>
          {{ downloadButtonCopy }}
        </button>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="form-row">
          <div class="col-md-12 my-4 payment-info-box">
            <div class="section text-center">
            <b>Location: {{ paymentInfo.locationName }}</b><br>
            </div>
            <template v-if="paymentInfo.discountAmount">
              <div class="section d-flex justify-content-between">
              <div><b>Discount:</b> </div> <div>${{ formatNumber(paymentInfo.discountAmount) }}<br></div>
              </div>
              <div class="section d-flex justify-content-between">
                <div><b>Discount Expiration:</b> </div> <div>{{ formatDate(paymentInfo.discountExpiration) }}<br></div>
              </div>
              <div class="section d-flex justify-content-between">
              <div><b>Total After Discount:</b> </div> <div><b>${{ formatNumber(paymentInfo.patientTotalWithDiscount) }}<br></b></div>
              </div>
              <template v-if="paymentInfo.amountPaid">
                <div class="section d-flex justify-content-between">
                  <div><b>Amount Paid:</b></div> <div>${{ formatNumber(paymentInfo.amountPaid) }}<br></div>
                </div>
              </template>
            </template>
              <div class="section text-right">
              <b>Total Amount Due: ${{ formatNumber(paymentInfo.totalAmount) }}</b><br>
              </div>
            <template v-if="paymentInfo.minimumAmount">
              <div class="section text-right">
              <b>Minimum Amount Due: ${{ formatNumber(paymentInfo.minimumAmount) }}</b><br>
              </div>
            </template>
          </div>
          <div class="col-md-12">
            <b-form-group :state="stateForField('amount')"
                          :invalid-feedback="errorForField('amount')"
                          label="Payment Amount">
              <b-input-group prepend="$">
                <b-form-input size="lg" :state="stateForField('amount')" v-model="payment.amount"
                              :formatter="formatNumberString" lazy-formatter></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div v-if="!paymentInfo.tokenPaymentAvailable || useDifferentCard" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <form @submit.prevent="submit()">
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group :state="stateForField('name')"
                            :invalid-feedback="errorForField('name')"
                            label="Cardholder Name">
                <b-form-input size="lg" :state="stateForField('name')" v-model="payment.name"
                              autocomplete="cc-name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group :state="stateForField('cardNumber')"
                            :invalid-feedback="errorForField('cardNumber')"
                            label="Card Number">
                <b-form-input size="lg" pattern="[0-9]*" :state="stateForField('cardNumber')"
                              v-model="payment.cardNumber" autocomplete="cc-number"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <b-form-group :state="stateForField('expiration')"
                            :invalid-feedback="errorForField('expiration')"
                            label="Expiration Date">
                <b-form-input maxlength="5" pattern="[0-9]*" size="lg" @input="expirationInput"
                              :state="stateForField('expiration')" :value="expirationDateDisplay" placeholder="MM/YY"
                              autocomplete="cc-exp"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group :state="stateForField('cvv')"
                            :invalid-feedback="errorForField('cvv')"
                            label="Security Code">
                <b-form-input size="lg" pattern="[0-9]*" :state="stateForField('cvv')" v-model="payment.cvv" placeholder="CVV"
                              autocomplete="cc-csc"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div v-if="paymentInfo.paymentTokenizationEnabled" class="form-row ml-2">
            <div><b-form-checkbox class="pl-4" size="lg" button-variant="orange" v-model="payment.tokenizeCard"><b>Save this card for future use</b></b-form-checkbox>
              <div class="mb-2 mt-0" style="font-size: 14px;"> *Your card will never be charged without prior communication
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 text-center">
              <b-input-group>
                <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2">
                  <font-awesome-icon class="mr-2" icon="lock-alt" /><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Submit Payment</button>
                <button v-if="paymentInfo.tokenPaymentAvailable" type="submit" @click.prevent="tokenPayment()" class="btn btn-primary btn-lg mx-auto mt-2">
                  <font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Pay with card ending in {{ paymentInfo.cardLast4 }}</button>
              </b-input-group>
            </div>
          </div>
          <div class="form-row">
            <b-form-checkbox size="lg" button-variant="orange" v-model="payment.emailReceipt">Send the receipt to my email</b-form-checkbox>
          </div>
        </form>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div v-if="paymentInfo.tokenPaymentAvailable && !useDifferentCard" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <form @submit.prevent="tokenPayment()">
          <div class="form-row">
            <div class="form-group col-md-12 text-center">
              <b-input-group>
              <button type="submit" @click.prevent="tokenPayment()" class="btn btn-primary btn-lg mx-auto mt-2">
              <font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Pay with card ending in {{ paymentInfo.cardLast4 }}</button>
              <button type="submit" @click.prevent="useDifferentCard = true" class="btn btn-primary btn-lg mx-auto mt-2">Use another card</button>
              </b-input-group>
            </div>
          </div>
          <div class="form-row">
            <b-form-checkbox size="lg" button-variant="orange" v-model="payment.emailReceipt">Send the receipt to my email</b-form-checkbox>
          </div>
        </form>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '../services/api'
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import { BInputGroup, BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue'
  import { saveAs } from 'file-saver'
  import _ from 'lodash'
  import cct from 'credit-card-type'
  import moment from 'moment'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Payment',

    components: { BFormGroup, BFormInput, BInputGroup, BFormCheckbox },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      token: {
        type: String
      }
    },

    data () {
      return {
        isLoading: false,
        useDifferentCard: false,
        paymentInfo: {
          orgName: '',
          orgLogoUrl: '',
          locationName: '',
          totalAmount: 0,
          minimumAmount: null,
          amountPaid: null,
          tokenOnFile: false,
          tokenPaymentAvailable: false,
          estimateId: null,
          billId: null,
          visitId: null,
          paymentTokenizationEnabled: false,
          cardLast4: 8234,
          discountAmount: 0,
          patientTotalWithDiscount: 0,
          discountExpiration: null,
          discountId: 0
        },
        payment: {
          paymentToken: _.clone(this.$props.token),
          expirationMonth: 0,
          expirationYear: 0,
          amount: 0,
          tokenizeCard: true,
          useCardToken: false,
          discountAmount: 0,
          discountId: 0,
          emailReceipt: true
        },
        isSaving: false,
        message: '',
        paymentResponse: {},
        isLoadingEstimate: false
      }
    },

    watch: {
      token (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.payment.paymentToken = newValue
          this.loadData()
        }
      }
    },

    computed: {
      downloadButtonCopy () {
        if (this.$store.isAuthenticated) {
          return 'View Estimate'
        }

        return 'Login to View Estimate'
      },

      isEstimatePayment () {
        if (this.paymentInfo.estimateId) {
          return true
        }

        return false
      },

      expirationDateDisplay () {
        if (this.payment.expirationMonth) {
          if (this.payment.expirationYear.length > 0) {
            return this.payment.expirationMonth + '/' + this.payment.expirationYear
          } else if (this.payment.expirationMonth.length > 1) {
            return this.payment.expirationMonth + '/'
          } else {
            return this.payment.expirationMonth
          }
        }
        return ''
      },

      cardType () {
        const cards = cct(this.payment.cardNumber)
        if (cards.length === 1) {
          return cards[0].niceType
        }

        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.paymentInfo.tokenPaymentAvailable || !this.payment.useCardToken) {
          if (!this.payment.name) {
            this.addErrorForField('name', 'Name is required.')
          }

          if (!this.payment.cardNumber) {
            this.addErrorForField('cardNumber', 'Card number is required.')
          }

          if (this.payment.amount <= 0) {
            this.addErrorForField('amount', 'The amount must be greater then 0')
          }
        }

        if (this.paymentInfo.minimumAmount && this.payment.amount < this.paymentInfo.minimumAmount) {
          this.addErrorForField('amount', 'A minimum payment of ' + this.formatNumber(this.paymentInfo.minimumAmount) + ' is required')
        }

        return !this.hasErrors()
      },

      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      },

      formatDate (date) {
        if (date) {
          return moment(date).format('MM/DD/YY')
        } else {
          return ''
        }
      },

      numberFromString (value) {
        if (value) {
          value = value.replace(',', '')
          return Number.parseFloat(value)
        }

        return 0
      },

      formatNumberString (value) {
        let number = 0.00
        if (typeof value === 'string') {
          value = value.replace(',', '')
          number = this.numberFromString(value)
        } else {
          number = value
        }

        if (number) {
          return this.formatNumber(number)
        }

        return number
      },

      expirationInput (value) {
        const expirationParts = value.split('/')
        if (expirationParts.length > 1) {
          this.payment.expirationMonth = expirationParts[0]
          this.payment.expirationYear = expirationParts[1]
        } else {
          this.payment.expirationMonth = expirationParts[0]
        }
      },

      loadData () {
        this.isLoading = true
        api.get('/WebClient/Payment?token=' + this.token).then(response => {
          this.trackEvent('Estimate', 'View', this.token)
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.paymentInfo = response.data
          if (this.paymentInfo.paymentTokenizationEnabled) {
            this.payment.tokenizeCard = true
          }
          if (this.paymentInfo.minimumAmount) {
            this.payment.amount = this.formatNumber(this.paymentInfo.minimumAmount)
          } else if (this.paymentInfo.patientTotalWithDiscount && moment(this.paymentInfo.discountExpiration).isSameOrAfter(moment.now())) {
            this.payment.amount = this.formatNumber(this.paymentInfo.patientTotalWithDiscount)
            this.payment.discountAmount = this.paymentInfo.discountAmount
          } else {
            this.payment.amount = this.formatNumber(this.paymentInfo.totalAmount)
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isLoading = false
        })
      },

      downloadEstimate () {
        if (this.$store.isAuthenticated) {
          this.$router.push({ name: 'estimate', params: { token: this.token, id: this.paymentInfo.estimateId } })
        } else {
          this.$router.push({ name: 'patient-payment-search', params: { token: this.token } })
        }
      },

      showPdf () {
        this.isLoadingEstimate = true

        api.get('/WebClient/Estimate/' + this.paymentInfo.estimateId + '/Download', {
          responseType: 'blob',
          headers: { 'Content-Type': 'application/pdf' }
        }).then(response => {
          saveAs(response.data, 'Estimate.pdf')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to load estimate: ' + error.message, error)
            this.addErrorForField('exception', error.message)
          }
        }).then(() => {
          // always executed
          this.isLoadingEstimate = false
        })
      },

      tokenPayment () {
        this.payment.useCardToken = true
        this.submit()
      },

      submit () {
        if (this.validate() !== true || this.isSaving) {
          return
        }

        if (typeof this.payment.amount !== 'number') {
          this.payment.amount = this.numberFromString(this.payment.amount)
        }
        this.payment.cardType = this.cardType.replace(' ', '')
        this.isSaving = true
        api.post('/WebClient/Payment', this.payment).then(response => {
          this.trackEvent('Estimate', 'Payment', this.token, this.payment.amount)
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          if (this.token) {
            this.loadData()
          }
          this.paymentResponse = response.data
          this.message = 'Payment of $' + this.formatNumber(response.data.amount) + ' applied'
          this.$scrollToTop()
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response.data) {
              this.setErrorsFromResponse(error.response.data)
              if (this.hasError('expirationYear')) {
                this.addErrorForField('expiration', this.errorForField('expirationYear'))
              }
              if (this.hasError('expirationMonth')) {
                this.addErrorForField('expiration', this.errorForField('expirationMonth'))
              }
            }
            this.$log.error('Error saving payment: ' + error.message, error)
            this.$scrollToTop()
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      if (this.token) {
        this.loadData()
      }
    }
  }
</script>

<style scoped>
  .logo-url {
      max-height: 140px;
      max-width: 100%;
  }
  .section {
    border-bottom: #dee2e6 1px solid;
    padding: 10px 5px;
  }
  .payment-info-box{
    box-shadow: 2px 2px 5px #a9a9a9;
    border-radius: 5px;
  }

  @media (max-width: 450px) {
    .payment-button {
      width: 250px;
    }
  }
</style>
