<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Please describe the surgery.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <b-form-group :state="stateForField('otherText')" :invalid-feedback="errorForField('otherText')">
                                <b-form-textarea size="lg" v-model="surgeryModel.otherText"></b-form-textarea>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import { BFormTextarea, BFormGroup } from 'bootstrap-vue'

  export default {
    name: 'SurgeryOther',

    components: { BFormGroup, BFormTextarea },

    mixins: [ErrorsMixin],

    props: {
      surgery: {
        type: Object,
        required: false
      }
    },

    data () {
      return {
        surgeryModel: {
          organizationPatientSurgeryId: 0,
          otherText: '',
          surgeryId: this.$store.surgeryOtherId
        },
        isSaving: false
      }
    },

    watch: {
      surgery (newValue, oldValue) {
        if (newValue && newValue.organizationPatientSurgeryId) {
          this.surgeryModel = newValue
        } else {
          this.surgeryModel = {
            organizationPatientSurgeryId: 0,
            otherText: '',
            surgeryId: this.$store.surgeryOtherId
          }
        }
      }
    },

    methods: {
      submit () {
        this.isSaving = true
        api.put('/Patient/Surgery', this.surgeryModel).then(response => {
          this.$router.push('/surgeries')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving surgery: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    }
  }
</script>

<style scoped>

</style>
