<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">We sent a 4-digit code to: <br/>{{ authorizationDestination }} <br/> Enter it below.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center">
                            <b-form-group :state="stateForField('authorizationCode')" :invalid-feedback="errorForField('authorizationCode')">
                                <b-form-input class="auth-code" type="number" pattern="[0-9]*" autocomplete="one-time-code" size="lg" :state="stateForField('authorizationCode')" v-model="authorization.authorizationCode" placeholder="Code"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
          <div class="col-xl-3"></div>
        </div>
        <div class="row mt-4" v-if="smsSent">
          <div class="col-xl-3"></div>
          <div class="col text-center">
            <a href="#" @click.prevent="requestEmailAuth()">Send Code via Email</a>
          </div><div class="col-xl-3"></div>
        </div>
        <div class="row mt-4">
            <div class="col-xl-3"></div>
            <div class="col text-center">
                <router-link to="/logout">Start Over</router-link>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import api from '../services/api'
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Authorize',

    components: { BFormGroup, BFormInput },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      next: {
        type: String,
        required: false
      }
    },

    data () {
      return {
        authorization: {},
        isSaving: false
      }
    },

    computed: {
      authorizationDestination () {
        if (this.$store.patientSearchResponse.smsMessageSent) {
          return this.$store.patientSearchResponse.smsMaskedNumber
        } else {
          return this.$store.patientSearchResponse.emailMaskedEmail
        }
      },

      smsSent () {
        if (this.$store.patientSearchResponse.smsMessageSent) {
          return true
        }

        return false
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.authorization.authorizationCode) {
          this.addErrorForField('authorizationCode', 'The authorization is required.')
        }

        return !this.hasErrors()
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.authorization.webClientId = this.$store.webClientId
        this.isSaving = true
        api.post('/WebClient/Authorize', this.authorization).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.providerName = response.data.providerName
          this.$store.providerFirstName = response.data.providerFirstName
          this.$store.providerLastName = response.data.providerLastName
          this.$store.providerCredential = response.data.providerCredential
          this.$store.orgName = response.data.organizationName
          this.$store.orgLogoUrl = response.data.logoUrl
          this.$store.headshotUrl = response.data.headshotUrl
          this.$store.appointmentDate = response.data.appointmentStart
          this.$store.isVirtualVisit = response.data.isVirtualVisit
          this.$store.paymentToken = response.data.paymentToken
          this.$store.locationName = response.data.locationName
          this.$store.locationTimezone = response.data.locationTimezone
          this.$store.locationStreet1 = response.data.locationStreet1
          this.$store.locationStreet2 = response.data.locationStreet2
          this.$store.locationCity = response.data.locationCity
          this.$store.locationState = response.data.locationState
          this.$store.locationZip = response.data.locationZip
          this.$store.billingSupportPhoneNumber = response.data.billingSupportPhoneNumber
          this.$store.billingSupportEmail = response.data.billingSupportEmail
          this.$store.userAuthenticated(response.data.idToken, response.data.sessionId, response.data.hasVisit, () => {
            if (response.data.hasVisit) {
              if (response.data.isBlocked) {
                this.$router.push('visit-error')
              } else if (this.next) {
                this.$router.push(this.next)
              } else {
                this.$router.push('appointment-confirmation')
              }
            } else {
              if (this.next) {
                this.$router.push(this.next + response.data.paymentToken)
              } else {
                this.$router.push('/payment/' + response.data.paymentToken)
              }
            }
            this.isSaving = false
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      requestEmailAuth () {
        this.isSaving = true
        const request = {
          webClientId: this.$store.webClientId,
          skipSms: true
        }

        api.post('/WebClient/PatientResendAuthorizationCode', request).then(response => {
          this.trackEvent(this.$options.name, 'ResendAuthEmail', 'ResponseTime', response.config.requestDuration)

          this.$store.startAuthorization(response.data)
          this.$forceUpdate()
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'ResendAuthEmailError', error)
            this.setFromApiErrorResponse(error)
            if (this.hasError('noVisitFound')) {
              this.trackEvent(this.$options.name, 'VisitNotFound', error)
              this.addErrorForField('exception', this.errorForField('noVisitFound'))
              this.clearFieldError('noVisitFound')
            }
          } else if (error.message) {
            this.addErrorForField('exception', error.message)
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    }
  }
</script>

<style scoped>
  .auth-code {
    width: 140px;
    text-align: center;
    margin: auto;
  }
</style>
