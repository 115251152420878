<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What is your billing address?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Street 1" :state="stateForField('street1')" :invalid-feedback="errorForField('street1')">
                                <b-form-input @input="clearFieldError('street1')" size="lg" :state="stateForField('street1')" v-model="billingAddress.address.street1"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Street 2" :state="stateForField('street2')" :invalid-feedback="errorForField('street2')">
                                <b-form-input @input="clearFieldError('street2')" size="lg" :state="stateForField('street2')" v-model="billingAddress.address.street2"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="City" :state="stateForField('city')" :invalid-feedback="errorForField('city')">
                                <b-form-input @input="clearFieldError('city')" size="lg" :state="stateForField('city')" v-model="billingAddress.address.city"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="State" :state="stateForField('stateId')" :invalid-feedback="errorForField('stateId')">
                                <b-form-select size="lg" v-model="billingAddress.address.stateId" :state="stateForField('stateId')" :options="stateRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Zip" :state="stateForField('zip')" :invalid-feedback="errorForField('zip')">
                                <b-form-input autocomplete="postal-code" @input="clearFieldError('zip')" size="lg" :state="stateForField('zip')" v-model="billingAddress.address.zip"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="verifyAddress()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
      <list-modal :header-image="require('../../assets/family-history.svg')" :header="confirmModalHeader" :modal-body="formatLocation(verifiedAddress)" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="confirmOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormSelect, BFormGroup, BFormInput } from 'bootstrap-vue/src/index'
  import AnalyticsMixin from '../../mixins/AnalyticsMixin'
  import ListModal from '@/components/ListModal.vue'

  export default {
    name: 'Address',

    components: { ListModal, BFormGroup, BFormInput, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin, DemographicsMixin, AnalyticsMixin],

    data () {
      return {
        billingAddress: {
          address: {},
          stateOptions: []
        },
        verifiedAddress: {},
        isSaving: false,
        showListModal: false,
        confirmModalHeader: 'Verified Address',
        confirmOptions: [
          {
            id: 1,
            name: 'Use verified address',
            selected: false
          },
          {
            id: 2,
            name: 'Use what I entered',
            selected: false
          }
        ]
      }
    },

    computed: {
      stateRadioOptions () {
        return this.optionsFromArray(this.billingAddress.stateOptions, 'stateId')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.billingAddress.address.street1) {
          this.addErrorForField('street1', 'Street 1 is required.')
        }

        if (!this.billingAddress.address.city) {
          this.addErrorForField('city', 'City is required.')
        }

        if (!this.billingAddress.address.stateId) {
          this.addErrorForField('stateId', 'State is required.')
        }

        if (!this.billingAddress.address.zip) {
          this.addErrorForField('zip', 'Zip is required.')
        }

        return !this.hasErrors()
      },
      modalClosed () {
        if (this.confirmOptions[0].selected === true) {
          this.billingAddress.address = this.verifiedAddress
          this.billingAddress.address.stateId = this.billingAddress.stateOptions.find(state => state.short === this.billingAddress.address.stateShort).stateId
          this.submit()
        }
        if (this.confirmOptions[1].selected === true) {
          this.submit()
        }
      },
      modalSelection (item) {
        for (const anOption of this.confirmOptions) {
          if (anOption.id === item.id) {
            anOption.selected = true
          } else {
            anOption.selected = false
          }
        }
      },
      formatLocation (location) {
        let name = location.street1
        if (location.street2) {
          name += ' ' + location.street2
        }
        name += ' ' + location.city + ', ' + location.stateShort + ' ' + location.zip

        return name
      },

      loadData () {
        api.get('/Patient/BillingAddress').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.billingAddress = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading billing address: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },
      verifyAddress () {
        if (this.validate() !== true) {
          return
        }
        this.billingAddress.address.stateShort = this.billingAddress.stateOptions.find(state => state.stateId === this.billingAddress.address.stateId).short
        const verifiedAddress = this.billingAddress.address
        api.post('Patient/VerifyAddress', verifiedAddress).then(response => {
          this.trackEvent(this.$options.name, 'VerifyAddress', 'ResponseTime', response.config.requestDuration)
          this.verifiedAddress = response.data
          if (!this.verifiedAddress) {
            this.submit()
          } else {
            if (this.verifiedAddress.city !== this.billingAddress.address.city || this.verifiedAddress.stateShort !== this.billingAddress.address.stateShort || this.verifiedAddress.street1 !== this.billingAddress.address.street1 || this.verifiedAddress.street2 !== this.billingAddress.address.street2 || this.verifiedAddress.zip !== this.billingAddress.address.zip) {
              this.showListModal = true
            } else {
              this.submit()
            }
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to verify address: ' + error.message, error)
          }
        })
      },

      submit () {
        this.isSaving = true
        api.put('/Patient/BillingAddress', this.billingAddress.address).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('Address', true, () => {
            this.$router.push(this.$store.nextRoute())
            this.isSaving = false
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving billing address: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
