<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Enter the policy number from your insurance card.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group label="Policy Number" :state="stateForField('policyNumber')" :invalid-feedback="errorForField('policyNumber')">
                    <b-form-input size="lg" :state="stateForField('policyNumber')" v-model="insurance.policyNumber"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="form-row">
            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PolicyNumber',

    components: { BFormGroup, BFormInput },

    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        insurance: {
          insuranceCompanyShortCode: this.$store.insurance.insuranceCompanyShortCode,
          policyNumber: '',
          insurancePriority: this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority),
          patientIsUpdating: true
        },
        isSaving: false
      }
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.insurance.policyNumber) {
          this.addErrorForField('policyNumber', 'Policy number is required.')
        }

        return !this.hasErrors()
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true

        api.put('/Patient/Insurance', this.insurance).then(response => {
          this.trackEvent(this.$options.name, 'Insurance', 'PolicyNumberEntered')

          if (this.$store.insurance.insurancePriority === 'secondary') {
            this.$store.setModuleStatus('Insurance', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            this.$store.insuranceBenefits = response.data
            this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving insurance: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
