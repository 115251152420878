<template>
    <div class="container-fluid">
      <div id="body-container">
        <div class="row">
            <div class="col">
                <h1 class="header">Payment Details</h1>
                <h2 class="subheader">Please enter your payment details below.</h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center  pb-4">
            <img src="../../assets/cc-amex.svg" /> &nbsp;
            <img src="../../assets/cc-discover.svg" /> &nbsp;
            <img src="../../assets/cc-visa.svg"/> &nbsp;
            <img src="../../assets/cc-mastercard.svg"/>
          </div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group :state="stateForField('cardholderName')"
                                          :invalid-feedback="errorForField('cardholderName')"
                                          class="bold-text"
                                          label="Name on Card">
                                <b-form-input size="lg" :state="stateForField('cardholderName')" v-model="payment.cardholderName" autocomplete="cc-name"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group :state="stateForField('cardNumber')"
                                          :invalid-feedback="errorForField('cardNumber')"
                                          class="bold-text"
                                          label="Card Number">
                                <b-form-input size="lg" pattern="[0-9]*" :state="stateForField('cardNumber')" v-model="payment.cardNumber" autocomplete="cc-number"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group :state="stateForField('expiration')"
                                          :invalid-feedback="errorForField('expiration')"
                                          class="bold-text"
                                          label="Expiration Date">
                                <b-form-input size="lg" pattern="[0-9]*" maxlength="5" @input="expirationInput" :state="stateForField('expiration')" :value="expirationDateDisplay" placeholder="MM/YY" autocomplete="cc-exp"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group :state="stateForField('cvv')"
                                          :invalid-feedback="errorForField('cvv')"
                                          class="bold-text"
                                          label="Security Code">
                                <b-form-input size="lg" pattern="[0-9]*" :state="stateForField('cvv')" v-model="payment.cvv" autocomplete="cc-csc" placeholder="CVC"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group :state="stateForField('amount')"
                                          :invalid-feedback="errorForField('amount')"
                                          class="bold-text"
                                          label="Payment Amount">
                                <b-input-group prepend="$">
                                    <b-form-input readonly size="lg" :state="stateForField('amount')" :value="this.formatNumberString(selectedPaymentAmount)" :formatter="formatNumberString" lazy-formatter></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div v-if="tokenizationEnabled" class="form-row">
                      <b-form-checkbox size="lg" button-variant="orange" v-model="payment.tokenize" class="bold-text">Securely save this card for faster check-in</b-form-checkbox>
                    </div>
                    <div class="form-row">
                      <b-form-checkbox size="lg" button-variant="orange" v-model="payment.emailReceipt">Send the receipt to my email</b-form-checkbox>
                    </div>
                    <div class="form-row">
                      <div style="width: 80%; font-size: 14px; text-center">
                        *Your card will never be charged without prior communication
                      </div>
                    </div>

                  <div class="row">
                    <div class="col-xl-3"></div>
                    <div class="col text-center">
                      <div class="form-row mt-4">
                        <div class="form-group col-md-12 text-center">
                            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto" style="width: 80%"><font-awesome-icon class="mr-2" icon="lock-alt" /><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Submit Payment</button>
                        </div>
                        <div class="form-group col-md-12 text-center">
                          <a href = "#" @click.prevent="skipPayment()" class="detail">Pay Cash or Check at Clinic</a>
                        </div>
                        </div>
                    </div>
                    <div class="col-xl-3"></div>
                    </div>
                </form>
            </div>
          <div class="col-xl-3"></div>
        </div>
      </div>
        <div id="footer" class="row mt-4">
         <div class="col-md-12 text-center py-3" style="background-color: #E8EFEF">
           <div v-if="supportEmail || supportPhoneNumber">Billing Questions? <br>
           <a :href="supportEmailMailto">{{ supportEmail }}</a>
          <br>
           <a :href="supportPhoneNumberConnectTo">{{ formatPhoneNumber(supportPhoneNumber) }}</a>
           <br>
             </div>
         <div class="mt-2"><i>Health Here is a registered ISO/MSP of Elavon, Inc.</i></div>
          </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import { BFormGroup, BFormInput, BInputGroup, BFormCheckbox } from 'bootstrap-vue'
  import cct from 'credit-card-type'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'VisitCreditCardPayment',

    components: { BFormGroup, BFormInput, BInputGroup, BFormCheckbox },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      paymentAmount: {
        type: Number,
        default: null
      },
      patientHasToken: {
        type: Boolean,
        default: false
      },
      tokenizationEnabled: {
        type: Boolean,
        default: false
      },
      discountId: {
        type: Number,
        default: 0
      },
      discountAmount: {
        type: Number,
        default: 0
      }
    },

    data () {
      return {
        isSaving: false,
        isSkipping: false,
        orgName: this.$store.orgName,
        supportEmail: this.$store.billingSupportEmail,
        supportPhoneNumber: this.$store.billingSupportPhoneNumber,
        visitPayment: {
          expirationMonth: null,
          expirationYear: null,
          visitCharges: []
        },
        payment: {
          cardNumber: '',
          cardholderName: '',
          totalAmount: null,
          expirationMonth: 0,
          expirationYear: 0,
          cvv: '',
          tokenize: this.$props.tokenizationEnabled,
          emailReceipt: true,
          discountAmount: this.$props.discountAmount,
          organizationOutstandingBalanceDiscountId: this.$props.discountId
        }
      }
    },

    computed: {
      expirationDateDisplay () {
        if (this.payment.expirationMonth) {
          if (this.payment.expirationYear.length > 0) {
            return this.payment.expirationMonth + '/' + this.payment.expirationYear
          } else if (this.payment.expirationMonth.length > 1) {
            return this.payment.expirationMonth + '/'
          } else {
            return this.payment.expirationMonth
          }
        }
        return ''
      },

      cardType () {
        const cards = cct(this.payment.cardNumber)
        if (cards.length === 1) {
          return cards[0].niceType
        }

        return ''
      },

      selectedPaymentAmount () {
        if (this.$props.paymentAmount) {
          return this.$props.paymentAmount
        } else if (this.visitPayment.totalAmount && this.visitPayment.totalPaid) {
          return this.formatNumber(this.visitPayment.totalAmount - this.visitPayment.totalPaid)
        } else if (this.payment.totalAmount) {
          return this.payment.totalAmount
        }

        return 0
      },

      isTokenizationEnabled () {
        return this.$props.tokenizationEnabled
      },

      supportEmailMailto () {
        return 'mailto:' + this.supportEmail
      },

      supportPhoneNumberConnectTo () {
        return 'tel:' + this.supportPhoneNumber
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.payment.cardholderName) {
          this.addErrorForField('cardholderName', 'Card holder name is required.')
        }

        if (!this.payment.cardNumber) {
          this.addErrorForField('cardNumber', 'Card number is required.')
        }

        if (!this.payment.cvv) {
          this.addErrorForField('cvv', 'CVV is required.')
        }

        if (!this.payment.expirationMonth || !this.payment.expirationYear) {
          this.addErrorForField('expiration', 'Expiration is required.')
        }

        return !this.hasErrors()
      },

      numberFromString (value) {
        if (value) {
          value = value.replace(',', '')
          return Number.parseFloat(value)
        }

        return 0
      },
      formatPhoneNumber (input) {
        input = input.replace(/\D/g, '')
        const size = input.length
        if (size > 0) { input = '(' + input }
        if (size > 3) { input = input.slice(0, 4) + ') ' + input.slice(4, 11) }
        if (size > 6) { input = input.slice(0, 9) + '-' + input.slice(9) }
        return input
      },
      formatNumberString (value) {
        let number = 0.00
        if (typeof value === 'string') {
          value = value.replace(',', '')
          number = this.numberFromString(value)
        } else {
          number = value
        }

        if (number) {
          return this.formatNumber(number)
        }

        return number
      },

      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      },

      expirationInput (value) {
        const newDate = value
        const expirationParts = newDate.split('/')
        if (expirationParts.length > 1) {
          this.payment.expirationMonth = expirationParts[0]
          if (expirationParts[1].length > 2) {
            this.payment.expirationYear = expirationParts[1].substring(0, 2)
          } else {
            this.payment.expirationYear = expirationParts[1]
          }
        } else {
          this.payment.expirationMonth = expirationParts[0]
        }
      },

      skipPayment () {
        this.trackEvent(this.$options.name, 'SkipPayment')

        this.isSkipping = true
        this.$store.setModuleStatus('Payment', true, () => {
          this.isSkipping = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      toggleTokenize () {
        this.payment.tokenize = !this.payment.tokenize
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        if (this.isSaving) {
          return
        }

        this.payment.cardNumber = this.payment.cardNumber.match(/\d/g).join('')
        this.payment.creditCardType = this.cardType.replace(' ', '')
        this.payment.amount = this.selectedPaymentAmount
        this.isSaving = true
        api.put('/Patient/Visit/Payment', this.payment).then(response => {
          this.trackEvent(this.$options.name, 'PaymentCompleted', 'Amount', this.payment.totalAmount)
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('Payment', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      loadData () {
        api.get('/Patient/Visit/Payment').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.visitPayment = response.data
          this.payment.totalAmount = this.formatNumber(this.visitPayment.totalAmount - this.visitPayment.totalPaid)
          if (this.visitPayment.hasPaid) {
            this.$store.setModuleStatus('Payment', true, () => {
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
#body-container {
  min-height: calc(90vh - 110px);
}

#footer {
  height: 110px;
}

.container-fluid {
  padding-bottom: 0 !important;
}
 .bold-text {
   font-weight: bold;
 }
</style>
