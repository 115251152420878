<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Tell us about your spouse.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Spouse Name" :state="stateForField('spouseName')" :invalid-feedback="errorForField('spouseName')">
                                <b-form-input @input="clearFieldError('spouseName')" size="lg" :state="stateForField('spouseName')" v-model="demographics.spouseName"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Spouse Phone Number" :state="stateForField('spousePhone')" :invalid-feedback="errorForField('spousePhone')">
                                <b-form-input pattern="[0-9]*" @input="numberInput" size="lg" :state="stateForField('spousePhone')" :value="spousePhoneDisplay"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Spouse Is Emergency Contact?" :state="stateForField('isSpouseEmergencyContact')" :invalid-feedback="errorForField('isSpouseEmergencyContact')">
                                <b-form-select size="lg" @input="clearFieldError('isSpouseEmergencyContact')" v-model="demographics.isSpouseEmergencyContact" :state="stateForField('isSpouseEmergencyContact')" :options="yesNoOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                  <div class="form-row">
                    <div class="col-md-6" v-if="demographics.isSpouseEmergencyContact">
                      <b-form-group label="Protected Information in Voicemail" :state="stateForField('voicemail')" :invalid-feedback="errorForField('voicemail')">
                        <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.voicemailAllowed" name="check-button" switch>
                  <span v-if="hipaaContact.voicemailAllowed">
                    Allowed
                  </span>
                          <span v-else>
                    Not Allowed
                  </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="demographics.isSpouseEmergencyContact">
                      <b-form-group label="Appointment Information" :state="stateForField('appointment')"
                                    :invalid-feedback="errorForField('appointment')">
                        <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.appointment" name="check-button" switch>
                    <span v-if="hipaaContact.appointment">
                      Allowed
                    </span>
                          <span v-else>
                      Not Allowed
                    </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="form-row" v-if="demographics.isSpouseEmergencyContact">
                    <div class="col-md-6">
                      <b-form-group label="Billing Information" :state="stateForField('billing')"
                                    :invalid-feedback="errorForField('billing')">
                        <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.billing" name="check-button" switch>
                  <span v-if="hipaaContact.billing">
                  Allowed
                  </span>
                          <span v-else>
                  Not Allowed
                  </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="demographics.isSpouseEmergencyContact">
                      <b-form-group label="Treatment Information" :state="stateForField('treatment')"
                                    :invalid-feedback="errorForField('treatment')">
                        <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.treatment" name="check-button" switch>
                  <span v-if="hipaaContact.treatment">
                    Allowed
                  </span>
                          <span v-else>
                    Not Allowed
                  </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormSelect, BFormGroup, BFormInput } from 'bootstrap-vue/src/index'
  import { BFormCheckbox } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Spouse',

    components: { BFormGroup, BFormInput, BFormSelect, BFormCheckbox },

    mixins: [ErrorsMixin, DemographicsMixin, AnalyticsMixin],

    data () {
      return {
        demographics: {
          spouseArea: '',
          spouseExchange: '',
          spouseNumber: '',
          isSpouseEmergencyContact: true
        },
        hipaaContact: {
          voicemailAllowed: true,
          treatment: true,
          billing: true,
          appointment: true
        },
        isSaving: false,
        yesNoOptions: [
          {
            text: 'Yes',
            value: true
          },
          {
            text: 'No',
            value: false
          }
        ]
      }
    },

    computed: {
      spousePhoneDisplay () {
        if (this.demographics.spouseArea) {
          if (this.demographics.spouseNumber.length > 0) {
            return '(' + this.demographics.spouseArea + ') ' + this.demographics.spouseExchange + '-' + this.demographics.spouseNumber
          } else if (this.demographics.spouseExchange.length > 0) {
            return '(' + this.demographics.spouseArea + ') ' + this.demographics.spouseExchange
          } else if (this.demographics.spouseArea.length > 0) {
            return '(' + this.demographics.spouseArea
          }
        }
        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.demographics.spouseName) {
          this.addErrorForField('spouseName', 'Spouse name is required.')
        }

        if (!this.demographics.spouseArea || !this.demographics.spouseExchange || !this.demographics.spouseNumber) {
          this.addErrorForField('spousePhone', 'Spouse phone is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Demographics').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.demographics = response.data
          if (response.data.hipaaContact !== null) {
            this.hipaaContact = response.data.hipaaContact
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading demographics: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      numberInput (value) {
        this.clearFieldError('spousePhone')
        const newNumber = value
        let cleanNumber = newNumber.replace(/\D/g, '')
        if (cleanNumber.length > 0) {
          if (parseInt(cleanNumber[0]) === 1) {
            cleanNumber = cleanNumber.substr(1, cleanNumber.length - 1)
          }

          this.demographics.spouseArea = cleanNumber.substr(0, cleanNumber.length > 3 ? 3 : cleanNumber.length)
          if (cleanNumber.length > 3) {
            this.demographics.spouseExchange = cleanNumber.substr(3, cleanNumber.length > 6 ? 3 : cleanNumber.length - 3)
          } else {
            this.demographics.spouseExchange = ''
          }
          if (cleanNumber.length > 6) {
            this.demographics.spouseNumber = cleanNumber.substr(6, cleanNumber.length < 10 ? cleanNumber.length - 6 : 4)
          } else {
            this.demographics.spouseNumber = ''
          }
        } else {
          this.demographics.spouseArea = ''
          this.demographics.spouseExchange = ''
          this.demographics.spouseNumber = ''
        }

      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.demographics.hipaaContact = this.hipaaContact

        this.isSaving = true
        api.put('/Patient/EmergencyContact', this.demographics).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          if (this.demographics.isSpouseEmergencyContact) {
            this.$store.setModuleStatus('MarriageStatus', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            // Prompt for emergency contact
            this.$router.push(('/emergency-contact'))
            this.isSaving = false
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving demographics: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
