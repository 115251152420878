<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Contacts who can access your health information.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
      <list-modal :header-image="require('../../assets/family-history.svg')" :header="modalHeader" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="confirmOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import ListModal from '@/components/ListModal'

  export default {
    name: 'HIPAAContacts',

    components: { MultiselectList, ListModal },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        hipaaContactsResponse: {
          hipaaContacts: [],
          contactTypeOptions: []
        },
        isSaving: false,
        showListModal: false,
        confirmOptions: [
          {
            id: 1,
            name: 'I do not want to add HIPAA contacts',
            selected: false
          },
          {
            id: 2,
            name: 'Let me add HIPAA contacts',
            selected: false
          }
        ],
        modalHeader: 'Please confirm the patient does not wish to add HIPAA contacts.'
      }
    },

    computed: {
      options () {
        let options = []
        if (this.hipaaContactsResponse.hipaaContacts && this.hipaaContactsResponse.hipaaContacts.length > 0) {
          options = this.hipaaContactsResponse.hipaaContacts.map((option) => {
            return {
              id: option.hipaaContactId,
              type: 'edit',
              name: option.name,
              selected: true
            }
          })
        }

        options.push({
          id: 0,
          type: 'add',
          name: 'Add Contact'
        })

        return options
      },
      selectedOptions () {
        return this.hipaaContactsResponse.hipaaContacts.filter((anOption) => {
          return anOption.hipaaContactId
        })
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/HipaaContacts').then(response => {
          this.hipaaContactsResponse = response.data
          this.$store.contactTypeOptions = this.hipaaContactsResponse.contactTypeOptions
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading surgeries: ' + error.message, error)
          }
        })
      },

      optionSelected (item) {
        if (item.type === 'add') {
          // go to surgery select
          this.$router.push('/hipaa-contact')
        } else {
          const contact = this.hipaaContactsResponse.hipaaContacts.filter((aContact) => {
            return aContact.hipaaContactId === item.id
          }).shift()

          if (contact) {
            this.$router.push({ name: 'hipaa-contact', params: { hipaaContactId: contact.hipaaContactId } })
          }
        }
      },
      modalClosed () {
        const confirmed = this.confirmOptions[0].selected === true
        if (confirmed) {
          this.submit(true)
        }
      },

      modalSelection (item) {
        for (const anOption of this.confirmOptions) {
          if (anOption.id === item.id) {
            anOption.selected = true
          } else {
            anOption.selected = false
          }
        }
      },
      submit (confirmed = false) {
        if (this.validate() !== true) {
          return
        }

        if (this.selectedOptions.length === 0 && confirmed === false) {
          this.showListModal = true
          return
        }
        this.isSaving = true
        this.$store.setModuleStatus('HIPAAContact', true, () => {
          this.$router.push(this.$store.nextRoute())
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
