<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Are your benefits with one of these employers?</h1>
        <h2 class="subheader">If yes, please select an employer below</h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="d-flex flex-wrap align-items-start justify-content-center">
          <div @click.prevent="selectEmployer(employer)" v-for="employer in employment.employerOptions" :key="employer.organizationEmployerId" class="employer">
            <div class="img-container">
              <span class="helper"></span>
              <img :src="employer.logoUrl" :alt="employer.name">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>

    <div class="form-row">
        <button type="submit" @click.prevent="selectNone()" class="btn btn-outline-primary btn-lg mx-auto" style="width: 80%">None of these</button>
    </div>

    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'EmployerList',

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        employment: {
          organizationEmployerId: 0,
          employerOptions: {}
        },
        isSaving: false
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Employment').then(response => {
          this.employment = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading employment: ' + error.message, error)
            this.setFromApiErrorResponse(error)
            this.trackEvent(this.$options.name, 'GetEmploymentError', error)
          }
        })

      },

      save () {
        this.isSaving = true

        api.put('/Patient/Employment', this.employment).then(response => {
          this.$store.setModuleStatus('Employment', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving employment: ' + error.message, error)
            this.setFromApiErrorResponse(error)
            this.trackEvent(this.$options.name, 'SaveEmploymentError', error)
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      selectEmployer (employer) {
        this.employment.organizationEmployerId = employer.organizationEmployerId
        this.save()
      },

      selectNone () {
        this.$router.push('employer')
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
.employer {
  display: inline-block;
  height: 180px;
  width: 180px;
  box-shadow: 2px 2px 5px darkgray;
  border: 2px solid transparent;
  margin: 1em;
  border-radius: 5px;
  background-color: white;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.img-container {
  margin: 2px;
  width: 174px;
  height: 174px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.img-container img {
  max-width: 100%;
  max-height: 100%;
}

.employer h1 {
  margin-top: 75px;
  font-size: 20px;
  color: rgba(23,56,96,1.0);
  font-weight: bold;
}

.employer:HOVER {
  cursor: pointer;
  border: 2px solid rgba(255,62,3,1.0);
}
</style>
