<template>
    <div class="container-fluid">

        <div v-if="!cameraToggle" class="row">
            <div class="col custom-col-padding">
                <h1 v-if="!firstImageSubmitted" class="header">Take a photo of the injury</h1>
                <div v-if="!firstImageSubmitted" class="portrait-icon-container"><i class="portrait-icon fas fa-portrait"></i></div>
                <h2 v-if="!firstImageSubmitted" class="header">For concerns of both left and right body parts, or if swelling is involved, please take one photo of each side</h2>
                <h1 v-if="firstImageSubmitted" class="header">Your photo has been saved. Would you like to take a second photo?</h1>
            </div>
        </div>

        <div v-if="!cameraToggle"  class="row">
            <div class="col-md-12 custom-button-center">
                <div class="btn btn-primary btn-lg mx-auto" @click.prevent="toggleCamera">Open Camera</div>
                <div class="btn btn-primary btn-lg mx-auto" @click.prevent="nextRoute"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Skip</div>
            </div>
        </div>

        <div v-if="cameraToggle" class="custom-camera-button">
            <div class="col-md-12 custom-button-center">
                <div v-if="!image" class="btn btn-primary btn-lg mx-auto" @click.prevent="onCapture">Take Photo</div>
                <div v-if="image" class="btn btn-primary btn-lg mx-auto" @click.prevent="clearImage">Retake Photo</div>
                <div v-if="image" class="btn btn-primary btn-lg mx-auto" style="margin-left: 10px !important;" @click.prevent="uploadImage">
                    <font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>
                    Save
                </div>
            </div>
        </div>

        <div v-if="initializeCamera" class="row custom-camera-view">
            <div :style="[cameraToggle ? {display: 'inline'} : {display: 'none'}]">
                <div v-if="image">
                    <img :style="{width: this.imageWidth}" :src="image">
                </div>
                <div :style="[image !== null ? {display: 'none'} : {display: 'inline'}]">
                    <web-cam ref="webcam"
                             :device-id="deviceId"
                             :width="this.screenWidth"
                             :height="this.screenHeight"
                             @error="onError"
                             @cameras="onCameras"
                    />
                </div>
            </div>
        </div>

        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>

    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import WebCam from '../../components/WebCam.vue'
  import AnalyticsMixin from '../../mixins/AnalyticsMixin'

  export default {
    name: 'VisitPhoto',

    components: { WebCam },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      captureOnly: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        initializeCamera: false,
        image: null,
        firstImageSubmitted: false,
        cameraToggle: false,
        camera: null,
        deviceId: null,
        devices: [],
        isSaving: false
      }
    },

    watch: {
      camera: function (id) {
        this.deviceId = id
      },
      devices: function () {
        const first = this.devices[0]
        if (first) {
          this.camera = first.deviceId
          this.deviceId = first.deviceId
        }
      }
    },

    mounted () {
      this.screenWidth = screen.width
      this.screenHeight = screen.width < 500 ? 500 : screen.height - 120
      this.inPortraitMode = window.matchMedia('(orientation: portrait)').matches
    },

    methods: {
      toggleCamera () {
        this.initializeCamera = true
        this.cameraToggle = !this.cameraToggle
      },

      nextRoute () {
        this.isSaving = true
        this.$store.setModuleStatus('VisitPhoto', true, () => {
          this.$router.push(this.$store.nextRoute())
        })
      },

      onCapture () {
        this.imageWidth = screen.width < 800 ? `${screen.width}px` : '800px'
        this.image = this.$refs.webcam.capture()
        this.clearAllErrors()
      },

      onError (error) {
        this.trackEvent(this.$options.name, 'CameraAccessError', error)
        this.$router.push(this.$store.nextRoute())
      },

      onCameras (cameras) {
        this.devices = cameras
      },

      clearImage () {
        this.image = null
      },

      uploadImage () {
        this.isSaving = true
        const imageFile = new File([this.dataURItoBlob(this.image)], `visit-photo${!this.firstImageSubmitted ? '1' : '2'}.jpeg`, { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)
        api.post('/Patient/VisitPhoto', multipartForm).then(response => {
          this.trackEvent(this.$options.name, 'UploadVisitPhoto', 'ResponseTime', response.config.requestDuration)
          if (this.firstImageSubmitted) {
            this.$store.setModuleStatus('VisitPhoto', true, () => {
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            this.firstImageSubmitted = true
            this.image = null
            this.cameraToggle = false
            this.isSaving = false
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.trackEvent(this.$options.name, 'SaveError', 'VisitPhotoNotSaved')
            this.$log.error('Error uploading visit photo: ' + error.message, error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      dataURItoBlob (dataURI) {
        // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
        // convert base64/URLEncoded data component to raw binary data held in a string

        if (!dataURI) {
          return
        }

        let byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
      }
    }
  }
</script>

<style scoped>
    h2 {
        font-size: 22px;
    }
    h4 {
        text-align: center
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0 !important;
    }
    .col {
        padding-left: 30px;
    }
    .row {
        margin-right: 0
    }
    .header {
        margin-bottom: 30px;
    }

    .portrait-icon-container {
        text-align: center;
    }

    .portrait-icon {
        font-size: 100px;
    }

    .custom-button-center {
        margin: 10px auto 0;
        display: flex;
        max-width: 350px;
    }

    .custom-camera-button {
        position: absolute !important;
        bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    @media only screen and (min-height: 670px) {
        .custom-camera-button {
            position: absolute !important;
            bottom: 50px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    @media only screen and (min-height: 720px) {
        .custom-camera-button {
            position: absolute !important;
            bottom: 100px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    .custom-camera-view {
        background: #000000;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
        margin: auto;
    }

    @media only screen and (min-width: 700px) {
        .custom-camera-view {
            background: #000000;
            max-height: 100%;
            max-width: 100%;
            overflow: hidden;
            margin: auto;
        }
    }
</style>
