const OptionsMixin = {

  methods: {
    optionsFromArray (responseOptions, idField) {
      if (responseOptions && responseOptions.length > 0) {
        const options = responseOptions.map((option) => {
          return {
            value: option[idField],
            text: option.name
          }
        })

        options.unshift({ value: 0, text: '' })

        return options
      }

      return []
    },

    multiselectOptionsFromArray (items, idField, nameField, altNameField, selectedField, type = 'item') {
      if (items && items.length > 0) {
        return items.map((option) => {
          return {
            id: option[idField],
            type,
            name: option[nameField] ? option[nameField] : option[altNameField] ? option[altNameField] : '',
            selected: selectedField === true ? true : option[selectedField]
          }
        })
      }

      return []
    },

    multiselectOptionsFromArrayWithSelectedIds (items, idField, nameField, selectedIds) {
      if (items && items.length > 0) {
        return items.map((option) => {
          return {
            id: option[idField],
            name: option[nameField],
            selected: selectedIds.includes(option[idField])
          }
        })
      }

      return []
    }
  }
}

export default OptionsMixin
