import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import VueLogger from 'vuejs-logger'
import store from './services/store.js'
import router from './services/router'
import * as vClickOutside from 'v-click-outside-x'
import VueMatomo from 'vue-matomo'
import VueMeta from 'vue-meta'
import UAparse from 'ua-parser-js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { faSpinner, faPhone, faPhoneSlash, faMicrophone, faMicrophoneSlash, faBars, faTimes, faSearch, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faMicrophoneAlt, faMicrophoneAltSlash, faVideoSlash, faVideo, faShieldCheck } from '@fortawesome/pro-light-svg-icons'
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons'

const isProduction = process.env.VUE_APP_ENVIRONMENT === 'production'

library.add(faSpinner)
library.add(faPhone)
library.add(faPhoneSlash)
library.add(faMicrophone)
library.add(faMicrophoneSlash)
library.add(faBars)
library.add(faTimes)
library.add(faSearch)
library.add(faCheckCircle)
library.add(faMicrophoneAlt)
library.add(faMicrophoneAltSlash)
library.add(faVideo)
library.add(faVideoSlash)
library.add(faShieldCheck)
library.add(faLockAlt)
library.add(faExclamationCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.config.productionTip = false

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)

Vue.use(UAparse)
Vue.use(VueMeta)
Vue.use(VueLogger, options)
Vue.use(vClickOutside)
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: process.env.VUE_APP_ANALYTICS_BASE_URL,
  siteId: process.env.VUE_APP_ANALYTICS_SITE_ID,

  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Enables automatically registering pageviews on the router
  router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Whether or not to log debug information
  // Default: false
  debug: !isProduction
})

if (isProduction) {
  Vue.config.warnHandler = function (msg, vm, trace) {
    const errorMessage = `Warn: ${msg}\nTrace: ${trace}`
    if (Vue.prototype.$matomo) {
      Vue.prototype.$matomo.trackEvent('WarnHandler', 'Error', errorMessage)
    }
    console.error(errorMessage)
  }

  Vue.config.errorHandler = function (err, vm, info) {
    const errorMessage = `Error: ${err.toString()}\nInfo: ${info}`
    if (Vue.prototype.$matomo) {
      Vue.prototype.$matomo.trackEvent('ErrorHandler', 'Error', errorMessage)
    }
    console.error(errorMessage)
  }

  const handler = function (msg, url, line, col, error) {

    const obj = {
      msg,
      url,
      line,
      col
    }
    if (Vue.prototype.$matomo) {
      Vue.prototype.$matomo.trackEvent('JSErrorHandler', 'Error', JSON.stringify(obj))
    }
  }

  window.onerror = handler
}

store.restoreSession()

Object.defineProperties(Vue.prototype, {
  $store: {
    get: function () {
      return store
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
