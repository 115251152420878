<template>
    <div id="full-container">
        <div ref="mainVideoContainer" id="main-video-container">
        </div>
        <participant-tray :show-local="showLocalInTray" @video-clicked="videoClicked" :main-display-track="mainVideoTrack"></participant-tray>
        <div id="control-container">
            <div v-if="!isConnected" class="text-center">
                <button type="button" @click.prevent="connectToRoom()" class="control-button connect mx-auto"><font-awesome-icon size="2x" v-if="isConnecting" icon="spinner" pulse/><font-awesome-icon size="2x" v-if="!isConnecting" :icon="['fal', 'video']"/></button>
                <div class="control-label text-center">Connect</div>
            </div>
            <div v-if="isConnected">
                <button type="button" @click.prevent="disconnectFromRoom()" class="control-button disconnect"><font-awesome-icon size="2x" v-if="!isConnecting" :icon="['fal', 'video-slash']"/></button>
                <div class="control-label text-center">Disconnect</div>
            </div>
            <div v-if="isConnected">
                <button type="button" @click.prevent="toggleMute()" class="control-button mute"><font-awesome-icon size="2x" v-if="!isMuted" :icon="['fal', 'microphone-alt']"/><font-awesome-icon size="2x" v-if="isMuted" :icon="['fal', 'microphone-alt-slash']"/></button>
                <div v-if="isMuted" class="control-label text-center">Unmute</div>
                <div v-if="!isMuted" class="control-label text-center">Mute</div>
            </div>
        </div>
        <div v-if="hasLogoUrl" id="logo-container">
            <img :src="virtualVisit.orgLogoUrl">
        </div>
    </div>
</template>

<script>
  import api from '../services/api'
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import TwilioVideo from 'twilio-video'
  import ParticipantTray from '../components/ParticipantTray'
  import VirtualVisitEvents from '../services/virtual-visit-events'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'VirtualVisit',

    mixins: [ErrorsMixin, AnalyticsMixin],

    components: { ParticipantTray },

    data () {
      return {
        virtualVisit: {
          token: null,
          roomSid: null
        },
        isSaving: false,
        localTracks: null,
        isConnected: false,
        isConnecting: false,
        isMuted: false,
        room: null,
        mainVideoTrack: null,
        showLocalInTray: false
      }
    },

    computed: {
      hasLogoUrl () {
        return this.virtualVisit && this.virtualVisit.orgLogoUrl
      },

      orgName () {
        return this.$store.orgName
      },

      localVideoTrack () {
        let videoTrack = null
        if (this.localTracks) {
          this.localTracks.forEach((track) => {
            if (track.kind === 'video') {
              videoTrack = track
            }
          })
        }

        return videoTrack
      }
    },

    watch: {
    },

    methods: {
      initializeVideo: function () {
        TwilioVideo.createLocalTracks().then(tracks => {
          this.localTracks = tracks
          this.showPreviewVideo()
        }).catch(error => {
          this.trackEvent(this.$options.name, 'InitializeVideoError', error.message)
          console.error(`Unable to initialize local video: ${error.message}`)
        })
      },

      showPreviewVideo: function () {
        const mainContainer = document.querySelector('#main-video-container')
        this.clearMainVideo()

        if (this.localTracks) {
          this.localTracks.forEach((track) => {
            mainContainer.prepend(track.attach())
          })
        }
      },

      clearMainVideo () {
        const mainContainer = document.querySelector('#main-video-container')
        const videos = document.querySelectorAll('#main-video-container > video')
        if (videos) {
          for (const el of videos) {
            mainContainer.removeChild(el)
          }
        }

        const audios = document.querySelectorAll('#main-video-container > audio')
        if (audios) {
          for (const el of audios) {
            mainContainer.removeChild(el)
          }
        }
      },

      connectToRoom: function () {
        this.clearAllErrors()
        this.isConnecting = true
        const connectionOptions = {
          logLevel: 'info',
          tracks: this.localTracks
        }

        api.get('/Patient/VirtualVisit/Start').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.virtualVisit = response.data
          TwilioVideo.connect(this.virtualVisit.token, connectionOptions).then(room => {
            VirtualVisitEvents.connectedToRoom(room)
            this.room = room
            this.setupInitialVideoView()
            this.room.on('trackSubscribed', this.trackSubscribed)
            this.room.on('trackUnpublished', this.trackUnpublished)
            this.room.on('participantDisconnected', this.participantDisconnected)
            this.trackEvent(this.$options.name, 'RoomConnectSuccess', room.name)
            this.isConnecting = false
            this.isConnected = true
          }).catch(error => {
            this.trackEvent(this.$options.name, 'RoomConnectError', error.message)
            this.$log.error(`Unable to connect to Room: ${error.message}`)
            this.isConnecting = false
            this.isConnected = false
          })
        }).catch(error => {
          this.isConnecting = false
          this.isConnected = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })

      },

      participantDisconnected (participant) {
        this.$log.debug('Participant Disconnected notification for:')
        this.$log.debug(participant)
        if (this.mainVideoTrack) {
          participant.videoTracks.forEach((publications) => {
            if (this.mainVideoTrack.sid === publications.trackSid) {
              this.setupInitialVideoView()
            }
          })
        }
      },

      showTrack (track) {
        this.mainVideoTrack = track

        const media = this.$refs.mainVideoContainer.querySelectorAll('video,audio')
        if (media) {
          for (const el of media) {
            this.$refs.mainVideoContainer.removeChild(el)
          }
        }

        if (track) {
          if (this.localVideoTrack) {
            if (this.localVideoTrack.sid !== track.sid) {
              this.showLocalInTray = true
            } else {
              this.showLocalInTray = false
            }
          }
          this.$refs.mainVideoContainer.prepend(track.attach())
        } else {
          this.showLocalInTray = false
          this.showPreviewVideo()
        }
      },

      setupInitialVideoView () {
        let screenShareTrack = null
        let providerVideo = null
        this.room.participants.forEach((participant) => {
          if (participant.identity.startsWith('User-')) {
            participant.videoTracks.forEach((publication) => {
              if (publication.isSubscribed) {
                if (publication.track.name === 'ScreenShare') {
                  screenShareTrack = publication.track
                } else {
                  providerVideo = publication.track
                }
              }
            })
          }
        })

        if (screenShareTrack) {
          this.showTrack(screenShareTrack)
        } else if (providerVideo) {
          this.showTrack(providerVideo)
        } else {
          this.showTrack(null)
        }
      },

      trackSubscribed (track, publication, participant) {
        if (track.kind === 'video') {
          if (!this.mainVideoTrack) {
            this.setupInitialVideoView()
          } else if (track.name === 'ScreenShare') {
            this.showTrack(track)
          }
        }
      },

      trackUnpublished (publication) {
        if (publication.track && publication.track.kind === 'video') {
          if (this.mainVideoTrack) {
            if (this.mainVideoTrack.sid === publication.track.sid) {
              this.setupInitialVideoView()
            }
          }
        } else if (publication.trackSid && this.mainVideoTrack) {
          if (this.mainVideoTrack.sid === publication.trackSid) {
            this.setupInitialVideoView()
          }
        }
      },

      disconnectFromRoom () {
        this.mainVideoTrack = null
        this.showPreviewVideo()
        if (this.room && this.room.state !== 'disconnected') {
          this.room.disconnect()
          this.isConnected = false
          this.room = null
          this.trackEvent(this.$options.name, 'DisconnectedRoom')
          const participantsContainer = document.querySelector('#participants-container')
          const participantContainers = document.querySelectorAll('#participants-container > div')
          if (participantContainers) {
            for (const el of participantContainers) {
              participantsContainer.removeChild(el)
            }
          }
        }
      },

      toggleMute () {
        const publications = this.room.localParticipant.audioTracks

        publications.forEach((publication) => {
          this.trackEvent(this.$options.name, 'MuteAudio', this.isMuted ? 'Unmuting' : 'Muting')
          if (this.isMuted) {
            this.isMuted = false
            publication.track.enable()
          } else {
            this.isMuted = true
            publication.track.disable()
          }
        })
      },

      videoClicked: function (track) {
        this.showTrack(track)
      },

      stopRecording: function () {
        for (const track of this.localTracks) {
          if (this.room && this.room.localParticipant) {
            this.room.localParticipant.unpublishTrack(track)
          }
          this.detachTrack(track)
          track.disable()
          track.stop()
        }
        this.localTracks = null
        this.trackEvent(this.$options.name, 'StoppedRecording')
      },

      detachTrack: function (track) {
        if (track) {
          track.detach().forEach((element) => {
            element.remove()
          })
        }
      }

    },

    created () {
      this.initializeVideo()
    },

    beforeDestroy () {
      this.stopRecording()
      this.disconnectFromRoom()
      document.body.className = ''
    },

    beforeCreate () {
      document.body.className = 'virtual-visit'
    }
  }
</script>

<style>
    body.virtual-visit {
        background-color: black;
    }

    #full-container {
        background-color: black;
    }
    #main-video-container{
        height: 100vh;
        width: 100vw;
    }
    #main-video-container video {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    #logo-container {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    #logo-container img {
        max-width: 80px;
        height: auto;
    }

    @media screen and (orientation:landscape) {
        #logo-container img {
            max-height: 100px;
            width: auto;
        }
    }

    #control-container {
        position: absolute;
        bottom: 20px;
        left: 0;
        margin-bottom: 20px;
        height: 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .control-button {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        margin-left: 20px;
        padding: 0;
    }

    .control-button:focus {
        outline: none;
    }

    .control-label {
        color: white;
        font-weight: bold;
    }

    .connect {
        border-color: green;
        background-color: green;
        color: white;
    }

    .disconnect {
        border-color: red;
        background-color: red;
        color: white;
    }

    .mute {
        background-color: white;
        color: black;
    }

    .disconnect:disabled {
        border-color: lightcoral;
        background-color: lightcoral;
        color: white;
    }

    @media screen and (orientation:landscape) {
        #main-video-container video {
            object-fit: contain;
            object-position: center;
            width: auto;
            height: 100%;
            display: block;
            margin: 0 auto;
            max-width: 100vw;
        }
    }
</style>
