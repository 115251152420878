<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Do any of these apply to you?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div v-for="specialAlert in specialAlerts" :key="specialAlert.specialAlertId" class="row option">
            <div class="col-1"></div>
            <div class="col-md-7 option-title">
              {{ specialAlert.name }}
            </div>
            <div class="col-md-3 option-choices">
              <div class="options">
                <span @click="specialAlert.hasSpecialAlert = true"><img v-if="specialAlert.hasSpecialAlert === true" class="radio-button" src="../../assets/radio-selected.svg"><img v-if="specialAlert.hasSpecialAlert !== true" class="radio-button" src="../../assets/radio-unselected.svg"> <span class="label">Yes</span></span>
                <span @click="specialAlert.hasSpecialAlert = false"><img v-if="specialAlert.hasSpecialAlert === false" class="radio-button" src="../../assets/radio-selected.svg"><img v-if="specialAlert.hasSpecialAlert !== false" class="radio-button" src="../../assets/radio-unselected.svg"> <span class="label">No</span></span>
              </div>
            </div>
            <div class="col-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'SpecialAlerts',

    components: { },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        specialAlerts: [],
        isSaving: false,
        isRemoving: false
      }
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        let missingResponse = false
        this.specialAlerts.forEach(specialAlert => {
          if (specialAlert.hasSpecialAlert === null) {
            missingResponse = true
          }
        })

        if (missingResponse) {
          this.addErrorForField('exception', 'You must provide a response for all items.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SpecialAlerts').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)

          this.specialAlerts = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading special alerts: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      optionSelected (item) {
        const selectedItem = this.specialAlerts.filter((candidate) => {
          return candidate.specialAlertId === item.id
        }).shift()

        if (selectedItem) {
          selectedItem.selected = !selectedItem.selected
        }
      },

      submit () {
        if (this.validate() !== true) {
          this.$scrollToTop()
          return
        }

        this.isSaving = true

        api.put('/Patient/SpecialAlerts', this.specialAlerts).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('SpecialAlerts', true, () => {
            this.$router.push(this.$store.nextRoute())
            this.isSaving = false
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving special alerts: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
  .radio-button {
    margin-top: 8px;
    margin-left: 8px;
    height: 52px;
    width: 52px;
    display: inline-block;
  }

  .options {
    font-size: 20px;
    font-weight: bold;
    padding-right: 8px;
    padding-bottom: 8px;
  }

  .options .label {
    padding-left: 8px;
  }

  .option-title {
    margin-left: 8px;
    display: inline-block;
    font-size: 20px;
    color: rgba(23,56,96,1.0);
    font-weight: bold;
    padding: .25em;
    padding-top: 20px;
    overflow: hidden;

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  @media (min-width: 769px) {
    .option-title {
      padding-left: 15em;
    }

    .option-choices {
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    .option {
      box-shadow: 2px 2px 5px darkgray;
      margin: .25em;
      border-radius: 5px;
      background-color: white;
      min-height: 72px;
      max-height: 100%;
      border: 2px solid transparent;
      clear: both;
      vertical-align: center;
    }

    .option-choices {
      padding: 0px;
      text-align: left;
    }
  }
</style>
