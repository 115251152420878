const AnalyticsMixin = {
  data () {
    return {
      errorsMixin: {
        errors: {}
      }
    }
  },

  methods: {
    trackEvent (category, action, name, value) {
      if (this.$matomo) {
        this.$matomo.trackEvent(category, action, name, value)
      } else {
        this.$log.debug('Matomo was not loaded, falling back to _paq queue')
        if (!window._paq) {
          window._paq = []
        }
        window._paq.push(['trackEvent', category, action, name, value])
      }
    },

    setUserId (userId) {
      if (this.$matomo) {
        this.$matomo.setUserId(userId)
      } else {
        this.$log.debug('Matomo was not loaded, falling back to _paq queue')
        if (!window._paq) {
          window._paq = []
        }
        window._paq.push(['setUserId', userId])
      }
    },

    resetUserId () {
      if (this.$matomo) {
        this.$matomo.resetUserId()
      } else {
        this.$log.debug('Matomo was not loaded, falling back to _paq queue')
        if (!window._paq) {
          window._paq = []
        }
        window._paq.push(['resetUserId'])
      }
    },

    setCustomDimension (dimension, value) {
      if (this.$matomo) {
        this.$matomo.setCustomDimension(dimension, value)
      } else {
        this.$log.debug('Matomo was not loaded, falling back to _paq queue')
        if (!window._paq) {
          window._paq = []
        }
        window._paq.push(['setCustomDimension', dimension, value])
      }
    }
  }
}

export default AnalyticsMixin
