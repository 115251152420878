<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header"></h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div v-if="logoUrl" class="row">
                    <div class="col text-center"><img class="logo-image" :src="logoUrl"></div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <h1 class="detail">Security settings on your browser are preventing this website from loading.</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'ErrorPage',

    data () {
      return {
        blockReason: {},
        isSaving: false
      }
    },

    computed: {
      logoUrl () {
        return this.$store.orgLogoUrl
      },

      orgName () {
        return this.$store.orgName
      }
    },

    methods: {
    }
  }
</script>

<style scoped>
    .appointment-image {
        max-height: 140px;
    }

    .logo-image {
        max-height: 140px;
        max-width: 49.9999%;
    }

    .detail {
        margin: 2em 0em;
        font-size: 24px;
        line-height: 1.6;
    }
</style>
