<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Have you had a Flu Vaccine?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Flu Vaccine" :state="stateForField('hasHadFluVaccine')" :invalid-feedback="errorForField('hasHadFluVaccine')">
                                <b-form-select size="lg" @input="clearFieldError('hasHadFluVaccine')" v-model="fluVaccine.hasHadFluVaccine" :state="stateForField('hasHadFluVaccine')" :options="yesNoOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showDate" label="Vaccination Date" :state="stateForField('fluVaccineDate')" :invalid-feedback="errorForField('fluVaccineDate')">
                                <b-input-group>
                                    <b-form-select size="lg" @input="clearFieldError('fluVaccineDate')" v-model="fluVaccine.fluVaccineDateMonth" :state="stateForField('fluVaccineDate')" :options="monthRadioOptions"></b-form-select>
                                    <b-form-select size="lg" @input="clearFieldError('fluVaccineDate')" v-model="fluVaccine.fluVaccineDateYear" :state="stateForField('fluVaccineDate')" :options="yearRadioOptions"></b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup, BInputGroup } from 'bootstrap-vue'
  import moment from 'moment'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'FluVaccine',

    components: { BFormGroup, BInputGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        fluVaccine: {
          hasHadFluVaccine: null,
          fluVaccineDate: null,
          fluVaccineDateMonth: null,
          fluVaccineDateYear: null
        },
        isSaving: false,
        yesNoOptions: [
          {
            text: 'Yes',
            value: true
          },
          {
            text: 'No',
            value: false
          }
        ]
      }
    },

    computed: {
      monthRadioOptions () {
        return [
          {
            value: null,
            text: ''
          },
          {
            value: 1,
            text: 'January'
          },
          {
            value: 2,
            text: 'February'
          },
          {
            value: 3,
            text: 'March'
          },
          {
            value: 4,
            text: 'April'
          },
          {
            value: 5,
            text: 'May'
          },
          {
            value: 6,
            text: 'June'
          },
          {
            value: 7,
            text: 'July'
          },
          {
            value: 8,
            text: 'August'
          },
          {
            value: 9,
            text: 'September'
          },
          {
            value: 10,
            text: 'October'
          },
          {
            value: 11,
            text: 'November'
          },
          {
            value: 12,
            text: 'December'
          }
        ]
      },

      yearRadioOptions () {
        const years = []
        for (let i = moment().year(); i > 1900; i--) {
          years.push(i)
        }

        return years
      },

      showDate () {
        if (this.fluVaccine.hasHadFluVaccine === true) {
          return true
        }

        return false
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.showDate && (!this.fluVaccine.fluVaccineDateMonth || !this.fluVaccine.fluVaccineDateYear)) {
          this.addErrorForField('fluVaccineDate', 'Vaccine date is required')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/FluVaccine').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.fluVaccine = response.data
          if (this.fluVaccine.fluVaccineDate) {
            const fluVaccineDate = moment(this.fluVaccine.fluVaccineDate)
            this.fluVaccine.fluVaccineDateMonth = fluVaccineDate.month() + 1
            this.fluVaccine.fluVaccineDateYear = fluVaccineDate.year()
          }

        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        if (this.fluVaccine.fluVaccineDateMonth && this.fluVaccine.fluVaccineDateYear) {
          this.fluVaccine.fluVaccineDate = this.fluVaccine.fluVaccineDateYear + '-' + this.fluVaccine.fluVaccineDateMonth + '-01'
        } else {
          this.fluVaccine.fluVaccineDate = null
        }

        this.isSaving = true
        api.put('/Patient/FluVaccine', this.fluVaccine).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('FluVaccine', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving flu vaccine: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
