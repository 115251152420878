<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What is {{ guarantorName }} address?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Street 1" :state="stateForField('street1')" :invalid-feedback="errorForField('street1')">
                                <b-form-input @input="clearFieldError('street1')" size="lg" :state="stateForField('street1')" v-model="visitGuarantor.address.street1"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Street 2" :state="stateForField('street2')" :invalid-feedback="errorForField('street2')">
                                <b-form-input @input="clearFieldError('street2')" size="lg" :state="stateForField('street2')" v-model="visitGuarantor.address.street2"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="City" :state="stateForField('city')" :invalid-feedback="errorForField('city')">
                                <b-form-input @input="clearFieldError('city')" size="lg" :state="stateForField('city')" v-model="visitGuarantor.address.city"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="State" :state="stateForField('stateId')" :invalid-feedback="errorForField('stateId')">
                                <b-form-select size="lg" v-model="visitGuarantor.address.stateId" :state="stateForField('stateId')" :options="stateRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Zip" :state="stateForField('zip')" :invalid-feedback="errorForField('zip')">
                                <b-form-input autocomplete="postal-code" type="number" pattern="[0-9]*" @input="clearFieldError('zip')" size="lg" :state="stateForField('zip')" v-model="visitGuarantor.address.zip"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup, BFormInput } from 'bootstrap-vue'

  export default {
    name: 'VisitGuarantorAddress',

    components: { BFormGroup, BFormInput, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        visitGuarantor: {
          address: {},
          stateOptions: []
        },
        guarantorName: 'guarantor\'s',
        isSaving: false
      }
    },

    computed: {
      stateRadioOptions () {
        return this.optionsFromArray(this.visitGuarantor.stateOptions, 'stateId')
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.visitGuarantor.address.street1) {
          this.addErrorForField('street1', 'Street 1 is required.')
        }

        if (!this.visitGuarantor.address.city) {
          this.addErrorForField('city', 'City is required.')
        }

        if (!this.visitGuarantor.address.stateId) {
          this.addErrorForField('stateId', 'State is required.')
        }

        if (!this.visitGuarantor.address.zip) {
          this.addErrorForField('zip', 'Zip is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/VisitGuarantor').then(response => {
          if (!response.data.address) {
            response.data.address = {}
          }
          this.visitGuarantor = response.data
          if (this.visitGuarantor.firstName && this.visitGuarantor.lastName) {
            this.guarantorName = this.visitGuarantor.firstName + ' ' + this.visitGuarantor.lastName + '\'s'
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading visit guarantor address: ' + error.message, error)
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/VisitGuarantor', this.visitGuarantor).then(response => {
          this.$store.setModuleStatus('VisitGuarantor', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving visit guarantor address: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
