<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Tell us about your alcohol usage.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Alcohol Usage" :state="stateForField('alcoholUsageId')" :invalid-feedback="errorForField('alcoholUsageId')">
                                <b-form-select size="lg" @input="clearFieldError('alcoholUsageId')" :state="stateForField('alcoholUsageId')" v-model="socialHistory.alcoholUsageId" :options="alcoholUsageRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="showAlcoholDetails" :label="detailQuestion" :state="stateForField('alcoholTimesPerYear')" :invalid-feedback="errorForField('alcoholTimesPerYear')">
                                <b-form-select size="lg" @input="clearFieldError('alcoholTimesPerYear')" :state="stateForField('alcoholTimesPerYear')" v-model="socialHistory.alcoholTimesPerYear" :options="alcoholDetailRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup } from 'bootstrap-vue'

  export default {
    name: 'TobaccoUsage',

    components: { BFormGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        socialHistory: {
          alcoholUsageOptions: []
        },
        isSaving: false,
        showListModal: false
      }
    },

    computed: {
      promptDetails () {
        return this.$store.boolForModuleConfig('SocialHistory', 'AlcoholDetails', true)
      },

      alcoholUsageRadioOptions () {
        return this.optionsFromArray(this.socialHistory.alcoholUsageOptions, 'alcoholUsageId')
      },

      selectedUsage () {
        return this.socialHistory.alcoholUsageOptions.filter(anItem => {
          return anItem.alcoholUsageId === this.socialHistory.alcoholUsageId
        }).shift()
      },

      showAlcoholDetails () {
        const usageOption = this.selectedUsage

        if (usageOption && this.promptDetails) {
          if (usageOption.alcoholUsageId !== 1) {
            return true
          }
        }

        return false
      },

      alcoholDetailRadioOptions () {
        return [
          {
            value: 0,
            text: 'None'
          },
          {
            value: 1,
            text: 'One'
          },
          {
            value: 2,
            text: 'Two'
          },
          {
            value: 3,
            text: 'Three or more'
          }
        ]
      },

      detailQuestion () {
        if (this.socialHistory.alcoholDrinksPerDay) {
          return 'How many times have you had more than ' + this.socialHistory.alcoholDrinksPerDay + ' drinks in the past year?'
        }

        return 'How many times have you had more than 4 drinks in the past year?'
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.socialHistory.alcoholUsageId) {
          this.addErrorForField('alcoholUsageId', 'Alcohol usage is required.')
        }

        if (this.showAlcoholDetails && !this.socialHistory.alcoholTimesPerYear && this.socialHistory.alcoholTimesPerYear !== 0) {
          this.addErrorForField('alcoholTimesPerYear', 'An answer is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SocialHistory').then(response => {
          this.socialHistory = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/SocialHistory', this.socialHistory).then(response => {
          if (this.$store.boolForModuleConfig('SocialHistory', 'PromptDrugUse', true)) {
            this.$router.push('drug-usage')
            this.isSaving = false
          } else {
            this.$store.setModuleStatus('SocialHistory', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving social history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
