<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">We found your appointment!</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div v-if="!logoUrl" class="row">
                    <div class="col text-center"><img class="appointment-image" src="../assets/appointment-found.svg"></div>
                </div>
                <div v-if="logoUrl" class="row">
                    <div class="col text-center"><img class="logo-image" :src="logoUrl"></div>
                </div>
                <div class="row">
                    <div class="col text-center detail">
                        <p v-if="providerName">
                            Provider:&nbsp;<b>{{ providerName }}</b>
                        </p>
                        <p>
                          Time: <b>{{ appointmentDate }}</b>
                        </p>
                        <div class="locationAddress" v-if="showLocation">
                          <b>{{locationName}}</b><br/>
                          {{locationStreet1}}<br/>
                          <template v-if="locationStreet2">
                            {{locationStreet2}}<br/>
                          </template>
                          {{locationCity}}, {{locationState}} {{locationZip}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <button type="submit" @click.prevent="next()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Continue</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import moment from 'moment-timezone'

  export default {
    name: 'AppointmentConfirmation',

    data () {
      return {
        authorization: {},
        isSaving: false
      }
    },

    computed: {
      providerName () {
        const first = this.$store.providerFirstName
        const last = this.$store.providerLastName
        const cred = this.$store.providerCredential

        if (last == null) return null

        let name = last

        if (first) name = first + ' ' + name

        if (cred) name += ', ' + cred

        return name
      },

      appointmentDate () {
        if (this.$store.appointmentDate) {
          if (this.$store.locationTimezone) {
            return moment(this.$store.appointmentDate).tz(this.$store.locationTimezone).format('dddd, MMMM Do YYYY, h:mm a zz')
          } else {
            return moment(this.$store.appointmentDate).format('dddd, MMMM Do YYYY, h:mm a')
          }
        }

        return ''
      },

      showLocation () {
        if (!this.locationName || this.$store.isVirtualVisit) {
          return false
        }

        return true
      },

      locationName () {
        return this.$store.locationName
      },

      locationStreet1 () {
        return this.$store.locationStreet1
      },

      locationStreet2 () {
        return this.$store.locationStreet2
      },

      locationCity () {
        return this.$store.locationCity
      },

      locationState () {
        return this.$store.locationState
      },

      locationZip () {
        return this.$store.locationZip
      },

      logoUrl () {
        return this.$store.orgLogoUrl
      },

      orgName () {
        return this.$store.orgName
      }
    },

    methods: {
      next () {
        this.$router.push(this.$store.nextRoute())

      }
    }
  }
</script>

<style scoped>
    .appointment-image {
      max-height: 140px;
    }

    .logo-image {
        max-height: 140px;
        max-width: 49.9999%;
    }

    .detail {
      margin: 2em 0em;
      font-size: 24px;
      line-height: 1.6;
    }

    .detail p {
      margin: 0;
    }

    .locationAddress {
      margin-top: 16pt;
    }
</style>
