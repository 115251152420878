import Vue from 'vue'
import TwilioVideo from 'twilio-video'
import AnalyticsMixin from '@/mixins/AnalyticsMixin'

const VirtualVisitEvents = new Vue({
  name: 'VirtualVisitEvents',

  mixins: [AnalyticsMixin],

  data () {
    return {
      room: null,
      isProviderConnected: false,
      isPatientConnected: false,
      localDataTrack: null
    }
  },

  watch: {
    isProviderConnected (newValue, oldValue) {
      if (newValue !== oldValue && newValue === true) {
        this.sendProviderConnected()
      }
    },

    isPatientConnected (newValue, oldValue) {
      if (newValue !== oldValue && newValue === true) {
        this.sendPatientConnected()
      }
    }
  },

  computed: {},

  methods: {
    connectedToRoom (room) {
      this.room = room
      this.setupLocalDataTrack(room.localParticipant)
      this.isPatientConnected = room.localParticipant.identity.startsWith('Patient-')
      this.isProviderConnected = room.localParticipant.identity.startsWith('User-')
      this.room.localParticipant.on('trackPublicationFailed', (error, localTrack) => {
        this.trackEvent(this.$options.name, 'LocalTrackPublishError', error.message)
        this.$log.error('Failed to publish LocalTrack "%s": %s', localTrack.name, error.message)
      })

      this.room.on('trackSubscriptionFailed', (error, remoteTrackPublication, remoteParticipant) => {
        this.trackEvent(this.$options.name, 'RemoteTrackSubscriptionError', error.message)
        this.$log.error('Failed to subscribe to RemoteTrack "%s" from RemoteParticipant "%s": %s"', remoteTrackPublication.trackName, remoteParticipant.identity, error.message)
      })
      this.room.on('disconnected', this.roomDisconnected)
      this.room.on('participantConnected', this.participantConnected)
      this.room.on('participantDisconnected', this.participantDisconnected)
      this.room.participants.forEach(this.participantConnected)
      this.$emit('room:connected', this.room)
    },

    roomDisconnected (room, error) {
      if (!error) {
        this.trackEvent(this.$options.name, 'RoomDisconnected')
      } else {
        this.trackEvent(this.$options.name, 'RoomDisconnectedWithError', error.message)
      }
      this.$emit('room:disconnected', this.room)

    },

    setupLocalDataTrack (localParticipant) {
      let dataTrack = null
      localParticipant.tracks.forEach((track) => {
        if (track.kind === 'data') {
          dataTrack = track
        }
      })
      if (dataTrack) {
        this.localDataTrack = dataTrack
      } else {
        dataTrack = new TwilioVideo.LocalDataTrack()
        localParticipant.publishTrack(dataTrack)
      }
    },

    participantConnected (participant) {
      if (participant.identity.startsWith('Patient-')) {
        this.isPatientConnected = true
      }
      if (participant.identity.startsWith('User-')) {
        this.isProviderConnected = true
      }
      let dataTrack = null
      participant.tracks.forEach((track) => {
        if (track.kind === 'data') {
          dataTrack = track
        }
      })
      if (dataTrack && dataTrack.isSubscribed) {
        this.connectDataTrack(dataTrack)
      }
      participant.on('trackSubscribed', this.connectDataTrack)
      participant.on('trackUnpublished', this.trackUnpublished)
      this.$emit('participant:connected', this.room)
    },

    participantDisconnected (participant) {
      this.$emit('participant:disconnected', this.room)
    },

    connectDataTrack (track) {
      if (track.kind === 'data') {
        track.on('message', this.messageReceived)
      }
    },

    trackUnpublished (publication) {
    },

    messageReceived (message) {
      const event = JSON.parse(message)
      if (event && event.eventName) {
        this.$log.error('Received event ' + event.eventName)
      }
    },

    sendProviderConnected () {
      const event = {
        eventName: 'PROVIDER_CONNECTED'
      }
      if (this.localDataTrack) {
        this.localDataTrack.send(JSON.stringify(event))
      }
    },

    sendPatientConnected () {
      const event = {
        eventName: 'PATIENT_CONNECTED'
      }
      if (this.localDataTrack) {
        this.localDataTrack.send(JSON.stringify(event))
      }
    }
  }
})

export default VirtualVisitEvents
