import { render, staticRenderFns } from "./ReviewOfSystems.vue?vue&type=template&id=6f07b3de&scoped=true"
import script from "./ReviewOfSystems.vue?vue&type=script&lang=js"
export * from "./ReviewOfSystems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f07b3de",
  null
  
)

export default component.exports