<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Which surgery?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :is-selected="isOptionSelected" :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'

  export default {
    name: 'SurgeryList',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin],

    props: {
      surgeryCategory: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        surgeriesResponse: {
          currentSurgeries: [],
          categories: []
        },
        selectedSurgery: {
          surgeryId: 0
        },
        isSaving: false
      }
    },

    computed: {
      options () {
        return this.multiselectOptionsFromArray(this.surgeryCategory.surgeries, 'surgeryId', 'name', '', 'nope')
      }
    },

    methods: {
      isOptionSelected (item) {
        if (item.id === this.selectedSurgery.surgeryId) {
          return true
        }

        return false
      },

      optionSelected (item) {
        this.clearFieldError('exception')

        const surgery = this.surgeryCategory.surgeries.filter((aSurgery) => {
          return aSurgery.surgeryId === item.id
        }).shift()

        if (surgery) {
          this.selectedSurgery = surgery
        }

      },

      submit () {
        if (this.selectedSurgery.surgeryId > 0) {
          this.isSaving = true
          if (this.selectedSurgery.hasOtherText) {
            this.$router.push('/surgery-other')
            this.isSaving = false
          } else {
            this.$router.push({ name: 'surgery-details', params: { surgery: this.selectedSurgery } })
            this.isSaving = false
          }
        } else {
          this.addErrorForField('exception', 'You must select a surgery')
        }
      }
    }
  }
</script>

<style scoped>

</style>
