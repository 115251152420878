<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What is your living situation?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'LivingSetting',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        socialHistory: {
          livingSettingOptions: [],
          livingSettingId: null
        },
        isSaving: false
      }
    },

    computed: {

      options () {
        const options = this.multiselectOptionsFromArrayWithSelectedIds(this.socialHistory.livingSettingOptions, 'livingSettingId', 'name', [this.socialHistory.livingSettingId])

        return options
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/SocialHistory').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.socialHistory = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/SocialHistory', this.socialHistory).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('exercise')
          this.isSaving = false
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      optionSelected (item) {

        const selectedItem = this.socialHistory.livingSettingOptions.filter((anItem) => {
          return anItem.livingSettingId === item.id
        }).shift()

        if (selectedItem) {
          item.isSelected = !item.isSelected
          this.socialHistory.livingSettingId = item.isSelected ? selectedItem.livingSettingId : null
        }
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
