<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Review the patient's information.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
            <form @submit.prevent="submit()">
                <div class="form-row">
                    <div class="col-md-6">
                      <h5><div class="form-group col-form-label">First Name: <b>{{ demographics.firstName }}</b></div></h5>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                      <h5><div class="form-group col-form-label">Last Name: <b>{{ demographics.lastName }}</b></div></h5>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                      <h5><div class="form-group col-form-label">Birth Date: <b>{{ birthDateFormatted }}</b></div></h5>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <b-form-group label="Gender" :state="stateForField('genderId')" :invalid-feedback="errorForField('genderId')">
                            <b-form-select size="lg" v-model="demographics.genderId" :options="genderRadioOptions"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <b-form-group label="Email" :state="stateForField('email')" :invalid-feedback="errorForField('email')">
                            <b-form-input @input="clearFieldError('email')" size="lg" :state="stateForField('email')" v-model="demographics.email"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
                <!-- <div class="form-row col-md-3">
                    <div>
                        <b-form-group label="Gender:" :state="stateForField('genderId')" :invalid-feedback="errorForField('genderId')">
                            <b-form-select size="lg" v-model="demographics.genderId" :options="genderRadioOptions"></b-form-select>
                        </b-form-group>
                    </div>
                </div> -->
                <!-- <div class="form-row col-lg-6">
                  <div class="text-left text-nowrap">
                    <h5>
                      <div class="form-group form-inline">
                        Gender:&nbsp;&nbsp;&nbsp;&nbsp;
                        <span><b-form-select size="lg" v-model="demographics.genderId" :options="genderRadioOptions"></b-form-select></span>
                      </div>
                    </h5>
                  </div>
                </div>
                <div class="form-row col-lg-6">
                  <div class="text-left text-nowrap">
                    <h5>
                      <div class="form-group form-inline">
                        Email:&nbsp;&nbsp;&nbsp;&nbsp;
                        <span><b-form-input @input="clearFieldError('email')" size="lg" :state="stateForField('email')" v-model="demographics.email"></b-form-input></span>
                      </div>
                    </h5>
                  </div>
                </div> -->
                <div class="form-row">
                    <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                </div>
              </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormSelect, BFormGroup, BFormInput } from 'bootstrap-vue'
  import moment from 'moment'
  import analyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Demographics',

    components: { BFormGroup, BFormInput, BFormSelect },

    mixins: [ErrorsMixin, DemographicsMixin, analyticsMixin],

    data () {
      return {
        demographics: {},
        isSaving: false
      }
    },

    computed: {
      birthDateFormatted () {
        if (this.demographics.birthDate) {
          return moment(this.demographics.birthDate.substring(0, 10)).format('L')
        }

        return ''
      },

      genderRadioOptions () {
        if (this.demographics.genderOptions && this.demographics.genderOptions.length > 0) {
          return this.demographics.genderOptions.map((option) => {
            return {
              value: option.genderId,
              text: option.name
            }
          })
        }

        return []
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.demographics.firstName) {
          this.addErrorForField('firstName', 'First name is required.')
        }

        if (!this.demographics.lastName) {
          this.addErrorForField('lastName', 'Last name is required.')
        }

        if (!this.demographics.birthDate) {
          this.addErrorForField('birthDate', 'Birth date is required.')
        } else {
          if (this.demographics.birthDate >= Date.now()) {
            this.addErrorForField('birthDate', 'Birth date must be in the past')
          }
        }

        if (!this.demographics.genderId) {
          this.addErrorForField('genderId', 'Gender is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Demographics').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.demographics = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.$log.error('Error searching for loading demographics: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      birthDateUpdated (birthDate) {
        this.clearFieldError('birthDate')
        this.demographics.birthDate = birthDate
      },

      birthDateInvalid (birthDate) {
        this.addErrorForField('birthDate', 'Invalid birth date')
      },

      birthDateChanged () {
        this.clearFieldError('birthDate')
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/Demographics', this.demographics).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          if (this.promptRace) {
            this.isSaving = false
            this.$router.push('/demographics-race')
          } else if (this.promptHeightWeight) {
            this.isSaving = false
            this.$router.push('/demographics-height-weight')
          } else if (this.promptAdditional) {
            this.isSaving = false
            this.$router.push('/demographics-additional')
          } else if (this.promptAdditionalPhone) {
            this.isSaving = false
            this.$router.push('/demographics-phone')
          } else {
            this.$store.setModuleStatus('Demographics', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.$log.error('Error saving demographics: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
