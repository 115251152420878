<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header"></h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div v-if="logoUrl" class="row">
                    <div class="col text-center"><img class="logo-image" :src="logoUrl"></div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <h1 class="detail">{{ blockReason.reason }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <h1 class="detail">Please call {{ blockReason.phoneNumber }} for more information.</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../services/api'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'VisitBlocked',
    mixins: [AnalyticsMixin],

    data () {
      return {
        blockReason: {},
        isSaving: false
      }
    },

    computed: {
      logoUrl () {
        return this.$store.orgLogoUrl
      },

      orgName () {
        return this.$store.orgName
      }
    },

    methods: {
      loadData () {
        api.get('/Patient/BlockReason').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.blockReason = response.data
          this.$store.logoutUser()
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading block reason: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
    .appointment-image {
        max-height: 140px;
    }

    .logo-image {
        max-height: 140px;
        max-width: 49.9999%;
    }

    .detail {
        margin: 2em 0em;
        font-size: 24px;
        line-height: 1.6;
    }
</style>
