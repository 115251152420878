<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What is your employment status?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'

  export default {
    name: 'Employment',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        employment: {
          employmentId: 0,
          employmentOptions: []
        },
        isSaving: false
      }
    },

    computed: {
      options () {
        const selectedIds = [this.employment.employmentId]
        return this.multiselectOptionsFromArrayWithSelectedIds(this.employment.employmentOptions, 'employmentId', 'name', selectedIds)
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.employment.employmentId) {
          this.addErrorForField('exception', 'You must select an option.')
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Employment').then(response => {
          this.employment = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching loading employment: ' + error.message, error)
          }
        })
      },

      optionSelected (item) {
        this.clearAllErrors()
        this.employment.employmentId = item.id
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/Employment', this.employment).then(response => {
          // patient is neither employed or self employed
          if (this.employment.employmentId !== 1 && this.employment.employmentId !== 5) {
            this.$store.setModuleStatus('Employment', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            this.isSaving = false
            this.$router.push('/occupation')
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving employment: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
