<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Please provide these additional details.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group v-if="promptDate" label="Surgery Date" :state="stateForField('surgeryDate')" :invalid-feedback="errorForField('surgeryDate')">
                                <b-input-group>
                                    <b-form-select size="lg" @input="clearFieldError('surgeryDate')" v-model="surgeryModel.month" :state="stateForField('surgeryDate')" :options="monthRadioOptions"></b-form-select>
                                    <b-form-select size="lg" @input="clearFieldError('surgeryDate')" v-model="surgeryModel.year" :state="stateForField('surgeryDate')" :options="yearRadioOptions"></b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group v-if="promptSide" label="Side" :state="stateForField('sideId')" :invalid-feedback="errorForField('sideId')">
                                <b-form-select size="lg" @input="clearFieldError('sideId')" v-model="surgeryModel.sideId" :state="stateForField('sideId')" :options="sideRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <button v-if="showRemove" type="button" @click.prevent="remove()" class="btn btn-primary btn-lg mx-auto"><font-awesome-icon class="mr-2" v-show="isDeleting" icon="spinner" pulse/>Remove Surgery</button>
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BFormSelect, BFormGroup, BInputGroup } from 'bootstrap-vue'
  import moment from 'moment'

  export default {
    name: 'SurgeryDetails',

    components: { BFormGroup, BFormSelect, BInputGroup },

    mixins: [ErrorsMixin, OptionsMixin],

    props: {
      surgery: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        surgeryModel: {
          organizationPatientSurgeryId: 0,
          otherText: '',
          surgeryId: 0,
          month: 0,
          year: 0,
          sideId: 0
        },
        isSaving: false,
        isDeleting: false
      }
    },

    watch: {
      surgery (newValue, oldValue) {
        this.initializeModel()
      }
    },

    computed: {
      promptSide () {
        if (this.surgery.sidedness) {
          return true
        }

        return false
      },

      promptDate () {
        return this.$store.boolForModuleConfig('Surgeries', 'PromptDate', true)
      },

      showRemove () {
        if (this.surgery.organizationPatientSurgeryId) {
          return true
        }

        return false
      },

      sideRadioOptions () {
        return this.optionsFromArray(this.$store.sideOptions, 'sideId')
      },

      monthRadioOptions () {
        return [
          {
            value: null,
            text: ''
          },
          {
            value: 1,
            text: 'January'
          },
          {
            value: 2,
            text: 'February'
          },
          {
            value: 3,
            text: 'March'
          },
          {
            value: 4,
            text: 'April'
          },
          {
            value: 5,
            text: 'May'
          },
          {
            value: 6,
            text: 'June'
          },
          {
            value: 7,
            text: 'July'
          },
          {
            value: 8,
            text: 'August'
          },
          {
            value: 9,
            text: 'September'
          },
          {
            value: 10,
            text: 'October'
          },
          {
            value: 11,
            text: 'November'
          },
          {
            value: 12,
            text: 'December'
          }
        ]
      },

      yearRadioOptions () {
        const years = ['']
        for (let i = moment().year(); i > 1900; i--) {
          years.push(i)
        }

        return years
      }
    },

    methods: {
      submit () {
        this.isSaving = true
        api.put('/Patient/Surgery', this.surgeryModel).then(response => {
          this.$router.push('/surgeries')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving surgery: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      remove () {
        this.isDeleting = true
        api.delete('/Patient/Surgery/' + this.surgery.organizationPatientSurgeryId).then(response => {
          this.$router.push('/surgeries')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error deleting surgery: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isDeleting = false
        })
      },

      initializeModel () {
        this.surgeryModel.organizationPatientSurgeryId = this.surgery.organizationPatientSurgeryId
        this.surgeryModel.surgeryId = this.surgery.surgeryId
        this.surgeryModel.otherText = this.surgery.otherText
        if (this.surgery.month) {
          this.surgeryModel.month = this.surgery.month
        }
        if (this.surgery.year) {
          this.surgeryModel.year = this.surgery.year
        }
        if (this.surgery.sideId) {
          this.surgeryModel.sideId = this.surgery.sideId
        }
      }
    },

    created () {
      this.initializeModel()

      if (!this.promptDate && !this.promptSide && !this.showRemove) {
        this.submit()
      }
    }
  }
</script>

<style scoped>

</style>
