<template>
    <b-modal :visible="visible" no-close-on-backdrop no-close-on-esc centered hide-header hide-footer>
        <div class="modal-body clearfix">
            <div class="modal-circle">
                <span class="helper"></span>
                <img :src="headerImage">
            </div>
            <div class="modal-content-container text-center">
                <h1>{{ header }}</h1>
            </div>
          <div v-if="modalBody" class="text-center mt-4">
            <h2>{{ modalBody }}</h2>
          </div>
            <div class="list-container">
                <div @click.prevent="selectItem(option)" v-for="option in options" :key="option.id" class="option">
                    <img v-if="option.selected" class="radio-button" src="../assets/radio-selected.svg">
                    <img v-if="!option.selected" class="radio-button" src="../assets/radio-unselected.svg">
                    <div class="title">{{ option.name }}</div>
                </div>
            </div>
        </div>
        <div class="text-center clearfix">
            <button type="button" @click.prevent="closeModal" class="btn btn-primary">Next</button>
        </div>
    </b-modal>
</template>

<script>
  import { BModal } from 'bootstrap-vue'

  export default {
    name: 'ListModal',

    components: { BModal },

    props: {
      visible: {
        type: Boolean,
        required: true
      },

      options: {
        type: Array,
        required: true
      },

      modalBody: {
        type: String,
        required: false
      },

      header: {
        type: String,
        required: true
      },

      buttonText: {
        type: String,
        default: 'Save'
      },

      headerImage: {
        default: require('../assets/question-mark.svg')
      }
    },

    methods: {
      closeModal () {
        this.$emit('modalClosed', true)
        this.$emit('update:visible', false)
      },

      save () {

      },

      selectItem (item) {
        this.$emit('optionSelected', item)
      }
    }
  }
</script>

<style scoped>
    .modal-content-container {
        margin-top: -80px;
        max-height: 400px;
        overflow-y: auto;
    }

    .modal-content-container h1 {
        margin-top: 1rem;
        font-size: 26px;
    }

    h2 {
      font-size: 26px;
    }
    .modal-circle {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: relative;
        top: -80px;
        left: calc(50% - 50px);
        border: 2px solid rgba(255,62,3,1.0);
        border-radius: 50px;
        background-color: white;
        vertical-align: middle;
        text-align: center;
    }

    .modal-circle img {
        max-width: 65px;
        max-height: 65px;
        display: inline-block;
        margin: 0 auto;
        vertical-align: middle;
    }

    .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .option {
        padding: 1.0rem 0;
        clear: both;
        display: flex;
    }
    .title {
        margin-left: 4px;
        display: inline-block;
        font-size: 20px;
        color: rgba(53,50,59,1.0);
        padding: .25em;
        max-width: 328px;
        float: left;
        padding-top: 10px;
    }

    .option img {
        margin-top: 4px;
        margin-left: 4px;
        height: 40px;
        width: 40px;
        display: inline-block;
        float: left;
    }
</style>
