<template>
    <div>
        <div ref="videoContainer" id="video-container"></div>
        <div ref="audioContainer" id="audio-container"></div>
    </div>
</template>

<script>
  import VirtualVisitEvents from '../services/virtual-visit-events'

  export default {
    name: 'ParticipantTray',

    props: {
      showLocal: {
        type: Boolean,
        default: false
      },
      mainDisplayTrack: {
        type: Object,
        required: false
      }
    },

    data () {
      return {
        isConnected: false,
        room: null
      }
    },

    watch: {
      showLocal (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue) {
            this.showLocalParticipant()
          } else {
            this.hideLocalParticipant()
          }
        }
      },

      mainDisplayTrack (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.reloadParticipantVideos()
        }
      }
    },

    methods: {

      roomConnected (room) {
        this.isConnected = true
        this.room = room
        this.room.on('participantConnected', this.participantConnected)
        this.room.on('participantDisconnected', this.participantDisconnected)
        this.room.localParticipant.on('trackPublished', (publication) => {
          if (publication.track && publication.track.kind !== 'data') {
            this.attachTrack(publication.track)
          }
        })
        this.room.localParticipant.tracks.forEach((publication) => {
          if (publication.kind === 'audio') {
            this.attachTrack(publication.track)
          }
        })
        this.room.participants.forEach(this.participantConnected)
      },

      roomDisconnected (room) {
        this.isConnected = false
        this.room = null

        const videoHolders = this.$refs.videoContainer.querySelectorAll('div')
        if (videoHolders) {
          for (const videoHolder of videoHolders) {
            this.$refs.videoContainer.removeChild(videoHolder)
          }
        }

        const audioHolders = this.$refs.audioContainer.querySelectorAll('div')
        if (audioHolders) {
          for (const audioHolder of audioHolders) {
            this.$refs.audioContainer.removeChild(audioHolder)
          }
        }
      },

      removeAllVideos () {
        const videoHolders = this.$refs.videoContainer.querySelectorAll('div')
        if (videoHolders) {
          for (const videoHolder of videoHolders) {
            this.$refs.videoContainer.removeChild(videoHolder)
          }
        }
      },

      reloadParticipantVideos () {
        if (!this.room) {
          return
        }

        this.removeAllVideos()

        this.room.participants.forEach((particpant) => {
          particpant.videoTracks.forEach((publication) => {
            if (publication.isSubscribed) {
              if (!this.$props.mainDisplayTrack || this.$props.mainDisplayTrack.sid !== publication.track.sid) {
                this.attachTrack(publication.track)
              }
            }
          })
        })

        let localId = null
        if (this.$props.mainDisplayTrack && this.$props.mainDisplayTrack.id) {
          localId = this.$props.mainDisplayTrack.id
        }
        if (localId || this.showLocal) {
          this.showLocalParticipant(localId)
        } else {
          this.hideLocalParticipant()
        }
      },

      showLocalParticipant (hiddenId) {
        if (this.isConnected) {
          this.room.localParticipant.videoTracks.forEach((publication) => {
            if (publication.isTrackEnabled && publication.track.kind === 'video') {
              if (!hiddenId || publication.track.id !== hiddenId) {
                this.attachTrack(publication.track)
              }
            }
          })
        }
      },

      hideLocalParticipant () {
        if (this.isConnected) {
          this.room.localParticipant.tracks.forEach((publication) => {
            if (publication.isTrackEnabled && publication.track.kind === 'video') {
              this.detachTrack(publication.track)
            }
          })
        }
      },

      participantConnected (participant) {
        participant.on('trackPublished', (publication) => {
          if (publication.isSubscribed) {
            this.attachTrack(publication.track)
          }

          publication.on('subscribed', (track) => {
            this.attachTrack(track)
          })
          publication.on('unsubscribed', (track) => {
            this.detachTrack(track)
          })
        })
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            this.attachTrack(publication.track)
          }

          publication.on('subscribed', (track) => {
            this.attachTrack(track)
          })
          publication.on('unsubscribed', (track) => {
            this.detachTrack(track)
          })
        })
      },

      participantDisconnected (participant) {
        participant.tracks.forEach(this.detachTrack)
      },

      attachTrack (track) {
        if (track.kind === 'video') {
          this.displayVideo(track)
        } else if (track.kind === 'audio') {
          this.addAudio(track)
        }
      },

      detachTrack (track) {
        if (track.kind === 'video') {
          this.removeVideo(track)
        } else if (track.kind === 'audio') {
          this.removeAudio(track)
        }
      },

      displayVideo (videoTrack) {
        const id = videoTrack.sid || videoTrack.id
        let videoHolder = document.getElementById(`video-${id}`)
        if (!videoHolder) {
          videoHolder = document.createElement('div')
          videoHolder.id = `video-${id}`
          videoHolder.classList.add('video-holder')
          videoHolder.addEventListener('click', (event) => {
            console.log('Video holder clicked for video sid: ' + id)
            this.$emit('video-clicked', videoTrack)
          })
          this.$refs.videoContainer.appendChild(videoHolder)
        }

        if (videoTrack.attach) {
          videoHolder.appendChild(videoTrack.attach())
        } else {
          this.$log.error('Track kind ' + videoTrack.kind + ' with name ' + videoTrack.name + ' doesn\'t have an attach method')
        }
      },

      removeVideo (videoTrack) {
        const id = videoTrack.sid || videoTrack.id
        const videoHolder = document.getElementById(`video-${id}`)
        if (videoHolder) {
          videoHolder.parentElement.removeChild(videoHolder)
        }
      },

      addAudio (audioTrack) {
        const id = audioTrack.sid || audioTrack.id
        let audioHolder = document.getElementById(`audio-${id}`)
        if (!audioHolder) {
          audioHolder = document.createElement('div')
          audioHolder.id = `audio-${id}`
          this.$refs.audioContainer.appendChild(audioHolder)
        }

        if (audioTrack.attach) {
          audioHolder.appendChild(audioTrack.attach())
        }
      },

      removeAudio (audioTrack) {
        const id = audioTrack.sid || audioTrack.id
        const audioHolder = document.getElementById(`audio-${id}`)
        if (audioHolder) {
          audioHolder.parentElement.removeChild(audioHolder)
        }
      }
    },

    created () {
      VirtualVisitEvents.$on('room:connected', this.roomConnected)
      VirtualVisitEvents.$on('room:disconnected', this.roomDisconnected)
    },

    beforeDestroy () {
      VirtualVisitEvents.$off('room:connected', this.roomConnected)
      VirtualVisitEvents.$off('room:disconnected', this.roomDisconnected)
    }
  }
</script>

<style>
    #video-container {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .video-holder {
        width: 150px;
        height: 150px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .video-holder > video {
        object-fit: scale-down;
        width: 100%;
        height: auto;
    }

    @media screen and (orientation:landscape) {
        #video-container {
            min-height: 100px;
        }

        .video-holder {
            width: 50px;
            height: 50px;
            margin-left: 20px;
            margin-top: 20px;
        }

        .video-holder > video {
            object-fit: scale-down;
            width: 100%;
            height: auto;
        }
    }
</style>
