<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Payment Options.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center pt-4">
                <button type="submit" @click.prevent="selectAmount(partialAmount1)" class="btn btn-outline-primary btn-lg mx-auto partial-payment-button">Pay ${{partialAmount1}} {{partialAmount1Description}}</button>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center pt-4">
                <button type="submit" @click.prevent="selectAmount(partialAmount2)" class="btn btn-outline-primary btn-lg mx-auto partial-payment-button">Pay ${{partialAmount2}} {{partialAmount2Description}}</button>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div v-if="copayAmount" class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center pt-4">
                <button type="submit" @click.prevent="selectAmount(copayAmount)" class="btn btn-outline-primary btn-lg mx-auto partial-payment-button">Pay ${{copayAmount}} (Copay only)</button>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center pt-4">
                <a href = "#" @click.prevent="skipPayment()" class="detail">Pay Cash or Check at Clinic</a>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PartialPaymentOptions',

    components: { },

    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        isSaving: false,
        isSkipping: false,
        payment: {
          expirationMonth: null,
          expirationYear: null,
          visitCharges: [],
          totalPaid: 0,
          totalAmount: 0,
          hasPaid: false,
          partialPayment1Percentage: 0.25,
          partialPayment2Percentage: 0.5
        }
      }
    },

    computed: {
      totalDue () {
        if (this.payment.totalPaid > 0) {
          return this.payment.totalAmount - this.payment.totalPaid
        }

        return this.payment.totalAmount
      },

      selfpayAmount () {
        const selfpayCharge = this.payment.visitCharges.filter((aCharge) => {
          return aCharge.type === 'SelfPayVisitCharge'
        }).shift()

        if (selfpayCharge) {
          return selfpayCharge.amount
        }

        return 0
      },

      copayAmount () {
        const copayCharge = this.payment.visitCharges.filter((aCharge) => {
          return aCharge.type === 'CoPay'
        }).shift()

        if (copayCharge) {
          return copayCharge.amount
        }

        return 0
      },

      otherChargeAmount () {
        return this.payment.totalAmount - this.copayAmount - this.selfpayAmount
      },

      partialAmount1 () {
        return (this.copayAmount + this.selfpayAmount + (this.otherChargeAmount * this.payment.partialPayment2Percentage)).toFixed(2)
      },

      partialAmount1Description () {
        const percentage = (this.payment.partialPayment2Percentage * 100).toFixed(0)
        if (this.copayAmount) {
          return '(Copay and ' + percentage + '%)'
        } else if (this.selfpayAmount) {
          return '(Self-Pay and ' + percentage + '%)'
        } else {
          return '(' + percentage + '%)'
        }
      },

      partialAmount2 () {
        return (this.copayAmount + this.selfpayAmount + (this.otherChargeAmount * this.payment.partialPayment1Percentage)).toFixed(2)
      },

      partialAmount2Description () {
        const percentage = (this.payment.partialPayment1Percentage * 100).toFixed(0)
        if (this.copayAmount) {
          return '(Copay and ' + percentage + '%)'
        } else if (this.selfpayAmount) {
          return '(Self-Pay and ' + percentage + '%)'
        } else {
          return '(' + percentage + '%)'
        }
      }
    },

    methods: {
      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      },

      skipPayment () {
        this.trackEvent(this.$options.name, 'SkipPayment')

        this.isSkipping = true
        this.$store.setModuleStatus('Payment', true, () => {
          this.isSkipping = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      selectAmount (amount) {
        const tokenizationEnabled = this.payment.paymentTokenizationEnabled

        if (tokenizationEnabled === true && this.payment.patientHasToken === true) {
          this.$router.push({ name: 'tokenize-payment', params: { patientHasToken: this.payment.patientHasToken, paymentAmount: amount, last4: this.payment.last4, cardType: this.payment.cardType } })
        } else {
          this.$router.push({ name: 'visit-credit-card', params: { patientHasToken: this.payment.patientHasToken, tokenizationEnabled, paymentAmount: amount } })
        }
      },

      loadData () {
        api.get('/Patient/Visit/Payment').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.payment = response.data
          if (this.payment.totalPaid > 0) {
            this.payment.visitCharges.push({ description: 'Payment', amount: -this.payment.totalPaid })
          }
          if (this.payment.hasPaid) {
            this.$store.setModuleStatus('Payment', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
.partial-payment-button {
    width: 340px;
}
</style>
