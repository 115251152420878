<template>
    <div class="container-fluid">
      <div id="body-container">
        <div class="row">
            <div class="col">
                <h1 class="header">Patient Balance</h1>
                <h2 class="subheader">
                  <span v-if="coPayCharge">Your health plan requires a copay for specialist visits. Securely enter your credit card information for a quicker check-in</span>
                  <span v-else-if="selfPayCharge">A Self Pay Visit Charge is required for specialist visits. Securely enter your credit card information for a quicker check-in</span>
                  <span v-else>Pay the balance due on your account</span>
                  <br /><br /><span><b>You may be responsible for additional fees incurred for this visit.</b></span>
                </h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="form-row">
                    <div class="col-md-12">
                        <b-table :items="payment.visitCharges" :fields="fields" foot-clone>
                            <template v-slot:foot(description)="">
                                <div class="text-right"><b>Total Due</b></div>
                            </template>
                            <template v-slot:foot(amount)="">
                                <span><b>{{ formatNumber(totalDue) }}</b></span>
                            </template>
                        </b-table>

                       <div v-if="payment.discountAmount > 0">
                         <div class="row">
                           <div class="col-8 text-right pr-5"><b>Discount</b></div>
                           <div class="col-4 text-right pr-4"><b>{{ formatNumber(payment.discountAmount) }}</b></div>
                         </div>
                         <div class="row">
                           <div class="col-8 text-right pr-5"><b>Total After Discount</b></div>
                           <div class="col-4 text-right pr-4"><b>{{ formatNumber(payment.totalWithDiscount) }}</b></div>
                         </div>
                         <div class="row">
                           <div class="col-8 text-right pr-5"><b>Discount Expiration</b></div>
                           <div class="col-4 text-right pr-4"><b>{{ formatDate(payment.discount.endDate) }}</b></div>
                         </div>
                       </div>
                    </div>
                </div>
            </div>
          <div class="col-xl-3"></div>
        </div>
        <div v-if="totalDue <= 0" class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center">
              <div class="form-row mt-4">
                <button type="submit" @click.prevent="next()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
              </div>
              </div>
            <div class="col-xl-3"></div>
        </div>
        <div v-if="totalDue > 0" class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center">
              <div class="form-row mt-4">
                <button type="submit" @click.prevent="fullPayment()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">
                  <span v-if="partialPaymentsEnabled && coPayCharge">Pay CoPay and Balance</span>
                  <span v-else-if="partialPaymentsEnabled && selfPayCharge">Pay Self Pay Charge and Balance</span>
                  <span v-else-if="partialPaymentsEnabled">Full Payment</span>
                  <span v-else>Make Payment</span>
                </button>
                </div>
              <div class="col-xl-3"></div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div v-if="totalDue > 0 && partialPaymentsEnabled" class="row">
            <div class="col-xl-3"></div>
            <div class="col text-center">
              <div class="form-row mt-4">
                <button type="submit" @click.prevent="partialPayment()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">
                  <span v-if="coPayCharge">Pay CoPay and Partial Balance</span>
                  <span v-else-if="selfPayCharge">Pay Self Pay Charge and Partial Balance</span>
                  <span v-else>Partial Payment</span>
                </button>
                </div>
              <div class="col-xl-3"></div>
            </div>
          <div class="col-xl-3"></div>
        </div>
        <div v-if="totalDue > 0 && partialPaymentsEnabled && coPayCharge" class="row">
          <div class="col-xl-3"></div>
          <div class="col text-center">
            <div class="form-row mt-4">
              <button type="submit" @click.prevent="copayPayment()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">Pay CoPay Only</button>
         </div>
            <div class="col-xl-3"></div>
          </div>
          <div class="col-xl-3"></div>
        </div>
        <div v-if="totalDue > 0 && partialPaymentsEnabled && selfPayCharge" class="row">
          <div class="col-xl-3"></div>
          <div class="col text-center">
            <div class="form-row mt-4">
            <button type="submit" @click.prevent="copayPayment()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">Pay Self Pay Charge Only</button>
            </div>
            <div class="col-xl-3"></div>
            </div>
          <div class="col-xl-3"></div>
          <div>
          </div>
        </div>
      <div class="col-xl-3"></div>
      </div>
      <div id="footer" class="row mt-4" v-if="billingSupportEmail || billingSupportPhoneNumber">
        <div class="col-md-12 text-center py-3" style="background-color: #E8EFEF">
          Billing Questions? <br>
         <a :href="billingSupportEmailMailTo">{{ billingSupportEmail }}</a>
          <br>
          <a :href="billingSupportPhoneNumberConnectTo">{{ formatPhoneNumber(billingSupportPhoneNumber) }}</a>
        </div>
      </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import { BTable } from 'bootstrap-vue'
  import moment from 'moment'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'VisitPayment',

    components: { BTable },

    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        isSaving: false,
        payment: {
          expirationMonth: null,
          expirationYear: null,
          visitCharges: [],
          totalPaid: 0,
          totalAmount: 0,
          hasPaid: false,
          partialPaymentsEnabled: false,
          partialPaymentMinimumAmount: 0,
          discountAmount: 0,
          totalWithDiscount: 0,
          billingSupportEmail: null,
          billingSupportPhoneNumber: null,
          discount: {
            endDate: null,
            organizationOutstandingBalanceDiscountId: 0
          }
        },
        fields: [
          {
            key: 'description',
            sortable: false
          },
          {
            key: 'amount',
            sortable: false,
            formatter: this.formatNumber,
            class: 'text-right'
          }
        ]
      }
    },

    computed: {
      totalDue () {
        if (this.payment.totalPaid > 0) {
          return this.payment.totalAmount - this.payment.totalPaid
        }

        return this.payment.totalAmount
      },

      billingSupportEmail () {
        return this.$store.billingSupportEmail
      },

      billingSupportPhoneNumber () {
        return this.$store.billingSupportPhoneNumber
      },

      partialPaymentsEnabled () {
        const otherCharge = this.payment.visitCharges.filter((aCharge) => {
          return (aCharge.type !== 'CoPay' && aCharge.type !== 'SelfPayVisitCharge')
        }).shift()

        return otherCharge && this.payment.partialPaymentsEnabled && this.payment.partialPaymentMinimumAmount <= this.totalDue
      },

      coPayCharge () {
        const coPay = this.payment.visitCharges.filter((aCharge) => {
          return (aCharge.type === 'CoPay')
        }).shift()

        return coPay
      },

      selfPayCharge () {
        const charge = this.payment.visitCharges.filter((aCharge) => {
          return (aCharge.type === 'SelfPayVisitCharge')
        }).shift()

        return charge
      },

      billingSupportEmailMailTo () {
        return 'mailto:' + this.billingSupportEmail
      },

      billingSupportPhoneNumberConnectTo () {
        return 'tel:' + this.billingSupportPhoneNumber
      }

    },

    methods: {
      formatPhoneNumber (input) {
        input = input.replace(/\D/g, '')
        const size = input.length
        if (size > 0) { input = '(' + input }
        if (size > 3) { input = input.slice(0, 4) + ') ' + input.slice(4, 11) }
        if (size > 6) { input = input.slice(0, 9) + '-' + input.slice(9) }
        return input
      },
      formatNumber (number) {
        if (number) {
          return '$' + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '$0.00'
      },

      formatDate (date) {
        if (date) {
          return moment(date).format('MM/DD/YY')
        } else {
          return ''
        }
      },

      next () {
        this.isSaving = true

        this.$store.setModuleStatus('Payment', true, () => {
          this.isSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      fullPayment () {
        let total = this.totalDue
        let discountId = 0
        if (this.payment.discountAmount > 0) {
          total = this.payment.totalWithDiscount
          discountId = this.payment.discount.organizationOutstandingBalanceDiscountId
        }
        const tokenizationEnabled = this.payment.paymentTokenizationEnabled
        if (tokenizationEnabled === true && this.payment.patientHasToken === true) {
          this.$router.push({ name: 'tokenize-payment', params: { patientHasToken: this.payment.patientHasToken, paymentAmount: total, cardType: this.payment.cardType, last4: this.payment.last4, discountId, discountAmount: this.payment.discountAmount } })
        } else {
          this.$router.push({ name: 'visit-credit-card', params: { patientHasToken: this.payment.patientHasToken, tokenizationEnabled, paymentAmount: total, discountId, discountAmount: this.payment.discountAmount } })
        }
      },

      partialPayment () {
        this.$router.push({ name: 'partial-payment-options' })
      },

      copayPayment () {
        const tokenizationEnabled = this.payment.paymentTokenizationEnabled

        let amount = this.totalDue
        if (this.coPayCharge) {
          amount = this.coPayCharge.amount
        } else if (this.selfPayCharge) {
          amount = this.selfPayCharge.amount
        }

        if (tokenizationEnabled === true && this.payment.patientHasToken === true) {
          this.$router.push({ name: 'tokenize-payment', params: { patientHasToken: this.payment.patientHasToken, paymentAmount: amount, last4: this.payment.last4, cardType: this.payment.cardType } })
        } else {
          this.$router.push({ name: 'visit-credit-card', params: { patientHasToken: this.payment.patientHasToken, paymentAmount: amount, tokenizationEnabled } })
        }
      },

      loadData () {
        api.get('/Patient/Visit/Payment').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.payment = response.data
          if (this.payment.totalPaid > 0) {
            this.payment.visitCharges.push({ description: 'Payment', amount: -this.payment.totalPaid })
          }
          if (this.payment.hasPaid) {
            this.trackEvent(this.$options.name, 'NoPaymentDue')
            this.$store.setModuleStatus('Payment', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

#body-container {
  min-height: calc(90vh - 110px);
}

#footer {
height: 110px;
}

.container-fluid {
  padding-bottom: 0 !important;
}
</style>
