<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Start typing the name of a medication to search.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group label="Medication" :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm && !searchResultsIncludeTerm" @click.prevent="selectMedication(searchTerm)" class="result text-center">
                        <h1 class="text-center">{{ this.$store.uppercaseFirstLetters(searchTerm) }}</h1>
                    </div>
                    <div @click.prevent="selectMedication(medication)" v-for="(medication,index) in searchResults" :key="index" class="result text-center">
                        <font-awesome-icon size="lg" class="blue-text mr-2" v-show="isAdding(medication)" icon="spinner" pulse/><h1 class="text-center">{{ medication }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <form-and-dosage-modal
                :name="selectedMedication"
                :header="modalHeader"
                :forms="forms" @save="saveMedication"
                :show-save-spinner="isSaving"
                :visible="showFormAndDosage"
                @update:visible="visible => showFormAndDosage = visible">
        </form-and-dosage-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'
  import FormAndDosageModal from '../../components/FormAndDosageModal'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'MedicationSearch',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, FormAndDosageModal },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        searchResults: {},
        searchTerm: '',
        searchResultsIncludeTerm: false,
        isSaving: false,
        selectedMedication: '',
        forms: [],
        showFormAndDosage: false,
        selectedRawMedication: ''
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    computed: {
      modalHeader () {
        if (this.selectedMedication) {
          return 'Please tell us about ' + this.selectedMedication
        }

        return 'Please tell us about this medication'
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      performSearch () {
        if (!this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/Medications/Search?query=' + this.searchTerm).then(response => {
          this.trackEvent(this.$options.name, 'Search', 'ResponseTime', response.config.requestDuration)
          this.searchResultsIncludeTerm = false
          this.searchResults = response.data.map((aResult) => {
            if (aResult && this.searchTerm && aResult.toLowerCase() === this.searchTerm.toLowerCase()) {
              this.searchResultsIncludeTerm = true
            }
            return this.$store.uppercaseFirstLetters(aResult)
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SearchError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      isAdding (medication) {
        if (medication === this.selectedRawMedication) {
          return true
        }

        return false
      },

      selectMedication (medication) {
        this.selectedRawMedication = medication
        this.isSaving = true
        medication = this.$store.uppercaseFirstLetters(medication)
        this.selectedMedication = medication
        api.get('/Patient/Medications/FormsAndDosages?name=' + medication).then(response => {
          this.trackEvent(this.$options.name, 'Load', 'FormsAndDosageResponseTime', response.config.requestDuration)
          if (response.data.forms !== null && response.data.forms.length > 0) {
            this.forms = response.data.forms.map((aForm) => {
              return { name: this.$store.uppercaseFirstLetters(aForm.name), dosages: aForm.dosages }
            })
            if (this.forms.length > 1) {
              this.forms.push({ name: '', dosages: [] })
            }
            this.showFormAndDosage = true
          } else {
            // Couldn't find any forms just set it all to other
            const newMedicaiton = {
              name: medication,
              form: 'Other',
              dosage: 'Other',
              medicationFrequencyId: 4 // This is the Other frequency id
            }

            this.saveMedication(newMedicaiton)
          }

        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'FormsAndDosageError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      saveMedication (medication) {
        this.isSaving = true

        if (!medication.organizationPatientMedicationId) {
          delete medication.organizationPatientMedicationId
        }

        api.put('/Patient/Medication', medication).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('medications')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      }

    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
        padding: .25em;
        display: inline-block;
    }

    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgba(255,62,3,1.0);
        }
    }
</style>
