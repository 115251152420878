const ErrorsMixin = {
  data () {
    return {
      errorsMixin: {
        errors: {}
      }
    }
  },

  computed: {
    generalErrorMessage () {
      return this.errorForField('exception')
    }
  },

  methods: {
    addErrorForField (fieldName, error) {
      if (Array.isArray(this.errorsMixin.errors[fieldName])) {
        this.errorsMixin.errors[fieldName].push(error)
      } else {
        this.$set(this.errorsMixin.errors, fieldName, [error])
      }
    },

    setErrors (errors) {
      this.errorsMixin.errors = errors
    },

    setFromApiErrorResponse (error) {
      if (error.response && error.response.data) {
        this.setErrorsFromResponse(error.response.data)
      } else if (error.message) {
        this.addErrorForField('exception', error.message)
      }
    },

    setErrorsFromResponse (response) {
      this.errorsMixin.errors = {}
      for (const fieldName in response) {
        this.addErrorForField(fieldName, response[fieldName].join('<br>'))
      }
    },

    hasErrors () {
      return Object.keys(this.errorsMixin.errors).length > 0
    },

    hasError (fieldName) {
      return this.errorsMixin.errors[fieldName] !== undefined && this.errorsMixin.errors[fieldName]
    },

    stateForField (fieldName) {
      if (this.hasError(fieldName)) {
        return false
      }
      return null
    },

    errorClass (fieldName) {
      if (this.hasError(fieldName)) {
        return 'is-invalid'
      }

      return ''
    },

    errorForField (fieldName) {
      if (this.hasError(fieldName)) {
        return this.errorsMixin.errors[fieldName][0]
      }
      return ''
    },

    clearFieldError (fieldName) {
      if (this.hasError(fieldName)) {
        this.$set(this.errorsMixin.errors, fieldName, undefined)
      }
    },

    clearAllErrors () {
      this.errorsMixin.errors = {}
    },

    errorsSummary () {
      const allErrors = []
      for (const key in this.errorsMixin.errors) {
        allErrors.push(key + ': ' + this.errorForField(key))
      }

      return allErrors.join(', ')
    }
  }
}

export default ErrorsMixin
