<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Were you referred by another Physician?</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col">
        <multiselect-list :options="options" :is-selected="isOptionSelected" @optionSelected="optionSelected"></multiselect-list>
      </div>
      <div class="col-md-1"></div>
  </div>
    <div class="row">
      <div class="col text-center mt-2">
        <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'HowDidYouHearAboutUsReferral',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        hdyhauResponse: {
          primaryPhysician: '',
          howDidYouHearAboutUsOptionId: 0,
          howDidYouHearAboutUsText: '',
          options: []
        },
        selectedOption: {
          id: 0
        },
        isSaving: false
      }
    },

    computed: {
      options: function () {
        const options = []

        options.push({
          id: 2,
          type: 'item',
          name: 'Yes',
          selected: false
        })

        if (this.hdyhauResponse.primaryPhysician && this.hdyhauResponse.primaryPhysician.length > 0) {
          options.push({
            id: 3,
            type: 'item',
            name: `Yes, Primary Care Physician\n${this.hdyhauResponse.primaryPhysician}`,
            selected: false
          })
        }

        options.push({
          id: 1,
          type: 'item',
          name: 'No',
          selected: false
        })

        return options
      }
    },

    methods: {
      loadData () {
        api.get('/Patient/HowDidYouHearAboutUs').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.hdyhauResponse = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      isOptionSelected (item) {
        if (item.id === this.selectedOption.id) {
          return true
        }

        return false
      },

      optionSelected (item) {
        this.clearFieldError('exception')

        const anOption = this.options.filter((anOption) => {
          return anOption.id === item.id
        }).shift()

        if (anOption) {
          this.selectedOption = anOption
        }
      },

      submit: function () {
        if (this.selectedOption.id > 0) {
          this.isSaving = true

          if (this.selectedOption.id === 1) {
            // Go to the list of options
            this.$router.push('/how-did-you-hear-about-us')
            this.isSaving = false
          } else if (this.selectedOption.id === 2) {
            // Go to provider search
            this.$router.push('/referring-physician')
            this.isSaving = false
          } else if (this.selectedOption.id === 3) {
            // Save the PCP referral and continue
            api.put('/Patient/HowDidYouHearAboutUs', { howDidYouHearAboutUsOptionId: 1 }).then(response => {
              this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
              this.hdyhauResponse = response.data
              this.$store.setModuleStatus('HowDidYouHearAboutUs', true, () => {
                this.$router.push(this.$store.nextRoute())
                this.isSaving = false
              })
            }).catch(error => {
              if (!this.$store.canHandleNetworkError(error)) {
                this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
                this.setFromApiErrorResponse(error)
              }
            })
          }
        } else {
          this.addErrorForField('exception', 'You must select an option')
        }
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
