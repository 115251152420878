<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Start typing the name of your insurance company to search.</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-md-6">
        <b-form-group label="Insurance Company" :state="stateForField('search')" :invalid-feedback="errorForField('search')">
          <b-input-group>
            <b-form-input autocomplete="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
            <b-input-group-append>
              <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-md-6">
        <div class="d-flex flex-wrap align-items-start justify-content-center">
          <div @click.prevent="selectCompany(insuranceCompany)" v-for="insuranceCompany in searchResults" :key="insuranceCompany.insuranceCompanyId" class="result">
            <h1 class="text-center">{{ insuranceCompany.name }}</h1>
          </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-xl-3"></div>
      <div class="form-row mt-2">
        <b-button @click.prevent="notListed" variant="primary btn-lg">Not Listed</b-button>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PayorSearch',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        searchResults: {},
        searchTerm: '',
        isSaving: false
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      performSearch () {
        if (!this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/InsuranceSearch?query=' + this.searchTerm).then(response => {
          this.trackEvent(this.$options.name, 'Insurance', 'PayorSearch', this.searchTerm)

          this.searchResults = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error getting insurance company: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      selectCompany (insuranceCompany) {
        this.$store.insurance.insuranceCompanyShortCode = insuranceCompany.short
        this.trackEvent(this.$options.name, 'Insurance', 'PayorSelected', insuranceCompany.short)
        this.$router.push('policy-number')
      },

      notListed () {
        this.$store.setModuleStatus('Insurance', true, () => {
          this.$router.push(this.$store.nextRoute())
        })
      }
    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
        padding: .25em;
    }

    .result:HOVER {
        cursor: pointer;
        border: 2px solid rgba(255,62,3,1.0);
    }
</style>
