<template>
    <b-modal :visible="visible" no-close-on-backdrop no-close-on-esc centered hide-header hide-footer>
        <div class="modal-body">
            <div class="modal-circle">
                <span class="helper"></span>
                <img src="../assets/medications.svg">
            </div>
            <div class="modal-content-container text-center">
                <h1>{{ header }}</h1>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-group label="Form" :state="stateForField('form')" :invalid-feedback="errorForField('form')">
                        <b-form-select size="lg" @input="clearFieldError('form')" :state="stateForField('form')" v-model="medication.form" :options="formRadioOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-group label="Dosage" :state="stateForField('dosage')" :invalid-feedback="errorForField('dosage')">
                        <b-form-select size="lg" @input="clearFieldError('dosage')" :state="stateForField('dosage')" v-model="medication.dosage" :options="dosageRadioOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-group label="Frequency" :state="stateForField('medicationFrequencyId')" :invalid-feedback="errorForField('medicationFrequencyId')">
                        <b-form-select size="lg" @input="clearFieldError('medicationFrequencyId')" :state="stateForField('medicationFrequencyId')" v-model="medication.medicationFrequencyId" :options="frequencyRadioOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
        </div>
        <div class="row justify-content-center text-center">
            <div v-if="showRemove" class="col-5">
                <button  type="submit" @click.prevent="remove()" class="btn btn-primary btn-lg mx-auto"><font-awesome-icon class="mr-2" v-show="showRemoveSpinner" icon="spinner" pulse/>Remove</button>
            </div>
            <div class="col-5">
                <button type="submit" @click.prevent="save()" class="btn btn-primary btn-lg mx-auto"><font-awesome-icon class="mr-2" v-show="showSaveSpinner" icon="spinner" pulse/>Save</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
  import ErrorsMixin from '../mixins/ErrorsMixin'
  import OptionsMixin from '../mixins/OptionsMixin'
  import { BModal, BFormGroup, BFormSelect } from 'bootstrap-vue'
  import _ from 'lodash'

  export default {
    name: 'FormAndDosageModal',

    components: { BModal, BFormGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin],

    props: {
      visible: {
        type: Boolean,
        required: true
      },

      name: {
        type: String,
        required: true
      },

      organizationPatientMedicationId: {
        type: Number,
        default: 0
      },

      header: {
        type: String,
        required: true
      },

      showRemove: {
        type: Boolean,
        default: false
      },

      forms: {
        type: Array,
        required: true
      },

      showSaveSpinner: {
        type: Boolean,
        default: false
      },

      showRemoveSpinner: {
        type: Boolean,
        default: false
      },

      selectedForm: {
        type: String,
        required: false
      },

      selectedDosage: {
        type: String,
        required: false
      },

      selectedFrequencyId: {
        type: Number,
        required: false
      }
    },

    data () {
      return {
        medication: {
          organizationPatientMedicationId: 0,
          name: _.clone(this.$props.name),
          form: '',
          dosage: '',
          medicationFrequencyId: 0
        },
        isSaving: false
      }
    },

    watch: {
      name (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.medication.name = _.clone(newValue)
        }
      },

      selectedForm (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.medication.form = _.clone(newValue)
        }
      },

      selectedDosage (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.medication.dosage = _.clone(newValue)
        }
      },

      selectedFrequencyId (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.medication.medicationFrequencyId = _.clone(newValue)
        }
      },

      organizationPatientMedicationId (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.medication.organizationPatientMedicationId = _.clone(newValue)
        }
      },

      forms (newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue !== null && newValue.length === 1) {
            this.medication.form = newValue[0].name
          }
        }
      }
    },

    computed: {
      frequencyRadioOptions () {
        return this.optionsFromArray(this.$store.frequencyOptions, 'medicationFrequencyId')
      },

      formRadioOptions () {
        if (this.forms === null) {
          return
        }
        return this.forms.map(aForm => {
          return aForm.name
        })
      },

      dosageRadioOptions () {
        if (this.forms === null) {
          return []
        }

        const currentForm = this.forms.filter((form) => {
          return form.name === this.medication.form
        }).shift()

        if (currentForm) {

          const options = currentForm.dosages.map(aDosage => {
            return aDosage.name
          })

          options.unshift('')
          options.push('Other')
          return options
        }

        return []
      }

    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.medication.form) {
          this.addErrorForField('form', 'Form is required.')
        }

        if (!this.medication.dosage) {
          this.addErrorForField('dosage', 'Dosage is required.')
        }

        if (!this.medication.medicationFrequencyId) {
          this.addErrorForField('medicationFrequencyId', 'Frequency is required.')
        }

        return !this.hasErrors()
      },

      closeModal () {
        this.$emit('update:visible', false)
      },

      save () {
        if (this.validate()) {
          this.$emit('save', this.medication)
        }
      },

      remove () {
        this.$emit('remove', this.medication)
      }
    }
  }
</script>

<style scoped>
    .modal-content-container {
        margin-top: -80px;
        max-height: 400px;
        overflow-y: auto;
    }

    .modal-content-container h1 {
        margin-top: 1rem;
        font-size: 26px;
    }

    .modal-circle {
        display: inline-block;
        width: 100px;
        height: 100px;
        position: relative;
        top: -80px;
        left: calc(50% - 50px);
        border: 2px solid rgba(255,62,3,1.0);
        border-radius: 50px;
        background-color: white;
        vertical-align: middle;
        text-align: center;
    }

    .modal-circle img {
        max-width: 65px;
        max-height: 65px;
        display: inline-block;
        margin: 0 auto;
        vertical-align: middle;
    }

    .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .option {
        padding: .5rem 0;
    }
    .option h1 {
        margin-left: 4px;
        display: inline-block;
        font-size: 20px;
        color: rgba(53,50,59,1.0);
        padding: .25em;
        max-width: 328px;
    }

    .option img {
        margin-top: 4px;
        margin-left: 4px;
        height: 40px;
        width: 40px;
        display: inline-block;
    }

</style>
