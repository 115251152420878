<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 v-if="isMinor" class="header">A parent or guardian must sign below.</h1>
                <h1 v-if="!isMinor" class="header">Please type your name below to sign your consent.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div v-if="isMinor" class="col-md-12">
                            <b-form-group label="Signature of Parent/Guardian" :state="stateForField('consentPersonName')" :invalid-feedback="errorForField('consentPersonName')">
                                <b-form-input size="lg" @input="clearFieldError('consentPersonName')" v-model="consent.consentPersonName" :state="stateForField('consentPersonName')"></b-form-input>
                            </b-form-group>
                        </div>
                        <div v-if="!isMinor" class="col-md-12">
                            <b-form-group label="Signature" :state="stateForField('signature')" :invalid-feedback="errorForField('signature')">
                                <b-form-input size="lg" @input="clearFieldError('signature')" v-model="consent.signature" :state="stateForField('signature')"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div v-if="isMinor" class="form-row">
                        <div class="col-md-12">
                            <b-form-group label="Birth Date of Parent/Guardian (MM/DD/YYYY)" :state="stateForField('consentPersonDateOfBirth')" :invalid-feedback="errorForField('consentPersonDateOfBirth')">
                                <date-input :current-date="consent.consentPersonDateOfBirth" @valid="birthDateUpdated" @invalid="birthDateInvalid" @change="birthDateChanged"/>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import DateInput from '../../components/DateInput'
  import { BFormInput, BFormGroup } from 'bootstrap-vue'

  export default {
    name: 'ConsentSignature',

    components: { DateInput, BFormGroup, BFormInput },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        isMinor: false,
        consent: {
          signature: '',
          consentPersonName: '',
          consentPersonDateOfBirth: null
        },
        isSaving: false
      }
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (this.isMinor) {
          if (!this.consent.consentPersonName) {
            this.addErrorForField('consentPersonName', 'Parent or guardian signature is required.')
          }
          if (!this.consent.consentPersonDateOfBirth) {
            this.addErrorForField('consentPersonDateOfBirth', 'Parent or guardian birth date is required.')
          }
        } else {
          if (!this.consent.signature) {
            this.addErrorForField('signature', 'Your signature is required.')
          }
        }

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Consent').then(response => {
          this.isMinor = response.data.isMinor
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading consent items: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/GenerateSignature', this.consent).then(response => {
          this.$store.setModuleStatus('Consent', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving consent signature: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      birthDateUpdated (birthDate) {
        this.clearFieldError('birthDate')
        this.consent.consentPersonDateOfBirth = birthDate
      },

      birthDateInvalid (birthDate) {
        this.addErrorForField('birthDate', 'Invalid birth date')
      },

      birthDateChanged () {
        this.clearFieldError('birthDate')
      }

    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
