<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Are you still using this pharmacy?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-12 text-center pharmacy-info pb-4">
                            <b>{{ pharmacy.name || pharmacy.otherText }}</b><br/>
                            {{ pharmacy.street1 }}<br/>
                            <span v-if="pharmacy.street2">{{ pharmacy.street2 }}<br/></span>
                            {{ pharmacy.city + ", " + pharmacy.stateShort + " " + pharmacy.zip }}
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 mt-2 text-center">
                            <button type="submit" @click.prevent="confirm()" class="btn btn-primary btn-lg mx-auto pharmacy-button"><font-awesome-icon class="mr-2" v-show="confirmSaving" icon="spinner" pulse/>This is correct</button>
                        </div>
                        <div class="col-md-6 mt-2 text-center">
                            <button type="submit" @click.prevent="update()" class="btn btn-primary btn-lg mx-auto pharmacy-button"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Let me update it</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PharmacyConfirmation',

    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        pharmacy: {
          name: '',
          otherText: '',
          street1: '',
          street2: '',
          city: '',
          stateShort: '',
          zip: ''
        },
        isSaving: false,
        confirmSaving: false
      }
    },

    computed: {
      phoneDisplay () {
        if (this.visitGuarantor.area) {
          if (this.visitGuarantor.number.length > 0) {
            return '(' + this.visitGuarantor.area + ') ' + this.visitGuarantor.exchange + '-' + this.visitGuarantor.number
          } else if (this.visitGuarantor.exchange.length > 0) {
            return '(' + this.visitGuarantor.area + ') ' + this.visitGuarantor.exchange
          } else if (this.visitGuarantor.area.length > 0) {
            return '(' + this.visitGuarantor.area
          }
        }
        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Pharmacy').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.pharmacy = response.data
          this.$store.patientZipCode = response.data.patientZipCode
          if (!this.pharmacy.street1) {
            this.$router.replace('/pharmacy-select')
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
          }
        })
      },

      confirm () {
        this.confirmSaving = true
        this.trackEvent(this.$options.name, 'PharmacyConfirmation', 'Confirmed')

        this.$store.setModuleStatus('Pharmacy', true, () => {
          this.confirmSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      update () {
        this.trackEvent(this.$options.name, 'PharmacyConfirmation', 'Update')
        this.isSaving = true
        this.$router.push('/pharmacy-select')
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
    .pharmacy-info {
        font-size: 22px;
    }

    .pharmacy-button {
        width: 190px;
    }
</style>
