<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header" v-html="headerCopy"></h1>
        <h2 class="subheader">Bypass the Line with Q Pass</h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col text-center">
          <img class="complete-image" src="../../assets/qpass.svg">
      <div class="col text-left">
        <div class="complete-copy">
          <p><span class="dot"><b>1</b></span> Capture Driver's License</p>
          <p><span class="dot"><b>2</b></span> Capture or Enter Insurance Information</p>
          <p><span class="dot"><b>3</b></span> Pay CoPay and/or Minimum Outstanding Balance</p>
        </div>
        <div class="form-row mt-2">
          <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto" style="width: 75%">Next</button>
        </div>
      </div>

      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import ErrorsMixin from '../../mixins/ErrorsMixin'

  export default {
    name: 'PaymentPassIntro',

    mixins: [ErrorsMixin],

    props: {
      token: {
        type: String,
        required: false
      }
    },

    data () {
      return {
        orgName: ''
      }
    },

    metaInfo () {
      return {
        meta: [{
          name: 'og:image',
          content: this.$store.apiBaseUrl + '/WebClient/ShareImage/' + this.token
        }]
      }
    },

    computed: {
      headerCopy () {
        return 'Activate Q Pass'
      }
    },

    methods: {
      submit () {
        this.$store.isPaymentPassSearch = true
        this.$router.push({ name: 'patient-search', params: { token: this.token } })
      }
    }
  }
</script>

<style scoped>
.dot {
  height: 30px;
  width: 30px;
  background-color: rgba(255,79,0,1.0);
  text-align: center;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.complete-image {
  max-height: 140px;
  max-width: 49.9999%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.complete-copy {
  font-size: 20px;
}

</style>
