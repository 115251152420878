<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Who else can access your health information?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Name" :state="stateForField('name')" :invalid-feedback="errorForField('name')">
                                <b-form-input @input="clearFieldError('name')" size="lg" :state="stateForField('name')" v-model="hipaaContact.name"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Phone Number" :state="stateForField('phone')" :invalid-feedback="errorForField('phone')">
                                <b-form-input pattern="[0-9]*" @input="numberInput" size="lg" :state="stateForField('phone')" :value="phoneDisplay"></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Relationship" :state="stateForField('contactTypeId')" :invalid-feedback="errorForField('contactTypeId')">
                                <b-form-select size="lg" @input="clearFieldError('contactTypeId')" v-model="hipaaContact.contactTypeId" :state="stateForField('contactTypeId')" :options="contactTypeOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Protected Information in Voicemail" :state="stateForField('voicemail')" :invalid-feedback="errorForField('voicemail')">
                                <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.voicemailAllowed" name="check-button" switch>
                                    <span v-if="hipaaContact.voicemailAllowed">
                                    Allowed
                                    </span>
                                    <span v-else>
                                    Not Allowed
                                    </span>
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                        <b-form-group label="Appointment Information" :state="stateForField('appointment')" :invalid-feedback="errorForField('appointment')">
                          <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.appointment" name="check-button" switch>
                                    <span v-if="hipaaContact.appointment">
                                    Allowed
                                    </span>
                            <span v-else>
                                    Not Allowed
                                    </span>
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                            <b-form-group label="Billing Information" :state="stateForField('billing')" :invalid-feedback="errorForField('billing')">
                                <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.billing" name="check-button" switch>
                                    <span v-if="hipaaContact.billing">
                                    Allowed
                                    </span>
                                    <span v-else>
                                    Not Allowed
                                    </span>
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-6">
                            <b-form-group label="Treatment Information" :state="stateForField('treatment')" :invalid-feedback="errorForField('treatment')">
                                <b-form-checkbox size="lg" class="mt-2" v-model="hipaaContact.treatment" name="check-button" switch>
                                    <span v-if="hipaaContact.treatment">
                                    Allowed
                                    </span>
                                    <span v-else>
                                    Not Allowed
                                    </span>
                                </b-form-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button v-if="showRemove" type="button" @click.prevent="remove()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isDeleting" icon="spinner" pulse/>Remove Contact</button>
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
      <list-modal :header-image="require('../../assets/family-history.svg')" :header="confirmModalHeader" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="confirmOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormSelect, BFormGroup, BFormInput, BFormCheckbox } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'
  import ListModal from '@/components/ListModal.vue'

  export default {
    name: 'HIPAAContact',

    components: { ListModal, BFormGroup, BFormInput, BFormSelect, BFormCheckbox },

    mixins: [ErrorsMixin, OptionsMixin, DemographicsMixin, AnalyticsMixin],

    props: {
      hipaaContactId: {
        type: Number,
        default: null
      }
    },

    data () {
      return {
        hipaaContact: {
          hipaaContactId: null,
          name: '',
          area: '',
          exchange: '',
          number: '',
          appointment: true,
          billing: true,
          treatment: true,
          voicemailAllowed: true,
          contactTypeId: null,
          contactTypeOptions: []
        },
        isSaving: false,
        isDeleting: false,
        showListModal: false,
        confirmOptions: [
          {
            id: 1,
            name: 'I do not want to add HIPAA contact',
            selected: false
          },
          {
            id: 2,
            name: 'Let me select an option',
            selected: false
          }
        ],
        confirmModalHeader: 'Please select at least one option to share with HIPAA contact'
      }
    },

    computed: {
      showRemove () {
        if (this.hipaaContact.hipaaContactId) {
          return true
        }

        return false
      },

      contactTypeOptions () {
        if (this.hipaaContact.contactTypeOptions && this.hipaaContact.contactTypeOptions.length > 0) {
          return this.optionsFromArray(this.hipaaContact.contactTypeOptions, 'contactTypeId')
        } else {
          return this.optionsFromArray(this.$store.contactTypeOptions, 'contactTypeId')
        }
      },

      phoneDisplay () {
        if (this.hipaaContact.area) {
          if (this.hipaaContact.number.length > 0) {
            return '(' + this.hipaaContact.area + ') ' + this.hipaaContact.exchange + '-' + this.hipaaContact.number
          } else if (this.hipaaContact.exchange.length > 0) {
            return '(' + this.hipaaContact.area + ') ' + this.hipaaContact.exchange
          } else if (this.hipaaContact.area.length > 0) {
            return '(' + this.hipaaContact.area
          }
        }
        return ''
      }
    },

    methods: {
      modalClosed () {
        if (this.confirmOptions[0].selected === true) {
          this.$router.push('/hipaa-contacts')
        } else {
          this.showListModal = false
        }
      },
      modalSelection (item) {
        for (const anOption of this.confirmOptions) {
          if (anOption.id === item.id) {
            anOption.selected = true
          } else {
            anOption.selected = false
          }
        }
      },
      validate () {
        this.clearAllErrors()

        if (!this.hipaaContact.name) {
          this.addErrorForField('name', 'Contact name is required.')
        }

        if (!this.hipaaContact.area || !this.hipaaContact.exchange || !this.hipaaContact.number) {
          this.addErrorForField('phone', 'Contact phone is required.')
        }

        if (!this.hipaaContact.contactTypeId) {
          this.addErrorForField('contactTypeId', 'Contact type is required.')
        }

        return !this.hasErrors()
      },

      loadData () {
        if (!this.hipaaContactId) {
          return
        }

        api.get('/Patient/HipaaContacts/' + this.hipaaContactId).then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.hipaaContact = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading hipaa contact: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      numberInput (value) {
        this.clearFieldError('phone')
        const newNumber = value
        let cleanNumber = newNumber.replace(/\D/g, '')
        if (cleanNumber.length > 0) {
          if (parseInt(cleanNumber[0]) === 1) {
            cleanNumber = cleanNumber.substr(1, cleanNumber.length - 1)
          }

          this.hipaaContact.area = cleanNumber.substr(0, cleanNumber.length > 3 ? 3 : cleanNumber.length)
          if (cleanNumber.length > 3) {
            this.hipaaContact.exchange = cleanNumber.substr(3, cleanNumber.length > 6 ? 3 : cleanNumber.length - 3)
          } else {
            this.hipaaContact.exchange = ''
          }
          if (cleanNumber.length > 6) {
            this.hipaaContact.number = cleanNumber.substr(6, cleanNumber.length < 10 ? cleanNumber.length - 6 : 4)
          } else {
            this.hipaaContact.number = ''
          }
        } else {
          this.hipaaContact.area = ''
          this.hipaaContact.exchange = ''
          this.hipaaContact.number = ''
        }

      },

      remove () {
        this.isDeleting = true
        api.delete('/Patient/HipaaContacts/' + this.hipaaContact.hipaaContactId).then(response => {
          this.$router.push('/hipaa-contacts')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error deleting hipaa contact: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isDeleting = false
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }
        if (this.hipaaContact.appointment === false && this.hipaaContact.billing === false && this.hipaaContact.treatment === false && this.hipaaContact.voicemailAllowed === false) {
          this.showListModal = true
          return
        }
        this.isSaving = true
        api.put('/Patient/HipaaContacts', this.hipaaContact).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('/hipaa-contacts')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.$log.error('Error saving hipaa contact: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
          this.isSaving = false
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
