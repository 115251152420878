<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Who is your employer?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm" @click.prevent="selectOtherEmployer(searchTerm)" class="result">
                        <h1 class="text-center">{{ searchTerm }}</h1>
                    </div>
                    <div @click.prevent="selectEmployer(employer)" v-for="(employer,index) in searchResults" :key="index" class="result">
                        <h1 class="text-center">{{ employer.mainText }}</h1>
                        <div class="text-center">{{ employer.description }}</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'

  export default {
    name: 'Employer',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        employment: {
          placeId: 0,
          organizationEmployerId: 0,
          employer: null
        },
        searchResults: {},
        searchTerm: '',
        pauseSearch: false,
        hasChanged: false,
        isSaving: false
      }
    },

    watch: {
      searchTerm (newValue, oldValue) {
        if (this.pauseSearch === false && newValue !== oldValue) {
          this.debounceSearch()
        }
      }
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Employment').then(response => {
          this.employment = response.data
          this.pauseSearch = true
          if (this.employment.employer) {
            this.searchTerm = this.employment.employer
          }
          this.$nextTick(() => {
            this.pauseSearch = false
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching loading employment: ' + error.message, error)
          }
        })
      },

      debounceSearch: _.debounce(function () { this.performSearch() }, 800),

      performSearch () {
        if (this.pauseSearch === true || !this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        this.hasChanged = true
        api.get('/Patient/EmployerSearch?query=' + this.searchTerm).then(response => {
          this.searchResults = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for occupation: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      selectEmployer (employer) {
        if (this.hasChanged === true) {
          this.employment.placeId = employer.placeId
          this.employment.employer = null
          this.employment.organizationEmployerId = null
        }
        this.save()
      },

      selectOtherEmployer (employerName) {
        if (this.hasChanged === true) {
          this.employment.placeId = null
          this.employment.organizationEmployerId = null
          this.employment.employer = employerName
        }
        this.save()
      },

      save () {
        this.isSaving = true

        api.put('/Patient/Employment', this.employment).then(response => {
          this.$store.setModuleStatus('Employment', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving employment: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }

    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,108,198,1.0);
        font-weight: bold;
        padding: .25em;
    }
    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgb(255, 79, 0);
        }
    }
</style>
