<script>
  import { Doughnut } from 'vue-chartjs'

  export default {
    extends: Doughnut,
    props: {
      familyTotal: {
        type: Number,
        default: null
      },
      familyMet: {
        type: Number,
        default: null
      },
      individualTotal: {
        type: Number,
        default: null
      },
      individualMet: {
        type: Number,
        default: null
      }
    },

    data () {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'top'
          },
          title: {
            display: false,
            text: ''
          },
          tooltips: {
            enabled: false
          },
          animation: {
            animateScale: false,
            animateRotate: true
          },
          cutoutPercentage: 70
        },
        chartData: {
          datasets: [{
                       data: [
                         this.$props.familyTotal,
                         this.$props.familyMet
                       ],
                       backgroundColor: [
                         '#adc2e3',
                         '#e8ad5e'
                       ],
                       label: 'Family',
                       weight: 2
                     },
                     {
                       data: [
                         this.$props.individualTotal,
                         this.$props.individualMet
                       ],
                       backgroundColor: [
                         '#adc2e3',
                         '#ec5c2a'
                       ],
                       label: 'Individual',
                       weight: 8
                     }],
          labels: [
            'Total',
            'Met'
          ]
        }
      }
    },

    mounted () {
      this.renderChart(this.chartData, this.options)
    }
  }</script>

<style scoped>

</style>
