<template>
    <div>
        <div v-click-outside="hideSidebar" class="row py-2 no-gutters">
            <div class="col-1 p-1 no-gutters text-center">
                <button type="button" @click.prevent="showSidebar" class="btn btn-outline-dark"><font-awesome-icon class="hamburger" icon="bars"/></button>
            </div>
            <div class="col-11 text-right pr-2">
                <img class="logo" src="../assets/clinic-q.svg">
            </div>
        </div>
        <div ref="sidebar" id="mySidepanel" class="sidepanel">
            <button type="button" @click.prevent="hideSidebar" class="btn btn-outline-primary closebtn"><font-awesome-icon icon="times"/></button>
            <div v-for="module in modules" :key="module.intakeModuleId">
                <a @click.prevent="goToModule(module)" href="#">{{ module.name }}</a>
            </div>
            <a v-if="isVirtualVisit" href="/#/virtual-visit">Virtual Visit</a>
        </div>
    </div>
</template>

<script>
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'
  export default {
    name: 'TheNav',
    mixins: [AnalyticsMixin],

    methods: {
      showSidebar () {
        // this.$refs.sidebar.style.width = '250px'
        this.$refs.sidebar.style.left = '0'
      },

      hideSidebar () {
        // this.$refs.sidebar.style.width = '0px'
        this.$refs.sidebar.style.left = '-250px'
      },

      goToModule (module) {
        const moduleRoute = this.$store.firstRouteForModule(module)
        if (moduleRoute) {
          this.trackEvent(this.$options.name, 'ManualNavigation', module.code)
          this.$router.push(moduleRoute)
          this.hideSidebar()
        } else {
          this.trackEvent(this.$options.name, 'ModuleRouteNotFound', module.code)
          this.$log.error('Could not find route for module', module)
        }
      }
    },

    computed: {
      modules () {
        return this.$store.modules
      },

      isVirtualVisit () {
        return this.$store.isVirtualVisit
      }
    }
  }
</script>

<style scoped>
    .logo {
        display: inline-block;
        height: 40px;
        margin-left: -8.33333%;
    }

    .sidepanel {
        height: 100%; /* Specify a height */
        width: 250px; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        z-index: 100; /* Stay on top */
        top: 0;
        left: -250px;
        background-color: rgb(233, 236, 240);
        overflow-x: hidden; /* Disable horizontal scroll */
        overflow-y: auto;
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
        padding-bottom: 40px;
    }

    @media (max-width: 376px) {
        .sidepanel {
            height: 90vh; /* Specify a height */
        }
    }

    /* The sidepanel links */
    .sidepanel a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: rgba(23,56,96,1.0);
        display: block;
        transition: 0.3s;
    }

    /* When you mouse over the navigation links, change their color */
    .sidepanel a:hover {
        color: rgba(255,62,3,1.0);
    }

    /* Position and style the close button (top right corner) */
    .sidepanel .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        margin-left: 2rem;
        margin-top: 1rem;
    }

</style>
