<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Who is responsible for this visit?</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col">
        <p class="detail-text">Patients who are under age 18 must have a guarantor who is the person responsible to pay the bill for their account.<br><br>
          The guarantor is always the patient unless the patient is an incapacitated adult or an unemancipated minor (under age 18), in which case, the guarantor is the patient's parent or legal guardian.</p>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row">
      <div class="col text-center mt-2">
        <button type="button" @click.prevent="goBack()" class="btn btn-primary btn-lg mx-auto">Back</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VisitGuarantorDetail',

    methods: {
      goBack () {
        this.$router.push('/visit-guarantor')
      }
    }
  }
</script>
