<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What is your shoe size?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Shoe Size" :state="stateForField('shoeSize')" :invalid-feedback="errorForField('shoeSize')">
                                <b-form-select size="lg" @input="clearFieldError('shoeSize')" v-model="orthoReasonForVisit.shoeSize" :state="stateForField('shoeSize')" :options="sizeRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Size Type" :state="stateForField('shoeSizeType')" :invalid-feedback="errorForField('shoeSizeType')">
                                <b-form-select size="lg" @input="clearFieldError('shoeSizeType')" v-model="orthoReasonForVisit.shoeSizeType" :state="stateForField('shoeSizeType')" :options="sizeTypeRadioOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import OrthoMixin from '../../mixins/OrthoMixin'
  import { BFormSelect, BFormGroup } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoFootSize',

    components: { BFormGroup, BFormSelect },

    mixins: [ErrorsMixin, OptionsMixin, OrthoMixin, AnalyticsMixin],

    data () {
      return {
        isSaving: false,
        orthoReasonForVisit: {
          primaryBodyPart: {
            code: '',
            name: ''
          },
          shoeSize: null,
          shoeSizeType: null
        }
      }
    },

    computed: {
      sizeRadioOptions () {
        const options = [{
          text: '',
          value: null
        }]
        for (let i = 0.5; i < 22; i += 0.5) {
          options.push({
            text: i,
            value: i
          })
        }
        return options
      },

      sizeTypeRadioOptions () {
        return [
          {
            text: '',
            value: null
          },
          {
            text: 'Men',
            value: 'Men'
          },
          {
            text: 'Women',
            value: 'Women'
          },
          {
            text: 'Children',
            value: 'Child'
          }
        ]
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.orthoReasonForVisit.shoeSize) {
          this.addErrorForField('shoeSize', 'You must select a shoe size.')
        }

        if (!this.orthoReasonForVisit.shoeSizeType) {
          this.addErrorForField('shoeSizeType', 'You must select a size type.')
        }

        return !this.hasErrors()
      },

      optionSelected (item) {
        this.clearAllErrors()

        this.orthoReasonForVisit.footOrthotics = item.value
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/OrthoReasonForVisit', this.orthoReasonForVisit).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
          const nextRoute = this.nextOrthoRouteName()
          this.$router.push(nextRoute)

        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      loadData () {
        api.get('/Patient/OrthoReasonForVisit').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
