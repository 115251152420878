<template>
  <div id="app">
    <template v-if="showNav">
      <TheNav></TheNav>
    </template>
    <transition
            name="slide-left"
            mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  import TheNav from './components/TheNav'

  export default {
    name: 'app',

    components: { TheNav },

    computed: {

      showNav () {
        if (this.$route.name === 'payment' || this.$route.name === 'virtual-visit') {
          return false
        }

        return true
      }
    }
  }
</script>

<style lang="scss">
  @import "assets/_custom.scss";
  @import "~bootstrap/scss/bootstrap.scss";
  @import '~bootstrap-vue/dist/bootstrap-vue.css';

  #app {
    font-family: 'Open Sans', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(53,50,59,1.0);
    text-size-adjust: none !important;
    -webkit-text-size-adjust: 100% !important;
  }

  #app .container-fluid {
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 480px){
    body,table,td,p,a,li,blockquote{
      -webkit-text-size-adjust:none !important;
    }

  }

  p,a,li,td,body,table,blockquote{
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
  }

  .blue-text {
    color: rgba(23,56,96,1.0);
  }

  .bright-orange {
    color: rgba(255,62,3,1.0);
  }

  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
  }

  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
  }

  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
  }

  .header {
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
  }

  .subheader {
    font-size: 4mm;
    margin-bottom: 2rem;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2rem;
    text-align: center;
  }

  @media (max-width: 767px) {
    .header {
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 30px;
    }
  }

  div.vdp-datepicker div input[type=text] {
    border: 0;
    color: rgba(53,50,59,1.0);
    width: 95%;
    height: 38px;
  }

  div.vdp-datepicker div input[type=text]:focus {
    outline-width: 0px;
    outline-color: transparent;
    outline-style: none;
  }

  div.vdp-datepicker:focus-within {
    color: #495057;
    background-color: #fff;
    border-color: #6eacee;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 108, 198, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(23, 108, 198, 0.25);
  }

  .vdp-datepicker__calendar .cell.today {
    border: 1px solid rgba(255,62,3,1.0);
  }

  .form-group legend.col-form-label {
    font-size: 20px;
  }

  div.invalid-feedback {
    font-size: 18px;
  }

  #app .modal-content {
    border-radius: 0.9rem;
  }

  .form-control[readonly].tap-input {
    background-color: transparent;
    cursor: pointer;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
