<template>
    <div class="container-fluid">

        <div v-if="!cameraToggle" class="row">
            <div class="col custom-col-padding">
                <h1 class="header">Patient Photo</h1>
                <h4>In order to provide the most personal care possible, we’d like a photo of the patient. Tap next to get started…</h4>
            </div>
        </div>

        <div v-if="!cameraToggle"  class="row">
            <div class="col-md-12 custom-button-center">
                <div class="btn btn-primary btn-lg mx-auto" @click.prevent="toggleCamera">Next</div>
            </div>
        </div>

        <div v-if="cameraToggle" class="row custom-camera-view">
            <div v-if="image">
                <img :style="{width: this.imageWidth}" :src="image">
            </div>
            <div v-if="!image" class="custom-circle-overlay"></div>
            <div :style="[image !== null ? {display: 'none'} : {display: 'inline'}]">
                <web-cam ref="webcam"
                         :device-id="deviceId"
                         :width="this.screenWidth"
                         :height="this.screenHeight"
                         @error="onError"
                         @cameras="onCameras"
                         cameraFacingMode="user"
                />
            </div>
        </div>

        <div v-if="cameraToggle" class="custom-camera-button">
            <div class="col-md-12 custom-button-center">
                <div v-if="!image" class="btn btn-primary btn-lg mx-auto" @click.prevent="onCapture">Take Photo</div>
                <div v-if="image" class="btn btn-primary btn-lg mx-auto" @click.prevent="clearImage">Retake Photo</div>
                <div v-if="image" class="btn btn-primary btn-lg mx-auto mt-2" style="margin-left: 10px !important; width: 40%" @click.prevent="uploadImage">
                    <font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>
                    Next
                </div>
            </div>
        </div>

        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>

    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import WebCam from '../../components/WebCam.vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PatientPhoto',

    components: { WebCam },

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      captureOnly: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        image: null,
        cameraToggle: false,
        camera: null,
        deviceId: null,
        devices: [],
        isSaving: false
      }
    },

    watch: {
      camera: function (id) {
        this.deviceId = id
      },
      devices: function () {
        const first = this.devices[0]
        if (first) {
          this.camera = first.deviceId
          this.deviceId = first.deviceId
        }
      }
    },

    mounted () {
      this.screenWidth = screen.width
      this.screenHeight = screen.width < 500 ? 500 : screen.height - 120
      this.inPortraitMode = window.matchMedia('(orientation: portrait)').matches
    },

    methods: {
      toggleCamera () {
        this.cameraToggle = !this.cameraToggle
      },

      onCapture () {
        this.imageWidth = screen.width < 800 ? `${screen.width}px` : '800px'
        this.image = this.$refs.webcam.capture()
        this.clearAllErrors()
      },

      onError (error) {
        this.trackEvent(this.$options.name, 'CameraAccessError', error)
        this.$router.push(this.$store.nextRoute())
      },

      onCameras (cameras) {
        this.devices = cameras
      },

      clearImage () {
        this.image = null
      },

      uploadImage () {
        this.isSaving = true
        const imageFile = new File([this.dataURItoBlob(this.image)], 'patient-photo.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)
        api.post('/Patient/Photo', multipartForm).then(response => {
          this.trackEvent(this.$options.name, 'UploadPatientPhoto', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('PatientPhoto', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
          this.isSaving = false
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.trackEvent(this.$options.name, 'SaveError', 'PatientPhotoNotSaved')
            this.$log.error('Error uploading patient photo: ' + error.message, error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      dataURItoBlob (dataURI) {
        // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
        // convert base64/URLEncoded data component to raw binary data held in a string

        if (!dataURI) {
          return
        }

        let byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
      }
    }
  }
</script>

<style scoped>
    h4 {
        text-align: center
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0 !important;
    }
    .col {
        padding-left: 30px;
    }
    .row {
        margin-right: 0
    }
    .header {
        margin-bottom: 30px;
    }

    .custom-button-center {
        margin: 10px auto 0;
        display: flex;
        max-width: 350px;
    }

    .custom-camera-button {
        position: absolute !important;
        bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    @media only screen and (min-height: 660px) {
        .custom-camera-button {
            position: relative !important;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    .custom-circle-overlay {
        position: absolute;
        border: 5px solid #fff;
        border-radius: 50%;
        width: 250px;
        height: 250px;
        top: 100px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    @media only screen and (min-width: 374px) {
        .custom-circle-overlay {
            position: absolute;
            border: 5px solid #fff;
            border-radius: 50%;
            width: 315px;
            height: 315px;
            top: 125px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    @media only screen and (min-width: 700px) {
        .custom-circle-overlay {
            position: absolute;
            border: 5px solid #fff;
            border-radius: 50%;
            width: 500px;
            height: 500px;
            top: 150px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    @media only screen and (min-width: 1100px) {
        .custom-circle-overlay {
            position: absolute;
            border: 5px solid #fff;
            border-radius: 50%;
            width: 900px;
            height: 900px;
            top: 500px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            text-align: center;
        }
    }

    .custom-camera-view {
        background: #000000;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
        margin: auto;
    }

    @media only screen and (min-width: 700px) {
        .custom-camera-view {
            background: #000000;
            max-height: 100%;
            max-width: 100%;
            overflow: hidden;
            margin: auto;
        }
    }
</style>
