<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Please select your insurance company.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div @click.prevent="selectCompany(topInsurance)" v-for="topInsurance in topInsurances" :key="topInsurance.insuranceCompanyId" class="payor">
                        <div class="img-container">
                            <span class="helper"></span>
                            <img :src="topInsurance.logoUrl">
                        </div>
                    </div>
                    <div @click.prevent="selectOther()" class="payor">
                        <h1 class="text-center">Other</h1>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'PayorSelect',

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        topInsurances: {},
        isSaving: false
      }
    },

    computed: {

    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/TopInsurances').then(response => {
          this.topInsurances = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error getting top insurances: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      selectCompany (insuranceCompany) {
        this.trackEvent(this.$options.name, 'Insurance', 'SelectedCompany', insuranceCompany.short)
        this.$store.insurance.insuranceCompanyShortCode = insuranceCompany.short
        this.$router.push('policy-number')
      },

      selectOther () {
        this.$router.push('payor-search')
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
    .payor {
        display: inline-block;
        height: 180px;
        width: 180px;
        box-shadow: 2px 2px 5px darkgray;
        border: 2px solid transparent;
        margin: 1em;
        border-radius: 5px;
        background-color: white;
    }

    .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    .img-container {
        margin: 2px;
        width: 174px;
        height: 174px;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }

    .img-container img {
        max-width: 100%;
        max-height: 100%;
    }

    .payor h1 {
        margin-top: 75px;
        font-size: 26px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
    }

    .payor:HOVER {
        cursor: pointer;
        border: 2px solid rgba(255,62,3,1.0);
    }
</style>
