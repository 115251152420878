<template>
  <div class="p-2">
    <div class="container-fluid pb-4">
      <div v-if="this.$store.isAuthenticated == false">
        <div class="row">
          <div class="col-xl-3"></div>
          <div class="col">
            <div v-if="paymentInfo.orgLogoUrl" class="text-center">
              <img class="logo-url" :src="paymentInfo.orgLogoUrl">
            </div>
          </div>
          <div class="col-xl-3"></div>
        </div>

        <div class="row py-2">
          <div class="form-group col-md-12 text-center">
            <button type="submit" @click.prevent="login()" class="btn btn-primary btn-lg mx-auto">
              Login to View Estimate
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row">
          <div class="col-xl-3"></div>
          <div class="col">
            <h1 class="header">Patient Cost Estimate</h1>
            <h2 class="subheader">This is not a bill. Actual cost may change</h2>
          </div>
          <div class="col-xl-3"></div>
        </div>
        <div class="row">
          <div class="col-xl-3"></div>
          <div class="col">
        <div class="section-header py-1 px-2 mt-4">
          <div class="col text-center">
            PATIENT
          </div>
        </div>
        <div class="section px-4">
          <div class="row pb-1">
            <div class="col-6">
              <span class="field-label">Name</span><br>{{ estimate.patient.firstName+" "+estimate.patient.lastName}}
            </div>
            <div class="row pb-1">
              <div class="col-6">
                <span class="field-label">DOB</span><br>{{ formatUtcDate(estimate.patient.dob) }}
              </div>
          </div>
            <div class="col-12">
              <span class="field-label">ID</span><br>{{ estimate.patient.patientIdFromOrg }}
            </div>
          </div>
        </div>
          </div>
          <div class="col-xl-3"></div>
        </div>
        <div class="row">
          <div class="col-xl-3"></div>
          <div class="col">
        <div v-if="estimate.isSelfPay === false && estimate.primaryInsurance && estimate.primaryInsurance.verification && estimate.primaryInsurance.verification.insuranceEligibilityStatus === 'Verified'" class="column">
          <div class="section-header py-1 px-2 mt-4">
            <div class="col text-center">
              PRIMARY BENEFITS
            </div>
          </div>
          <div class="section px-4">
            <div class="row">
              <div class="col-12">
                <span class="field-label">Payer </span>{{ estimate.primaryInsurance.insuranceCompany}}
              </div>
            </div>
            </div>
            <div class="section px-4">
              <div  class="row py-2">
                <div class="col">
                  <h4 class="insurance-header">INDIVIDUAL COVERAGE</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12 pb-2">
                  <span class="field-label">Deductible - </span>{{ individualDeductible }}
                  <div v-if="hasIndividualDeductible" class="progress insurance-progress my-2">
                    <div class="progress-bar" role="progressbar"
                         :style="'width: ' + individualDeductiblePercent + '%'"></div>
                  </div>
                </div>
              <div class="col-12 pb-2">
                <span class="field-label">Out Of Pocket - </span>{{ individualMaxOopDisplay }}
                <div v-if="hasIndividualMaxOop"  class="progress insurance-progress my-2">
                  <div class="progress-bar orange" role="progressbar"
                       :style="'width: ' + individualMaxOopPercent + '%'"></div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <template v-if="hasFamilyBenefits">
          <div class="section px-4">
            <div  class="row py-2">
              <div class="col">
                <h4 class="insurance-header">FAMILY COVERAGE</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pb-2">
                <span class="field-label">Deductible - </span>{{ familyDeductibleDisplay }}
                <div v-if="hasFamilyDeductible" class="progress insurance-progress my-2">
                  <div class="progress-bar" role="progressbar"
                       :style="'width: ' + familyDeductiblePercent + '%'"></div>
                </div>
              </div>
              <div class="col-12 pb-2">
                <span class="field-label">Out Of Pocket - </span>{{ familyMaxOopDisplay }}
              <div v-if="hasFamilyMaxOop" class="progress insurance-progress my-2">
                <div class="progress-bar" role="progressbar"
                     :style="'width: ' + familyOutOfPocketPercent + '%'"></div>
              </div>
            </div>
            </div>
            </div>
        </template>
          </div>
          <div class="col-xl-3"></div>
        </div>
          </div>
      <div class="row">
        <div class="col-xl-3"></div>
        <div class="col">
      <div v-if="estimate.estimateNote" class="column">
        <div class="section-header py-1 px-2 mt-4">
          <div class="col text-center">
            NOTE
          </div>
        </div>
        <div class="section px-4">
          <div class="row pb-1">
            <div class="col-12">
              <STRONG>{{estimate.estimateNote}}</STRONG>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div class="col-xl-3"></div>
      </div>
      <div class="row">
        <div class="col-xl-3"></div>
        <div class="col">
        <div class="section-header py-1 px-2 mt-4">
          <div class="col text-center">
            ESTIMATE
          </div>
        </div>

        <div class="section px-4">
          <div class="row pb-1">
            <div class="col-6">
              <span class="field-label">Estimate ID</span><br>#{{ estimate.organizationPatientEstimateId }}
            </div>
            <div class="col-6">
              <span class="field-label">Date Created</span><br>{{ formatDateShort(estimate.createdAt) }}
            </div>
          </div>
        </div>
        <div class="section px-4">
          <div class="row">
            <div class="col-6">
              <span class="field-label">Service Location</span><br> <span v-html="formatLocation(estimate.location)"/>
            </div>
            <div class="col-6">
              <span class="field-label">Phone</span><br>{{ estimate.location.phone }}
            </div>
          </div>
        </div>
        <div class="section px-4">
          <div class="row">
            <div class="col-6">
              <span class="field-label">Date Of Service</span><br>{{ formatUtcDate(estimate.procedureDate) }}
            </div>
            <div class="col-6">
              <span class="field-label">Tax ID</span><br>{{ estimate.taxId }}
            </div>
          </div>
        </div>
        <div class="section px-4">
          <div class="row estimate-summary-header">
            <div class="col-6">
              <span class="field-label">Provider</span><br>{{ estimate.providerName }}
            </div>
            <div class="col-6">
              <span class="field-label">NPI</span><br>{{ estimate.npi }}
            </div>
          </div>
        </div>
        <div class="section px-4" v-if="estimate.primaryIcdCmCode">
          <div class="row estimate-summary-header">
            <div class="col-12 mb-2">
              <span class="field-label">Primary ICD10 Code </span>{{ estimate.primaryIcdCmCode.code + ' ' + estimate.primaryIcdCmCode.description }}
            </div>
            <div class="col-12" v-if="estimate.secondaryIcdCmCode">
              <span class="field-label">Secondary ICD10 Code </span>{{ estimate.secondaryIcdCmCode.code + ' ' + estimate.secondaryIcdCmCode.description }}
            </div>
          </div>
        </div>

        <div class="section px-4" v-if="estimate.lines.length > 0">
          <div class="row py-2">
            <div class="col">
              <h4 class="estimate-header">PROFESSIONAL FEES</h4>
            </div>
          </div>
          <div class="row py-2" v-for="line in estimate.lines" :key="line.organizationPatientEstimateLineId">
            <div class="col-4 pb-1"><span class="field-label">Code</span><br>{{ line.code }}</div>
            <div class="col-4 pb-1"><span class="field-label">Amount</span><br>${{ formatNumber(line.price) }}</div>
            <div class="col-4 pb-2"><span class="field-label">Quantity</span><br>{{ line.quantity }}</div>
            <div class="col-12 pb-1"><span class="field-label">Description</span><br>{{ line.description }}</div>
          </div>
        </div>

        <div class="section px-4" v-if="estimate.fees.length > 0">
          <div  class="row py-2">
            <div class="col">
              <h4 class="estimate-header">ADDITIONAL PROFESSIONAL FEES</h4>
            </div>
          </div>
          <div class="row py-2" v-for="fee in estimate.fees" :key="fee.organizationPatientEstimateFeeId">
            <div class="col-12 pb-1"><span class="field-label">Description</span><br>{{ fee.description }}
              <font-awesome-icon title="Item not covered" v-if="estimate.isSelfPay === false && !fee.coveredItem"
                                 class="ml-2 orange" icon="exclamation-circle"/>
            </div>
            <div class="col-12 pb-1"><span class="field-label">Amount</span><br>${{ formatNumber(fee.amount) }}</div>
          </div>
          <div v-if="estimate.isSelfPay === false" class="row py-2">
            <div class="col fee-notes">
              <font-awesome-icon title="Item not covered" class="ml-2 orange" icon="exclamation-circle"/>
              Indicates amount does not count towards deductible.
            </div>
          </div>
        </div>

        <div class="section px-4" v-if="estimate.hasImplant">
          <div class="row py-2">
            <div class="col">
              <h4 class="estimate-header">IMPLANT</h4>
            </div>
          </div>
          <div class="row  py-2">
            <div class="col-12 pb-1"><span class="field-label">Description</span><br>{{ estimate.implantDescription }}</div>
            <div class="col-12 pb-1"><span class="field-label">Amount</span><br>{{ estimate.implantCost }}</div>
          </div>
        </div>

        <div v-if="estimate.isAsc">
          <div class="section px-4" v-if="estimate.ascLines.length > 0">
            <div class="row py-2">
              <div class="col">
                <h4 class="estimate-header">FACILITY FEES</h4>
              </div>
            </div>
            <div class="row py-2" v-for="(fee, index) in estimate.ascLines" :key="'asc-line-' + index">
              <div class="col-4 pb-1"><span class="field-label">Code</span><br>{{ fee.code }}</div>
              <div class="col-4 pb-1"><span class="field-label">Amount</span><br>${{ formatNumber(fee.price) }}</div>
              <div class="col-4 pb-1"><span class="field-label">Quantity</span><br>{{ fee.quantity }}</div>
              <div class="col-12 pb-1"><span class="field-label">Description</span><br>{{ fee.description }}</div>
            </div>
            <div v-if="estimate.ascCopayAmount" class="row py-2">
              <div class="col-12 pb-1"><span class="field-label">Copay</span><br>${{ formatNumber(estimate.ascCopayAmount) }}</div>
            </div>
          </div>

          <div class="section px-4" v-if="estimate.ascFees.length > 0">
            <div class="row py-2">
              <div class="col">
                <h4 class="estimate-header">ADDITIONAL FACILITY FEES</h4>
              </div>
            </div>
            <div class="row py-2" v-for="(ascFee, index) in estimate.ascFees" :key="'asc-fee-' + index">
              <div class="col-12 pb-1"><span class="field-label">Description</span><br>{{ ascFee.description }}
                <font-awesome-icon title="Item not covered" v-if="estimate.isSelfPay === false && !ascFee.coveredItem"
                                   class="ml-2 orange" icon="exclamation-circle"/>
              </div>
              <div class="col-12 pb-1"><span class="field-label">Amount</span><br>${{ formatNumber(ascFee.amount) }}</div>
            </div>
            <div v-if="estimate.isSelfPay === false" class="row py-2">
              <div class="col fee-notes">
                <font-awesome-icon title="Item not covered" class="ml-2 orange" icon="exclamation-circle"/>
                Indicates amount does not count towards deductible.
              </div>
            </div>
          </div>
        </div>

        <div v-if="estimate.coProviderFees.length > 0">
          <div class="section px-4">
            <div class="row py-2">
              <div class="col-12 pb-1 text-right"><span class="field-label">Estimate Total: </span><strong>${{ formatNumber(estimate.estimateTotal) }}</strong></div>
            </div>
          </div>

          <div class="section px-4">
            <div class="row pt-2">
              <div class="col">
                <h4 class="estimate-header">⚠️ CO-PROVIDER FEES</h4>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <i class="fee-notes">Expected fees to be billed by other provider(s). Not included in this Estimate Total.</i>
              </div>
            </div>
            <div class="row py-2" v-for="(coProviderFee, index) in estimate.coProviderFees" :key="'cop-fee-' + index">
              <div class="col-12 pb-1"><span class="field-label">Description</span><br>{{ coProviderFee.description }}</div>
              <div class="col-6 pb-1"><span class="field-label">Name</span><br>{{ formatProviderName(coProviderFee.coProvider) }}</div>
              <div class="col-6 pb-1"><span class="field-label">NPI</span><br>{{ coProviderFee.coProvider.npi }}</div>
              <div class="col-6 pb-1"><span class="field-label">TIN</span><br>{{ coProviderFee.coProvider.tin }}</div>
              <div class="col-6 pb-1"><span class="field-label">Amount</span><br>${{ formatNumber(coProviderFee.amount) }}</div>
            </div>
          </div>
        </div>

        <div class="section px-4">
          <div class="row py-2">
            <div class="col-12 pb-1 text-right"><span class="field-label">Estimate Total:</span><strong> ${{ formatNumber(estimate.estimateTotal) }}</strong></div>
            <div class="col-12 pb-1 text-right"><span class="field-label">Patient Total:</span><strong> ${{ formatNumber(estimate.patientTotal) }}</strong></div>
            <div class="col-12 pb-1 text-right"><span class="field-label">Amount Paid:</span><strong> ${{ formatNumber(estimate.amountPaid) }}</strong></div>
            <template v-if="estimate.discountAmount > 0">
              <div class="col-12 pb-1 text-right"><span class="field-label">Discount Available:</span> ${{ formatNumber(estimate.discountAmount) }}</div>
              <div class="col-12 pb-1 text-right"><span class="field-label">Patient Total With Discount:</span><strong> ${{ formatNumber(estimate.patientTotalWithDiscount) }}</strong></div>
              <div class="col-12 pb-1 text-right"><span class="field-label">Discount Expires:</span> {{ formatDateUtc(estimate.discountExpiration) }}</div>
            </template>
          </div>
        </div>

        <div class="row py-2 mt-3">
          <div class="form-group col-md-12 text-center">
            <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto">
              <span v-if="estimate.signatureEnabled">Continue to Signature</span><span v-else>Continue to Payment</span>
            </button>
          </div>
        </div>
      </div>
        <div class="col-xl-3"></div>
    </div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import moment from 'moment-timezone'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'Estimate',

    components: {},

    mixins: [ErrorsMixin, AnalyticsMixin],

    props: {
      id: {
        default: 0
      },
      token: {
        type: String
      }
    },

    data () {
      return {
        estimate: {
          depositAmount: 0.00,
          emailLastSentAt: null,
          isAsc: false,
          isSelfPay: false,
          benefitOverride: false,
          skipVerify: false,
          organizationAllowableFeeScheduleId: 0,
          organizationAscFeeScheduleId: 0,
          primaryInsurance: {
            verification: {
              insuranceEligibilityStatus: 'Verified',
              individualDeductibleMet: null,
              individualDeductible: null,
              individualMaxOopMet: null,
              individualMaxOop: null,
              familyDeductibleMet: null,
              familyDeductible: null,
              familyMaxOopMet: null,
              familyMaxOop: null
            }
          },
          location: {
            name: '',
            locationId: 0,
            ascOrganizationId: 0
          },
          deductibleAmount: null,
          coinsuranceAmount: null,
          maxOopAmount: null,
          lines: [],
          fees: [],
          ascFees: [],
          useDefaultDepositAmount: true,
          ascCopayAmount: null,
          hasPaymentToken: false,
          last4: null,
          cardType: null,
          hasImplant: false,
          implantDescription: '',
          implantCost: 0.00,
          discountPercentage: 0.00,
          discountAmount: 0.00,
          discountExpiration: null,
          patientTotalWithDiscount: 0.00,
          coProviders: [],
          coProviderFees: [],
          patient: {
            firstName: '',
            lastName: ''
          }
        },
        showingLoadingModal: false,
        paymentInfo: {
          orgName: '',
          orgLogoUrl: '',
          locationName: '',
          totalAmount: 0,
          minimumAmount: null,
          amountPaid: null,
          tokenOnFile: false,
          tokenPaymentAvailable: false,
          estimateId: null,
          billId: null,
          visitId: null,
          paymentTokenizationEnabled: false,
          cardLast4: 8234,
          discountAmount: 0,
          patientTotalWithDiscount: 0,
          discountExpiration: null,
          discountId: 0
        }
      }
    },
    computed: {
      hasIndividualDeductible () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return false
        }
        if (!this.estimate.primaryInsurance.verification.individualDeductibleMet && !this.estimate.primaryInsurance.verification.individualDeductible) {
          return false
        }
        return true
      },
      individualDeductible () {
        if (this.hasIndividualDeductible) {
          return '$' + this.formatNumber(this.estimate.primaryInsurance.verification.individualDeductibleMet) + ' of $' + this.formatNumber(this.estimate.primaryInsurance.verification.individualDeductible)
        }
        return 'Not Applicable'
      },
      individualDeductiblePercent () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return
        }
        return (this.estimate.primaryInsurance.verification.individualDeductibleMet / this.estimate.primaryInsurance.verification.individualDeductible) * 100
      },
      hasIndividualMaxOop () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return false
        }

        if (!this.estimate.primaryInsurance.verification.individualMaxOopMet && !this.estimate.primaryInsurance.verification.individualMaxOop) {
          return false
        }

        return true
      },

      individualMaxOopDisplay () {
        if (this.hasIndividualMaxOop) {
          return '$' + this.formatNumber(this.estimate.primaryInsurance.verification.individualMaxOopMet) + ' of $' + this.formatNumber(this.estimate.primaryInsurance.verification.individualMaxOopMet)
        }

        return 'Not Applicable'
      },
      individualMaxOopPercent () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return
        }
        return (this.estimate.primaryInsurance.verification.individualMaxOopMet / this.estimate.primaryInsurance.verification.individualMaxOop) * 100
      },

      hasFamilyDeductible () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return false
        }

        if (!this.estimate.primaryInsurance.verification.familyDeductibleMet && !this.estimate.primaryInsurance.verification.familyDeductible) {
          return false
        }

        return true
      },
      familyDeductiblePercent () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return
        }
        return (this.estimate.primaryInsurance.verification.familyDeductibleMet / this.estimate.primaryInsurance.verification.familyDeductible) * 100
      },
      familyDeductibleDisplay () {
        if (this.hasFamilyDeductible) {
          return '$' + this.formatNumber(this.estimate.primaryInsurance.verification.familyDeductibleMet) + ' of $' + this.formatNumber(this.estimate.primaryInsurance.verification.familyDeductible)
        }

        return 'Not Applicable'
      },
      hasFamilyMaxOop () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return false
        }

        if (!this.estimate.primaryInsurance.verification.familyMaxOopMet && !this.estimate.primaryInsurance.verification.familyMaxOop) {
          return false
        }

        return true
      },
      familyOutOfPocketPercent () {
        if (this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return
        }
        return (this.estimate.primaryInsurance.verification.familyMaxOopMet / this.estimate.primaryInsurance.verification.familyMaxOop) * 100
      },
      familyMaxOopDisplay () {
        if (this.hasFamilyMaxOop) {
          return '$' + this.formatNumber(this.estimate.primaryInsurance.verification.familyMaxOopMet) + ' of $' + this.formatNumber(this.estimate.primaryInsurance.verification.familyMaxOop)
        }

        return 'Not Applicable'
      },
      hasFamilyBenefits () {
        if (this.estimate.isSelfPay === true || this.estimate.primaryInsurance === null || !this.estimate.primaryInsurance.verification) {
          return false
        }

        if (!this.estimate.primaryInsurance.verification.familyDeductibleMet &&
          !this.estimate.primaryInsurance.verification.familyDeductible &&
          !this.estimate.primaryInsurance.verification.familyMaxOopMet &&
          !this.estimate.primaryInsurance.verification.familyMaxOop) {
          return false
        }

        return true
      }
    },

    methods: {
      formatDate (date) {
        if (date) {
          return moment(date).format('MM/DD/YY h:mm a')
        } else {
          return ''
        }
      },

      formatDateShort (date) {
        if (date) {
          return moment(date).format('MM/DD/YY')
        } else {
          return ''
        }
      },

      formatDateUtc (date) {
        if (date) {
          return moment(date).utc().format('MM/DD/YYYY')
        }

        return ''
      },

      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      },

      formatProviderName (coProvider) {
        let name = coProvider.facilityName
        if (coProvider.lastName) {
          name += ' - ' + coProvider.lastName
          if (coProvider.firstName) {
            name += ', ' + coProvider.firstName
          }
        }

        return name
      },

      formatLocation (location) {
        let name = location.street1
        if (location.street2) {
          name += '<br>' + location.street2
        }
        name += '<br>' + location.city + ', ' + location.stateShort + ' ' + location.zip

        return name
      },

      loadEstimate () {
        this.showingLoadingModal = true
        this.clearAllErrors()

        api.get('/Webclient/Estimate/' + this.id).then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.estimateChanged = false
          this.estimate = response.data

          // Sort the lines by discount rate
          this.estimate.lines.sort((a, b) => {
            return a.discountEligible ? b.discountRate - a.discountRate : -1
          })
          if (this.estimate.hasAsc) {
            // Sort the lines by discount rate
            this.estimate.lines.sort((a, b) => {
              return b.discountRate - a.discountRate
            })
          }
          this.estimate.depositAmount = this.formatNumber(this.estimate.depositAmount)
          if (this.estimate.implantCost) {
            this.estimate.implantCost = this.formatNumber(this.estimate.implantCost)
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to load estimate: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.status === 404) {
              this.addErrorForField('exception', 'Could not find this estimate.')
            } else {
              if (error.response && error.response.data) {
                this.setErrorsFromResponse(error.response.data)
              } else {
                this.addErrorForField('exception', error.message)
              }
            }
          }
        }).then(() => {
          // always executed
          this.showingLoadingModal = false
        })
      },

      loadTokenData () {
        this.isLoading = true
        api.get('/WebClient/Payment?token=' + this.token).then(response => {
          this.trackEvent('Estimate', 'View', this.token)
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.paymentInfo = response.data
          this.id = this.paymentInfo.estimateId
          if (this.$store.isAuthenticated) {
            this.loadEstimate()
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isLoading = false
        })
      },

      formatUtcDate (dob) {
        if (dob) {
          return moment(dob).utc().format('MM/DD/YYYY')
        } else {
          return ''
        }
      },

      submit () {
        if (this.estimate.signatureEnabled) {
          this.$router.push({ name: 'estimate-signature', params: { id: this.$props.id, token: this.$props.token } })
        } else {
          this.$router.push({ name: 'payment', params: { token: this.$props.token } })
        }
      },

      login () {
        this.$router.push({ name: 'patient-payment-search', params: { token: this.token, next: '/estimate/' } })
      }

    },

    created () {
      if (this.id) {
        this.loadEstimate()
      } else if (this.token) {
        this.loadTokenData()
      }
    }
  }
</script>

<style scoped>

.section-header {
  background-color: rgba(23,56,96,1.0);
  color: white;
  font-weight: bold;
  font-size: 1.4em;
}

.insurance-header {
  color: #FF3E03;
}

.section {
  background-color: #f9f9f9;
  border-left-color: #dee2e6;
  border-right-color: #dee2e6;
  border-bottom-color: #dee2e6;
  border-top-color: white;
  border-width: 1px;
  border-style: solid;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.field-label {
  font-weight: 800;
}

.patient-summary-header h5 {
  color: #001859;
}

.section-header {
  border-radius: 5px 5px 0px 0px;
}

.patient-summary .column {
  padding: 0.75rem;
  vertical-align: bottom;
  border-collapse: collapse;
}

.estimate-header h6 {
  color: #001859;
  margin-bottom: .5em;
  margin-top: .5em;
}

.estimate-header {
  color: #001859;
}

.detail-block h5 {
  font-size: 1.2em;
}

.fee-notes {
  font-size: 14px;
}

.orange {
  color: #ff4600;
}
</style>
