<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">When did you have the {{ this.priorTreatmentModel.name }}?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import MultiselectList from '../../components/MultiselectList'
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import OrthoMixin from '../../mixins/OrthoMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoPriorTreatmentPeriod',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, OrthoMixin, AnalyticsMixin],

    props: {
      priorTreatmentOption: {
        type: Object,
        required: true
      },
      periodOptions: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        isSaving: false,
        priorTreatmentModel: {
          orthoPriorTreatmentId: 0,
          name: '',
          isSelected: true,
          orthoPriorTreatmentPeriodId: 0,
          hasProviderSearch: false,
          providerId: 0,
          provider: {}
        }
      }
    },

    watch: {
      priorTreatmentOption (newValue, oldValue) {
        this.initializeModel()
      }
    },

    computed: {
      options () {
        const selectedIds = [this.priorTreatmentModel.orthoPriorTreatmentPeriodId]
        return this.multiselectOptionsFromArrayWithSelectedIds(this.periodOptions, 'periodId', 'name', selectedIds)
      }
    },

    methods: {

      initializeModel () {
        this.priorTreatmentModel.orthoPriorTreatmentId = this.priorTreatmentOption.orthoPriorTreatmentId
        this.priorTreatmentModel.name = this.priorTreatmentOption.name
        this.priorTreatmentModel.providerId = this.priorTreatmentOption.providerId
        this.priorTreatmentModel.provider = this.priorTreatmentOption.provider
        this.priorTreatmentModel.orthoPriorTreatmentPeriodId = this.priorTreatmentOption.orthoPriorTreatmentPeriodId
        this.priorTreatmentModel.hasProviderSearch = this.priorTreatmentOption.hasProviderSearch
      },

      validate () {
        this.clearAllErrors()

        if (!this.priorTreatmentModel.orthoPriorTreatmentPeriodId) {
          this.addErrorForField('exception', 'You must select an option.')
        }

        return !this.hasErrors()
      },

      optionSelected (item) {
        this.clearAllErrors()

        this.priorTreatmentModel.orthoPriorTreatmentPeriodId = item.id
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/OrthoPriorTreatment', this.priorTreatmentModel).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.priorTreatmentModel = response.data
          if (this.priorTreatmentModel.hasProviderSearch) {
            this.$router.push({
              name: 'ortho-prior-treatment-provider',
              params: {
                priorTreatmentOption: this.priorTreatmentModel
              }
            })
          } else {
            this.$router.push('/ortho-prior-treatments')
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      }
    },

    created () {
      this.initializeModel()
    }
  }
</script>

<style scoped>

</style>
