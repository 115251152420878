<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Your annual deductibles.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <benefit-chart :family-total="familyTotal" :family-met="familyMet" :individual-total="individualTotal" :individual-met="individualMet"></benefit-chart>
                <div class="legend">
                    <template v-if="individualTotal">
                        <div class="legend-individual">Individual</div>
                        <div>${{ individualMet }} / ${{ individualTotal }}</div>
                    </template>
                    <template v-if="familyTotal">
                        <div class="legend-family">Family</div>
                        <div>${{ familyMet }} / ${{ familyTotal }}</div>
                    </template>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center mt-4 details-container">
                <div v-if="isCopay">
                    <span class="field-label">Copay Amount: </span> ${{ copayAmount }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="form-row">
            <div class="col-md-12 text-center p-2 mt-2">
                <h2>Learn more about how healthcare costs work</h2>
                <a target="_blank" href="https://healthhere.wistia.com/medias/qd9o91zmzu?wvideo=qd9o91zmzu"><img class="video-thumbnail" src="https://embed-ssl.wistia.com/deliveries/0f5a39502ee324d33e6a9305d16bcdf3.jpg?image_crop_resized=900x506&image_play_button=true&image_play_button_size=2x&image_play_button_color=fda729e0" alt="How Healthcare Costs Work" /></a>
            </div>
        </div>
        <div class="form-row">
            <div class="col-xl-3"></div>
            <div class="col-md-6 text-center pt-4">
                <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import BenefitChart from '../../components/BenefitChart'
  import ErrorsMixin from '../../mixins/ErrorsMixin'

  export default {
    name: 'Benefits',

    components: { BenefitChart },

    mixins: [ErrorsMixin],

    data () {
      return {
        isSaving: false,
        video: null
      }
    },

    computed: {
      isCopay () {
        return this.$store.insuranceBenefits.isCopay
      },

      copayAmount () {
        if (this.$store.insuranceBenefits.copayAmount) {
          return this.$store.insuranceBenefits.copayAmount.toFixed(2)
        }

        return this.$store.insuranceBenefits.copayAmount
      },

      familyTotal () {
        return this.$store.insuranceBenefits.familyDeductible
      },

      familyMet () {
        return this.$store.insuranceBenefits.familyDeductibleMet
      },

      individualTotal () {
        return this.$store.insuranceBenefits.individualDeductible
      },

      individualMet () {
        return this.$store.insuranceBenefits.individualDeductibleMet
      }
    },

    methods: {
      submit () {
        this.isSaving = true

        this.$store.setModuleStatus('Insurance', true, () => {
          this.isSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      },

      playVideo () {
        if (this.video) {
          this.video.play()
        }
      }
    }
  }
</script>

<style scoped>
    .details-container {
        font-size: 20px;
    }

    .field-label {
        font-weight: bolder;
    }

    .legend {
        text-align: center;
        position: absolute;
        top: calc(50% - 55px);
        right: calc(50% - 100px);
        z-index: 3;
        width: 200px;
        height: 150px;
        font-size: 18px;
    }

    .legend-individual {
        color: #ec5c2a;
        font-weight: bold;
    }

    .legend-family {
        color: #e8ad5e;
        font-weight: bold;
    }

    .video-thumbnail {
        width: 100%;
        max-width: 900px;
    }
</style>
