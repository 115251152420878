<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Has anyone in your family had any of these?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
        <list-modal :header-image="require('../../assets/family-history.svg')" :header="modalHeader" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="modalOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import ListModal from '../../components/ListModal'

  export default {
    name: 'FamilyHistory',

    components: { MultiselectList, ListModal },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        familyHistory: {
          diseases: [],
          familyMembers: []
        },
        isSaving: false,
        showListModal: false,
        showingFamilyModal: false,
        selectedDisease: null,
        confirmOptions: [
          {
            id: 1,
            name: 'No medical history',
            selected: false
          },
          {
            id: 2,
            name: 'Let me add family history',
            selected: false
          }
        ]
      }
    },

    computed: {
      options () {
        return this.multiselectOptionsFromArray(this.familyHistory.diseases, 'familyHistoryDiseaseId', 'name', 'otherText', 'hasHad')
      },

      familyMemberOptions () {
        let selectedIds = []
        if (this.selectedDisease) {
          selectedIds = this.selectedDisease.familyMembers.map((member) => {
            return member.familyMemberId
          })
        }
        return this.multiselectOptionsFromArrayWithSelectedIds(this.familyHistory.familyMembers, 'familyMemberId', 'name', selectedIds)
      },

      familyMemberHeader () {
        if (this.selectedDisease) {
          return 'Which family members had ' + this.selectedDisease.name + '?'
        }

        return 'Which family members had this?'
      },

      modalHeader () {
        if (this.showingFamilyModal) {
          return this.familyMemberHeader
        } else {
          return 'Please confirm the patient has no family medical history.'
        }
      },

      modalOptions () {
        if (this.showingFamilyModal) {
          return this.familyMemberOptions
        } else {
          return this.confirmOptions
        }
      },

      selectedOptions () {
        return this.familyHistory.diseases.filter((anOption) => {
          return anOption.hasHad
        })
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/FamilyHistory').then(response => {
          this.familyHistory = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for patient: ' + error.message, error)
          }
        })
      },

      optionSelected (item) {
        const selectedItem = this.familyHistory.diseases.filter((anItem) => {
          return anItem.familyHistoryDiseaseId === item.id
        }).shift()

        if (selectedItem) {
          this.selectedDisease = selectedItem
          if (selectedItem.hasHad === false) {
            // Going to prompt for family members
            this.showingFamilyModal = true
            this.showListModal = true
          }
          selectedItem.hasHad = !selectedItem.hasHad
        }
      },

      modalClosed () {
        if (this.showingFamilyModal) {
          this.showingFamilyModal = false
        } else {
          const confirmed = this.confirmOptions[0].selected === true
          if (confirmed) {
            this.submit(true)
          }
        }
      },

      modalSelection (item) {
        if (this.showingFamilyModal) {
          this.familyMemberSelected(item)
        } else {
          for (const anOption of this.confirmOptions) {
            if (anOption.id === item.id) {
              anOption.selected = true
            } else {
              anOption.selected = false
            }
          }
        }
      },

      familyMemberSelected (item) {
        if (item.selected) {
          // Remove family member
          this.selectedDisease.familyMembers = this.selectedDisease.familyMembers.filter((member) => {
            return member.familyMemberId !== item.id
          })
        } else {
          // Add family member
          const familyMember = this.familyHistory.familyMembers.filter((member) => {
            return member.familyMemberId === item.id
          }).shift()

          if (familyMember) {
            this.selectedDisease.familyMembers.push(familyMember)
          }
        }
      },

      submit (confirmed = false) {
        if (this.validate() !== true) {
          return
        }

        if (this.selectedOptions.length === 0 && confirmed === false) {
          this.showListModal = true
          return
        }

        this.isSaving = true
        api.put('/Patient/FamilyHistory', this.familyHistory.diseases).then(response => {
          this.$store.setModuleStatus('FamilyHistory', true, () => {
            this.isSaving = false
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving family history: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
