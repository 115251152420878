<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What are your allergies?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
        <list-modal :header-image="require('../../assets/allergies.svg')" :header="modalHeader" @optionSelected="modalSelection" @modalClosed="modalClosed" :options="modalOptions" :visible="showListModal" @update:visible="visible => showListModal = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import ListModal from '../../components/ListModal'

  export default {
    name: 'Allergies',

    components: { MultiselectList, ListModal },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        allergyResponse: {
          allergies: [],
          reactionOptions: []
        },
        isSaving: false,
        showListModal: false,
        selectedAllergy: null,
        showingReactionModal: false,
        confirmOptions: [
          {
            id: 1,
            name: 'No known drug allergies',
            selected: false
          },
          {
            id: 2,
            name: 'Let me add allergies',
            selected: false
          }
        ]
      }
    },

    computed: {
      options () {
        const options = this.multiselectOptionsFromArray(this.allergyResponse.allergies, 'allergyId', 'name', 'otherName', 'hasAllergy')
        options.push({
          id: 'other',
          type: 'add',
          name: 'Other',
          selected: false
        })

        return options
      },

      promptReactions () {
        return this.$store.boolForModuleConfig('Allergies', 'PromptReactions', false)
      },

      modalHeader () {
        if (this.showingReactionModal) {
          return this.reactionHeader
        } else {
          return 'Please confirm the patient has no known drug allergies.'
        }
      },

      modalOptions () {
        if (this.showingReactionModal) {
          return this.reactionOptions
        } else {
          return this.confirmOptions
        }
      },

      reactionOptions () {
        let selectedIs = []
        if (this.selectedAllergy) {
          selectedIs = this.selectedAllergy.reactions.map((reaction) => {
            return reaction.allergyReactionId
          })
        }
        return this.multiselectOptionsFromArrayWithSelectedIds(this.allergyResponse.reactionOptions, 'allergyReactionId', 'name', selectedIs)
      },

      reactionHeader () {
        if (this.selectedAllergy) {
          if (this.selectedAllergy.name) {
            return 'What kind of reaction do you have with ' + this.selectedAllergy.name + '?'
          } else if (this.selectedAllergy.otherName) {
            return 'What kind of reaction do you have with ' + this.selectedAllergy.otherName + '?'
          }
        }

        return 'What kind of reaction do you have?'
      },

      selectedOptions () {
        return this.allergyResponse.allergies.filter((anAllergy) => {
          return anAllergy.hasAllergy
        })
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      loadData () {
        api.get('/Patient/Allergies').then(response => {
          this.allergyResponse = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading allergies: ' + error.message, error)
          }
        })
      },

      optionSelected (item) {
        if (item.id === 'other') {

          this.isSaving = true
          api.put('/Patient/Allergies', this.allergyResponse.allergies).then(response => {
            this.$router.push({
              name: 'allergies-search',
              params: { showReactions: this.promptReactions, reactionOptions: this.allergyResponse.reactionOptions }
            })
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$log.error('Error saving allergies: ' + error.message, error)
            }
          }).then(() => {
            this.isSaving = false
          })
          return
        }

        const selectedItem = this.allergyResponse.allergies.filter((anItem) => {
          // Have to match on name because there are a mix of ids
          if (anItem.name) {
            return anItem.name === item.name
          } else {
            return anItem.otherName === item.name
          }

        }).shift()

        if (selectedItem) {
          this.selectedAllergy = selectedItem
          if (selectedItem.hasAllergy === false && this.promptReactions) {
            // Going to prompt for reactions
            this.showingReactionModal = true
            this.showListModal = true
          }
          selectedItem.hasAllergy = !selectedItem.hasAllergy
        }
      },

      modalClosed () {
        if (this.showingReactionModal) {
          this.showingReactionModal = false
        } else {
          const confirmed = this.confirmOptions[0].selected === true
          if (confirmed) {
            this.submit(true)
          }
        }
      },

      modalSelection (item) {
        if (this.showingReactionModal) {
          this.reactionSelected(item)
        } else {
          for (const anOption of this.confirmOptions) {
            if (anOption.id === item.id) {
              anOption.selected = true
            } else {
              anOption.selected = false
            }
          }
        }
      },

      reactionSelected (item) {
        if (item.selected) {
          // Remove reaction
          this.selectedAllergy.reactions = this.selectedAllergy.reactions.filter((member) => {
            return member.allergyReactionId !== item.id
          })
        } else {
          // Add reaction
          const reaction = this.allergyResponse.reactionOptions.filter((member) => {
            return member.allergyReactionId === item.id
          }).shift()

          if (reaction) {
            this.selectedAllergy.reactions.push(reaction)
          }
        }
      },

      submit (confirmed = false) {
        if (this.validate() !== true) {
          return
        }

        if (this.selectedOptions.length === 0 && confirmed === false) {
          this.showListModal = true
          return
        }
        this.isSaving = true
        api.put('/Patient/Allergies', this.allergyResponse.allergies).then(response => {
          this.$store.setModuleStatus('Allergies', true, () => {
            this.$router.push(this.$store.nextRoute())
            this.isSaving = false
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving allergies: ' + error.message, error)
          }
          this.isSaving = false

        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
