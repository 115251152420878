<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Please provide your height and weight.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-6">
                            <b-form-group label="Height" :state="stateForField('height')" :invalid-feedback="errorForField('height')">
                                <b-input-group>
                                    <b-form-input pattern="[0-9]*" @input="feetInput" size="lg" :state="stateForField('height')" :value="heightFeet"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text><strong>feet</strong></b-input-group-text>
                                    </b-input-group-append>
                                    <b-form-input pattern="[0-9]*" @input="inchInput" size="lg" :state="stateForField('height')" :value="heightInches"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text><strong>inches</strong></b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group label="Weight" :state="stateForField('weight')" :invalid-feedback="errorForField('weight')">
                                <b-input-group>
                                    <b-form-input pattern="[0-9]*" @input="clearFieldError('weight')" size="lg" :state="stateForField('weight')" v-model="demographics.weight"></b-form-input>
                                    <b-input-group-append>
                                        <b-input-group-text><strong>pounds</strong></b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import DemographicsMixin from '../../mixins/DemographicsMixin'
  import { BFormGroup, BFormInput, BInputGroup, BInputGroupText, BInputGroupAppend } from 'bootstrap-vue'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'DemographicsHeightWeight',

    components: { BFormGroup, BFormInput, BInputGroup, BInputGroupText, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin, DemographicsMixin, AnalyticsMixin],

    data () {
      return {
        demographics: {},
        isSaving: false
      }
    },

    computed: {
      heightFeet () {
        if (this.demographics.height) {
          return Math.floor(this.demographics.height / 12)
        }

        return ''
      },

      heightInches () {
        if (this.demographics.height) {
          return this.demographics.height % 12
        }

        return ''
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.demographics.height) {
          this.addErrorForField('height', 'Height is required.')
        }

        if (!this.demographics.weight) {
          this.addErrorForField('weight', 'Weight is required.')
        }

        return !this.hasErrors()
      },

      feetInput (value) {
        this.clearFieldError('height')
        if (!value) {
          return
        }

        this.demographics.height = this.heightInches + (parseInt(value) * 12)
      },

      inchInput (value) {
        this.clearFieldError('height')
        if (!value) {
          return
        }

        let inchInput = parseInt(value)
        if (inchInput > 11) {
          inchInput = 11
        }
        this.demographics.height = (this.heightFeet * 12) + inchInput
      },

      loadData () {
        api.get('/Patient/Demographics').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.demographics = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for loading demographics: ' + error.message, error)
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/Demographics', this.demographics).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          if (this.promptAdditional) {
            this.isSaving = false
            this.$router.push('/demographics-additional')
          } else if (this.promptAdditionalPhone) {
            this.isSaving = false
            this.$router.push('/demographics-phone')
          } else {
            this.$store.setModuleStatus('Demographics', true, () => {
              this.isSaving = false
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.$log.error('Error saving demographics: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
