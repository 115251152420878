<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">How did you hear about us?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :options="options" :is-selected="isOptionSelected" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'HowDidYouHearAboutUs',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        hdyhauResponse: {
          primaryPhysician: '',
          howDidYouHearAboutUsOptionId: 0,
          howDidYouHearAboutUsText: '',
          options: []
        },
        selectedOption: {
          howDidYouHearAboutUsOptionId: 0
        },
        isSaving: false
      }
    },

    computed: {
      options () {
        let options = []
        if (this.hdyhauResponse.options && this.hdyhauResponse.options.length > 0) {
          options = this.hdyhauResponse.options.map((option) => {
            return {
              id: option.howDidYouHearAboutUsOptionId,
              type: 'item',
              name: option.name,
              selected: this.hdyhauResponse.howDidYouHearAboutUsOptionId === option.howDidYouHearAboutUsOptionId
            }
          })
        }

        return options
      }
    },

    methods: {

      loadData () {
        api.get('/Patient/HowDidYouHearAboutUs').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.hdyhauResponse = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      isOptionSelected (item) {
        if (item.id === this.selectedOption.howDidYouHearAboutUsOptionId) {
          return true
        }

        return false
      },

      optionSelected (item) {
        this.clearFieldError('exception')

        const anOption = this.hdyhauResponse.options.filter((anOption) => {
          return anOption.howDidYouHearAboutUsOptionId === item.id
        }).shift()

        if (anOption) {
          this.selectedOption = anOption
        }

      },

      submit () {
        if (this.selectedOption.howDidYouHearAboutUsOptionId > 0) {
          this.isSaving = true

          api.put('/Patient/HowDidYouHearAboutUs', this.selectedOption).then(response => {
            this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
            this.hdyhauResponse = response.data
            if (this.selectedOption.hasText) {
              // Get details
              this.$store.setModuleStatus('HowDidYouHearAboutUs', true, () => {
                this.$router.push(this.$store.nextRoute())
                this.isSaving = false
              })
            } else if (this.selectedOption.hasProviderSearch) {
              // Go to provider search
              this.$router.push('/referring-physician')
              this.isSaving = false
            } else {
              // Save and continue
              this.$store.setModuleStatus('HowDidYouHearAboutUs', true, () => {
                this.$router.push(this.$store.nextRoute())
                this.isSaving = false
              })
            }
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.setFromApiErrorResponse(error)
            }
          })
        } else {
          this.addErrorForField('exception', 'You must select an option')
        }
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
