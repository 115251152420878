<template>
  <div class="container-fluid">
    <div v-if="logoUrl" class="row">
      <div class="col text-center">
        <img class="logo-image" :src="logoUrl">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h1 class="header">Success!</h1>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-md-6 text-center pt-4">
        <img class="success-check"  src="../../assets/green_checkmark_circle.png">
      </div>
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <br />
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-md-6 text-center pt-4">
        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto my-4"><font-awesome-icon class="mr-2" v-show="isLoadingReceipt" icon="spinner" pulse/>View Receipt</button>

      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import { saveAs } from 'file-saver'

  export default {
    name: 'PaymentSuccess',

    components: {},

    mixins: [ErrorsMixin],

    props: {
      paymentAmount: {
        default: null
      },
      logoUrl: {
        default: ''
      },
      token: {
        default: ''
      }
    },

    data () {
      return {
        orgName: this.$store.orgName,
        isSaving: false,
        isLoadingReceipt: false
      }
    },

    computed: {
      selectedPaymentAmount () {
        if (this.$props.paymentAmount) {
          return this.$props.paymentAmount
        }
        return 0
      }
    },

    methods: {
      validate () {
        return !this.hasErrors()
      },

      submit () {
        const self = this
        this.isLoadingReceipt = true

        api.get('/Patient/Payment/Receipt/' + self.token, {
          responseType: 'blob',
          headers: { 'Content-Type': 'application/pdf' }
        }).then(response => {
          saveAs(response.data, 'Receipt.pdf')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to load receipt: ' + error.message, error)
            this.addErrorForField('exception', error.message)
          }
        }).then(() => {
          // always executed
          this.isLoadingReceipt = false
        })
      },

      numberFromString (value) {
        if (value) {
          value = value.replace(',', '')
          return Number.parseFloat(value)
        }

        return 0
      },

      formatNumberString (value) {
        let number = 0.0
        if (typeof value === 'string') {
          value = value.replace(',', '')
          number = this.numberFromString(value)
        } else {
          number = value
        }

        if (number) {
          return this.formatNumber(number)
        }

        return number
      },

      formatNumber (number) {
        if (number) {
          return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }

        return '0.00'
      }
    }
  }
  </script>
  <style scoped>
  .numberCircleOrange {
    height: 9em;
    width: 9em;
    border: 2px solid #fb3709;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .col-centered {
    float: none;
    margin: 0 auto;
  }

  .detail {
    margin: 15px;
    font-size: 20px;
    line-height: 1.6;
    text-align: center;
  }

  .price {
    margin: 15px;
    font-size: 1.6em;
    line-height: 1.6;
    font-weight: 800;
  }
  .success-check {
    max-height: 140px;
    max-width: 49.9999%;
  }
  .logo-image {
    max-height: 140px;
    max-width: 49.9999%;
  }
  </style>
