<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Which type of surgery?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <multiselect-list :is-selected="isOptionSelected" :options="options" @optionSelected="optionSelected"></multiselect-list>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
            </div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import MultiselectList from '../../components/MultiselectList'

  export default {
    name: 'SurgeryCategories',

    components: { MultiselectList },

    mixins: [ErrorsMixin, OptionsMixin],

    data () {
      return {
        surgeriesResponse: {
          currentSurgeries: [],
          categories: []
        },
        selectedCategory: {
          surgeryCategoryId: 0
        },
        isSaving: false
      }
    },

    computed: {
      options () {
        return this.multiselectOptionsFromArray(this.surgeriesResponse.categories, 'surgeryCategoryId', 'name', '', 'nope')
      }
    },

    methods: {
      isOptionSelected (item) {
        if (item.id === this.selectedCategory.surgeryCategoryId) {
          return true
        }

        return false
      },

      loadData () {
        api.get('/Patient/Surgeries').then(response => {
          this.surgeriesResponse = response.data
          this.$store.sideOptions = this.surgeriesResponse.sides
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error loading surgeries: ' + error.message, error)
          }
        })
      },

      optionSelected (item) {
        this.clearFieldError('exception')
        if (item.id === 'other') {
          this.selectedCategory = item
        } else {
          const category = this.surgeriesResponse.categories.filter((aCategory) => {
            return aCategory.surgeryCategoryId === item.id
          }).shift()

          if (category) {
            this.selectedCategory = category
          }
        }
      },

      submit () {
        if (this.selectedCategory.surgeryCategoryId > 0) {
          this.isSaving = true
          if (this.selectedCategory.surgeryCategoryId === this.$store.surgeryCategoryOtherId) {
            this.$router.push('/surgery-other')
            this.isSaving = false
          } else {
            this.$router.push({ name: 'surgery-list', params: { surgeryCategory: this.selectedCategory } })
            this.isSaving = false
          }
        } else {
          this.addErrorForField('exception', 'You must select a category')
        }
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>

</style>
