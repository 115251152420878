import { render, staticRenderFns } from "./FluVaccine.vue?vue&type=template&id=210ce5f4&scoped=true"
import script from "./FluVaccine.vue?vue&type=script&lang=js"
export * from "./FluVaccine.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210ce5f4",
  null
  
)

export default component.exports