<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div v-if="logoUrl" class="row">
                    <div class="col text-center"><img class="logo-image py-1" :src="logoUrl"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div v-if="headshotUrl" class="row">
                    <div class="col text-center"><img class="provider-image py-2" :src="headshotUrl"></div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="row" style="padding-bottom: 15px;">
                    <div class="col text-center">
                        <span class="detail">{{ thanksSentence }}</span>
                    </div>
                </div>
                <div class="row" style="padding-bottom: 25px;">
                    <div class="col-3"></div>
                    <div class="col-6 text-center">
                        <span class="detail">- {{ providerName }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <button type="submit" @click.prevent="next()" class="btn btn-primary btn-lg mx-auto"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Check in now</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ProviderThanks',

    data () {
      return {
        isSaving: false
      }
    },

    computed: {
      thanksSentence () {
        return 'Thank you for entrusting me with your care.'
      },

      providerName () {
        const first = this.$store.providerFirstName
        const last = this.$store.providerLastName
        const cred = this.$store.providerCredential
        let name = first + ' ' + last

        if (cred) {
          name += ', ' + cred
        }

        return name
      },

      headshotUrl () {
        return this.$store.headshotUrl
      },

      logoUrl () {
        return this.$store.orgLogoUrl
      },

      orgName () {
        return this.$store.orgName
      }
    },

    methods: {
      next () {
        this.isSaving = true
        this.$store.setModuleStatus('ProviderThanks', true, () => {
          this.isSaving = false
          this.$router.push(this.$store.nextRoute())
        })
      }
    }
  }
</script>

<style scoped>
    .appointment-image {
      max-height: 140px;
    }

    .logo-image {
        max-height: 100px;
        max-width: 49.9999%;
    }

    .provider-image {
        max-height: 200px;
        max-width: 75.9999%;
    }

    .detail {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.6;
      width: 20%;
    }
</style>
