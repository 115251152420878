<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">What date did this occur?</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <form @submit.prevent="submit()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <b-form-group label="MM/DD/YYYY" :state="stateForField('injuryOnsetDate')" :invalid-feedback="errorForField('injuryOnsetDate')">
                                <date-input :current-date="orthoReasonForVisit.injuryOnsetDate" @valid="onsetDateUpdated" @invalid="onsetDateInvalid" @change="onsetDateChanged"/>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="form-row mt-4">
                        <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Next</button>
                    </div>
                </form>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import OrthoMixin from '../../mixins/OrthoMixin'
  import { BFormGroup } from 'bootstrap-vue'
  import DateInput from '../../components/DateInput'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'OrthoOnsetDate',

    components: { BFormGroup, DateInput },

    mixins: [ErrorsMixin, OptionsMixin, OrthoMixin, AnalyticsMixin],

    data () {
      return {
        isSaving: false,
        orthoReasonForVisit: {
          injuryOnsetDate: ''
        }
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        if (!this.orthoReasonForVisit.injuryOnsetDate) {
          this.addErrorForField('injuryOnsetDate', 'A date is required')
        }

        return !this.hasErrors()
      },

      onsetDateUpdated (date) {
        this.clearFieldError('injuryOnsetDate')
        this.orthoReasonForVisit.injuryOnsetDate = date
      },

      onsetDateInvalid (date) {
        this.addErrorForField('injuryOnsetDate', 'Invalid date')
      },

      onsetDateChanged (injuryOnsetDate) {
        this.clearFieldError('injuryOnsetDate')
        if (injuryOnsetDate instanceof Date && !isNaN(injuryOnsetDate)) {
          this.orthoReasonForVisit.injuryOnsetDate = injuryOnsetDate

        }
      },

      submit () {
        if (this.validate() !== true) {
          return
        }

        this.isSaving = true
        api.put('/Patient/OrthoReasonForVisit', this.orthoReasonForVisit).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$router.push('ortho-details')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
            this.setFromApiErrorResponse(error)
          }
        }).then(() => {
          this.isSaving = false
        })
      },

      loadData () {
        api.get('/Patient/OrthoReasonForVisit').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.orthoReasonForVisit = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 2px;
        background: rgba(23,108,198,1.0);
        outline: none;
        cursor: pointer;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 40px;
        height: 40px;
        background: rgba(255,79,0,1.0);
        cursor: pointer;
        border-radius: 20px;
        z-index: 15;
    }

    .slider::-moz-range-thumb {
        width: 40px;
        height: 40px;
        background: rgba(255,79,0,1.0);
        cursor: pointer;
        border-radius: 20px;
        z-index: 15;
    }

    .sliderticks {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }

    .sliderticks p {
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 1px;
        font-size: 24px;
        font-weight: bold;
        height: 10px;
        line-height: 40px;
        margin: 10px 0 20px 0;
        color: rgba(255,79,0,1.0);
        cursor: pointer;
    }
</style>
