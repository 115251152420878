<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Start typing the name of an allergen to search.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm" @click.prevent="selectMedication(searchTerm)" class="result">
                        <h1 class="text-center">{{ searchTerm }}</h1>
                    </div>
                    <div @click.prevent="selectMedication(medication)" v-for="(medication,index) in searchResults" :key="index" class="result">
                        <h1 class="text-center">{{ medication }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <list-modal :header-image="require('../../assets/allergies.svg')"
                    :header="reactionHeader"
                    @optionSelected="reactionSelected"
                    @modalClosed="reactionsClosed"
                    :options="reactionOptionList"
                    :visible="showReactionList"
                    @update:visible="visible => showReactionList = visible"></list-modal>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'
  import ListModal from '../../components/ListModal'

  export default {
    name: 'AllergiesSearch',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, ListModal },

    mixins: [ErrorsMixin, OptionsMixin],

    props: {
      showReactions: {
        type: Boolean,
        default: false
      },

      reactionOptions: {
        type: Array,
        required: false
      }
    },

    data () {
      return {
        searchResults: {},
        searchTerm: '',
        isSaving: false,
        selectedAllergyName: '',
        showReactionList: false,
        selectedReactions: []
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    computed: {
      reactionOptionList () {
        let selectedIs = []
        if (this.selectedReactions) {
          selectedIs = this.selectedReactions.map((reaction) => {
            return reaction.allergyReactionId
          })
        }

        return this.multiselectOptionsFromArrayWithSelectedIds(this.reactionOptions, 'allergyReactionId', 'name', selectedIs)
      },

      reactionHeader () {
        if (this.selectedAllergyName) {
          return 'What kind of reaction do you have with ' + this.selectedAllergyName + '?'
        }

        return 'What kind of reaction do you have?'
      }
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      performSearch () {
        if (!this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/Medications/Search?query=' + this.searchTerm).then(response => {
          this.searchResults = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error searching for medication: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        })
      },

      showReactionModal () {
        this.showReactionList = true
      },

      reactionSelected (item) {
        if (item.selected) {
          // Remove reaction
          this.selectedReactions = this.selectedReactions.filter((aReaction) => {
            return aReaction.allergyReactionId !== item.id
          })
        } else {
          // Add reaction
          const reaction = this.reactionOptions.filter((aReaction) => {
            return aReaction.allergyReactionId === item.id
          }).shift()

          if (reaction) {
            this.selectedReactions.push(reaction)
          }
        }
      },

      reactionsClosed () {
        this.saveAllergy()
      },

      selectMedication (medication) {
        this.isSaving = true
        medication = medication.toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')
        this.selectedAllergyName = medication

        if (this.showReactions) {
          this.showReactionModal()
        } else {
          this.saveAllergy()
        }
      },

      saveAllergy () {
        this.isSaving = true

        const newAllergy = {
          otherName: this.selectedAllergyName,
          hasAllergy: true,
          reactions: this.selectedReactions
        }

        api.put('/Patient/Allergy', newAllergy).then(response => {
          this.$router.push('allergies')
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error saving allergy: ' + error.message, error)
            if (error.response && error.response.data) {
              this.setErrorsFromResponse(error.response.data)
            } else if (error.message) {
              this.addErrorForField('exception', error.message)
            }
          }
        }).then(() => {
          this.isSaving = false
        })
      }

    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
        padding: .25em;
    }
    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgba(255,62,3,1.0);
        }
    }
</style>
