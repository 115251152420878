<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Upload photos of your {{insuranceType}} insurance card</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col text-center">
            <p>Having the correct insurance information on file is important for your care.</p>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" v-if="formatError === true">Please select only JPG files</div>
        <div class="row">
          <div class="col text-center mt-2">
            <div>
              <span class="insurance-images">
                <img class="photo-preview-container mb-4" v-if="frontImagePreview" id="preview" alt="" :src="frontImagePreview" />
                <img v-if="frontImageUploaded === false" class="preview-card-image mb-4" src="../../assets/insurance-front.svg">
                <img class="photo-preview-container mb-4" v-if="backImagePreview" id="preview" alt="" :src="backImagePreview" />
                <img v-if="frontImageUploaded === true && !frontImagePreview && backImageUploaded === false && isSaving === false" class="preview-card-image mb-4" src="../../assets/insurance-back.svg">
              </span><br>
              <div class="button-container">
              <div v-if="showFrontImage"><label for="file-upload" class="custom-file-upload">
                {{frontImageUploaded === true ? "Change" : "Select"}} Front Image
              </label>
              <input
                  id="file-upload"
                  type="file"
                  accept="image/jpeg"
                  @change="onFileChangeFront"/>
              </div>
             <div v-if="showBackImage && isSaving === false"> <label for="file-upload" class="custom-file-upload">
                {{backImageUploaded === true ? "Change" : "Select"}} Back Image
              </label>
              <input
                  id="file-upload"
                  type="file"
                  accept="image/jpeg"
                  @change="onFileChangeBack"/>
             </div>
              <button v-if="frontImageUploaded !== true" type="button" class="btn btn-primary btn-lg mx-auto" @click.prevent="onSkipInsuranceUpload">Skip</button>
                <button v-if="showConfirmButton === true" type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto mt-2" style="width: 40%"><font-awesome-icon class="mr-2" v-show="isSaving" icon="spinner" pulse/>Confirm</button>
            </div>
              </div>
            </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
  </div>
</template>
<script>

  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'UploadFile',
    mixins: [ErrorsMixin, AnalyticsMixin],
    props: {
      captureOnly: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        backImage: {},
        backImagePreview: '',
        backImageUploaded: false,
        frontImageUrl: {},
        frontImageComplete: false,
        frontImage: {},
        frontImagePreview: '',
        frontImageUploaded: false,
        isSaving: false,
        showBackImage: false,
        showConfirmButton: false,
        showFrontImage: true,
        isClassificationRunning: false,
        formatError: false
      }
    },
    computed: {
      insuranceType () {
        return this.$store.insurance.insurancePriority
      }
    },

    methods: {
      onFileChangeFront (e) {
        const file = e.target.files[0]
        if (e.target.files[0].type === 'image/jpeg') {
          this.frontImagePreview = URL.createObjectURL(file)
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.frontImage = e.target.result
          }
          this.formatError = false
          this.frontImageUploaded = true
          this.showConfirmButton = true
        } else {
          this.formatError = true
        }
      },
      onFileChangeBack (e) {
        const file = e.target.files[0]
        if (e.target.files[0].type === 'image/jpeg') {
          this.backImagePreview = URL.createObjectURL(file)
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.backImage = e.target.result
          }
          this.formatError = false
          this.backImageUploaded = true
          this.showConfirmButton = true
        } else {
          this.formatError = true
        }
      },

      uploadFrontImage () {
        if (this.frontImage === null) {
          return
        }
        const imageFile = new File([this.dataURItoBlob(this.frontImage)], 'insurance-card-front.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)
        const priority = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)
        if (this.captureOnly) {
          api.put('/Patient/InsuranceUploadRaw?insurancePriority=' + priority + '&cardSide=front', multipartForm).then(response => {
            this.trackEvent(this.$options.name, 'UploadFront', 'ResponseTime', response.config.requestDuration)
            this.$store.insurance.insuranceCardFront = response.data.url
            this.frontImage = response.data.url
            this.frontImageComplete = true
            this.showConfirmButton = false
            this.isSaving = false
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.trackEvent(this.$options.name, 'SaveError', 'CardImageNotSaved')
              this.$log.error('Error uploading front card image: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          })
        } else {
          this.isClassificationRunning = true
          api.put('/Patient/InsuranceUploadRawAndClassify?insurancePriority=' + priority + '&cardSide=front', multipartForm).then(response => {
            this.trackEvent(this.$options.name, 'UploadAndClassifyFront', 'ResponseTime', response.config.requestDuration)
            this.$store.insuranceBenefits = response.data
            this.$store.insurance.insuranceCardFront = response.data.insuranceFrontPhotoUrl
            this.frontImage = response.data.insuranceFrontPhotoUrl
            this.trackEvent(this.$options.name, 'ClassificationResult', response.data.classifierSuccess)
            this.frontImageComplete = true
            this.showConfirmButton = false
            this.isSaving = false
            if (this.userConfirmed === true) {
              this.continueModule()
            }
          }).catch(error => {
            if (!this.$store.canHandleNetworkError(error)) {
              this.$store.logErrorResponse(this.$options.name, 'SaveError', error)
              this.trackEvent(this.$options.name, 'SaveError', 'CardImageNotSaved')
              this.$log.error('Error uploading front card image: ' + error.message, error)
              this.setFromApiErrorResponse(error)
            }
          }).finally(() => {
            this.isClassificationRunning = false
          })
        }
      },

      uploadBackImage () {
        if (this.backImage === null) {
          return
        }
        const imageFile = new File([this.dataURItoBlob(this.backImage)], 'insurance-card-back.jpeg', { type: 'image/jpeg', lastModified: Date.now() })
        const multipartForm = new FormData()
        multipartForm.append('file', imageFile)

        const priority = this.$store.mapInsurancePriorityId(this.$store.insurance.insurancePriority)
        api.put('/Patient/InsuranceUploadRaw?insurancePriority=' + priority + '&cardSide=back', multipartForm).then(response => {
          this.trackEvent(this.$options.name, 'UploadBack', 'ResponseTime', response.config.requestDuration)
          this.$store.insurance.insuranceCardBack = response.data.url
          this.backImage = response.data.url
          this.isSaving = false
          this.continueModule()
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'UploadBackError', error)
            this.trackEvent(this.$options.name, 'UploadBackError', 'CardImageNotSaved')
            this.$log.error('Error saving insurance card image: ' + error.message, error)
            this.setFromApiErrorResponse(error)
          }
        })
      },
      continueModule () {
        this.$router.push({ name: 'card-capture-verification', params: { captureOnly: this.captureOnly, frontImage: this.frontImage, backImage: this.backImage } })
      },
      onSkipInsuranceUpload () {
        if (this.captureOnly === false) {
          this.$router.push('/payor-select')
        } else {
          this.$store.setModuleStatus('Insurance', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }
      },
      dataURItoBlob (dataURI) {
        // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
        // convert base64/URLEncoded data component to raw binary data held in a string

        if (!dataURI) {
          return
        }

        let byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
      },

      submit () {
        this.isSaving = true
        if (this.frontImageComplete === false) {
          this.uploadFrontImage()
          this.showFrontImage = false
          this.showBackImage = true
          this.frontImagePreview = ''
        } else {
          this.uploadBackImage()
        }
      }
    }
  }
</script>

<style scoped>
img{
  max-width: 350px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  color: #fff;
  background-color: rgb(23, 56, 96);
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
img {
 max-height: 200px;
}
</style>
