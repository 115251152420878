import { render, staticRenderFns } from "./OrthoSeverity.vue?vue&type=template&id=079b2c64&scoped=true"
import script from "./OrthoSeverity.vue?vue&type=script&lang=js"
export * from "./OrthoSeverity.vue?vue&type=script&lang=js"
import style0 from "./OrthoSeverity.vue?vue&type=style&index=0&id=079b2c64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079b2c64",
  null
  
)

export default component.exports