<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div @click.prevent="selectItem(option)" v-for="option in options" :key="option.id" class="option">
                        <template v-if="option.type === 'add'">
                            <img class="radio-button" src="../assets/add-button.svg">
                        </template>
                        <template v-else-if="option.type === 'edit'">
                            <img class="radio-button" src="../assets/edit-button.svg">
                        </template>
                        <template v-else>
                            <img v-if="isItemSelected(option)" class="radio-button" src="../assets/radio-selected.svg">
                            <img v-if="!isItemSelected(option)" class="radio-button" src="../assets/radio-unselected.svg">
                        </template>
                        <div class="title" :class="classForTitle(option.name)">{{ option.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'MultiselectList',

    props: {
      options: {
        type: Array,
        required: true
      },
      isSelected: {
        type: Function,
        required: false
      }
    },

    data () {
      return {
        isSaving: false
      }
    },

    methods: {
      classForTitle (title) {
        if (!title) {
          return ''
        }

        if (screen.width <= 375 || title.length >= 20) {
          return 'two-line'
        }

        return ''
      },

      selectItem (item) {
        this.$emit('optionSelected', item)
      },

      isItemSelected (item) {
        if (this.isSelected) {
          return this.isSelected(item)
        } else {
          return item.selected
        }
      }
    }
  }
</script>

<style scoped>

    .option {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 400px;
        min-height: 72px;
        max-height: 100%;
        border: 2px solid transparent;
        clear: both;
        vertical-align: center;
    }

    .title {
        margin-left: 8px;
        display: inline-block;
        font-size: 20px;
        color: rgba(23,56,96,1.0);
        font-weight: bold;
        padding: .25em;
        max-width: 310px;
        /* max-height: 50px; */
        float: left;
        padding-top: 20px;
        overflow: hidden;

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    @media (max-width: 320px) {
        .title {
            width: 180px;
        }
    }

    @media (min-width: 321px) and (max-width: 375px) {
        .title {
            width: 220px;
        }
    }

    @media (min-width: 376px) and (max-width: 416px) {
        .title {
            width: 230px;
        }
    }

    .title.two-line {
        padding-top: 2px;
        max-height: 100%;
    }

    .option img {
        margin-top: 8px;
        margin-left: 8px;
        height: 52px;
        width: 52px;
        display: inline-block;
        float: left;
    }

    @media (min-width: 1061px) {
        .option:HOVER {
            cursor: pointer;
            border: 2px solid rgba(255,19,52,1.0);
        }
    }
</style>
