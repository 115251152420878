<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Verify the photos of your {{insuranceType}} insurance card</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="col-md-12 mt-2 text-center">
          <img class="preview-image" v-if="frontImage" :src="frontImage">
          <img class="default-preview-image" v-if="!frontImage" src="../../assets/insurance-front.svg">
        </div>
        <div class="col-md-12 mt-2 text-center">
          <img class="default-preview-image" v-if="!backImage" src="../../assets/insurance-back.svg">
          <img class="preview-image" v-if="backImage" :src="backImage">
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col">
      <div class="col-md-12 mt-2 text-center">
        <button type="button" class="btn btn-primary btn-lg mx-auto" @click="retakePhotos">Change Photos</button>
       </div>
          <div class="col-md-12 mt-2 text-center">
            <button type="button" class="btn btn-primary btn-lg mx-auto" @click="continueModule">Use These Photos</button>
     </div>
      </div>
    </div>

  </div>
</template>

<script>
  import ErrorsMixin from '@/mixins/ErrorsMixin'

  export default {
    name: 'CardCaptureVerification',

    mixins: [ErrorsMixin],

    props: {
      captureOnly: {
        type: Boolean,
        default: false
      },
      frontImage: {
        type: String
      },
      backImage: {
        type: String
      }
    },

    computed: {
      insuranceType () {
        return this.$store.insurance.insurancePriority
      }
    },

    methods: {
      continueModule () {
        if (this.captureOnly) {
          if (this.$store.insurance.insurancePriority === 'secondary') {
            this.$store.setModuleStatus('Insurance', true, () => {
              this.$router.push(this.$store.nextRoute())
            })
          } else {
            this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
          }
        } else {
          if (this.$store.insurance.insurancePriority === 'secondary') {
            if (this.$store.insuranceBenefits.insuranceEligibilityStatus === 'Verified') {
              this.$store.setModuleStatus('Insurance', true, () => {
                this.$router.push(this.$store.nextRoute())
              })
            } else {
              this.$router.push({ name: 'payor-select' })
            }
          } else {
            if (this.$store.insuranceBenefits.insuranceEligibilityStatus === 'Verified') {
              this.$router.push({ name: 'insurance', params: { priority: 'secondary' } })
            } else {
              this.$router.push({ name: 'payor-select' })
            }
          }
        }
      },

      retakePhotos () {
        this.$router.push({ name: 'camera-access' })
      }
    }
  }
</script>

<style scoped>
h3 {
  font-weight: bold;
  color: #ffffff;
  margin-top: 30px;
}
.container-fluid {
  padding-right: 0
}
.row {
  margin-right: 0
}
.header {
  margin-bottom: 30px;
}
.preview-image {
  max-height: 200px;
}
.default-preview-image {
  max-height: 125px;
}
</style>
