import Vue from 'vue'
import Router from 'vue-router'
import PatientSearch from '../views/PatientSearch'
import store from './store'
import api from './api'
import { UAParser } from 'ua-parser-js'
import Authorize from '../views/Authorize'
import Demographics from '../views/Demographics/Demographics'
import DemographicsRace from '../views/Demographics/DemographicsRace'
import DemographicsAdditional from '../views/Demographics/DemographicsAdditional'
import DemographicsPhone from '../views/Demographics/DemographicsPhone.vue'
import PatientPhoto from '../views/PatientPhoto/PatientPhoto'
import VisitPhoto from '../views/VisitPhoto/VisitPhoto'
import Address from '../views/Address/Address'
import DriversLicenseCapture from '../views/DriversLicenseCapture/DriversLicenseCapture'
import PayorSelect from '../views/Insurance/PayorSelect'
import PayorSearch from '../views/Insurance/PayorSearch'
import CardCapture from '../views/Insurance/CardCapture.vue'
import CardCaptureVerification from '../views/Insurance/CardCaptureVerification.vue'
import PolicyNumber from '../views/Insurance/PolicyNumber'
import AppointmentConfirmation from '../views/AppointmentConfirmation'
import FamilyHistory from '../views/FamilyHistory/FamilyHistory'
import Allergies from '../views/Allergies/Allergies'
import MedicalHistory from '../views/MedicalHistory/MedicalHistory'
import Medications from '../views/Medications/Medications'
import MedicationSearch from '../views/Medications/MedicationSearch'
import Benefits from '../views/Insurance/Benefits'
import SocialHistory from '../views/SocialHistory/SocialHistory'
import TobaccoUsage from '../views/SocialHistory/TobaccoUsage'
import AlcoholUsage from '../views/SocialHistory/AlcoholUsage'
import DrugUsage from '../views/SocialHistory/DrugUsage'
import PreCheckComplete from '../views/PreCheckComplete'
import BodyRegions from '../views/OrthoReasonForVisit/BodyRegions'
import BodyParts from '../views/OrthoReasonForVisit/BodyParts'
import PrimaryBodyPart from '../views/OrthoReasonForVisit/PrimaryBodyPart'
import OrthoPainType from '../views/OrthoReasonForVisit/OrthoPainType'
import OrthoPainCause from '../views/OrthoReasonForVisit/OrthoPainCause'
import OrthoLawyer from '../views/OrthoReasonForVisit/OrthoLawyer'
import OrthoSeverity from '../views/OrthoReasonForVisit/OrthoSeverity'
import OrthoBetter from '../views/OrthoReasonForVisit/OrthoBetter'
import OrthoWorse from '../views/OrthoReasonForVisit/OrthoWorse'
import OrthoOnset from '../views/OrthoReasonForVisit/OrthoOnset'
import OrthoOnsetDate from '../views/OrthoReasonForVisit/OrthoOnsetDate'
import OrthoDetails from '../views/OrthoReasonForVisit/OrthoDetails'
import OrthoPriorTreatments from '../views/OrthoReasonForVisit/OrthoPriorTreatments'
import OrthoPriorTreatmentPeriod from '../views/OrthoReasonForVisit/OrthoPriorTreatmentPeriod'
import PharmacySearch from '../views/Pharmacy/PharmacySearch'
import PharmacySelect from '../views/Pharmacy/PharmacySelect'
import ReviewOfSystems from '../views/ReviewOfSystems/ReviewOfSystems'
import AllergiesSearch from '../views/Allergies/AllergiesSearch'
import Surgeries from '../views/Surgeries/Surgeries'
import SurgeryCategories from '../views/Surgeries/SurgeryCategories'
import SurgeryOther from '../views/Surgeries/SurgeryOther'
import SurgeryList from '../views/Surgeries/SurgeryList'
import SurgeryDetails from '../views/Surgeries/SurgeryDetails'
import HowDidYouHearAboutUs from '../views/HowDidYouHearAboutUs/HowDidYouHearAboutUs'
import HowDidYouHearAboutUsReferral from '../views/HowDidYouHearAboutUs/HowDidYouHearAboutUsReferral'
import ReferringPhysician from '../views/HowDidYouHearAboutUs/ReferringPhysician'
import PrimaryPhysician from '../views/PrimaryPhysician/PrimaryPhysician'
import Spouse from '../views/MarriageStatus/Spouse'
import EmergencyContact from '../views/MarriageStatus/EmergencyContact'
import VisitGuarantor from '../views/VisitGuarantor/VisitGuarantor'
import VisitGuarantorAddress from '../views/VisitGuarantor/VisitGuarantorAddress'
import Consent from '../views/Consent/Consent'
import ConsentItemDetail from '../views/Consent/ConsentItemDetail'
import ConsentSignature from '../views/Consent/ConsentSignature'
import Insurance from '../views/Insurance/Insurance'
import PatientPaymentSearch from '../views/PatientPaymentSearch'
import OrthoPriorTreatmentProvider from '../views/OrthoReasonForVisit/OrthoPriorTreatmentProvider'
import OrthoPainCharacteristics from '../views/OrthoReasonForVisit/OrthoPainCharacteristics'
import OrthoSplint from '../views/OrthoReasonForVisit/OrthoSplint'
import OrthoShoulderNeck from '../views/OrthoReasonForVisit/OrthoShoulderNeck'
import OrthoShoulderElbow from '../views/OrthoReasonForVisit/OrthoShoulderElbow'
import OrthoShoulderTingling from '../views/OrthoReasonForVisit/OrthoShoulderTingling'
import OrthoShoulderDislocation from '../views/OrthoReasonForVisit/OrthoShoulderDislocation'
import OrthoKnee from '../views/OrthoReasonForVisit/OrthoKnee'
import OrthoKneeDistance from '../views/OrthoReasonForVisit/OrthoKneeDistance'
import OrthoKneePain from '../views/OrthoReasonForVisit/OrthoKneePain'
import Exercise from '../views/SocialSetting/Exercise'
import DailyActivity from '../views/SocialSetting/DailyActivity'
import VisitPayment from '../views/Payment/VisitPayment'
import VisitCreditCardPayment from '../views/Payment/VisitCreditCardPayment'
import PharmacyConfirmation from '../views/Pharmacy/PharmacyConfirmation'
import DemographicsHeightWeight from '../views/Demographics/DemographicsHeightWeight'
import Falls from '../views/Senior/Falls'
import PneumoniaVaccine from '../views/Senior/PneumoniaVaccine'
import Employment from '../views/Employment/Employment'
import Occupation from '../views/Employment/Occupation'
import Employer from '../views/Employment/Employer'
import EmployerList from '../views/Employment/EmployerList'
import EmployerConfirm from '../views/Employment/EmployerConfirm'
import HIPAAContacts from '../views/HIPAAContacts/HIPAAContacts'
import HIPAAContact from '../views/HIPAAContacts/HIPAAContact'
import ConservativeTherapies from '../views/ConservativeTherapy/ConservativeTherapies'
import ConservativeTherapyDetails from '../views/ConservativeTherapy/ConservativeTherapyDetails'
import TherapyMedicationSearch from '../views/ConservativeTherapy/TherapyMedicationSearch'
import FluVaccine from '../views/FluVaccine/FluVaccine'
import LivingSetting from '../views/SocialSetting/LivingSetting'
import OrthoShoulderDifficultyReaching from '../views/OrthoReasonForVisit/OrthoShoulderDifficultyReaching'
import OrthoShoulderCortisone from '../views/OrthoReasonForVisit/OrthoShoulderCortisone'
import OrthoShoulderPainWake from '../views/OrthoReasonForVisit/OrthoShoulderPainWake'
import OrthoRecurring from '../views/OrthoReasonForVisit/OrthoRecurring'
import OrthoBackLegPain from '../views/OrthoReasonForVisit/OrthoBackLegPain'
import OrthoBackNeckPain from '../views/OrthoReasonForVisit/OrthoBackNeckPain'
import OrthoBackLoseControl from '../views/OrthoReasonForVisit/OrthoBackLoseControl'
import OrthoFootOrthotics from '../views/OrthoReasonForVisit/OrthoFootOrthotics'
import OrthoFootSize from '../views/OrthoReasonForVisit/OrthoFootSize'
import Blocker from '../views/Blocker/Blocker'
import VisitBlocked from '../views/VisitBlocked'
import VirtualVisit from '../views/VirtualVisit'
import WorkersCompPrompt from '../views/WorkersCompPrompt/WorkersCompPrompt'
import WorkersCompBlock from '../views/WorkersCompPrompt/WorkersCompBlock.vue'
import ErrorPage from '../views/ErrorPage'
import PartialPaymentOptions from '../views/Payment/PartialPaymentOptions'
import TokenizePayment from '../views/Payment/TokenizePayment'
import PaymentAuthSuccess from '../views/Payment/PaymentAuthSuccess'
import PaymentSuccess from '../views/Payment/PaymentSuccess'
import ProviderThanks from '../views/ProviderThanks'
import SpecialAlerts from '../views/SpecialAlerts/SpecialAlerts'
import Payment from '../views/Payment'
import CameraAccess from '../views/Insurance/CameraAccess'
import PaymentPassIntro from '../views/PaymentPass/PaymentPassIntro'
import PaymentPassComplete from '../views/PaymentPass/PaymentPassComplete'
import Estimate from '../views/Estimate/Estimate'
import EstimateSignature from '../views/Estimate/EstimateSignature'
import PatientSurvey from '../views/Survey/PatientSurvey'
import PatientSurveyQuestion from '../views/Survey/PatientSurveyQuestion'
import PatientSurveyComplete from '../views/Survey/PatientSurveyComplete'
import LivingWill from '../views/Senior/LivingWill'
import PowerOfAttorney from '../views/Senior/PowerOfAttorney'
import OncologyMedicalHistory from '@/views/OncologyMedicalHistory/OncologyMedicalHistory.vue'
import VisitGuarantorDetail from '@/views/VisitGuarantor/VisitGuarantorDetail'
import UploadFile from '../views/Insurance/UploadFile'
import SpecialBenefitsConfirm from '../views/SpecialBenefits/SpecialBenefitsConfirm.vue'
import SpecialBenefitsEmployer from '../views/SpecialBenefits/SpecialBenefitsEmployer.vue'
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/patient-search'
    },
    {
      path: '/patient-search',
      name: 'patient-search',
      component: PatientSearch,
      props: (route) => ({ token: route.query.token }),
      beforeEnter: (to, from, next) => {
        if (store.isAuthenticated && store.hasVisit) {
          const nextRoute = store.nextRoute()

          // if we have a 'session', and a completed check in
          // we'll loop here infinitely, or until the js vm stops
          // the recursion
          if (to.path === '/patient-search' && nextRoute === '/') {
            store.logoutUser()
          }

          if (nextRoute) {
            next({ path: nextRoute, replace: true })
          } else {
            next()
          }
        } else if (store.isLoggingIn) {
          store.$on('user:authenticated', (authenticated) => {
            if (authenticated) {
              const nextRoute = store.nextRoute()
              if (nextRoute) {
                next({ path: nextRoute, replace: true })
                return
              }
            }

            next()
          })
        } else if (store.isAuthorizationInProgress()) {
          next({ name: 'authorize', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/patient-payment-search',
      name: 'patient-payment-search',
      component: PatientPaymentSearch,
      props: (route) => ({ token: route.query.token })
    },
    {
      path: '/patient-payment-search/:token',
      name: 'patient-payment-search-token',
      component: PatientPaymentSearch,
      props: true
    },
    {
      path: '/checkin/:token',
      name: 'checkin',
      component: PatientSearch,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.isAuthenticated && store.hasVisit) {
          const nextRoute = store.nextRoute()
          if (nextRoute) {
            next({ path: nextRoute, replace: true })
          } else {
            next()
          }
        } else if (store.isLoggingIn) {
          store.$once('user:authenticated', (authenticated) => {
            if (authenticated) {
              const nextRoute = store.nextRoute()
              if (nextRoute) {
                next({ path: nextRoute, replace: true })
                return
              }
            }

            next()
          })
        } else if (store.isAuthorizationInProgress()) {
          next({ name: 'authorize', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/payment-pass/:token',
      name: 'payment-pass-token',
      component: PaymentPassIntro,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.isLoggingIn) {
          store.$once('user:authenticated', (authenticated) => {
            if (authenticated) {
              const nextRoute = store.nextRoute()
              if (nextRoute) {
                next({ path: nextRoute, replace: true })
                return
              }
            }

            next()
          })
        } else if (store.isAuthorizationInProgress()) {
          next({ name: 'authorize', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/payment-pass',
      name: 'payment-pass',
      component: PaymentPassIntro,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.isLoggingIn) {
          store.$once('user:authenticated', (authenticated) => {
            if (authenticated) {
              const nextRoute = store.nextRoute()
              if (nextRoute) {
                next({ path: nextRoute, replace: true })
                return
              }
            }

            next()
          })
        } else if (store.isAuthorizationInProgress()) {
          next({ name: 'authorize', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/payment-pass-complete',
      name: 'payment-pass-complete',
      component: PaymentPassComplete
    },
    {
      path: '/connect/:token',
      name: 'connect',
      component: PatientSearch,
      props: (route) => ({ next: 'virtual-visit', token: route.params.token }),
      params: { next: 'virtual-visit' },
      beforeEnter: (to, from, next) => {
        if (store.isAuthenticated && store.hasVisit) {
          next({ path: 'virtual-visit', replace: true })
        } else if (store.isLoggingIn) {
          store.$once('user:authenticated', (authenticated) => {
            if (authenticated) {
              next({ path: 'virtual-visit', replace: true })
              return
            }

            next()
          })
        } else if (store.isAuthorizationInProgress()) {
          next({ name: 'authorize', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/authorize',
      name: 'authorize',
      component: Authorize,
      props: true,
      beforeEnter: (to, from, next) => {
        if (store.isAuthenticated && store.hasVisit) {
          const nextRoute = store.nextRoute()
          if (nextRoute) {
            next({ path: nextRoute, replace: true })
          } else {
            next()
          }
        } else if (store.isLoggingIn) {
          store.$once('user:authenticated', (authenticated) => {
            if (authenticated) {
              const nextRoute = store.nextRoute()
              if (nextRoute) {
                next({ path: nextRoute, replace: true })
                return
              }
            }

            next()
          })
        } else if (store.isAuthorizationInProgress()) {
          next()
        } else {
          next({ name: 'patient-search', replace: true })
        }
      }
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: (to, from, next) => {
        store.clearAuthorizationState()
        store.logoutUser()
        next({ path: '/patient-search', replace: true })
      }
    },
    {
      path: '/visit-error',
      name: 'visit-error',
      component: VisitBlocked
    },
    {
      path: '/camera-access',
      name: 'camera-access',
      component: CameraAccess,
      props: true
    },
    {
      path: '/visit-check',
      name: 'visit-check',
      component: Blocker
    },
    {
      path: '/demographics',
      name: 'demographics',
      component: Demographics
    },
    {
      path: '/demographics-race',
      name: 'demographics-race',
      component: DemographicsRace
    },
    {
      path: '/demographics-additional',
      name: 'demographics-additional',
      component: DemographicsAdditional
    },
    {
      path: '/demographics-height-weight',
      name: 'demographics-height-weight',
      component: DemographicsHeightWeight
    },
    {
      path: '/demographics-phone',
      name: 'demographics-phone',
      component: DemographicsPhone
    },
    {
      path: '/patient-photo',
      name: 'patient-photo',
      component: PatientPhoto,
      beforeEnter: (to, from, next) => {
        const parser = new UAParser()
        const deviceType = parser.getResult().device.type
        if (!['mobile', 'tablet'].includes(deviceType)) {
          store.setModuleStatus('PatientPhoto', true, () => {
            const nextPath = store.nextRoute()
            if (nextPath === from.path) {
              next(false)
            } else {
              next(store.nextRoute())
            }
          })
        } else {
          api.get('/Patient/Photo').then(response => {
            if (response.data.url) {
              store.setModuleStatus('PatientPhoto', true, () => {
                const nextPath = store.nextRoute()
                if (nextPath === from.path) {
                  next(false)
                } else {
                  next(store.nextRoute())
                }
              })
            } else {
              next()
            }
          })
        }
      }
    },
    {
      path: '/visit-photo',
      name: 'visit-photo',
      component: VisitPhoto,
      beforeEnter: (to, from, next) => {
        const parser = new UAParser()
        const deviceType = parser.getResult().device.type
        if (!['mobile', 'tablet'].includes(deviceType)) {
          store.setModuleStatus('VisitPhoto', true, () => {
            const nextPath = store.nextRoute()
            if (nextPath === from.path) {
              next(false)
            } else {
              next(store.nextRoute())
            }
          })
        } else {
          api.get('/Patient/VisitPhotos').then(response => {
            if (response.data.urls.length > 0) {
              store.setModuleStatus('VisitPhoto', true, () => {
                const nextPath = store.nextRoute()
                if (nextPath === from.path) {
                  next(false)
                } else {
                  next(store.nextRoute())
                }
              })
            } else {
              next()
            }
          })
        }
      }
    },
    {
      path: '/address',
      name: 'address',
      component: Address
    },
    {
      path: '/visit-payment',
      name: 'visit-payment',
      component: VisitPayment,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/Visit/Payment').then(response => {
          if (response.data.hasPaid) {
            store.setModuleStatus('Payment', true, () => {
              const nextPath = store.nextRoute()
              if (nextPath === from.path) {
                next(false)
              } else {
                next(store.nextRoute())
              }
            })
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('Payment', 'LoadError', error)
            Vue.$log.error('Error fetching payment module data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/flu-vaccine',
      name: 'flu-vaccine',
      component: FluVaccine
    },
    {
      path: '/visit-credit-card',
      name: 'visit-credit-card',
      props: true,
      component: VisitCreditCardPayment
    },
    {
      path: '/insurance/:priority',
      name: 'insurance',
      component: Insurance,
      props: true
    },
    {
      path: '/card-capture',
      name: 'card-capture',
      component: CardCapture,
      props: true
    },
    {
      path: '/card-capture-verification',
      name: 'card-capture-verification',
      component: CardCaptureVerification,
      props: true
    },
    {
      path: '/upload-file',
      name: 'upload-file',
      component: UploadFile,
      props: true
    },
    {
      path: '/payor-select',
      name: 'payor-select',
      component: PayorSelect
    },
    {
      path: '/payor-search',
      name: 'payor-search',
      component: PayorSearch
    },
    {
      path: '/policy-number',
      name: 'policy-number',
      component: PolicyNumber,
      beforeEnter: (to, from, next) => {
        if (!store.insurance.insuranceCompanyShortCode) {
          next({ name: 'payor-select', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/appointment-confirmation',
      name: 'appointment-confirmation',
      component: AppointmentConfirmation
    },
    {
      path: '/provider-thanks',
      name: 'provider-thanks',
      component: ProviderThanks
    },
    {
      path: '/family-history',
      name: 'family-history',
      component: FamilyHistory
    },
    {
      path: '/allergies',
      name: 'allergies',
      component: Allergies
    },
    {
      path: '/allergies-search',
      name: 'allergies-search',
      component: AllergiesSearch,
      props: true
    },
    {
      path: '/medical-history',
      name: 'medical-history',
      component: MedicalHistory
    },
    {
      path: '/medications',
      name: 'medications',
      component: Medications
    },
    {
      path: '/medication-search',
      name: 'medication-search',
      component: MedicationSearch
    },
    {
      path: '/payment/:token',
      name: 'payment-token',
      component: Payment,
      props: true
    },
    {
      path: '/payment',
      name: 'payment',
      component: Payment,
      props: true
    },
    {
      path: '/benefits',
      name: 'benefits',
      component: Benefits
    },
    {
      path: '/social-history',
      name: 'social-history',
      component: SocialHistory
    },
    {
      path: '/living-setting',
      name: 'living-setting',
      component: LivingSetting
    },
    {
      path: '/exercise',
      name: 'exercise',
      component: Exercise
    },
    {
      path: '/daily-activity',
      name: 'daily-activity',
      component: DailyActivity
    },
    {
      path: '/tobacco-usage',
      name: 'tobacco-usage',
      component: TobaccoUsage
    },
    {
      path: '/alcohol-usage',
      name: 'alcohol-usage',
      component: AlcoholUsage
    },
    {
      path: '/drug-usage',
      name: 'drug-usage',
      component: DrugUsage
    },
    {
      path: '/precheck-complete',
      name: 'precheck-complete',
      component: PreCheckComplete
    },
    {
      path: '/body-regions',
      name: 'body-regions',
      component: BodyRegions
    },
    {
      path: '/body-parts',
      name: 'body-parts',
      component: BodyParts,
      props: true
    },
    {
      path: '/primary-body-part',
      name: 'primary-body-part',
      component: PrimaryBodyPart
    },
    {
      path: '/ortho-pain-type',
      name: 'ortho-pain-type',
      component: OrthoPainType
    },
    {
      path: '/ortho-pain-cause',
      name: 'ortho-pain-cause',
      component: OrthoPainCause
    },
    {
      path: '/ortho-pain-characteristics',
      name: 'ortho-pain-characteristics',
      component: OrthoPainCharacteristics
    },
    {
      path: '/ortho-lawyer',
      name: 'ortho-lawyer',
      component: OrthoLawyer
    },
    {
      path: '/ortho-splint',
      name: 'ortho-splint',
      component: OrthoSplint
    },
    {
      path: '/ortho-severity',
      name: 'ortho-severity',
      component: OrthoSeverity,
      props: true
    },
    {
      path: '/ortho-better',
      name: 'ortho-better',
      component: OrthoBetter
    },
    {
      path: '/ortho-worse',
      name: 'ortho-worse',
      component: OrthoWorse
    },
    {
      path: '/ortho-onset',
      name: 'ortho-onset',
      component: OrthoOnset
    },
    {
      path: '/ortho-onset-date',
      name: 'ortho-onset-date',
      component: OrthoOnsetDate
    },
    {
      path: '/ortho-details',
      name: 'ortho-details',
      component: OrthoDetails
    },
    {
      path: '/ortho-recurring',
      name: 'ortho-recurring',
      component: OrthoRecurring
    },
    {
      path: '/ortho-shoulder-neck',
      name: 'ortho-shoulder-neck',
      component: OrthoShoulderNeck
    },
    {
      path: '/ortho-shoulder-elbow',
      name: 'ortho-shoulder-elbow',
      component: OrthoShoulderElbow
    },
    {
      path: '/ortho-shoulder-tingling',
      name: 'ortho-shoulder-tingling',
      component: OrthoShoulderTingling
    },
    {
      path: '/ortho-shoulder-dislocation',
      name: 'ortho-shoulder-dislocation',
      component: OrthoShoulderDislocation
    },
    {
      path: '/ortho-shoulder-difficulty-reaching',
      name: 'ortho-shoulder-difficulty-reaching',
      component: OrthoShoulderDifficultyReaching
    },
    {
      path: '/ortho-shoulder-cortisone',
      name: 'ortho-shoulder-cortisone',
      component: OrthoShoulderCortisone
    },
    {
      path: '/ortho-shoulder-pain-wake',
      name: 'ortho-shoulder-pain-wake',
      component: OrthoShoulderPainWake
    },
    {
      path: '/ortho-foot-orthotics',
      name: 'ortho-foot-orthotics',
      component: OrthoFootOrthotics
    },
    {
      path: '/ortho-foot-size',
      name: 'ortho-foot-size',
      component: OrthoFootSize
    },
    {
      path: '/ortho-knee',
      name: 'ortho-knee',
      component: OrthoKnee
    },
    {
      path: '/ortho-knee-distance',
      name: 'ortho-knee-distance',
      component: OrthoKneeDistance
    },
    {
      path: '/ortho-knee-pain',
      name: 'ortho-knee-pain',
      component: OrthoKneePain
    },
    {
      path: '/ortho-back-leg-pain',
      name: 'ortho-back-leg-pain',
      component: OrthoBackLegPain
    },
    {
      path: '/ortho-back-neck-pain',
      name: 'ortho-back-neck-pain',
      component: OrthoBackNeckPain
    },
    {
      path: '/ortho-back-lose-control',
      name: 'ortho-back-lose-control',
      component: OrthoBackLoseControl
    },
    {
      path: '/ortho-prior-treatments',
      name: 'ortho-prior-treatments',
      component: OrthoPriorTreatments
    },
    {
      path: '/ortho-prior-treatment-period',
      name: 'ortho-prior-treatment-period',
      component: OrthoPriorTreatmentPeriod,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.priorTreatmentOption) {
          next({ name: 'ortho-prior-treatments', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/ortho-prior-treatment-provider',
      name: 'ortho-prior-treatment-provider',
      component: OrthoPriorTreatmentProvider,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.priorTreatmentOption) {
          next({ name: 'ortho-prior-treatments', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/pharmacy-confirmation',
      name: 'pharmacy-confirmation',
      component: PharmacyConfirmation,
      props: true
    },
    {
      path: '/pharmacy-search',
      name: 'pharmacy-search',
      component: PharmacySearch,
      props: true
    },
    {
      path: '/pharmacy-select',
      name: 'pharmacy-select',
      component: PharmacySelect
    },
    {
      path: '/review-of-systems',
      name: 'review-of-systems',
      component: ReviewOfSystems
    },
    {
      path: '/surgeries',
      name: 'surgeries',
      component: Surgeries
    },
    {
      path: '/surgery-categories',
      name: 'surgery-categories',
      component: SurgeryCategories
    },
    {
      path: '/surgery-other',
      name: 'surgery-other',
      component: SurgeryOther
    },
    {
      path: '/surgery-list',
      name: 'surgery-list',
      component: SurgeryList,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.surgeryCategory) {
          next({ name: 'surgery-categories', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/surgery-details',
      name: 'surgery-details',
      component: SurgeryDetails,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.surgery) {
          next({ name: 'surgeries', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/patient-survey/:token',
      name: 'patient-survey-token',
      component: PatientSurvey,
      props: true
    },
    {
      path: '/patient-survey',
      name: 'patient-survey',
      component: PatientSurvey,
      props: true
    },
    {
      path: '/patient-survey-question',
      name: 'patient-survey-question',
      component: PatientSurveyQuestion,
      props: true
    },
    {
      path: '/patient-survey-complete',
      name: 'patient-survey-complete',
      component: PatientSurveyComplete,
      props: true
    },
    {
      path: '/how-did-you-hear-about-us',
      name: 'how-did-you-hear-about-us',
      component: HowDidYouHearAboutUs
    },
    {
      path: '/how-did-you-hear-about-us-referral',
      name: 'how-did-you-hear-about-us-referral',
      component: HowDidYouHearAboutUsReferral
    },
    {
      path: '/referring-physician',
      name: 'referring-physician',
      component: ReferringPhysician
    },
    {
      path: '/primary-physician',
      name: 'primary-physician',
      component: PrimaryPhysician
    },
    {
      path: '/spouse',
      name: 'spouse',
      component: Spouse,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/Demographics').then(response => {
          if (response.data.maritalStatusId !== 2) { // patient is not married
            next('/emergency-contact')
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('MarriageStatus', 'LoadError', error)
            Vue.$log.error('Error fetching marriage status module data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/emergency-contact',
      name: 'emergency-contact',
      component: EmergencyContact
    },
    {
      path: '/visit-guarantor',
      name: 'visit-guarantor',
      component: VisitGuarantor
    },
    {
      path: '/visit-guarantor-detail',
      name: 'visit-guarantor-detail',
      component: VisitGuarantorDetail
    },
    {
      path: '/visit-guarantor-address',
      name: 'visit-guarantor-address',
      component: VisitGuarantorAddress
    },
    {
      path: '/consent',
      name: 'consent',
      component: Consent
    },
    {
      path: '/consent-item-detail',
      name: 'consent-item-detail',
      component: ConsentItemDetail,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.item) {
          next({ name: 'consent', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/consent-signature',
      name: 'consent-signature',
      component: ConsentSignature
    },
    {
      path: '/drivers-license-capture',
      name: 'drivers-license-capture',
      component: DriversLicenseCapture,
      beforeEnter: (to, from, next) => {
        const parser = new UAParser()
        const deviceType = parser.getResult().device.type
        if (['mobile', 'tablet'].includes(deviceType)) {
          api.get('/Patient/DLFrontPhoto').then(response => {
            if (response.data.imageOnFile === true) {
              store.setModuleStatus('DLFront', true, () => {
                const nextPath = store.nextRoute()
                if (nextPath === from.path) {
                  next(false)
                } else {
                  next(store.nextRoute())
                }
              })
            } else {
              next()
            }
          }).catch(error => {
            if (!store.canHandleNetworkError(error)) {
              store.logErrorResponse('DLFrontPhoto', 'LoadError', error)
              Vue.$log.error('Error fetching drivers license image: ' + error.message, error)
              next()
            }
          })
        } else {
          // Skip for desktop devices
          store.setModuleStatus('DLFront', true, () => {
            const nextPath = store.nextRoute()
            if (nextPath === from.path) {
              next(false)
            } else {
              next(store.nextRoute())
            }
          })
        }
      }
    },
    {
      path: '/falls',
      name: 'falls',
      component: Falls,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/Senior').then(response => {
          if (response.data.isSenior === false) {
            store.setModuleStatus('Senior', true, () => {
              const nextPath = store.nextRoute()
              if (nextPath === from.path) {
                next(false)
              } else {
                next(store.nextRoute())
              }
            })
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('Senior', 'LoadError', error)
            Vue.$log.error('Error fetching senior module data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/pneumonia-vaccine',
      name: 'pneumonia-vaccine',
      component: PneumoniaVaccine,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/Senior').then(response => {
          if (response.data.isSenior === false) {
            store.setModuleStatus('Senior', true, () => {
              const nextPath = store.nextRoute()
              if (nextPath === from.path) {
                next(false)
              } else {
                next(store.nextRoute())
              }
            })
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('Senior', 'LoadError', error)
            Vue.$log.error('Error fetching senior module data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/living-will',
      name: 'living-will',
      component: LivingWill,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/Senior').then(response => {
          if (response.data.isSenior === false) {
            store.setModuleStatus('Senior', true, () => {
              const nextPath = store.nextRoute()
              if (nextPath === from.path) {
                next(false)
              } else {
                next(store.nextRoute())
              }
            })
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('Senior', 'LoadError', error)
            Vue.$log.error('Error fetching senior module data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/power-of-attorney',
      name: 'power-of-attorney',
      component: PowerOfAttorney,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/Senior').then(response => {
          if (response.data.isSenior === false) {
            store.setModuleStatus('Senior', true, () => {
              const nextPath = store.nextRoute()
              if (nextPath === from.path) {
                next(false)
              } else {
                next(store.nextRoute())
              }
            })
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('Senior', 'LoadError', error)
            Vue.$log.error('Error fetching senior module data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/special-benefits-employer',
      name: 'special-benefits-employer',
      component: SpecialBenefitsEmployer,
      beforeEnter: (to, from, next) => {
        api.get('/Patient/SpecialBenefits/').then(response => {
          if (!response.data.benefitsDescription) {
            store.setModuleStatus('SpecialBenefits', true, () => {
              const nextPath = store.nextRoute()
              if (nextPath === from.path) {
                next(false)
              } else {
                next(store.nextRoute())
              }
            })
          } else {
            next()
          }
        }).catch(error => {
          if (!store.canHandleNetworkError(error)) {
            store.logErrorResponse('SpecialBenefits', 'LoadError', error)
            Vue.$log.error('Error fetching special benefits data: ' + error.message, error)
            next()
          }
        })
      }
    },
    {
      path: '/special-benefits-confirm',
      name: 'special-benefits-confirm',
      component: SpecialBenefitsConfirm
    },
    {
      path: '/employment',
      name: 'employment',
      component: Employment
    },
    {
      path: '/occupation',
      name: 'occupation',
      component: Occupation
    },
    {
      path: '/employer',
      name: 'employer',
      component: Employer
    },
    {
      path: '/employer-list',
      name: 'employer-list',
      component: EmployerList
    },
    {
      path: '/employer-confirm',
      name: 'employer-confirm',
      component: EmployerConfirm
    },
    {
      path: '/hipaa-contacts',
      name: 'hipaa-contacts',
      component: HIPAAContacts
    },
    {
      path: '/hipaa-contact',
      name: 'hipaa-contact',
      component: HIPAAContact,
      props: true
    },
    {
      path: '/conservative-therapies',
      name: 'conservative-therapies',
      component: ConservativeTherapies,
      props: true
    },
    {
      path: '/conservative-therapy',
      name: 'conservative-therapy',
      component: ConservativeTherapyDetails,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.params.therapy) {
          next({ name: 'conservative-therapies', replace: true })
        } else {
          next()
        }
      }
    },
    {
      path: '/conservative-therapy-medication',
      name: 'conservative-therapy-medication',
      component: TherapyMedicationSearch,
      props: true
    },
    {
      path: '/virtual-visit',
      name: 'virtual-visit',
      component: VirtualVisit
    },
    {
      path: '/workers-comp',
      name: 'workers-comp',
      component: WorkersCompPrompt
    },
    {
      path: '/workers-comp-block',
      name: 'workers-comp-block',
      component: WorkersCompBlock
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage
    },
    {
      path: '/partial-payment-options',
      name: 'partial-payment-options',
      props: true,
      component: PartialPaymentOptions
    },
    {
      path: '/tokenize-payment',
      name: 'tokenize-payment',
      component: TokenizePayment,
      props: true
    },
    {
      path: '/payment-auth-success',
      name: 'payment-auth-success',
      component: PaymentAuthSuccess,
      props: true
    },
    {
      path: '/payment-success',
      name: 'payment-success',
      component: PaymentSuccess,
      props: true
    },
    {
      path: '/special-alerts',
      name: 'special-alerts',
      component: SpecialAlerts
    },
    {
      path: '/estimate-signature/',
      name: 'estimate-signature',
      component: EstimateSignature,
      props: true
    },
    {
      path: '/estimate/:token',
      name: 'estimate',
      component: Estimate,
      props: true
    },
    {
      path: '/oncology-medical-history',
      name: 'oncology-medical-history',
      component: OncologyMedicalHistory
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.isUnprotectedRoute = function (to) {
  if (to.name === 'logout' || to.name === 'patient-search' || to.name === 'patient-payment-search' || to.name === 'patient-payment-search-token' || to.name === 'authorize' || to.name === 'payment-token' || to.name === 'payment' || to.name === 'checkin' || to.name === 'connect' || to.name === 'error' || to.name === 'payment-auth-success' || to.name === 'payment-success' || to.name === 'payment-pass-token' || to.name === 'payment-pass' || to.name === 'estimate' || to.name === 'patient-survey-token' || to.name === 'patient-survey-question' || to.name === 'patient-survey-complete' || to.name === 'workers-comp-block') {
    return true
  }
  return false
}

router.beforeEach((to, from, next) => {
  Vue.$log.debug('Checking route ' + from.path + ' -> ' + to.path)

  if (router.isUnprotectedRoute(to)) {
    Vue.$log.debug('Allowing route to unprotected page')
    next()
    return
  }
  if (store.isLoggingIn) {
    Vue.$log.debug('Store isLoggingIn is true')
    store.$on('user:authenticated', (authenticated) => {
      if (authenticated) {
        Vue.$log.debug('Allowing route in auth event')
        next()
      } else {
        Vue.$log.debug('Redirecting to patient search in auth event')
        next({ path: '/patient-search', replace: true })
      }
    })
  } else {
    if (store.isAuthenticated !== true) {
      Vue.$log.debug('Redirecting to patient search due to unauthenticated user')
      next({ path: '/patient-search', replace: true })
    } else {
      Vue.$log.debug('Allowing route due to authenticted user')
      next()
    }
  }
})

export default router
