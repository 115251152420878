<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">Start typing the name of a medication to search.</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div v-if="generalErrorMessage" class="row">
            <div class="col-xl-3"></div>
            <div class="col">
                <div class="alert alert-danger" role="alert">
                    {{ generalErrorMessage }}
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <b-form-group label="Medication" :state="stateForField('search')" :invalid-feedback="errorForField('search')">
                    <b-input-group>
                        <b-form-input autofocus autocomplete="off" autocapitalize="none" autocorrect="off" size="lg" :state="stateForField('search')" v-model="searchTerm"></b-form-input>
                        <b-input-group-append>
                            <b-button @click.prevent="performSearch" variant="primary"><font-awesome-icon icon="search"/></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div class="col-xl-3"></div>
        </div>
        <div class="row">
            <div class="col-xl-3"></div>
            <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-center">
                    <div v-if="searchTerm" @click.prevent="selectMedication(searchTerm)" class="result text-center">
                        <h1 class="text-center">{{ this.$store.uppercaseFirstLetters(searchTerm) }}</h1>
                    </div>
                    <div @click.prevent="selectMedication(medication)" v-for="(medication,index) in searchResults" :key="index" class="result text-center">
                        <font-awesome-icon class="mr-2 blue-text" v-show="isAdding(medication)" size="lg" icon="spinner" pulse/><h1 class="text-center">{{ medication }}</h1>
                    </div>
                </div>
            </div>
            <div class="col-xl-3"></div>
        </div>
    </div>
</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
  import _ from 'lodash'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'

  export default {
    name: 'TherapyMedicationSearch',

    components: { BButton, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend },

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    data () {
      return {
        searchResults: {},
        searchTerm: '',
        isSaving: false,
        selectedMedication: '',
        forms: [],
        selectedRawMedication: ''
      }
    },

    watch: {
      searchTerm: _.debounce(function (newValue, oldValue) { if (newValue !== oldValue) this.performSearch() }, 800)
    },

    computed: {
    },

    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      performSearch () {
        if (!this.searchTerm || this.searchTerm.length < 2) {
          return
        }

        api.get('/Patient/Medications/Search?query=' + this.searchTerm).then(response => {
          this.trackEvent(this.$options.name, 'Search', 'ResponseTime', response.config.requestDuration)
          this.searchResults = response.data.map((aResult) => {
            return this.$store.uppercaseFirstLetters(aResult)
          })
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'SearchError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      isAdding (medication) {
        if (medication === this.selectedRawMedication) {
          return true
        }

        return false
      },

      selectMedication (medication) {
        this.selectedRawMedication = medication
        this.isSaving = true
        medication = this.$store.uppercaseFirstLetters(medication)
        const newModel = {
          name: null,
          medicationName: medication,
          otherText: null,
          startDate: null,
          startDateMonth: null,
          startDateYear: null,
          endDate: null,
          endDateMonth: null,
          endDateYear: null,
          relief: 5
        }
        this.$router.push({ name: 'conservative-therapy', params: { therapy: newModel } })
      }
    }
  }
</script>

<style scoped>

    .result {
        box-shadow: 2px 2px 5px darkgray;
        margin: .25em;
        border-radius: 5px;
        background-color: white;
        width: 100%;
        border: 2px solid transparent;
    }

    .result h1 {
        font-size: 24px;
        color: rgba(23,108,198,1.0);
        font-weight: bold;
        padding: .25em;
        display: inline-block;
    }

    @media (min-width: 1061px) {
        .result:HOVER {
            cursor: pointer;
            border: 2px solid rgb(255, 79, 0);
        }
    }
</style>
