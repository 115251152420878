<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h1 class="header">{{ item.detailHeader }}</h1>
                <h2 class="subheader"></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col">
                <p v-if="item.detailTextIsMarkdown" class="detail-text" v-html="item.detailTextHtml"></p>
                <p v-if="!item.detailTextIsMarkdown" class="detail-text">{{ item.detailText }}</p>
            </div>
            <div class="col-md-1"></div>
        </div>
        <div class="row">
            <div class="col text-center mt-2">
                <button type="button" @click.prevent="goBack()" class="btn btn-primary btn-lg mx-auto">Back</button>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ConsentItemDetail',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    methods: {
      goBack () {
        this.$router.push('/consent')
      }
    }
  }
</script>

<style scoped>
.detail-text {
    font-size: 20px;
}
</style>
